import React, { FormEvent, ReactElement } from 'react';
import { FormValidityState } from '../../lib/types';
import NLIForm from '../NLIForm/NLIForm';
import styles from './SoloNLIForm.module.scss';

/* Solo Not Logged In Form */

interface SoloNLIFormProps {
  title: string,
  eyebrow: string,
  contentWrapClass?: string,
  contentClass?: string,
  formWrapClass?: string,
  formClass?: string,
  onSubmit?: (e: FormEvent) => void,
  setter?: React.Dispatch<React.SetStateAction<FormValidityState>>,
  defaultState?: FormValidityState,
  children?: ReactElement | ReactElement[];
}

const SoloNLIForm = (props: SoloNLIFormProps): JSX.Element => {
  
  return (
    <div className={`content-wrap ${props.contentWrapClass || ''}`}>
        <div className={`content ${styles.content} ${props.contentClass || ''}`}>
          <NLIForm 
            title={props.title}
            eyebrow={props.eyebrow}
            onSubmit={props.onSubmit}
            setter={props.setter}
            defaultState={props.defaultState}
            formWrapClass={props.formWrapClass || ''}
            formClass={props.formClass || ''}
          >
            {props.children}
          </NLIForm>
        </div>
      </div>
  )
};

export default SoloNLIForm;