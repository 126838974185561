import React, { ChangeEvent, useEffect, useState } from 'react';
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import styles from './CheckoutComponent.module.scss';
import { STRIPE_API_KEY } from '../../lib/constants';
import { useCreditPackages, useSubject } from '../../lib/hooks';
import CreditsService from '../../lib/services/credits.service';
import { CreditPackageData, PaymentData } from '../../lib/types';
import CheckoutForm from './CheckoutForm';
import { purchaseErrorMsg$ } from '../../pages/Purchase/state';
import Input from '../Input';
import Select from '../Select';
import SVGIcon from '../SVGIcon/SVGIcon';
import { useSearchParams } from 'react-router-dom';
import Modal from '../Modal/Modal';
import { userData$ } from '../../lib/state';

const stripePromise = loadStripe(STRIPE_API_KEY as string);

const CheckoutComponent = (props: {returnUrl: string}): JSX.Element => {
  const [price, setPrice] = useState<string>()
  const {singlePackage, multiPackage} = useCreditPackages();
  const [searchParams, setSearchParams] = useSearchParams();
  const packageType = searchParams.get('packageType');
  const [curPackage, setCurPackage] = useState<CreditPackageData>();
  const [options, setOptions] = useState<StripeElementsOptions>();
  const [couponValid, setCouponValid] = useState(false);
  const [disable, setDisable] = useState(false);
  const [coupon, setCoupon] = useState<string>();
  const [couponApplied, setCouponApplied] = useState(false);
  const [paymentData, setPaymentData] = useState<PaymentData>();
  const errorMessage = useSubject(purchaseErrorMsg$);
  const userData = useSubject(userData$);

  const onTrash = () => {
    searchParams.delete('packageType');
    setSearchParams(searchParams);
    setCurPackage(undefined);
    setOptions(undefined);
    setPrice(undefined);
    setCouponApplied(false);
  }

  const onSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.isTrusted) {
      searchParams.append('packageType', e.target.value);
      setSearchParams(searchParams);
    }
  }

  const covertToDecimal = (amount: number): string => {
    return (amount/100).toFixed(2).toString();
  }

  const onPaymentData = (dta: PaymentData) => {
    setPaymentData(dta);
    if (dta.client_secret) setOptions({clientSecret: dta.client_secret, appearance: {theme: 'stripe'}});
    setPrice(covertToDecimal(dta.amount));
  }

  const onCouponEnter = (e: ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      setCoupon(e.target.value);
      setCouponValid(e.target.value !== '' && e.target.validity.valid);
    }, 500);
  }

  const onApplyCoupon = () => {
    if (coupon && paymentData) {
      setDisable(true);
      CreditsService.updatePayment(paymentData.payment_intent_id, coupon || '').subscribe({
        next: (dta) => {
          setCouponApplied(true);
          onPaymentData(dta);
          setDisable(false);
          setCoupon(undefined);
          setCouponValid(false);
        },
        error: (err) => purchaseErrorMsg$.next(err.response.promotion_code || err.response.detail)
      })
    }
  }

  const onCloseErrorModal = () => {
    purchaseErrorMsg$.next(null);
    setCouponApplied(false);
    if (curPackage) {
      const pkg = curPackage;
      setCurPackage(undefined);
      setOptions(undefined);
      setTimeout(() => {
        setCurPackage(pkg);
      }, 200);
    }
  }

  useEffect(() => {
    if (curPackage) {
      setDisable(true);
      CreditsService.createPayment(curPackage.id, userData?.is_amspa_referral || false).subscribe({
        next: onPaymentData,
        error: (err) => purchaseErrorMsg$.next(err.response.product_id || err.response.detail),
        complete: () => setDisable(false)
      });
    }
  }, [curPackage]);

  useEffect(() => {
    if (packageType && singlePackage && multiPackage) {
      if (packageType === 'single') setCurPackage(singlePackage);
      else if (packageType === 'multiple') setCurPackage(multiPackage);
    } else if (!packageType) {
      if (paymentData) {
        CreditsService.cancelPayment(paymentData.payment_intent_id).subscribe({
          error: (err) => purchaseErrorMsg$.next(err.response.detail)
        });
        setPaymentData(undefined);
      }
      if (curPackage) setCurPackage(undefined);
    }
  }, [packageType, singlePackage, multiPackage])
  
  return (
    <div className={`content ${styles.content}`}>

      <div className={styles.orderWrap}>
        <div className={styles.header}>
          <h1>Order Summary</h1>
          <button className={styles.trashBtn} onClick={onTrash}>
            <SVGIcon id='trash' />
          </button>
        </div>
        
        <div className='form-like' data-ready={!!price || !packageType}>
          {
            curPackage && price &&
            <>
              <Input key={price} label={`1x ${curPackage.name}`} 
                attributes={{id: 'item-price', type: 'text', defaultValue: `$${price}`, disabled: true}} />

                <div className={styles.couponWrap} data-valid={couponValid}>
                  <Input key={price}
                    attributes={{id: 'coupon-code', type: 'text',
                    placeholder: couponApplied ? 'Coupon Applied' : 'Add coupon code',
                    onChange: onCouponEnter, minLength: 5}} />
                  <button type='button' className='btn' disabled={!couponValid || disable} 
                    onClick={onApplyCoupon}>
                      {
                        !couponApplied && 'Apply'
                      }
                      {
                        couponApplied && 'Replace Coupon'
                      }
                  </button>
                </div>
            </>
          }
          {
            !packageType && singlePackage && multiPackage &&
            <Select options={[
              {value: 'default', label: 'Select a Credit Package', disabled: true},
              {value: 'single', label: `1x ${singlePackage.name} $${covertToDecimal(singlePackage.default_price.unit_amount)}`},
              {value: 'multiple', label: `1x ${multiPackage.name} $${covertToDecimal(multiPackage.default_price.unit_amount)}`}
            ]} attributes={{id: 'select-package', defaultValue: 'default', onChange: onSelect}} />
          }

          <Input label='Subtotal' className={styles.subtotal}
            attributes={{id: 'subtotal', type: 'text', defaultValue: price ? `$${price}` : '', disabled: true}} />

          <div className={styles.total}>
            <span>Total</span> 
            <span>{price ? `$${price}` : ''}</span>
          </div>
        </div>
      </div>

      <div className={styles.paymentWrap}>
        <h2>Payment Method</h2>

        {
          options && curPackage && price && paymentData &&
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm 
              creditPackage={curPackage} 
              price={price} 
              paymentData={paymentData}
              disable={disable}
              returnUrl={props.returnUrl}
            />
          </Elements>
        }
      </div>

      <Modal show={!!errorMessage} onCloseBtn={onCloseErrorModal}>
        <p className='eyebrow error' style={{width: '100%', textAlign: 'center', fontSize: '22px'}}>ERROR!</p>
        <p className='error'>{errorMessage}</p>
      </Modal>
    </div>
  )
};

export default CheckoutComponent;
