import React, { useEffect, useState } from 'react';
import { IconColor } from './types';
import './SVGIcon.scss';

const SVGIcon = (props: {id: string, color?: IconColor, rotate?: number}): JSX.Element => {
  const [ origin, setOrigin ] = useState<string>('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setOrigin(window.location.origin);
    }
  }, [])
  
  return (
    <svg className={`svg-icon svg-icon--${props.id} ${props.color || 'black'}`}>
      <use 
        xlinkHref={`${origin}/images/store.svg#${props.id}`} 
        transform={`rotate(${props.rotate || 0})`}
        transform-origin='center'
      ></use>
    </svg>
  )
};

export default SVGIcon;