import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { debounceTime, delay, fromEvent, map, of, switchMap, tap } from 'rxjs';
import { useObservable } from '../../lib/hooks';
import SVGIcon from '../SVGIcon/SVGIcon';
import Tag from '../Tag/Tag';
import styles from './SearchInput.module.scss';

const SearchInput = (props: {isWidget?: boolean, alwaysCollapsible?: boolean, revealCallback?: (b: boolean) => void }): JSX.Element => {
  const [reveal, setReveal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search') || undefined;
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLInputElement | null>(null);
  const [storedValues, setStoredValues] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>();
  const [inputValue] = useObservable(
    of(null).pipe(
      delay(40),
      switchMap(() =>
        fromEvent(ref.current as HTMLInputElement, 'keyup').pipe(
          map(e => (e.target as HTMLInputElement).value),
          tap(v => setActive(v !== '')),
          debounceTime(1000)
        )
      )
    )
  );

  const onEnter = () => {
    const val = ref.current?.value || '';
    if (val !== '') {
      const arr = [...storedValues, ...val.split(',')]
      setStoredValues([...new Set(arr)]);
      (ref.current as HTMLInputElement).value = '';
    }
  }

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onEnter();
    }
  }

  const onRemoveValue = (value: string) => {
    const arr = [...storedValues].filter(v => v !== value);
    setStoredValues(arr);
  }

  useEffect(() => {
    if (search){
      setStoredValues(search.split(','));
      (ref.current as HTMLInputElement).value = '';
    } 
  }, [])

  useEffect(() => {
    if (props.revealCallback) props.revealCallback(reveal);
  }, [reveal])

  useEffect(() => {
    setSearchValue(storedValues.join());
  }, [storedValues])

  useEffect(() => {
    setSearchValue([inputValue, ...storedValues].filter(v => v!== '').join())
  }, [inputValue])

  useEffect(() => {
    if (searchValue && searchValue !== '') {
      searchParams.set('search', searchValue);
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
    } else if (searchParams.has('search')) {
      searchParams.delete('search');
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
    }
  }, [searchValue])

  
  return (
    <div className={styles.outer}>
      <div className={styles.container} data-reveal={reveal} data-is-widget={props.isWidget || false} 
        data-always-collapsible={props.alwaysCollapsible || false}>
        <button className={styles.icon} onClick={() => setReveal(!reveal)} data-active={active}>
          <SVGIcon id='search' />
        </button>

        <input ref={ref} type='search' defaultValue={search} placeholder='Search for job title, type...' 
              data-active={active} onBlur={() => setActive(false)} onKeyDown={onKeyDown}/>

        <button className={styles.icon} onClick={onEnter} data-active={active}>
          <SVGIcon id='return' />
        </button>
      </div>
      {
        storedValues.length !== 0 &&
        <div className={styles.tagsContainer}>
          {
            storedValues.map((v,i) => <Tag key={i} text={v} onClick={onRemoveValue} onClickArgs={[v]} isWidget={props.isWidget || false}/>)
          }
        </div>
      }
    </div>
  )
};

export default SearchInput;