import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../lib/app-routes';
import { useJobChoiceDetails } from '../../lib/hooks';
import { ApplicantData } from '../../lib/types';
import { applicant$ } from '../../pages/Dashboard/state';
import JobDetail from '../JobDetail/JobDetail';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './ApplicantCard.module.scss';

const ApplicantCard = (props: {data: ApplicantData, isReview?: boolean}): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {jobCategoryOptions} = useJobChoiceDetails()

  const onViewProfile = () => {
    applicant$.next(props.data);
    const path = ROUTES.viewCandidate.path.replace(':uuid', props.data.uuid);
    navigate(path);
  }

  const onReview = () => {
    applicant$.next(props.data);
    searchParams.set('approve', 'application');
    searchParams.set('approveID', props.data.uuid);
    setSearchParams(searchParams);
  }
  
  return (
    <div className={styles['container']}>
      <img className='profile-image' src={props.data.candidate.avatar_image_url} />
      
      <div className={styles.nameWrap}>
        <h4>
          {props.data.candidate.first_name} {props.data.candidate.last_name}
          <span>
            {
              !!jobCategoryOptions &&
              jobCategoryOptions?.filter((o) => props.data.candidate.categories.includes(o.value)).map(o => o.label).join(', ')
            }
            {
              props.data.candidate.categories.includes('other')  && props.data.candidate.category_other !== '' &&
              ` (${props.data.candidate.category_other})`
            }
            </span>
        </h4>
        {
          props.isReview &&
          <h5 className='eyebrow'>
            <span>Applying to: </span>
            {props.data.job.title} - <span>{props.data.job.company_name} ({props.data.job.employer_name})</span></h5>
        }
      </div>

      {
        props.data.is_new &&
        <div className={styles.newTag}>new</div>
      }

      <div className={styles.detailsWrap}>
        {
          props.data.candidate.years_experience &&
          <JobDetail icon='briefcase' text={`${props.data.candidate.years_experience} years experience`} />   
        }
        <JobDetail icon='building' text={props.data.job.duration} />
        {
          props.data.candidate.city !== ''
          ? <JobDetail icon='map-pin' text={`${props.data.candidate.city}${props.data.candidate.state && props.data.candidate.state !== '' ? `, ${props.data.candidate.state}` : ''}`} />
          : <JobDetail icon='map-pin' 
                      text={`${props.data.candidate.state && props.data.candidate.state !== '' ? props.data.candidate.state + ' ' : ''}${props.data.candidate.zip_code}`} />
        }
        
        <JobDetail icon='clock' text={props.data.age} />

        {
          props.isReview &&
          <JobDetail icon='timer' text={props.data.life_cycle} lifeCycle={props.data.life_cycle}/>
        }
      </div>
      
      {
        !props.isReview &&
        <button className='btn btn--oval' onClick={onViewProfile}>
          <SVGIcon id='arrow' rotate={-45} />
          View Profile
        </button>
      }

      {
        props.isReview &&
        <button className='btn btn--oval' onClick={onReview}>
          {props.data.life_cycle === 'review' ? 'Review & Approve' : 'View Details'}
        </button>
      }
      
    </div>
  )
};

export default ApplicantCard;