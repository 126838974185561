import { FormValidityState, InputValidityState, SelectOptionLifeCycle } from "./types";

export const ENV = process.env.NODE_ENV;
export const DOMAIN = process.env.REACT_APP_DOMAIN;
export const SHOW_CONSOLE = process.env.REACT_APP_SHOW_CONSOLE === 'true' || false;
export const GMAPS_API_KEY = process.env.REACT_APP_GMAPS_API_KEY;
export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
export const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/; // eslint-disable-line
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const SPECIAL_CHAR_REGEX = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/; // eslint-disable-line
export const ALL_DIGITS_REGEX = /^\d+$/;
export const MIN_LENGTH_PW = 8;
export const PW_LENGTH_ERROR_MSG = `password must be at least ${MIN_LENGTH_PW} characters`;
export const ACCEPT_PDF_DOC_DOCX = 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
export const ACCEPT_PNG_JPG = 'image/png,image/jpeg';
export const DEFAULT_PAGE_SIZE = 6;
export const IS_SAFARI = navigator.userAgent.indexOf("Chrome") === -1 && navigator.userAgent.indexOf("Safari") > -1;

export const DEFAULT_INPUT_VALIDITY_STATE: InputValidityState = {
  valid: false,
  value: undefined,
  isDirty: false,
  message: undefined,
  count: 0
}

export const DEFAULT_FORM_VALIDITY_STATE: FormValidityState = {
  touched: false,
  valid: false,
  validity: {},
  values: {}
}

export const DEFAULT_REGISTER_FORM_VALIDITY_STATE: FormValidityState = {
  touched: false,
  valid: false,
  validity: {
    email: false,
    password: false,
    re_password: false,
    role: false
  },
  values: {
    email: '',
    password: '',
    re_password: '',
    role: ''
  }
}

export const ModifiedKeysArr = [
  'duration', 'city', 'state', 'category', 'category_other', 'requires_recruiter', 
'is_exclusive', 'compensation', 'qualifications', 'description', 'key_responsibilities', 'title'
]

export const lifeCycleOptions: SelectOptionLifeCycle[] = [
  {
      label: 'Active',
      value: 'active'
  },
  {
      label: 'Deactivated',
      value: 'deactivated'
  },
  {
    label: 'Draft',
    value: 'draft'
  },
  {
    label: 'Expired',
    value: 'expired'
  },
  {
    label: 'Pending Approval',
    value: 'review'
  },
];
