import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/global.scss';
import App from './App';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from './pages/Account/Login/Login';
import Registration from './pages/Account/Registration/Registration';
import LandingPage from './pages/LandingPage/LandingPage';
import VerifyEmail from './pages/Account/VerifyEmail/VerifyEmail';
import ForgotPassword from './pages/Account/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/Account/ResetPassword/ResetPassword';
import { ROUTES } from './lib/app-routes';
import Profile from './pages/Account/Profile/Profile';
import Onboarding from './pages/Account/Onboarding/Onboarding';
import JobPostSuccess from './pages/Employer/JobPostSuccess/JobPostSuccess';
import Dashboard from './pages/Dashboard/Dashboard';
import ViewCandidate from './pages/Employer/ViewCandidate/ViewCandidate';
import OrderHistory from './pages/Employer/OrderHistory/OrderHistory';
import Jobboard from './pages/Jobboard/Jobboard';
import Job from './pages/Jobboard/Job/Job';
import Purchase from './pages/Purchase/Purchase';
import Checkout from './pages/Purchase/Checkout/Checkout';
import ErrorBoundary from './components/ErrorBoundary';
import CheckoutSuccess from './pages/Purchase/CheckoutSuccess/CheckoutSuccess';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import ScrollToTop from './components/ScrollToTop';
import AdminRegistration from './pages/Account/Registration/AdminRegistration';
import JobboardWidget from './pages/Jobboard/JobboardWidget';
import GuardedRoutes from './components/GuardedRoutes';
/* import reportWebVitals from './reportWebVitals'; */

const baseUrl: string = document.getElementsByTagName('base')[0]?.getAttribute('href') || '/';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ErrorBoundary>
  
  <BrowserRouter basename={baseUrl}>
    <ScrollToTop>
      <Routes>
        <Route path='/' element={<App />}>
          <Route index element={<LandingPage />}/>
          <Route path={ROUTES.jobboard.path.substring(1)} element={<Jobboard />} />
          <Route path={ROUTES.job.path.substring(1)} element={<Job />} />
          <Route path={ROUTES.jobboardWidget.path.substring(1)} element={<JobboardWidget />} />
          <Route path={ROUTES.purchase.path.substring(1)} element={<Purchase />} />
          <Route path={ROUTES.jobsRedirect.path.substring(1)} element={<Navigate to='/' replace={true} />} />
          <Route path="*" element={<PageNotFound />} />
          <Route element={<GuardedRoutes />}>
            <Route path={ROUTES.registration.path.substring(1)} element={<Registration />}/>
            <Route path={ROUTES.adminRegistration.path.substring(1)} element={<AdminRegistration />} />
            <Route path={ROUTES.verifyEmail.path.substring(1)} element={<VerifyEmail />}/>
            <Route path={ROUTES.login.path.substring(1)} element={<Login />}/>
            <Route path={ROUTES.forgotPassword.path.substring(1)} element={<ForgotPassword />} />
            <Route path={ROUTES.resetPassword.path.substring(1)} element={<ResetPassword />} />
            <Route path={ROUTES.profile.path.substring(1)} element={<Profile />} />
            <Route path={ROUTES.onboarding.path.substring(1)} element={<Onboarding />} />
            <Route path={ROUTES.dashboard.path.substring(1)} element={<Dashboard />} />
            <Route path={ROUTES.jobPostSubmitted.path.substring(1)} element={<JobPostSuccess />} />
            <Route path={ROUTES.viewCandidate.path.substring(1)} element={<ViewCandidate />} />
            <Route path={ROUTES.orderHistory.path.substring(1)} element={<OrderHistory />} />
            <Route path={ROUTES.checkout.path.substring(1)} element={<Checkout />} />
            <Route path={ROUTES.checkoutSuccess.path.substring(1)} element={<CheckoutSuccess />} />
          </Route>
        </Route>
      </Routes>
    </ScrollToTop>
  </BrowserRouter>
  </ErrorBoundary>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
