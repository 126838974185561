import React, { FormEvent, InputHTMLAttributes, MutableRefObject, ReactElement, useEffect, useRef, useState } from 'react';
import { DEFAULT_INPUT_VALIDITY_STATE, IS_SAFARI } from '../lib/constants';
import { useInputState, useSelectState } from '../lib/hooks';
import { CheckboxRadioAttributes, InputValidityState, SelectOption, ValidarorFn, ValidatorFnAndArgs } from '../lib/types';
import SVGIcon from './SVGIcon/SVGIcon';


const RadioGroup = (props: {  
  options: SelectOption[],
  attributes: CheckboxRadioAttributes,
  label?: string,
  className?: string;
  errorMessage?: string,
  forceError?: boolean,
  children?: ReactElement | ReactElement[]
}): JSX.Element => {
  const [attr, setAttr ] = useState<CheckboxRadioAttributes | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();

  const onInvalid = (e: FormEvent<HTMLFieldSetElement>) => {
    e.preventDefault();
    setHasError(true);
    setErrorMsg('This field is required.');
    setTimeout(() => {
      document.querySelector('.input-error')?.parentElement?.scrollIntoView(IS_SAFARI ? false : {block: 'center'});
    }, 100);
  }

  const onChange = (e: FormEvent<HTMLFieldSetElement>) => {
    if (hasError) setHasError(false);
    if (errorMsg) setErrorMsg(undefined);
  }

  useEffect(() => {
    setHasError(props.forceError || false);
  }, [props.forceError])

  useEffect(() => {
    setErrorMsg(props.errorMessage);
  }, [props.errorMessage])

  useEffect(() => {
    setAttr({ 
      ...props.attributes,
      name: props.attributes.name || props.attributes.id,
      defaultValue: undefined
    });
  }, [props.attributes])

  return (
    <div className={`form-sub-group radio-group-outer ${props.className ? props.className : ''}`}>
      { 
        props.label && 
        <div className='form-sub-sub-group form-sub-group--border radio-group-label-wrap'>
          <label htmlFor={props.attributes.id}>{props.label}</label>
        </div>
      }

      <div className='form-sub-sub-group fieldset-wrap'>  
        <fieldset className={`${props.label ? ' has-label' : ''}`} onInvalid={onInvalid} onChange={onChange}>
          {
            props.options.map((el, i) => 
              <label key={i + 'radio-span'}>
                <input key={el.value} 
                  type='radio' 
                  {...attr} 
                  value={el.value} 
                  disabled={el.disabled === true} 
                  defaultChecked={props.attributes?.defaultValue == el.value} 
                />
                <span className='radio'>
                  <SVGIcon id='radio' />
                </span>
                {el.label}
              </label>
            )
          }
        </fieldset>
        {
          (errorMsg && hasError) &&
          <div className='input-error' style={{bottom: '-7px'}}>{errorMsg}</div>
        }
      </div>
      
      {
        props.children &&
        <div className='form-sub-sub-group radio-group-children-wrap'>
          {props.children}
        </div>
      }
    </div>
  )
};

export default RadioGroup;