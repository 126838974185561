import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { usePaginate, useQuery, useSubject } from '../../lib/hooks';
import CandidateJobService from '../../lib/services/candidate.job.service';
import { ApplicantJobData, JobPostData } from '../../lib/types';
import styles from './Jobboard.module.scss';
import { jobboardCount$, jobboardJobs$, jobboardPageSize } from './state';
import SVGIcon from '../../components/SVGIcon/SVGIcon';
import CandidateJobCard from '../../components/CandidateJobCard/CandidateJobCard';
import SearchInput from '../../components/SearchInput/SearchInput';
import JobFilters from '../../components/Filters/JobFilters/JobFilters';
import { ROUTES } from '../../lib/app-routes';
import WidgetService from '../../lib/services/widget.service';
import { useNavigate, useParams } from 'react-router-dom';


const Jobboard = (): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [prevH, setPrevH] = useState<number>();
  const [{page}, setSearchParams, searchParams] = useQuery();
  const jobsData = useSubject<JobPostData[] | ApplicantJobData[]>(jobboardJobs$);
  const jobsCount = useSubject<number>(jobboardCount$);
  const {hideDesc} = useParams();
  const [isIFrame, setIsIFrame] = useState(false);
  const navigate = useNavigate();
  const [{ count, next, previous, results}, error] = usePaginate(CandidateJobService.getJobs, searchParams.toString() || `page=1&page_size=${jobboardPageSize}`);

  const onNext = () => {
    if (next) setSearchParams(next.split('?')[1]);
  }

  const onPrevious = () => {
    if (previous) setSearchParams(previous.split('?')[1]);
  }

  const getShowStart = (): number | string => {
    return !page || page == '1' ? '1' : (parseInt(page) - 1) * jobboardPageSize + 1;
  }

  const getShowEnd = (): number | string => {
    return !page || page == '1' ? jobsData.length : ((parseInt(page) - 1) * jobboardPageSize) + jobsData.length;
  }

  useEffect(() => {
    if (results) jobboardJobs$.next(results);
    jobboardCount$.next(count);
    setTimeout(() => {
      const h = ref.current?.offsetHeight as number;
      setPrevH(h);
    }, 100);
  }, [results, count]);

  useEffect(() => {
    let isIF = false;
    try {
      // ok if same domain, err if cross origin
      if(window.parent.window.location.origin.includes('jobsnob.net')) {
        // for testing on dev
        isIF = true;
        setIsIFrame(true);
        WidgetService.postMount();
      }
    } catch(err: any) {
      isIF = true;
      setIsIFrame(true);
      WidgetService.postMount();
    } finally {
      if (!isIF) navigate(ROUTES.jobboard.path);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current){
        const h = ref.current.offsetHeight;
        if (h !== prevH) {
          setPrevH(h);
        }
      } 
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (prevH) WidgetService.postHeight(prevH);
  }, [prevH]);

  
  return (
    <div ref={ref}>
      { 
        isIFrame &&
        <>
          <Helmet>
            <title>JobSnob: Job Board</title>
          </Helmet>
          <div className={`content-wrap ${styles['topContainer']}`} data-widget>
            <div className={`content ${styles.content}`}>
              <div className={styles.searchWrap}>
                <a id='jobSearch' className={styles.jobSearch}></a>
                <SearchInput isWidget={true}/>
                <JobFilters pageSize={jobboardPageSize} isJobboard={true} isWidget={true}/>
              </div>
            </div>
          </div>

          <div className={`content-wrap ${styles['btnContainer']}`}  data-widget>
            <div className='content'>
              <button className='btn btn--widget-blue btn--auto-width'
                onClick={() => WidgetService.postHref(ROUTES.registration.path, ['role=employer'])}>
                <SVGIcon id='plus' color='white' />
                Post a New Job
              </button>
            </div>
          </div>

          <div className={`content-wrap ${styles['listContainer']}`}  data-widget>
            <p className='eyebrow'>
              {
                jobsCount > 0 && jobsData && !error &&
                `SHOWING ${getShowStart()}-${getShowEnd()} OF ${jobsCount} JOBS`
              }
              {
                jobsCount === 0 && !error && 'No results'
              }
              {
                jobsCount === -1 && !error && '...loading'
              }
              {
                error && `ERROR! ${error.message}`
              }
            </p>

            <div className={`content ${styles.listWrap}`}>
              {
                jobsData &&
                jobsData.map((job) => 
                  <CandidateJobCard 
                    key={job.uuid as string + Math.random()} 
                    type='job' 
                    jobData={job}
                    disabled={false}
                    isWidget={true}
                    hideDescription={hideDesc === '1'}
                  />
                )
              }

              <p className='eyebrow pages'>
                {
                  jobsCount > 0 && page &&
                  `PAGE ${page} OF ${Math.ceil(jobsCount / jobboardPageSize)}`
                }
              </p>

              {
                (next || previous) &&
                <div className={styles.btnWrap}>
                  {
                    previous &&
                    <a href='#jobSearch' className='btn btn--widget-blue btn--border' onClick={onPrevious}>Previous</a>
                  }
                  {
                    next &&
                    <a href='#jobSearch' className='btn btn--widget-blue btn--border' onClick={onNext}>Next</a>
                  }
                </div>
              }

              <div className={styles.logoPowered}></div>
            </div>
          </div>
        </>
      
      }
  </div>
  )
};

export default Jobboard;
