import React, { ChangeEvent, useEffect, useState } from 'react';
import { switchMap } from 'rxjs';
import { ACCEPT_PDF_DOC_DOCX } from '../../lib/constants';
import { useSubject } from '../../lib/hooks';
import ResumeService from '../../lib/services/resume.service';
import { userData$ } from '../../lib/state';
import { ErrorResponse, ResumeData, UserData } from '../../lib/types';
import { selectedProfile$ } from '../../pages/Dashboard/state';
import Checkbox from '../Checkbox/Checkbox';
import UploadButton from '../UploadButton';
import styles from './ResumeInput.module.scss';

const ResumeInput = (props: {error?: ErrorResponse, onResumeUploaded?: () => void, notCandidate?: boolean}): JSX.Element => {
  const [resumeError, setResumeError] = useState<ErrorResponse | null>(null);
  const [resume, setResume] = useState<ResumeData>();
  const [uploading, setUploading] = useState(false);
  const userData = useSubject<UserData | null>(props.notCandidate ? selectedProfile$ : userData$);

  const onUploadResume = (e: ChangeEvent<HTMLInputElement> ) => {
    if(resumeError) setResumeError(null);
    setUploading(true);
    //
    const file = (e.target.files as FileList)[0]; 
    const curResID = userData?.profile.resumes.length ? userData?.profile.resumes[0].uuid : undefined;
    //
    if (curResID) {
      ResumeService.deleteResume(curResID).pipe(
        switchMap(() => ResumeService.uploadResume(file))
      ).subscribe({
        error: err => {setResumeError(err); setUploading(false);},
        complete: () => setUploading(false)
      });
    } else {
      ResumeService.uploadResume(file, props.notCandidate ? userData?.profile.uuid : undefined)
      .subscribe({
        error: err => {setResumeError(err); setUploading(false);},
        complete: () => setUploading(false)
      });
    }
  }

  useEffect(() => {
    if(userData) {
      setResume(userData.profile.resumes[0]);
    }
  }, [userData])

  useEffect(() => {
    if(resume) {
      setTimeout(() => {
        if(props.onResumeUploaded) props.onResumeUploaded();
      }, 200);
    }
  }, [resume])
  
  return (
    <div className={styles.container}>
      <label className={styles.label}>Resume</label>
      {
        resume &&
        <>
          <Checkbox
            label={resume?.file_name || ''} 
            subLabel={resume ? `Uploaded on ${resume?.file_upload_date}` : ''}
            attributes={{ id: 'resume', required: true, defaultChecked: !!resume, disabled: true }}
          />
          <div className={styles.attatchWrap}>
            <span>OR</span>
            <button className='btn--file'>
              <span>Select a different resume</span>
              <input
                type='file'
                name='resume_file'
                id='resume_file'
                accept={ACCEPT_PDF_DOC_DOCX}
                onChange={onUploadResume}
              />
            </button>
          </div>
        </>
      }

      {
        !resume &&
        <>
          <UploadButton 
            uploading={uploading}
            text='Upload Resume'
            className='btn--transparent btn--border'
            inputName='resume_file'
            accept={ACCEPT_PDF_DOC_DOCX}
            onChange={onUploadResume}
          />
          <input hidden name='resume' required={true} />
          <p>
            {
              !props.notCandidate &&
              <>Applying for a job requires at least one resume.<br /></>
            }
            You can upload documents in the the following file formats: DOC, DOCX or PDF. (max 5MB)</p>
        </>
        
      }
        
      {
        (resumeError || props.error?.response.resume) &&
        <p className='input-error'>{resumeError?.response.detail || props.error?.response.file}</p>
      }
      {
        !props.notCandidate &&
        <p>Resumes are not searchable or shared with any employers without your explicit consent.</p>
      }
    </div>
  )
};

export default ResumeInput;