import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ModifiedKeysArr } from '../../lib/constants';
import { useSubject } from '../../lib/hooks';
import AdminService from '../../lib/services/admin.service';
import { JobModifiedKeys, JobPostData, ModifiedJobData } from '../../lib/types';
import { getDeepClone } from '../../lib/util.functions';
import { dashboardErrorMsg$, dashboardPageSize, DEFAULT_ADMIN_JOBS_LIFE_CYCLE_IN, jobForReview$, modifiedJobs$, myJobs$ } from '../../pages/Dashboard/state';
import JobDisplay from '../JobDisplay/JobDisplay';
import Modal from '../Modal/Modal';
import styles from './AdminReviewJobPost.module.scss';

const AdminReviewJobPost = (props: {type: 'new' | 'modified' | 'inactive' | 'active'}): JSX.Element => {
  const jobData = useSubject(jobForReview$);
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState<'approve' | 'decline' | 'delete'>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [changed, setChanged] = useState<JobModifiedKeys[]>();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState<string>();
  const [reactivateSuccess, setReactivateSuccess] = useState(false);
  const navigate = useNavigate();

  const onCloseModal = () => {
    setShowModal(false);
    setTimeout(() => {
      setError(undefined);
    }, 600);
  }

  const onBtn = (type: 'approve' | 'decline' | 'delete') => {
    setAction(type);
    setShowModal(true);
  }

  const onEdit = () => {
    if (props.type === 'inactive' || props.type === 'active') {
      searchParams.delete('activateID');
      searchParams.delete('view');
      searchParams.set('edit', jobData?.uuid as string);
    } else {
      searchParams.set('edit', searchParams.get('approveID') as string);
    }
    setSearchParams(searchParams);
  }

  const onBack = () => {
    document.body.scrollTo(0,0);
    navigate(-1);
  }

  const onReactivate = () => {
    const data: JobPostData = getDeepClone(jobData);
    data.life_cycle = 'active';
    AdminService.updateJob(searchParams.get('activateID') as string, data).subscribe({
      next: dta => {
        jobForReview$.next(dta);
        setReactivateSuccess(true);
        setShowModal(true);
      },
      error: err => {
        setError(err.response.detail || err.message);
        setShowModal(true);
      }
    })
  }

  const onError = (err: any) => {
    dashboardErrorMsg$.next(err.response.detail || err.message);
    setShowModal(false);
    setDisabled(false);
  }

  const onConfirm = () => {
    setDisabled(true);
    if (props.type === 'new') {
      AdminService.approveReview(jobData?.uuid as string, action === 'approve').subscribe({
        next: (dta) => {
          myJobs$.next(myJobs$.value.filter(j => j.uuid !== dta.uuid));
          AdminService.getJobsCount('life_cycles=review').subscribe();
        },
        error: onError,
        complete: onBack
      });
    } else if (props.type === 'modified') {
      AdminService.approveModified(jobData?.uuid as string, action === 'approve').subscribe({
        next: (dta) => modifiedJobs$.next(modifiedJobs$.value.filter(j => j.uuid !== dta.uuid)),
        error: onError,
        complete: onBack
      });
    } else if (props.type === 'active') {
      AdminService.deleteJob(jobData?.uuid as string).subscribe({
        next: () => myJobs$.next(myJobs$.value.filter(j => j.uuid !== jobData?.uuid)),
        error: onError,
        complete: onBack
      })
    }
  }

  useEffect(() => {
    if (jobData && props.type === 'modified') {
      const arr: JobModifiedKeys[] = [];
      ModifiedKeysArr.forEach(key => {
        if (jobData[key as keyof typeof jobData]?.toString() !== (jobData as ModifiedJobData).job[key as keyof typeof jobData]?.toString()) {
          arr.push(key as JobModifiedKeys);
        }
      });
      setChanged(arr);
    }
    document.body.scrollTo(0,0);
  }, [jobData]);

  return (
    <>
      <div className={`content ${styles['content']}`}>
        <div className={styles.header}>
          <h2>{props.type !== 'inactive' && props.type !== 'active' ? `Review ${props.type}` : jobData?.life_cycle} Job Post</h2>
        </div>

        <div className={styles.jobInfoWrap} data-side-by-side={props.type === 'modified'}>
          <JobDisplay data={jobData} review={true} modified={props.type === 'modified' || undefined} changed={changed}/>
          {
            props.type === 'modified' &&
            <JobDisplay data={(jobData as ModifiedJobData).job} review={true} modified={false}/>
          }
        </div>

        <div className={styles.btnWrap}>
          {
            props.type !== 'inactive' && props.type !== 'active' &&
            <>
              <button className='btn btn--destructive' onClick={() => onBtn('decline')}>Decline</button>
              <button className='btn' onClick={() => onBtn('approve')}>Approve</button>
            </>
          }
          {
            props.type === 'new' &&
            <button className='btn btn--white btn--border' onClick={onEdit}>Edit</button>
          }
          {
            props.type === 'inactive' &&
            <button className='btn' onClick={onReactivate}>Reactivate</button>
          }
          {
            props.type === 'active' &&
            <>
              <button className='btn btn--destructive' onClick={() => onBtn('delete')}>Delete</button>
              <button className='btn btn--border' onClick={onEdit}>Edit</button>
            </>
          }
        </div>
      </div>

      <Modal show={showModal} onCloseBtn={onCloseModal}>
        <>
        {
          !error && !reactivateSuccess &&
          <>
            <p>You are about to <span className='bold uppercase'>{action}</span> a job post and this action cannot be undone.</p>
            <div className={styles.btnWrap} data-modal>
              <button type='button' className='btn btn--white btn--border btn--auto-width' 
                disabled={disabled} onClick={onCloseModal}>
                Cancel
              </button>
              <button type='button' className='btn btn--auto-width' 
                disabled={disabled} onClick={onConfirm}>
                Continue to {action}
              </button>
            </div>
          </>
        }
        {
          !!error && !reactivateSuccess &&
          <>
            <p className='eyebrow error'>Error</p>
            <p>{error}</p>
          </>
        }
        {
          reactivateSuccess &&
          <div className={styles.btnWrap} data-modal>
              <button type='button' className='btn btn--white btn--border btn--auto-width' 
                disabled={disabled} onClick={onBack}>
                Back to Jobs List
              </button>
              <button type='button' className='btn btn--auto-width' 
                disabled={disabled} onClick={onEdit}>
                Edit Job
              </button>
            </div>

        }
        </>
      </Modal>
    </>
    
  )
};

export default AdminReviewJobPost