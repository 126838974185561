import { catchError, map, Observable } from 'rxjs';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { DOMAIN } from '../constants';
import Logger from '../logger';
import { userData$ } from '../state';
import { CrashReportData, CrashReportResponse } from '../types';
import { objToFormData } from '../util.functions';



export default abstract class CrashService {

  public static report(data: CrashReportData ): Observable<CrashReportResponse | unknown> {
    if (!data.account) data.account = userData$.value?.uuid;
    Logger.log('CrashService.report()', data);
    return ajax.post(`${DOMAIN}/api/crashes/`, objToFormData(data)).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response as CrashReportResponse),
      catchError((err: AjaxError, caught: any) => {
        Logger.error(JSON.stringify(err.response));
        return caught;
      }))
  }

}
