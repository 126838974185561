import React from 'react';
import Input from '../../../components/Input';
import { ProfileProps } from './types';
import styles from './Profile.module.scss';
import ChangePassword from './ChangePassword';

const AdminProfile = (props: ProfileProps): JSX.Element => {
  return (
    <div className='content'>

      {/* Profile Details */}
      <div className={styles.pallet}>
        <h2>Profile Details</h2>
        <Input
          attributes={{ type: 'email', id: 'email', disabled: true, defaultValue: props.userData.email }} 
        />
      </div>

      {/* Change PW */}
      <ChangePassword disabled={props.disabled} formState={props.formState} setDisabled={props.setDisabledCallback}/>

    </div>
  )
};

export default AdminProfile;