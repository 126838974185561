import React from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../lib/app-routes';
import WidgetService from '../lib/services/widget.service';
import { JobPostData } from '../lib/types';

const OnboardingApplyRedirect = (props: {jobData: JobPostData, isWidget?: boolean}): JSX.Element => {
  return (
    <>
      {
        props.isWidget
        ? WidgetService.postHref(ROUTES.registration.path, [`onboarding_redirect=${props.jobData.uuid}`, 'role=candidate'])
        : <Navigate to={`${ROUTES.registration.path}?onboarding_redirect=${props.jobData.uuid}&role=candidate`} />
      }
    </>
  )
};

export default OnboardingApplyRedirect;