import { catchError, map, Observable, tap } from 'rxjs';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { draftsAppsModCount$, jobsCount$, newApplicantsCount$ } from '../../pages/Dashboard/state';
import { DOMAIN } from '../constants';
import Logger from '../logger';
import { ApplicantData, ApplicationByProxyData, CreateUserData, JobPostData, LifeCycleValue, ModifiedJobData, 
        PaginateApplicantsData, PaginateJobsData, PaginateUsersData, UserData } from '../types';
import { handleFormError, objToFormData } from '../util.functions';
import AuthService from './auth.service';


export default abstract class AdminService {

  public static getJobsList(queryParams: string): Observable<PaginateJobsData> {
    Logger.log('AdminService.getJobsList', queryParams);
    return AuthService.tokenGuaranteeSwitchMap(AdminService.jobsList, queryParams);
  }

  public static getModifiedList(queryParams: string): Observable<PaginateJobsData> {
    Logger.log('AdminService.modifiedList', queryParams);
    return AuthService.tokenGuaranteeSwitchMap(AdminService.modifiedList, queryParams);
  }

  public static approveReview(uuid: string, approved: boolean): Observable<JobPostData> {
    Logger.log('AdminService.approveReview', uuid);
    return AuthService.tokenGuaranteeSwitchMap(AdminService.reviewApprove, {uuid, approved});
  }

  public static approveModified(uuid: string, approved: boolean): Observable<{uuid: string}> {
    Logger.log('AdminService.approveModified', uuid);
    return AuthService.tokenGuaranteeSwitchMap(AdminService.modifiedApprove, {uuid, approved});
  }

  public static getModifiedCount(): Observable<{count: number}> {
    Logger.log('AdminService.getModifiedCount');
    return AuthService.tokenGuaranteeSwitchMap(AdminService.modifiedCount, null);
  }

  public static getJobsCount(queryParams: string): Observable<{count: number}> {
    Logger.log('AdminService.getJobsCount', queryParams);
    return AuthService.tokenGuaranteeSwitchMap(AdminService.jobsCount, queryParams);
  }

  public static getApplicationsCount(queryParams: string): Observable<{count: number}> {
    Logger.log('AdminService.getApplicationsCount', queryParams);
    return AuthService.tokenGuaranteeSwitchMap(AdminService.applicationsCount, queryParams);
  }

  public static getApplicationsList(queryParams: string): Observable<PaginateApplicantsData> {
    Logger.log('AdminService.getApplications', queryParams);
    return AuthService.tokenGuaranteeSwitchMap(AdminService.applicationList, queryParams);
  }

  public static getApplication(uuid: string): Observable<ApplicantData> {
    Logger.log('AdminService.getApplication', uuid);
    return AuthService.tokenGuaranteeSwitchMap(AdminService.applicationDetail, uuid);
  }

  public static updateApplication(data: {uuid: string, life_cycle?: LifeCycleValue, viewed?: boolean}): Observable<{life_cycle?: LifeCycleValue, viewed?: boolean, uuid: string}> {
    Logger.log('AdminService.updateApplication', data);
    return AuthService.tokenGuaranteeSwitchMap(AdminService.applicationUpdate, data);
  }

  public static postApplication(data: ApplicationByProxyData): Observable<any> {
    Logger.log('AdminService.postApplication', data);
    return AuthService.tokenGuaranteeSwitchMap(AdminService.applicationPost, data);
  }

  public static getUsers(queryParams: string): Observable<PaginateUsersData> {
    Logger.log('AdminService.getUsers', queryParams);
    return AuthService.tokenGuaranteeSwitchMap(AdminService.usersList, queryParams);
  }

  public static getUser(uuid: string): Observable<UserData> {
    Logger.log('AdminService.getUser', uuid);
    return AuthService.tokenGuaranteeSwitchMap(AdminService.userDetail, uuid);
  }

  public static updateUser(uuid: string, is_active: boolean): Observable<{is_active: boolean, uuid: string}> {
    Logger.log('AdminService.updateUser', uuid);
    return AuthService.tokenGuaranteeSwitchMap(AdminService.userUpdate, {uuid, is_active});
  }

  public static updateJob(uid: string, data: JobPostData): Observable<JobPostData> {
    Logger.log('AdminService.updateJob()', uid, data);
    return AuthService.tokenGuaranteeSwitchMap(AdminService.jobUpdate, {uid, data});
  }

  public static deleteJob(uid: string): Observable<any> {
    Logger.log('AdminService.deleteJob()', uid);
    return AuthService.tokenGuaranteeSwitchMap(AdminService.jobDelete, uid);
  }

  public static getJobApplications(uid: string, queryParams: string): Observable<PaginateApplicantsData> {
    Logger.log('AdminService.getJobApplications()', uid, queryParams);
    return AuthService.tokenGuaranteeSwitchMap(AdminService.jobApplicationList, {uid, queryParams});
  }

  public static createUser(userData: CreateUserData): Observable<any> {
    Logger.log('AdminService.createUser()', userData);
    return AuthService.tokenGuaranteeSwitchMap(AdminService.userCreate, userData);
  }

  public static downloadUserReport(): Observable<string> {
    Logger.log('downloadUserReport()');
    return AuthService.tokenGuaranteeSwitchMap(AdminService.downloadReport, 'users');
  }

  private static jobsList(access: string, queryParams: string): Observable<PaginateJobsData> {
    return ajax.get(`${DOMAIN}/api/administrators/jobs/?${queryParams}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static modifiedList(access: string, queryParams: string): Observable<PaginateJobsData> {
    return ajax.get(`${DOMAIN}/api/administrators/modifications/?${queryParams}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      map((dta) => {
        (dta.results as ModifiedJobData[]).map(o => {
          o.company_name = o.job.company_name;
          o.employer_name = o.job.employer_name;
          return o;
        });
        return dta;
      }),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static reviewApprove(access: string, dta: {uuid: string, approved: boolean}): Observable<JobPostData> {
    const life_cycle: LifeCycleValue = dta.approved ? 'active' : 'deactivated';
    return ajax.patch(`${DOMAIN}/api/administrators/jobs/${dta.uuid}/`, {life_cycle}, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static modifiedApprove(access: string, dta: {uuid: string, approved: boolean}): Observable<{uuid: string}> {
    return ajax.patch(`${DOMAIN}/api/administrators/modifications/${dta.uuid}/`, objToFormData({approved: dta.approved}), { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static modifiedCount(access: string): Observable<{count: number}> {
    return ajax.get(`${DOMAIN}/api/administrators/counts/modifications/`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      tap(dta => draftsAppsModCount$.next(dta.count)),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static jobsCount(access: string, queryParams: string): Observable<{count: number}> {
    return ajax.get(`${DOMAIN}/api/administrators/counts/jobs/?${queryParams}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      tap(dta => jobsCount$.next(dta.count)),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static applicationsCount(access: string, queryParams: string): Observable<{count: number}> {
    return ajax.get(`${DOMAIN}/api/administrators/counts/applications/?${queryParams}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      tap(dta => newApplicantsCount$.next(dta.count)),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static applicationList(access: string, queryParams: string): Observable<PaginateApplicantsData> {
    return ajax.get(`${DOMAIN}/api/administrators/applications/?${queryParams}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static applicationDetail(access: string, uuid: string): Observable<ApplicantData> {
    return ajax.get(`${DOMAIN}/api/administrators/applications/${uuid}/`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static applicationUpdate(access: string, data: {uuid: string, life_cycle?: LifeCycleValue, viewed?: boolean}): Observable<{life_cycle?: LifeCycleValue, viewed?: boolean, uuid: string}> {
    return ajax.patch(`${DOMAIN}/api/administrators/applications/${data.uuid}/`, objToFormData(data), { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static applicationPost(access: string, data: ApplicationByProxyData): Observable<any> {
    return ajax.post(`${DOMAIN}/api/administrators/applications/`, objToFormData(data), { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static usersList(access: string, queryParams: string): Observable<PaginateUsersData> {
    return ajax.get(`${DOMAIN}/api/administrators/users/?${queryParams}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static userDetail(access: string, uuid: string): Observable<UserData> {
    return ajax.get(`${DOMAIN}/api/administrators/users/${uuid}/`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static userUpdate(access: string, data: {is_active: boolean, uuid: string}): Observable<{is_active: boolean, uuid: string}> {
    return ajax.patch(`${DOMAIN}/api/administrators/users/${data.uuid}/`, objToFormData({is_active: data.is_active}), { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static jobUpdate(access: string, dta: {uid: string, data: JobPostData}): Observable<JobPostData> {
    return ajax.patch(`${DOMAIN}/api/administrators/jobs/${dta.uid}`, dta.data, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e, [
        'category', 'city', 'compensation', 'description', 'duration', 'key_responsibilities', 
        'qualifications', 'requires_recruiter', 'state', 'title', 'expiration_date', 'additional_notification_emails'
      ]))
    );
  }

  private static jobDelete(access: string, uid: string): Observable<any> {
    return ajax.delete(`${DOMAIN}/api/administrators/jobs/${uid}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static jobApplicationList(access: string, dta: {uid: string, queryParams: string}): Observable<PaginateApplicantsData> {
    return ajax.get(`${DOMAIN}/api/administrators/jobs/${dta.uid}/applications/?${dta.queryParams}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static userCreate(access: string, userData: CreateUserData): Observable<any> {
    return ajax.post(`${DOMAIN}/api/administrators/users/`, objToFormData(userData), { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static downloadReport(access: string, reportType: string):  Observable<string> {
    return new Observable((observer) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', `${DOMAIN}/api/administrators/reports/${reportType}`, true);
      xhr.setRequestHeader('Authorization', `Bearer ${access}`);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        const urlCreator = window.URL || window.webkitURL;
        const fileUrl = urlCreator.createObjectURL(this.response);
        const tag = document.createElement('a');
        tag.href =  fileUrl;
        tag.target = '_blank';
        tag.download = `${reportType}.csv`;
        document.body.appendChild(tag);
        tag.click();
        observer.next(fileUrl);
        observer.complete();
      };
      xhr.onerror = () => {
        // most likely error will be CORS
        AdminService._altDownloadReport(`${DOMAIN}/api/administrators/reports/${reportType}`, reportType);
      };
      xhr.send();

      return () => {
        xhr.abort();
      };
    })
  }

  /* if file is cross domain it will open in another window */
  private static _altDownloadReport(url: string, reportType: string): void {
    const tag = document.createElement('a');
    tag.href = url;
    tag.target = '_blank';
    tag.download = `${reportType}.csv`;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  }

}