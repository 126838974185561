import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import CandidateJobCard from '../../../components/CandidateJobCard/CandidateJobCard';
import CoverLetterInput from '../../../components/CoverLetterInput/CoverLetterInput';
import Input from '../../../components/Input';
import ResumeInput from '../../../components/ResumeInput/ResumeInput';
import { ROUTES } from '../../../lib/app-routes';
import { useFormState, useQuery, useSubject } from '../../../lib/hooks';
import CandidateJobService from '../../../lib/services/candidate.job.service';
import { userData$ } from '../../../lib/state';
import { ApplicationData, ErrorResponse, FormValidityState, JobPostData } from '../../../lib/types';
import { emailValidator, phoneValidator } from '../../../lib/validator.functions';
import styles from './OnboardApply.module.scss';

const OnboardApply = (props: {jobData: JobPostData}): JSX.Element => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const formState = useFormState<FormValidityState>(formRef);
  const userData = useSubject(userData$);
  const [jobData, setJobData] = useState<JobPostData>();
  const [applyError, setApplyError] = useState<ErrorResponse>();
  const [{ applySuccess }, setQuery] = useQuery();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault(); console.log(userData?.profile.resumes);
    //
    const resID = userData?.profile.resumes[0].uuid as string;
    const values: ApplicationData = {
      ...formState.values as ApplicationData, 
      job: jobData?.uuid as string, 
      resume: formState.values.resume === 'on' ? resID : '',
      cover_letter: formState.values.cover_letter !== '' ? formState.values.cover_letter : undefined
    };
    delete values.resume_file;
    //
    CandidateJobService.apply(values).subscribe({
      next: () => setQuery(`applySuccess=true`),
      error: err => setApplyError(err)
    })
  }
  

  useEffect(() => {
    if (props.jobData) setJobData(props.jobData);
  }, [props.jobData])
  
  return (
    <div className={`content ${styles.content}`}>
      <div className={styles.cardWrap}>
        {
          jobData && 
          <CandidateJobCard type='job' jobData={jobData} disabled={true} hideLink={true} />
        }
      </div>

      <h2 className='eyebrow'>Applying to {jobData?.title}</h2>

      <form ref={formRef} onSubmit={onSubmit}>
        <Input label='First Name*'
          forceError={!!applyError}
          errorMessage={applyError ? applyError.response.first_name : undefined }
          attributes={{ type: 'text', id: 'first_name', required: true,
                      defaultValue: userData?.profile.first_name || undefined }} 
        />

        <Input label='Last Name*'
          forceError={!!applyError}
          errorMessage={applyError ? applyError.response.last_name : undefined }
          attributes={{ type: 'text', id: 'last_name', required: true,
                      defaultValue: userData?.profile.last_name || undefined }}
        />

        <Input label='Email*'
          forceError={!!applyError}
          errorMessage={applyError?.response.email || 'email is not valid'}
          validatorFns={[emailValidator]}
          attributes={{type: 'email', id: 'email', required: true, autoComplete: 'email',
                      defaultValue: userData?.email }} 
        />

        <Input label='Phone*'
          validatorFns={[phoneValidator]}
          forceError={!!applyError}
          errorMessage={applyError ? applyError.response.phone_number : 'not a valid phone number' }
          attributes={{ type: 'tel', id: 'phone_number', autoComplete: 'tel',
                      defaultValue: userData?.profile.phone_number || undefined, required: true }}
        />

        <ResumeInput onResumeUploaded={() => {
          formRef.current?.dispatchEvent(new Event('change', {bubbles: true}));
        }} />

        <CoverLetterInput />

        <div className={styles.btnWrap}>
          <p>We will include a copy of your full profile with your application</p>

          <Link to={ROUTES.dashboard.path.replace(':pageID', 'jobs')} className='btn btn--grey-6 btn--border btn--dt-wide'>
            Cancel
          </Link>

          <button type='submit' className='btn btn--dt-wide' disabled={formState && !formState.valid}>
            Apply
          </button>
        </div>
      </form>
      
    </div>
  )
};

export default OnboardApply;