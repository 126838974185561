import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Modal from '../../../components/Modal/Modal';
import PastOrderCard from '../../../components/PastOrderCard/PastOrderCard';
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import { ROUTES } from '../../../lib/app-routes';
import { DEFAULT_PAGE_SIZE } from '../../../lib/constants';
import { useCreditPackages, useCredits, usePaginate, useQuery, useSubject } from '../../../lib/hooks';
import CreditsService from '../../../lib/services/credits.service';
import { ErrorResponse, OrderData } from '../../../lib/types';
import styles from './OrderHistory.module.scss';
import Logger from '../../../lib/logger';
import { userData$, loggedIn$ } from '../../../lib/state';

const OrderHistory = (): JSX.Element => {
  const userData = useSubject(userData$);
  const loggedIn = useSubject(loggedIn$);
  const navigate = useNavigate();
  const [{page}, setSearchParams, searchParams] = useQuery();
  const [credits, creditError]  = useCredits();
  const {singlePackage, multiPackage} = useCreditPackages();
  const [{ count, next, previous, results}, error] = usePaginate(CreditsService.getOrderHistory, searchParams.toString() || `page=1&page_size=${DEFAULT_PAGE_SIZE}`);

  const onNext = () => {
    if (next) setSearchParams(next.split('?')[1]);
  }

  const onPrevious = () => {
    if (previous) setSearchParams(previous.split('?')[1]);
  }

  const getShowStart = (): number | string => {
    return page == '1' ? page : (parseInt(page) - 1) * DEFAULT_PAGE_SIZE;
  }

  const getShowEnd = (): number | string => {
    return page == '1' ? results.length : ((parseInt(page) - 1) * DEFAULT_PAGE_SIZE) + results.length - 1;
  }

  const onPostNew = () => {
    navigate(ROUTES.dashboard.path.replace(':pageID', 'drafts') + '?post=new');
  }

  const onBuyAgain = (id: string) => {
    if (id === singlePackage?.id) {
      navigate(ROUTES.checkout.path + '?packageType=single');
    } else if (id === multiPackage?.id) {
      navigate(ROUTES.checkout.path + '?packageType=multiple');
    }
  }

  useEffect(() => {
    if (loggedIn && userData?.requires_onboarding) {
      Logger.log('Redirecting user to on-boarding from Purchase.');
      navigate(ROUTES.onboarding.path.replace(':step', 'complete-profile'));
    }
  }, [userData])

  return (
    <>
      <Helmet>
        <title>JobSnob: Order History</title>
      </Helmet>
      <div className={`content-wrap ${styles['content-wrap']} bg-account`}>
        <div className={styles.header}>
          <p className='eyebrow'>employer</p>
          <h1>Order History</h1>
        </div>

        <div className='content'>
          <div className={styles.listHeader}>
            <h2>Available Job Posts ({credits || 0})</h2>

            <p className={`eyebrow${error ? ' error' : ''}`}>
              {
                count > 0 && results && !error &&
                `SHOWING ${getShowStart()}-${getShowEnd()} OF ${count} ORDERS`
              }
              {
                count === 0 && !error && 'No results'
              }
              {
                !results && !error && '...loading'
              }
              {
                error && `ERROR! ${(error as ErrorResponse).response.detail || error.message}`
              }
            </p>

            <button data-desktop className='btn btn--auto-width' onClick={onPostNew}>
              <SVGIcon id='plus' color='white' />
              Post a new job
            </button>
          </div>

          <div className={styles.ordersContainer}>
            <h2>Past Purchases</h2>
            <p data-mobile>Sign up to get exclusive job listings and industry news</p>
            {
              results &&
              results.map((dta: OrderData, i) => <PastOrderCard key={i} data={dta} onBuyAgain={onBuyAgain}/>)
            }
            {
              (previous || next) &&
              <div className={styles.bottonBtnWrap} data-desktop>
                {
                  previous &&
                  <button className='btn btn--grey-6 btn--border' onClick={onPrevious}>Previous</button>
                }
                {
                  next &&
                  <button className='btn btn--grey-6 btn--border' onClick={onNext}>Next</button>
                }
              </div>
            }
          </div>

          <div data-mobile className={styles.bottonBtnWrap}>
            {
              previous &&
              <button className='btn btn--white btn--border' onClick={onPrevious}>Previous</button>
            }
            {
              next &&
              <button className='btn btn--white btn--border' onClick={onNext}>Next</button>
            }
            <button className='btn' onClick={onPostNew}>
                <SVGIcon id='plus' color='white' />
                Post a new job
            </button>
          </div>
        </div>
        {
          creditError &&
          <Modal show={!!creditError}>
            <p className='eyebrow error'>
              {creditError.response.count || creditError.response.detail || creditError.message}
            </p>
          </Modal>
        }
      </div>
    </>
  )
};

export default OrderHistory;
