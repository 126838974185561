import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useFormState, useQuery, useSubject } from '../../lib/hooks';
import CandidateJobService from '../../lib/services/candidate.job.service';
import { userData$ } from '../../lib/state';
import { ApplicationData, ErrorResponse, FormValidityState, JobPostData, UserData } from '../../lib/types';
import { getDeepClone } from '../../lib/util.functions';
import { emailValidator, phoneValidator } from '../../lib/validator.functions';
import { myJobs$ } from '../../pages/Dashboard/state';
import { job$ } from '../../pages/Jobboard/state';
import CoverLetterInput from '../CoverLetterInput/CoverLetterInput';
import Input from '../Input';
import Modal from '../Modal/Modal';
import ResumeInput from '../ResumeInput/ResumeInput';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './ApplyModal.module.scss';

const ApplyModal = (props: {page: 'current' | 'jobboard'}): JSX.Element => {
  const [{ apply }, setQuery] = useQuery();
  const formRef = useRef<HTMLFormElement | null>(null);
  const formState = useFormState<FormValidityState>(formRef);
  const userData = useSubject<UserData | null>(userData$);
  const [jobData, setJobData] = useState<JobPostData>();
  const [error, setError] = useState<ErrorResponse>();
  const [resID, setResID] = useState<string>();
  const [disabled, setDisabled] = useState(true);
  const myJobs = useSubject(myJobs$);
  const job = useSubject(job$);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setDisabled(true);
    //
    const values: ApplicationData = {
      ...formState.values as ApplicationData, 
      job: jobData?.uuid as string, 
      resume: resID as string,
      cover_letter: formState.values.cover_letter
    };
    delete values.resume_file;
    //
    CandidateJobService.apply(values).subscribe({
      next: () => {
        if (myJobs) {
          const jobs: JobPostData[] = getDeepClone(myJobs);
          const updateJob = jobs.find((o) => o.uuid === jobData?.uuid);
          if (updateJob) {
            updateJob.has_applied = true;
            myJobs$.next(jobs);
          }
        }
        //
        if (job) {
          const updateJob: JobPostData = getDeepClone(job);
          updateJob.has_applied = true;
          job$.next(updateJob);
        }
      },
      error: err => {setError(err); setDisabled(false);},
      complete: () => setQuery(`applySuccess=${props.page}`)
    })
  }

  useEffect(() => {
    if (apply && !jobData) {
      CandidateJobService.getJob(apply).subscribe({
        next: dta => setJobData(dta)
      })
    }
  }, [apply])

  useEffect(() => {
    if (userData && userData.profile.resumes.length) {
      setResID(userData.profile.resumes[0].uuid as string);
    }
  }, [userData])

  useEffect(() => {
    if (formState?.valid && resID && disabled) {
      setDisabled(false);
    }
  }, [formState, resID])
  
  return (
    <div className={`modal-container ${styles['container']}`}>
      <div className={`modal-content ${styles.content}`} data-loaded={!!jobData}>

        <button className='close-btn' onClick={() => setQuery('')}>
          <SVGIcon id='close' color='accent-grey'/>
        </button>

        <div className={styles.msgWrap}>
          <div>
            <p className='eyebrow'>Apply. Fast.</p>
            <h2>Your Dream Career Starts Here</h2>
          </div>

          <p>We&apos;ve got the key your dream career.<br />Add your resume and submit your application.</p>

          <div className='icon60 icon60--black' data-desktop>
            <SVGIcon id='chevron' color='white' rotate={-90} />
          </div>
        </div>

        <div className={styles.formWrap}>
          <form ref={formRef} onSubmit={onSubmit}>
            <p className='eyebrow'>Applying to &quot;{jobData?.title}&quot;</p>
            {
              !!error && !!error.response.job &&
              <Modal show={!!error} onCloseBtn={() => setError(undefined)}>
                <p className='eyebrow error' style={{width: '100%', textAlign: 'center', fontSize: '22px'}}>ERROR!</p>
                <p>{error.response.job}</p>
              </Modal>
            }
            <Input label='First Name*'
              forceError={!!error}
              errorMessage={error ? error.response.profile.first_name : undefined }
              attributes={{ type: 'text', id: 'first_name', disabled: !!userData,
                required: true, defaultValue: userData?.profile.first_name }} 
            />

            <Input label='Last Name*'
              forceError={!!error}
              errorMessage={error ? error.response.profile.last_name : undefined }
              attributes={{ type: 'text', id: 'last_name', disabled: !!userData,
                required: true, defaultValue: userData?.profile.last_name }} 
            />

            <Input label='Email*'
              forceError={!!error}
              errorMessage={error ? error.response.profile.email : undefined }
              validatorFns={[emailValidator]}
              attributes={{ type: 'email', id: 'email', required: true,
                defaultValue: userData?.email }} 
            />

            <Input label='Phone*'
              validatorFns={[phoneValidator]}
              forceError={!!error}
              errorMessage={error ? error.response.profile.phone_number : 'not a valid phone number'}
              attributes={{ type: 'tel', id: 'phone_number', required: true, autoComplete: 'tel', 
                          defaultValue: userData?.profile.phone_number || undefined }}
            />

            <ResumeInput error={error} onResumeUploaded={() => {
              formRef.current?.dispatchEvent(new Event('change', {bubbles: true}));
            }} />

            <CoverLetterInput />

            <div className={styles.btnWrap}>
              <p>We will include a copy of your full profile with your application</p>
              <button type='submit' className='btn btn--dt-wide' disabled={disabled}>Apply</button>
            </div>
            
          </form>
        </div>

      </div>
    </div>
  )
};

export default ApplyModal;