import React, { ChangeEvent } from 'react';
import SVGIcon from './SVGIcon/SVGIcon';
import UseAnimations from "react-useanimations";
import loading from 'react-useanimations/lib/loading';

interface IProps {
  accept?: string;
  inputID?: string;
  inputName?: string;
  className?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  text: string;
  uploadText?: string;
  uploading: boolean;
  disabled?: boolean;
}

const UploadButton = (props: IProps): JSX.Element => {
  return (
    <button className={`btn  btn--file ${props.className || ''}`} disabled={props.disabled || false}>
      <span>
        {
          props.uploading && <UseAnimations animation={loading} />
        }
        {
          !props.uploading && <SVGIcon id='plus' />
        }
        {
          props.uploading && (props.uploadText || 'Uploading...')
        }
        {
          !props.uploading && props.text
        }
      </span>
      <input
        type='file'
        name={props.inputName}
        id={props.inputID}
        accept={props.accept}
        onChange={props.onChange}
        disabled={props.uploading}
        data-ignore-touch='true'
      />
    </button>
  )
};

export default UploadButton;