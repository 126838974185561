import { Observable, map, catchError, tap, of } from "rxjs";
import { ajax, AjaxResponse, AjaxError } from "rxjs/ajax";
import { singlePackage$, multiPackage$, credits$ } from "../../pages/Purchase/state";
import { DOMAIN } from "../constants";
import Logger from "../logger";
import { CreditPackageData, CreditPackagesListData, OrderData, PaginateOrderHistoryData, PaymentData } from "../types";
import { handleFormError, objToFormData } from "../util.functions";
import AuthService from "./auth.service";


export default abstract class CreditsService {

  /* public static getPackageList(): Observable<CreditPackagesListData> {
    Logger.log('CreditsService.getPackagelist()');
    return AuthService.tokenGuaranteeSwitchMap(CreditsService.packageList, undefined);
  } */

  public static getAvailableCredits(): Observable<{count: number}> {
    Logger.log('CreditsService.getAvailableCredits()');
    return AuthService.tokenGuaranteeSwitchMap(CreditsService.availableCredits, undefined);
  }

  public static getOrderHistory(queryParams: string): Observable<PaginateOrderHistoryData> {
    Logger.log('CreditsService.getOrderHistory()', queryParams);
    return AuthService.tokenGuaranteeSwitchMap(CreditsService.orderHistory, queryParams);
  }

  public static createPayment(product_id: string, is_amspa_referral: boolean): Observable<PaymentData> {
    Logger.log('CreditsService.createPayment()', product_id);
    return AuthService.tokenGuaranteeSwitchMap(CreditsService.paymentCreate, {product_id, is_amspa_referral});
  }

  public static updatePayment(payment_intent_id: string, promotion_code: string): Observable<PaymentData> {
    Logger.log('CreditsService.updatePayment()', payment_intent_id, promotion_code);
    return AuthService.tokenGuaranteeSwitchMap(CreditsService.paymentUpdate, {payment_intent_id, promotion_code});
  }

  public static cancelPayment(payment_intent_id: string): Observable<any> {
    Logger.log('CreditsService.cancelPayment()', payment_intent_id);
    return AuthService.tokenGuaranteeSwitchMap(CreditsService.paymentCancel, payment_intent_id);
  }

  public static checkoutPayment(payment_intent_id: string, checkout_code: string): Observable<unknown> {
    Logger.log('CreditsService.checkoutPayment()', payment_intent_id);
    return AuthService.tokenGuaranteeSwitchMap(CreditsService.paymentCheckout, {payment_intent_id, checkout_code});
  }

  public static getPackageList(): Observable<CreditPackagesListData> {
    Logger.log('CreditsService.getPackagelist()');
    return ajax.get(`${DOMAIN}/api/employers/credits/packages/`).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      tap((data) => {
        singlePackage$.next(data.packages.find((p: CreditPackageData ) => p.metadata.credits === '1'));
        multiPackage$.next(data.packages.find((p: CreditPackageData ) => p.metadata.credits > 1))
      }),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static loadingCredits = false;

  private static availableCredits(access: string): Observable<{count: number} | null> {
    if (CreditsService.loadingCredits) return of(null);
    CreditsService.loadingCredits = true;
    return ajax.get(`${DOMAIN}/api/employers/counts/credits/`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response as {count: number}),
      tap(dta => {
        credits$.next(dta.count);
        CreditsService.loadingCredits = false;
      }),
      catchError((e: AjaxError) => {
        CreditsService.loadingCredits = false;
        return handleFormError(e)
      })
    );
  }

  private static orderHistory(access: string, queryParams: string): Observable<PaginateOrderHistoryData> {
    return ajax.get(`${DOMAIN}/api/employers/credits/?${queryParams}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static paymentCreate(access: string, data: {product_id: string, is_amspa_referral: boolean}): Observable<PaymentData> {
    return ajax.post(`${DOMAIN}/api/employers/credits/payments/`, objToFormData(data), { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static paymentUpdate(access: string, dta: {payment_intent_id: string, promotion_code: string}): Observable<PaymentData> {
    return ajax({
      url: `${DOMAIN}/api/employers/credits/payments/${dta.payment_intent_id}`,
      method: 'PUT',
      body: objToFormData({promotion_code: dta.promotion_code}),
      headers: { 'Authorization': `Bearer ${access}` },
      withCredentials: true
    }).pipe(
      tap((ajaxResp: AjaxResponse<any>) => console.dir(ajaxResp)),
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static paymentCancel(access: string, payment_intent_id: string): Observable<unknown> {
    return ajax.delete(`${DOMAIN}/api/employers/credits/payments/${payment_intent_id}`, { 'Authorization': `Bearer ${access}` }).pipe(
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static paymentCheckout(access: string, dta: {payment_intent_id: string, checkout_code: string}): Observable<OrderData> {
    return ajax({
      url: `${DOMAIN}/api/employers/credits/payments/${dta.payment_intent_id}/checkout`,
      method: 'POST',
      body: objToFormData({checkout_code: dta.checkout_code}),
      headers: { 'Authorization': `Bearer ${access}` },
      withCredentials: true
    }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

}