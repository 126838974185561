import React, { useEffect, useState } from 'react';
import { useJobChoiceDetails } from '../../lib/hooks';
import { LifeCycleValue } from '../../lib/types';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './JobDetail.module.scss';

const JobDetail = (props: {icon: string, text: string, lifeCycle?: LifeCycleValue, maxWidth?: string}): JSX.Element => {
  const {jobDurationOptions} = useJobChoiceDetails();
  const [text, setText] = useState<string>(props.text);

  useEffect(() => {
    if (props.icon === 'building' && jobDurationOptions) {
      const txt = jobDurationOptions.find(o => o.value === props.text)?.label
      if (txt) setText(txt);
    }
  }, [jobDurationOptions])

  return (
    <div className={styles.detail} data-lifecycle={props.lifeCycle} style={{maxWidth: props.maxWidth}}>
      <SVGIcon id={props.icon} />
      <span>{text}</span>
    </div>
  )
};

export default JobDetail;