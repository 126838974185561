import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import SVGIcon from '../../components/SVGIcon/SVGIcon';
import { ROUTES } from '../../lib/app-routes';
import { useCreditPackages, useSubject } from '../../lib/hooks';
import { loggedIn$, userData$ } from '../../lib/state';
import styles from './Purchase.module.scss';
import Logger from '../../lib/logger';

const Purchase = (): JSX.Element => {
  const loggedIn = useSubject(loggedIn$);
  const userData = useSubject(userData$);
  const navigate = useNavigate();
  const {singlePackage, multiPackage} = useCreditPackages();
  const [singleFeatures, setSingleFeatures] = useState<string[]>();
  const [multiFeatures, setMultiFeatures] = useState<string[]>();

  useEffect(() => {
    if (singlePackage) {
      const arr = [];
      for (const key in singlePackage.metadata) {
        if (Object.prototype.hasOwnProperty.call(singlePackage.metadata, key) && key.includes('feature')) {
          arr.push(singlePackage.metadata[key]);
        }
      }
      setSingleFeatures(arr);
    }
  }, [singlePackage])

  useEffect(() => {
    if (multiPackage) {
      const arr = [];
      for (const key in multiPackage.metadata) {
        if (Object.prototype.hasOwnProperty.call(multiPackage.metadata, key) && key.includes('feature')) {
          arr.push(multiPackage.metadata[key]);
        }
      }
      setMultiFeatures(arr);
    }
  }, [multiPackage])


  useEffect(() => {
    if (loggedIn && userData?.requires_onboarding) {
      Logger.log('Redirecting user to on-boarding from Purchase.');
      navigate(ROUTES.onboarding.path.replace(':step', 'complete-profile'));
    }
  }, [userData])

  return (
    <>
      <Helmet>
        <title>JobSnob: Employers Purchase Credit Packages</title>
      </Helmet>
      <div className={`content-wrap bg-purchase ${styles.topContentWrap}`}>
        <div className='content'>

          <div className={styles.postWrap}>
            <div className={styles.header}>
              <p className='eyebrow'>What&apos;s right for you?</p>
              <h1>Post one job, or fill multiple roles</h1>
            </div>
            <p>Nicely done! If you’re just looking to fill one job, select the single job post. Otherwise, buy a package to save on multiple job posts.</p>
            <div className='icon60 icon60--black'>
              <SVGIcon id='chevron' color='white' />
            </div>
          </div>

          {
            singlePackage && singleFeatures &&
            <div className={styles.singleWrap}>
              <div className={styles.header}>
                <p className='eyebrow'>Purchase Details</p>
                <h2><span>{singlePackage.name}</span></h2>
              </div>

              <div className={styles.listWrap}>
                <p className='eyebrow'>What&apos;s Included</p>
                <ul>
                  {
                    singleFeatures.map((val, i) => 
                      <li key={i}><SVGIcon id='check' color='salmon' />{val}</li>
                    )
                  }
                </ul>
              </div>

              <Link to={loggedIn ? ROUTES.checkout.path + '?packageType=single' : ROUTES.registration.path + '?role=employer'} 
                className='btn btn--border btn--dt-wide'>
                Buy and Post
              </Link>
            </div>
          }
          
          {
            multiPackage && multiFeatures &&
            <div className={styles.multiWrap}>
              <div className={styles.header}>
                <p className='eyebrow'>{multiPackage.description}</p>
                <h2><span>{multiPackage.name}</span></h2>
              </div>

              <div className={styles.listWrap}>
                <p className='eyebrow'>What&apos;s Included</p>
                <ul>
                  {
                    multiFeatures.map((val, i) => 
                      <li key={i}><SVGIcon id='check' color='salmon' />{val}</li>
                    )
                  }
                </ul>
              </div>

              <Link to={loggedIn ? ROUTES.checkout.path + '?packageType=multiple' : ROUTES.registration.path + '?role=employer'} 
                className='btn btn--dt-wide'>
                Buy and Post
              </Link>
            </div>
          }

        </div>
      </div>

      <div className={`content-wrap ${styles.bottomContentWrap}`}>
        <div className='content'>
          <div className='icon60 icon60--border-earthy-grey'>
            <SVGIcon id='conexions' color='earthy-grey' />
          </div>

          <div className={styles.header}>
            <p className='eyebrow'>Work with us</p>
            <h2><span>Hire a</span> Recruiter</h2>
          </div>

          <div className={styles.pWrap}>
            <p>
              <span className='italic'>Finding your talent is our talent.</span> With a seriously impressive network of the most sought-after Medical Aesthetic professionals, 
              JobSnob connects you with candidates with the experience and skills you need.
            </p>
            <p>
              For positions that require more experience and/or specialized skill sets, such as <span className='bold'>Aesthetic Injector, 
              Practice Manager,</span> and <span className='bold'>Director-level positions,</span> we strongly recommend our concierge recruiting services. 
              A more tailored approach is often most successful in securing top talent for these and other high level and revenue-generating roles.
            </p>
          </div>

          <a href='mailto:JobSnob <info@jobsnob.net>?subject=Recruiting Inquiry&body=Please include the following information, and a JobSnob team member will be in touch shortly.%0D%0A%0D%0AFull Name -%0D%0APractice/Business Name -%0D%0AOpen Position/s -%0D%0APhone Number -%0D%0AEmail -%0D%0AOther relevant information -%0D%0A%0D%0AThank you,%0D%0AThe JobSnob Team' target='_blank' rel='noreferrer' className='btn btn--border btn--grey-light btn--dt-wide'>
            Get More Info
          </a>
        </div>
      </div>

      <div className={`content-wrap ${styles.gridContentWrap}`}>
        <div className='content'>
          <h2 data-header="1"><span>Job Board</span></h2>
          <h2 data-header="2"><span>Concierge Recruiting</span></h2>

          <h3 data-subheader="1" className="eyebrow">Recommended Positions</h3>
          <h3 data-subheader="1" data-extra className="eyebrow">Recommended Positions</h3>
          <p data-content="1-1"><em>Support Roles</em>,<br />Front Desk,<br />Medical Assistant,<br />Social Media Coordinator</p>
          <p data-content="1-2"><em>Highly Skilled Roles</em>,<br />Aesthetic Injector, Practice Manager,<br />Aesthetician, etc.</p>
          <div data-separator="1"></div>
          
          <h3 data-subheader="2" className="eyebrow">Level of JobSnob Involvement</h3>
          <h3 data-subheader="2" data-extra className="eyebrow">Level of JobSnob Involvement</h3>
          <p data-content="2-1">Self-Managed</p>
          <p data-content="2-2">White Glove, Concierge Service</p>
          <div data-separator="2"></div>

          <h3 data-subheader="3" className="eyebrow">What&apos;s Included</h3>
          <h3 data-subheader="3" data-extra className="eyebrow">What&apos;s Included</h3>
          <ul data-content="3-1">
            <li><SVGIcon id='check' color='stone-grey' />60 day job post</li>
            <li><SVGIcon id='check' color='stone-grey' />Inclusion in national candidate email marketing</li>
            <li><SVGIcon id='check' color='stone-grey' />Experienced Medical Aesthetic job seekers</li>
            <li><SVGIcon id='check' color='stone-grey' />Jobs are visible on the Google Jobs platform</li>
          </ul>
          <ul data-content="3-2">
            <li><SVGIcon id='check' color='stone-grey' />Full-cycle support from screening to hire</li>
            <li><SVGIcon id='check' color='stone-grey' />Highly curated candidate submissions</li>
            <li><SVGIcon id='check' color='stone-grey' />Complimentary job board posting for additional visibility</li>
            <li><SVGIcon id='check' color='stone-grey' />A dedicated JobSnob recruiter to lead and support the entire process</li>
            <li><SVGIcon id='check' color='stone-grey' />Access to passive candidates who are not actively searching & applying to jobs</li>
          </ul>
          <div data-separator="3"></div>

          <h3 data-subheader="4" className="eyebrow">Cost</h3>
          <h3 data-subheader="4" data-extra className="eyebrow">Cost</h3>
          <p data-content="4-1">$</p>
          <p data-content="4-2">$$</p>
        </div>
      </div>
    </>
  )
};

export default Purchase;
