import React from 'react';
import Registration from './Registration';

const AdminRegistration = (): JSX.Element => {
  
  return (
    <Registration isAdmin={true} />
  )
};

export default AdminRegistration;