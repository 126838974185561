import React from 'react';
import styles from './LPMatchmaker.module.scss';
import { fromEvent, map } from 'rxjs';
import { useObservable } from '../../../lib/hooks';

const LPMatchmaker = (): JSX.Element => {
  const [bgWidth] = useObservable<number>(
    fromEvent(window, 'resize').pipe(
      map(() => window.innerWidth)
  ));
  
  return (
    <section className={styles['matchmaker-wrap']}>
      <div className={styles.bg} style={{width: `${bgWidth || window.innerWidth}px`}}></div>

      <div className={styles.innerWrap}>
        <div className={styles.content}>
          <div className={styles.titleWrap}>
            <p className='eyebrow'>Acquisitions</p>
            <h2><span>We&apos;re the</span> Matchmaker<br /><span>Between</span> Buyers &amp; Sellers</h2>
          </div>

          <div className={styles.pWrap}>
            <p>Whether you&apos;re a practice owner planning your exit strategy or an investor interested in acquisition opportunities, JobSnob can connect you with the right people.</p>
            <p>Register your company details or your desired purchase location and we&apos;ll take it from there.</p>
          </div>

          <div className={styles.btnWrap}>
            <a href='https://info.jobsnob.net/acquisitions/#seller' className='btn btn--border btn--auto-width'>I&apos;m a seller</a>
            <a href='https://info.jobsnob.net/acquisitions/#buyer' className='btn btn--border btn--auto-width'>I&apos;m a buyer</a>
          </div>
        </div>
      </div>
      
    </section>
  )
};

export default LPMatchmaker;
