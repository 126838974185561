import { BehaviorSubject, Subject } from "rxjs";
import StorageService from "../../lib/services/storage.service";
import { ApplicantData, JobPostData, ModifiedJobData, UserData } from "../../lib/types";

export const myJobs$ = new BehaviorSubject<JobPostData[]>([]);

export const activeJobs$ = new BehaviorSubject<JobPostData[]>([]);

export const selectedJob$ = new BehaviorSubject<JobPostData | ModifiedJobData | null>(StorageService.get('selectedJob') || null);

selectedJob$.subscribe(dta => StorageService.save('selectedJob', dta, true));

export const selectedProfile$ = new BehaviorSubject<UserData | null>(StorageService.get('selectedProfile') || null);

selectedProfile$.subscribe(dta => StorageService.save('selectedProfile', dta, true));

export const jobForReview$ = new BehaviorSubject<JobPostData | ModifiedJobData | null>(StorageService.get('jobForReview') || null);

jobForReview$.subscribe(dta => StorageService.save('jobForReview', dta, true));

export const modifiedJobs$ = new BehaviorSubject<ModifiedJobData[]>([]);

export const myDraftsOrApps$ = new BehaviorSubject<JobPostData[] | ApplicantData[]>([]);

export const jobsCount$ = new BehaviorSubject<number>(0);

export const draftsAppsModCount$ = new BehaviorSubject<number>(0);

export const newApplicantsCount$ = new BehaviorSubject<number>(0);

export const applicant$ = new BehaviorSubject<ApplicantData | null>(null);

export const dashboardPageSize = 10;

export const dashboardErrorMsg$ = new Subject<string | null>();

export const DEFAULT_ADMIN_JOBS_LIFE_CYCLE_IN = 'active__review';