import React from 'react';
import { Helmet } from 'react-helmet';
import styles from './PageNotFound.module.scss';

const PageTemplate = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>JobSnob: 404 Page Not Found</title>
      </Helmet>
      <div className='content-wrap'>
        <div className={`content ${styles.content}`}>
          <h1>
            <span className={styles.num}>404</span>
            <span className={styles.text}>
              <span>Page</span>
              <span>Not</span>
              <span>Found</span>
            </span>
          </h1>
        </div>
      </div>
    </>
  )
};

export default PageTemplate;
