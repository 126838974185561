import React, { FormEvent, ReactElement, useEffect, useRef } from 'react';
import { useFormState } from '../../lib/hooks';
import { FormValidityState } from '../../lib/types';
import './NLIForm.scss';

/* Not Logged In Form */

interface NLIFormProps {
  title: string,
  eyebrow: string,
  formWrapClass?: string,
  formClass?: string,
  onSubmit?: (e: FormEvent) => void,
  setter?: React.Dispatch<React.SetStateAction<FormValidityState>>,
  defaultState?: FormValidityState,
  children?: ReactElement | ReactElement[];
}

const NLIForm = (props: NLIFormProps): JSX.Element => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const formState = useFormState<FormValidityState>(formRef, props.defaultState);

  useEffect(() => {
    if (props.setter) props.setter(formState);
  }, [formState, props.setter])
  
  return (
    <div className={`form-wrap ${props.formWrapClass || ''}`}>
      <div className='eyebrow-hl-wrap'>
        <p className='eyebrow'>{props.eyebrow}</p>
        <h1>{props.title}</h1>
      </div>

      <form ref={formRef}
        className={`${props.formClass || ''}`}
        onSubmit={props.onSubmit}
      >
        {props.children}
      </form>
    </div>
  )
};

export default NLIForm;