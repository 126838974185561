import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useJobChoiceDetails } from '../../lib/hooks';
import { UserData } from '../../lib/types';
import JobDetail from '../JobDetail/JobDetail';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './RecruiterCandidateCard.module.scss';

const RecruiterCandidateCard = (props: {data: UserData}): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {jobCategoryOptions} = useJobChoiceDetails()

  const onViewProfile = () => {
    searchParams.set('profileID', props.data.uuid);
    setSearchParams(searchParams);
  }
  
  return (
    <div className={styles['container']}>
      <img className='profile-image' src={props.data.profile.avatar_image_url} />
      
      <div className={styles.nameWrap}>
        <h4>
          {props.data.profile.first_name} {props.data.profile.last_name}
          <span>
            {
              !!jobCategoryOptions &&
              jobCategoryOptions?.filter((o) => props.data.profile.categories.includes(o.value)).map(o => o.label).join(', ')
            }
            {
              props.data.profile.categories.includes('other') && props.data.profile.category_other !== '' &&
              ` (${props.data.profile.category_other})`
            }
            </span>
        </h4>
      </div>

      <div className={styles.detailsWrap}>
        {
          props.data.profile.years_experience &&
          <JobDetail icon='briefcase' text={`${props.data.profile.years_experience} years experience`} />   
        }

        {
          props.data.profile.city !== ''
          ? <JobDetail icon='map-pin' text={`${props.data.profile.city}${props.data.profile.state && props.data.profile.state !== '' ? `, ${props.data.profile.state}` : ''}`} />
          : <JobDetail icon='map-pin' 
                      text={`${props.data.profile.state && props.data.profile.state !== '' ? props.data.profile.state + ' ' : ''}${props.data.profile.zip_code}`} />
        }

        {
          props.data.profile.resumes.length > 0 &&
          <JobDetail icon='document' text={`has a resume`} />
        }
      </div>

      <button className='btn btn--oval' onClick={onViewProfile}>
        <SVGIcon id='arrow' rotate={-45} />
        View Profile
      </button>
      
    </div>
  )
};

export default RecruiterCandidateCard;