import { catchError, map, Observable, retry, tap } from 'rxjs';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { DOMAIN } from '../constants';
import Logger from '../logger';
import { loggedIn$,  userData$ } from '../state';
import { AvatarData, PasswordUpdateData, RegistrationData, ResumeData, UserData,  UserUpdateData,  VerifyEmailData, VerifyNewPWData } from '../types';
import { getDeepClone, handleFormError, objToFormData } from '../util.functions';
import AuthService from './auth.service';
import GTMService from './gtm.service';


class UserServiceClass {

  constructor() {
    loggedIn$.subscribe(b => {
      if (b) this.getUser().subscribe();
    })
  }

  register(data: RegistrationData): Observable<UserData> {
    Logger.log('UserService.register()', data);
    return ajax.post(`${DOMAIN}/api/users/`, objToFormData(data)).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      tap(() => {
        if (data.role === 'candidate' || data.role === 'employer') {
          GTMService.pushEvent({ 'event': `${data.role}_account_created` });
        }
      }),
      catchError((e: AjaxError) => handleFormError(
        e, ['email', 'password', 're_password', 'zip_code', 'first_name', 'last_name']
      )))
  }

  verifyEmail(data: VerifyEmailData): Observable<any> {
    Logger.log('UserService.verifyEmail()', data);
    return ajax.post(`${DOMAIN}/api/users/activation/`, objToFormData(data)).pipe(
      retry(2),
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e, ['uuid', 'token']))
    )
  }

  resendEmail(data: {email: string}): Observable<any> {
    Logger.log('UserService.resendEmail()', data);
    return ajax.post(`${DOMAIN}/api/users/resend_activation/`, objToFormData(data)).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e, ['email']))
    );
  }

  resetPassword(data: {email: string}): Observable<any> {
    Logger.log('UserService.resetPassword()', data);
    return ajax.post(`${DOMAIN}/api/users/reset_password/`, objToFormData(data)).pipe(
      catchError((e: AjaxError) => handleFormError(e, ['email']))
    );
  }

  verifyResetPassword(data: VerifyNewPWData): Observable<any> {
    Logger.log('UserService.verifyResetPassword()', data);
    return ajax.post(`${DOMAIN}/api/users/reset_password_confirm/`, objToFormData(data)).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e, ['uid', 'token', 'new_password', 're_new_password']))
    );
  }

  getUser(): Observable<UserData> {
    Logger.log('UserService.getUser()');
    return AuthService.tokenGuaranteeSwitchMap(this.getUserData, undefined);
  }

  updateUser(data: UserUpdateData): Observable<UserData> {
    Logger.log('UserService.updateUser()', data);
    return AuthService.tokenGuaranteeSwitchMap(this.userUpdate, data);
  }

  updatePassword(data: PasswordUpdateData): Observable<any> {
    Logger.log('UserService.updatePassword()', data);
    return AuthService.tokenGuaranteeSwitchMap(this.passwordUpdate, data);
  }

  updateAvatar(data: {uuid: string, file: File | ''}): Observable<AvatarData> {
    Logger.log('UserService.updateAvatar()', data);
    return AuthService.tokenGuaranteeSwitchMap(this.avatarUpdate, data);
  }

  private getUserData(access: string): Observable<UserData> {
    return ajax.get(`${DOMAIN}/api/users/me/`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => {
        const dta: UserData = ajaxResp.response;
        if (dta.company){
          if (dta.company.referral_source === '') dta.company.referral_source = 'default';
          if (dta.company.total_annual_revenue === '') dta.company.total_annual_revenue = 'default';
        } 
        return dta;
      }),
      tap((dta: UserData) => {
        userData$.next(dta);
      }),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private userUpdate(access: string, data: UserUpdateData): Observable<UserData> {
    return ajax.patch(`${DOMAIN}/api/users/me/`, data, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      tap((dta: UserData) => userData$.next(dta)),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private passwordUpdate(access: string, data: PasswordUpdateData): Observable<any> {
    return ajax.post(`${DOMAIN}/api/users/set_password/`, objToFormData(data), { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e, ['current_password', 'new_password', 're_new_password']))
    );
  }

  private avatarUpdate(access: string, data: {uuid: string, file: File | ''}): Observable<AvatarData> {
    return ajax.patch(`${DOMAIN}/api/profiles/${data.uuid}/avatar/`, objToFormData({avatar_image_file: data.file}), { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      tap((dta: AvatarData) => {
        const ud = getDeepClone(userData$.value);
        if (ud) ud.profile.avatar_image_url = dta.avatar_image_url;
        userData$.next(ud);
      }),
      catchError((e: AjaxError) => handleFormError(e, ['avatar_image_url', 'uuid']))
    );
  }
}

const UserService = new UserServiceClass();
export default UserService;