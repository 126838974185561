import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../lib/app-routes';
import { useSubject } from '../../lib/hooks';
import { checkoutModuleShow$, checkoutReturnUrl$, credits$ } from '../../pages/Purchase/state';
import CheckoutComponent from '../CheckoutComponent/CheckoutComponent';
import Modal from '../Modal/Modal';
import styles from './CheckoutModal.module.scss';

const CheckoutModal = (): JSX.Element => {
  const credits = useSubject(credits$);
  const show = useSubject(checkoutModuleShow$);
  const returnUrl = useSubject(checkoutReturnUrl$);
  const [searchParams, setSearchParams] = useSearchParams();

  const onCloseBtn = () => {
    searchParams.delete('packageType');
    setSearchParams(searchParams);
    checkoutModuleShow$.next(false);
  }
  
  return (
    <>
      {
        credits === 0 && returnUrl && location.pathname !== ROUTES.checkout.path &&
        <Modal show={show} modalClass={styles.checkoutModal} onCloseBtn={onCloseBtn}>
          <CheckoutComponent returnUrl={returnUrl}/>
        </Modal>
      }
    </>
  )
};

export default CheckoutModal;