import React, { ChangeEvent, FormEvent, MouseEvent, ReactElement, useEffect, useState } from 'react';
import { IS_SAFARI } from '../../lib/constants';
import { SelectAttributes, SelectOption } from '../../lib/types';
import Select from '../Select';
import SVGIcon from '../SVGIcon/SVGIcon';
import Tag from '../Tag/Tag';
import styles from './SelectAddMulti.module.scss';

interface IProps {
  attributes: SelectAttributes;
  options: SelectOption[];
  defaultValues?: string[];
  label?: string;
  defaultLabel?: string;
  className?: string;
  errorMessage?: string;
  forceError?: boolean;
  children?: ReactElement | ReactElement[];
}

const SelectAddMulti = (props: IProps): JSX.Element => {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [values, setValues] = useState<SelectOption[]>(props.defaultValues ? props.options.filter(c => props.defaultValues?.includes(c.value)) : [{label: 'empty', value: ''}]);
  const [catKey, setCatKey] = useState(0);
  const [hasError, setHasError] = useState<boolean>(!!props.forceError);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(props.errorMessage);
  const [selectEl, setSelectEl] = useState<HTMLSelectElement>();

  const onSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.isTrusted) addValue(e.target.value);
  }

  const addValue = (value: string) => {
    const vArr: SelectOption[] = [...values];
    const opt = options.find(c => c.value === value);
    if (opt) {
      opt.disabled = true;
      vArr.push(opt);
    }
    setValues(vArr.filter(o => o.value !== ''));
    setOptions([...options]);
    setCatKey(catKey + 1);
    setHasError(false);
    setErrorMsg(undefined);
  }

  const onRemoveValue = (idx: number) => {
    const opt = options.find(c => c.value === values[idx].value);
    if (opt) opt.disabled = false;
    setOptions([...options]);
    let vArr: SelectOption[] = [...values];
    vArr.splice(idx, 1);
    if (!vArr.length) vArr = [{label: 'empty', value: ''}];
    setValues(vArr);
    setTimeout(() => {
      selectEl?.dispatchEvent(new Event('change', {bubbles: true}));
    }, 200);
  }

  const onInvalid = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setHasError(true);
    setErrorMsg('At least 1 selection is required.');
    setTimeout(() => {
      document.querySelector('.input-error')?.parentElement?.scrollIntoView(IS_SAFARI ? false : {block: 'center'});
    }, 100);
  }

  useEffect(() => {
    const opts = props.options;
    if (props.defaultValues) { 
      props.defaultValues.forEach(val => {
        const opt = opts.find(o => o.value === val);
        if (opt) opt.disabled = true;
      })
    }
    //
    if (props.defaultLabel) {
      setOptions([ 
        {label: props.defaultLabel, value: 'default' }, 
        ...opts.filter((o: SelectOption) => o.value !== 'default')
      ]);
    } else {
      setOptions(opts);
    }
  }, [props.options])
  
  return (
    <div>
      <Select key={catKey}
        label={props.label}
        options={options}
        forceError={!!hasError}
        errorMessage={hasError ? errorMsg : undefined }
        attributes={{ ...props.attributes, name: 'ignore', required: false, onChange: onSelect }}
        getElement={(el: HTMLSelectElement) => setSelectEl(el)}
      />

      {
        !!values.length &&
        <div className={styles.tagsWrap} data-empty={values.length === 1 && values[0].value === ''}>
          {
            values.map((opt, i) =>
              <div key={i} className={styles.tag} data-hidden={opt.value === ''}>
                <input key={opt.value} 
                  name={opt.value === '' ? 'ignore' : props.attributes.name || props.attributes.id} 
                  data-group-index={i} 
                  defaultValue={opt.value} 
                  required={props.attributes.required || false} 
                  tabIndex={-1} 
                  hidden 
                  onInvalid={onInvalid} />
                <span>{opt.label}</span>
                <button type='button' title='delete' onClick={(e: MouseEvent) => {e.preventDefault(); onRemoveValue(i);}}>
                  <SVGIcon id='close' />
                </button>
              </div>
            )
          }
        </div>
      }
    </div>
  )
};

export default SelectAddMulti;
