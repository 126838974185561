import React from 'react';
import styles from './CoverLetterInput.module.scss';

const CoverLetterInput = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <label>Cover Letter (optional)</label>
      <textarea name='cover_letter' rows={3} placeholder='Copy and paste the text of your cover letter here.' />
    </div>
  )
};

export default CoverLetterInput;