import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import { ROUTES } from '../../../lib/app-routes';
import { useSubject } from '../../../lib/hooks';
import { DEFAULT_ADMIN_JOBS_LIFE_CYCLE_IN, draftsAppsModCount$, jobsCount$, newApplicantsCount$ } from '../state';
import { DashboardPage } from '../types';
import styles from './DashboardNav.module.scss';

const AdminDashboardNav = (props: {pageID: DashboardPage}): JSX.Element => {
  const [curActive, setCurActive] = useState<DashboardPage>();
  const [curIndex, setCurIndex] = useState<number>(1);
  const modCount = useSubject(draftsAppsModCount$);
  const reviewCount = useSubject(jobsCount$);
  const appCount = useSubject(newApplicantsCount$)
  const navigate = useNavigate();

  const onClick = (page: DashboardPage) => {
    let params = '';
    switch (page) {
      case 'applications':
        params = '?life_cycles=review';
        break;

      case 'apply-by-proxy':
        params = '?life_cycle__in=active';
        break;

      case 'jobs':
        params = `?life_cycle__in=${DEFAULT_ADMIN_JOBS_LIFE_CYCLE_IN}`;
        break;

      default:
        break;
    }
    navigate(`${ROUTES.dashboard.path.replace(':pageID', page)}${params}`);
  }

  useEffect(() => {
    setCurActive(props.pageID);
    switch (props.pageID) {
      case 'users':
        setCurIndex(0);
        break;

      case 'applications':
        setCurIndex(1);
        break;

      case 'apply-by-proxy':
        setCurIndex(2);
        break;

      case 'jobs':
        setCurIndex(3);
        break;
      
      case 'modifications':
        setCurIndex(4);
        break;

      case 'create-candidate':
        setCurIndex(5);
        break;

      default:
        break;
    }
  }, [props.pageID]);
  
  return (
    <div>
      <div className={styles['nav-wrap']} data-admin="1">
        <button onClick={() => onClick('users')}>
          <div className={styles.iconWrap} data-active={curActive === 'users'} data-notify-count={undefined}>
            <SVGIcon 
              id='people'
              color={curActive === 'users' ? 'white' : 'earthy-grey'}
            />
          </div>
          <p className='eyebrow'>All Users</p>
        </button>

        <button onClick={() => onClick('applications')}>
          <div className={styles.iconWrap} data-active={curActive === 'applications'} data-notify-count={appCount || undefined}>
            <SVGIcon 
              id='paper-airplane'
              color={curActive === 'applications' ? 'white' : 'earthy-grey'}
            />
          </div>
          <p className='eyebrow'>Applications</p>
        </button>

        <button onClick={() => onClick('apply-by-proxy')}>
          <div className={styles.iconWrap} data-active={curActive === 'apply-by-proxy'} data-notify-count={undefined}>
            <SVGIcon 
              id='order'
              color={curActive === 'apply-by-proxy' ? 'white' : 'earthy-grey'}
            />
          </div>
          <p className='eyebrow'>Apply by Proxy</p>
        </button>

        <div className={styles.line} data-length={3} data-index={curIndex}></div>
      </div>

      <div className={styles['nav-wrap']} data-admin="2">
        <button onClick={() => onClick('jobs')}>
          <div className={styles.iconWrap} data-active={curActive === 'jobs'} data-notify-count={reviewCount || undefined}>
            <SVGIcon 
              id='briefcase' 
              color={curActive === 'jobs' ? 'white' : 'earthy-grey'}
            />
          </div>
          <p className='eyebrow'>Jobs</p>
        </button>

        <button onClick={() => onClick('modifications')}>
          <div className={styles.iconWrap} data-active={curActive === 'modifications'} data-notify-count={modCount || undefined}>
            <SVGIcon 
              id='checkmark'
              color={curActive === 'modifications' ? 'white' : 'earthy-grey'}
            />
          </div>
          <p className='eyebrow'>Modified Jobs</p>
        </button>

        {<button onClick={() => onClick('create-candidate')}>
          <div className={styles.iconWrap} data-active={curActive === 'create-candidate'} data-notify-count={undefined}>
            <SVGIcon 
              id='user' 
              color={curActive === 'create-candidate' ? 'white' : 'earthy-grey'}
            />
          </div>
          <p className='eyebrow'>Create Candidate</p>
        </button>}

        <div className={styles.line} data-length={3} data-index={curIndex}></div>
      </div>
    </div>
  )
};

export default AdminDashboardNav;