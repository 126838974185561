import React, { FormEvent, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Input from '../../../components/Input';
import NLIForm from '../../../components/NLIForm/NLIForm';
import { DEFAULT_FORM_VALIDITY_STATE, MIN_LENGTH_PW, PW_LENGTH_ERROR_MSG } from '../../../lib/constants';
import { ROUTES } from '../../../lib/app-routes';
import { ErrorResponse, FormValidityState, LoginSubmitData } from '../../../lib/types';
import { getDeepClone } from '../../../lib/util.functions';
import { emailValidator } from '../../../lib/validator.functions';
import styles from './Login.module.scss';
import StorageService from '../../../lib/services/storage.service';
import AuthService from '../../../lib/services/auth.service';

const Login = (): JSX.Element => {
  const [formState, setFormState] = useState<FormValidityState>(getDeepClone(DEFAULT_FORM_VALIDITY_STATE));
  const [error, setError] = useState<ErrorResponse | null>(null);

  const onSubmit = (e: FormEvent): void => {
    e.preventDefault();
    setError(null);
    //
    const values: LoginSubmitData = { ...formState.values as unknown as LoginSubmitData };
    if (values.remember === 'on') StorageService.save('remember', values.email);
    else StorageService.remove('remember');
    delete values.remember;
    //
    AuthService.login(values).subscribe({
      error: (e: ErrorResponse) => setError(e)
    });
  };
  
  return (
    <>
      <Helmet>
        <title>JobSnob: Login</title>
      </Helmet>

      <div className='content-wrap bg1-light'>
        <div className={`content ${styles.content}`}>

        <NLIForm 
            title='Log In'
            eyebrow='Are you a snob like us?'
            onSubmit={onSubmit}
            setter={setFormState}
            defaultState={formState}
            formWrapClass={styles.formWrap}
          >
            <Input label='Email'
              forceError={!!error}
              errorMessage={error?.response.email || 'email is not valid'}
              validatorFns={[emailValidator]}
              attributes={{ type: 'email', id: 'email', required: true, 
                          defaultValue: StorageService.get('remember'), autoComplete: 'email' }} 
            />

            <Input label='Password'
              forceError={!!error}
              errorMessage={error?.response.password || PW_LENGTH_ERROR_MSG}
              attributes={{type: 'password', id: 'password', required: true , minLength: MIN_LENGTH_PW, autoComplete: 'currrent-password' }} 
            />

            <div className={styles.checkLinkWrap}>
              <Checkbox label='Remember me' attributes={{ id: 'remember', defaultChecked: true }} />
              <Link to={ROUTES.forgotPassword.path}>
                Forgot Password
              </Link>
            </div>
            

            <div className='add-margin'>
              <button className='btn' type='submit'>login</button>
            </div>
            
            <div className='link-wrap'>
              <Link to={ROUTES.registration.path}>
                i want to create an account
              </Link>
            </div>

            <div className='link-wrap'>
              <p className='eyebrow'>
                We got a facelift!
                <br />
                If you’re having trouble logging in,&nbsp;
                <Link to={ROUTES.forgotPassword.path}>
                  click here
                </Link>.
              </p>
            </div>
          </NLIForm>

          <div className={`quote-wrap ${styles['quoteWrap']}`}>
            <div className={`quote-content ${styles.quoteContent}`}>
              <p className='eyebrow'>Snob Quotes</p>
              <h2 className='quote'>“We pair smart people with smart positions<br />— and vice versa.”</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Login;
