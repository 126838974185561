import React, { ReactElement, useEffect, useState } from 'react';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './Modal.module.scss';

interface IProps {
  show: boolean;
  modalClass?: string;
  containerClass?: string;
  onCloseBtn?: () => void;
  children?: ReactElement | ReactElement[]
}

const Modal = (props: IProps): JSX.Element => {
  const [ show, setShow ] = useState(false);

  const onCloseBtn = () => {
    setShow(false);
    if (props.onCloseBtn) props.onCloseBtn();
  }

  useEffect(() => {
    setShow(props.show);
  }, [props.show])
  
  return (
    <div className={`${styles.container} ${props.containerClass || ''}`} data-show={show}>
      <div className={`${styles.modal} ${props.modalClass || ''}`} data-show={show}>
        <button className='close-btn' onClick={onCloseBtn}>
          <SVGIcon id='close' color='accent-grey'/>
        </button>
        {props.children}
      </div>
    </div>
  )
};

export default Modal;