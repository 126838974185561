import React, { useEffect, useState } from 'react';
import { CandidateData, ResumeData } from '../../lib/types';
import styles from './AdminViewUser.module.scss';
import JobDetail from '../JobDetail/JobDetail';
import { useProfileChoiceDetails } from '../../lib/hooks';
import { parsePhoneNumber } from 'libphonenumber-js';
import SVGIcon from '../SVGIcon/SVGIcon';
import ResumeService from '../../lib/services/resume.service';

const ViewApplicant = (props: {data: CandidateData, resume: ResumeData, coverLetter?: string, jobTitle: string}): JSX.Element => {
  const {
    jobCategoryOptions,
    compensationOptions,
    compensationBonusAnnualOptions,
    compensationCommissionOtherPercentageOptions,
    compensationCommissionOtherTypeOptions,
    compensationCommissionProductPercentageOptions,
    compensationCommissionProductTypeOptions,
    compensationCommissionServicePercentageOptions,
    compensationCommissionServiceTypeOptions,
    compensationHourlyRateOptions,
    compensationSalaryRangeAnnualOptions,
    compensationTotalAnnualOptions,
    candidateReferralSourceOptions,
    candidateStateOptions,
    candidateYearsExperienceOptions
  } = useProfileChoiceDetails();
  const [mapURL, setMapUrl] = useState<string>();
  const [phone, setPhone] = useState<string>();

  const onDownloadResume = () => {
    ResumeService.downloadResume({ 
      fileName: props.resume.file_name as string,
      url: props.resume.file_url as string
    }).subscribe();
  }

  useEffect(() => {
    if (props.data) {
      const url = new URL('https://www.google.com/maps/search/');
      url.searchParams.append('api', '1');
      url.searchParams.append('query', `${props.data.address} ${props.data.city} ${props.data.state} ${props.data.zip_code}`);
      setMapUrl(url.href);
      //
      if (props.data.phone_number && props.data.phone_number !== '') {
        const phoneNumber = parsePhoneNumber(props.data.phone_number, 'US')
        setPhone(props.data.phone_number.startsWith('+') ? phoneNumber.formatInternational() : phoneNumber.formatNational());
      }
    }
  }, [props.data])
  
  return (
    <div className={styles.container}>
      <div className={styles.header} data-applicant>
        <img className='profile-image' src={props.data.avatar_image_url} />
        <div className={styles.roleWrap}>
          <span className='eyebrow'>Candidate</span>
        </div>
        <h1>{props.data.first_name} {props.data.last_name}</h1>
        <h2>
          <span>
            {jobCategoryOptions?.filter((o) => props.data.categories.includes(o.value)).map(o => o.label).join(', ')}
            {props.data.categories.includes('other') && props.data.category_other !== '' ? `(${props.data.category_other})` : ''}
          </span>
        </h2>
        <h2>
          <a href={`mailto: ${props.data.email.toLowerCase()}`} target='_blank' rel='noreferrer'>
            {props.data.email}
          </a>
        </h2>
      </div>

      <div className={styles.moreInfo}>
        {
          !!props.data.years_experience &&
          <div className={styles.detailsWrap}>
            {
              candidateYearsExperienceOptions &&
              <JobDetail icon='briefcase' text={`${candidateYearsExperienceOptions.find(o => o.value === props.data.years_experience)?.label} years experience`} />
            }
          </div>
        }

        <div className={styles.left} data-candidate>
          <div className={styles.bioWrap}>
            <h3 className='eyebrow'>Cover Letter</h3>
            <div className={styles.bio}>
              {
                props.coverLetter &&
                props.coverLetter.split('\n').map((v, i) => v !== '' ? (v.length === 1 ? <span></span> : <p key={i}>{v}</p>) : null)
              }
            </div>
          </div>

          <div className={styles.bioWrap}>
            <h3 className='eyebrow'>Bio</h3>
            <div className={styles.bio}>
              {
                props.data.bio &&
                props.data.bio.split('\n').map((v, i) => v !== '' ? (v.length === 1 ? <br /> : <p key={i}>{v}</p>) : null)
              }
            </div>
          </div>

          <div className={styles.linkWrap}>
            {
              props.data.date_of_birth &&
              <div>
              <SVGIcon id='cake-birthday' />
                {new Date(props.data.date_of_birth).toLocaleDateString()}
              </div>
            }
            
            <a href={`tel: ${props.data.phone_number}`}>
              <SVGIcon id='phone' />
              {phone}
            </a>

            <a href={mapURL} target='_blank' rel='noreferrer'>
              <SVGIcon id='map-pin' />
              {
                props.data.address !== '' && `${props.data.address}, `
              }
              {
                props.data.city !== '' && `${props.data.city}, `
              }
              {
                props.data.state !== '' && `${props.data.state} `
              }
              {props.data.zip_code}
            </a>
          </div>

          {
            jobCategoryOptions &&
            <p className={styles.infoP}>Job Categories: <span>{jobCategoryOptions?.filter((o) => props.data.categories.includes(o.value)).map(o => o.label).join(', ')}</span></p>
          }

          {
            props.data.category_other &&
            <p className={styles.infoP}>Job Category (Other): <span>{props.data.category_other}</span></p>
          }

          {
            compensationOptions &&
            <p className={styles.infoP}>Compensation Type: <span>{compensationOptions.find(o => o.value === props.data.compensation)?.label}</span></p>
          }

          {
            props.data.compensation.match(/^salary$/) &&
            compensationSalaryRangeAnnualOptions &&
            <p className={styles.infoP}>Salary Range (Annual): <span>{compensationSalaryRangeAnnualOptions.find(o => o.value === props.data.compensation_salary_range_annual)?.label}</span></p>
          }

          {
            props.data.compensation.match(/^salary_commission$/) &&
            compensationSalaryRangeAnnualOptions &&
            compensationCommissionOtherPercentageOptions &&
            compensationCommissionOtherTypeOptions &&
            compensationCommissionProductPercentageOptions &&
            compensationCommissionProductTypeOptions &&
            compensationCommissionServicePercentageOptions &&
            compensationCommissionServiceTypeOptions &&
            <>
              <p className={styles.infoP}>Salary Range (Annual): <span>{compensationSalaryRangeAnnualOptions.find(o => o.value === props.data.compensation_salary_range_annual)?.label}</span></p>
              {
                props.data.compensation_commission_product &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionProductTypeOptions.find(o => o.value === props.data.compensation_commission_product_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionProductPercentageOptions.find(o => o.value === props.data.compensation_commission_product_percentage)?.label}</span></p>
                </>
              }
              {
                props.data.compensation_commission_service &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionServiceTypeOptions.find(o => o.value === props.data.compensation_commission_service_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionServicePercentageOptions.find(o => o.value === props.data.compensation_commission_service_percentage)?.label}</span></p>
                </>
              }
              {
                props.data.compensation_commission_other &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionOtherTypeOptions.find(o => o.value === props.data.compensation_commission_other_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionOtherPercentageOptions.find(o => o.value === props.data.compensation_commission_other_percentage)?.label}</span></p>
                </>
              }
            </>
          }

          {
            props.data.compensation.match(/^salary_bonus/) &&
            compensationSalaryRangeAnnualOptions &&
            compensationBonusAnnualOptions &&
            <>
              <p className={styles.infoP}>Salary Range (Annual): <span>{compensationSalaryRangeAnnualOptions.find(o => o.value === props.data.compensation_salary_range_annual)?.label}</span></p>
              <p className={styles.infoP}>Annual Bonus: <span>{compensationBonusAnnualOptions.find(o => o.value === props.data.compensation_bonus_annual)?.label}</span></p>
            </>
          }

          {
            props.data.compensation.match(/^hourly$/) &&
            compensationHourlyRateOptions &&
            <p className={styles.infoP}>Hourly Rate: <span>{compensationHourlyRateOptions.find(o => o.value === props.data.compensation_hourly_rate)?.label}</span></p>
          }

          {
            props.data.compensation.match(/^hourly_commission$/) &&
            compensationHourlyRateOptions &&
            compensationCommissionOtherPercentageOptions &&
            compensationCommissionOtherTypeOptions &&
            compensationCommissionProductPercentageOptions &&
            compensationCommissionProductTypeOptions &&
            compensationCommissionServicePercentageOptions &&
            compensationCommissionServiceTypeOptions &&
            <>
              <p className={styles.infoP}>Hourly Rate: <span>{compensationHourlyRateOptions.find(o => o.value === props.data.compensation_hourly_rate)?.label}</span></p>
              {
                props.data.compensation_commission_product &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionProductTypeOptions.find(o => o.value === props.data.compensation_commission_product_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionProductPercentageOptions.find(o => o.value === props.data.compensation_commission_product_percentage)?.label}</span></p>
                </>
              }
              {
                props.data.compensation_commission_service &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionServiceTypeOptions.find(o => o.value === props.data.compensation_commission_service_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionServicePercentageOptions.find(o => o.value === props.data.compensation_commission_service_percentage)?.label}</span></p>
                </>
              }
              {
                props.data.compensation_commission_other &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionOtherTypeOptions.find(o => o.value === props.data.compensation_commission_other_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionOtherPercentageOptions.find(o => o.value === props.data.compensation_commission_other_percentage)?.label}</span></p>
                </>
              }
            </>
          }

          {
            props.data.compensation.match(/^commission$/) &&
            compensationCommissionOtherPercentageOptions &&
            compensationCommissionOtherTypeOptions &&
            compensationCommissionProductPercentageOptions &&
            compensationCommissionProductTypeOptions &&
            compensationCommissionServicePercentageOptions &&
            compensationCommissionServiceTypeOptions &&
            <>
              {
                props.data.compensation_commission_product &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionProductTypeOptions.find(o => o.value === props.data.compensation_commission_product_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionProductPercentageOptions.find(o => o.value === props.data.compensation_commission_product_percentage)?.label}</span></p>
                </>
              }
              {
                props.data.compensation_commission_service &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionServiceTypeOptions.find(o => o.value === props.data.compensation_commission_service_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionServicePercentageOptions.find(o => o.value === props.data.compensation_commission_service_percentage)?.label}</span></p>
                </>
              }
              {
                props.data.compensation_commission_other &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionOtherTypeOptions.find(o => o.value === props.data.compensation_commission_other_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionOtherPercentageOptions.find(o => o.value === props.data.compensation_commission_other_percentage)?.label}</span></p>
                </>
              }
            </>
          }

          {
            compensationTotalAnnualOptions &&
            <p className={styles.infoP}>Total Annual Compensation: <span>{compensationTotalAnnualOptions.find(o => o.value === props.data.compensation_total_annual)?.label}</span></p>
          }

          {
            candidateReferralSourceOptions &&
            <p className={styles.infoP}>Referral Source: <span>{candidateReferralSourceOptions.find(o => o.value === props.data.referral_source)?.label || 'None'}</span></p>
          }

          {
            props.data.referral_source_other &&
            <p className={styles.infoP}>Referral Source (Other): <span>{props.data.referral_source_other}</span></p>
          }

          <div className={styles.btnWrap} data-column>
            {
              props.coverLetter &&
              <a className='btn btn--oval btn--transparent btn--border' target='_blank' rel='noreferrer'
                href={'data:attachment/text,' + encodeURI(props.coverLetter)}
                download={`${props.jobTitle}-${props.data.first_name}-${props.data.last_name}-cover-letter.txt`}
              >
                <SVGIcon id='download' />
                Download Cover Letter
              </a>
            }

            <button className='btn btn--oval btn--transparent btn--border' onClick={onDownloadResume}>
              <SVGIcon id='download' />
              Download Resume
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ViewApplicant;