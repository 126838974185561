import { MutableRefObject, useEffect, useState } from "react";
import { URLSearchParamsInit, useSearchParams } from "react-router-dom";
import { BehaviorSubject, combineLatestWith, fromEvent, map, merge, Observable, of, ReplaySubject, startWith, Subject, Subscription, switchMap, tap, timeout } from "rxjs";
import { singlePackage$, multiPackage$, credits$ } from "../pages/Purchase/state";
import { DEFAULT_FORM_VALIDITY_STATE, DEFAULT_INPUT_VALIDITY_STATE } from "./constants";
import CreditsService from "./services/credits.service";
import DetailService from "./services/detail.service";
import { jobCategoryOptions$, compensationOptions$, compensationBonusAnnualOptions$, compensationCommissionOtherPercentageOptions$, compensationCommissionOtherTypeOptions$, compensationCommissionProductPercentageOptions$, compensationCommissionProductTypeOptions$, compensationCommissionServicePercentageOptions$, compensationCommissionServiceTypeOptions$, compensationHourlyRateOptions$, compensationSalaryRangeAnnualOptions$, compensationTotalAnnualOptions$, candidateReferralSourceOptions$, candidateStateOptions$, candidateYearsExperienceOptions$, registerFlowOptions$, roleOptions$, totalAnnualRevenueOptions$, businessTypeOptions$, employerReferralSourceOptions$, stateOptions$, jobDurationOptions$, jobPostLifeCycleOptions$, serverConstants$} from "./state";
import { CreditPackageData, ErrorResponse, FormValidityState, InputValidityState, PaginateData, SelectOption, ValidarorFn, ValidatorFnAndArgs, ServerConstantsData } from "./types";
import { getDeepClone } from "./util.functions";
import { updateFormState, validateRef } from "./validator.functions";

export function useQuery(): [
  {[key: string]: string}, 
  (nextInit: URLSearchParamsInit, navigateOptions?: {replace?: boolean | undefined; state?: any;} | undefined) => void, 
  URLSearchParams
] {
  const [searchParams, setSearchParams] = useSearchParams();
  const getValues = () => {
    const o: {[key: string]: string} = {};
    searchParams.forEach((v, k) => o[k] = v);
    return o;
  }
  const [obj, setObj] = useState(getValues());
  useEffect(() => {
    setObj(getValues());
  }, [searchParams])

  return [obj, setSearchParams, searchParams];
}

export function useSubject<T>(subject$: Subject<T> | BehaviorSubject<T> | ReplaySubject<T>): T {
  const initVal = (subject$ as BehaviorSubject<T>).value;
  const [value, setValue] = useState<T>(initVal);
  useEffect(() => {
    const sub = subject$.subscribe(setValue);
    return () => sub.unsubscribe();
  }, [])
  return value;
}

export function useObservable<T>(obs$: Observable<T>): [T | undefined, Error | ErrorResponse | undefined, boolean] {
  const [value, setValue] = useState<T>();
  const [error, setError] = useState<Error | ErrorResponse>();
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    const sub = obs$.subscribe({
      next: (v) => setValue(v),
      error: (err) => setError(err),
      complete: () => setComplete(true)
    });
    return () => sub.unsubscribe();
  }, [])
  return [value, error, complete];
}

export function usePaginate<T>(obsFn$: (params: string) => Observable<PaginateData | T>, defaultParams?: string, ignore?: boolean): [PaginateData | T, Error | ErrorResponse | undefined, boolean] {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState<PaginateData | T>({count: -1, next: null, previous: null, results: [], query_params: {}});
  const [error, setError] = useState<Error | ErrorResponse>();
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    if(defaultParams) setSearchParams(defaultParams, {replace: true});
  }, [defaultParams])

  useEffect(() => {
    let str = searchParams.toString();
    let sub: Subscription;
    if (!ignore || str !== '') {
      if (complete) setComplete(false);
      sub = obsFn$(str).subscribe({
        next: (v) => setValue(v),
        error: (err) => setError(err),
        complete: () => setComplete(true)
      });
    }
    return () => {if (sub) sub.unsubscribe()};
  }, [searchParams])
  

  return [value, error, complete];
}

export function useFormState<T>(formRef: MutableRefObject<HTMLFormElement | null>, defaultState: FormValidityState = getDeepClone(DEFAULT_FORM_VALIDITY_STATE)): FormValidityState {
  const fstate: [FormValidityState | undefined, any, boolean]  = useObservable<FormValidityState>(
    of(defaultState).pipe(
      switchMap((defaultValue) => merge(
        fromEvent(formRef.current as HTMLFormElement, 'keyup'),
        fromEvent(formRef.current as HTMLFormElement, 'change'),
      ).pipe(
        tap(e => {
          if (e.isTrusted && !(e.target as HTMLElement).dataset.ignoreTouch && !(formRef.current as HTMLFormElement).dataset.touched) {
            (formRef.current as HTMLFormElement).dataset.touched = 'true';
          }
      }),
        map(() => updateFormState(formRef.current as HTMLFormElement, defaultValue)),
      ))
    )
  );
  return fstate[0] as FormValidityState;
}

export function useInputState<T>(ref: MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>, defaultState: InputValidityState = {...DEFAULT_INPUT_VALIDITY_STATE}, 
  validatorFns?: (ValidarorFn | ValidatorFnAndArgs)[]): InputValidityState {
  //
  const obs$ = of(defaultState).pipe(
    switchMap((stateValue) => merge(
      fromEvent(ref.current as HTMLInputElement, 'keyup'),
      fromEvent(ref.current as HTMLInputElement, 'change')
    ).pipe(
      map(() => validateRef(ref, stateValue, validatorFns))
    ))
  );
  //
  const state: [InputValidityState | undefined, any, boolean] = useObservable<InputValidityState>(
    obs$.pipe(timeout({
      each: 40, 
      with: () => obs$.pipe(
        startWith(validateRef(ref, defaultState, validatorFns)),
        tap((v) => {
          if (v.count === 0) {
            setTimeout(() => {
              if (ref.current) (ref.current as HTMLInputElement).dispatchEvent(new Event('change', {bubbles: true}))
            }, 20);
          }
        })
      )}
    ))
  );
  return state[0] as InputValidityState;
}

export function useSelectState<T>(ref: MutableRefObject<HTMLSelectElement | null>, defaultState: InputValidityState = {...DEFAULT_INPUT_VALIDITY_STATE}, 
  validatorFns?: (ValidarorFn | ValidatorFnAndArgs)[]): InputValidityState {
  //
  const obs$ = of(defaultState).pipe(
    switchMap((stateValue) => merge(
      fromEvent(ref.current as HTMLSelectElement, 'blur'),
      fromEvent(ref.current as HTMLSelectElement, 'change')
    ).pipe(
      map(() => validateRef(ref, state[0] || stateValue, validatorFns))
    ))
  );
  //
  const state: [InputValidityState | undefined, any, boolean] = useObservable<InputValidityState>(
    obs$.pipe(timeout({
      each: 40, 
      with: () => obs$.pipe(
        startWith(validateRef(ref, defaultState, validatorFns)),
        tap((v) => {
          if (v.count === 0) {
            setTimeout(() => {
              if (ref.current) (ref.current as HTMLSelectElement).dispatchEvent(new Event('change', {bubbles: true}))
            }, 20);
          }
        })
      )}
    ))
  );
  return state[0] as InputValidityState;
}


/* USE DETAILS HOOK FACTORY */
export function useLoadDetails<T>(subjects: Array<BehaviorSubject<T | null>>, loadFn: () => Observable<any>): Array<T | null> {
    const sub1$ = subjects.shift() as BehaviorSubject<T | null>;
    const [details] = useObservable<Array<T | null>>(sub1$.pipe(combineLatestWith(...subjects)));

    useEffect(() => {
      /* Load detail if any the coresponding BehaviorSubjects emit null */
      if (details?.reduce((prev, cur) => !prev ? prev : cur) === null) loadFn().subscribe();
    }, [details])

    if (details) return details;
    else return new Array(subjects.length + 1).fill(null);
}

export function useUserChoiceDetails(): {roleOptions: SelectOption[] | null, registerFlowOptions: SelectOption[] | null} {
  const [roleOptions, registerFlowOptions] = useLoadDetails<SelectOption[]>([roleOptions$, registerFlowOptions$], DetailService.getUserChoiceDetail);
  return {roleOptions, registerFlowOptions};
}

export function useProfileChoiceDetails(): {
  jobCategoryOptions: SelectOption[] | null,
  compensationOptions: SelectOption[] | null,
  compensationBonusAnnualOptions: SelectOption[] | null,
  compensationCommissionOtherPercentageOptions: SelectOption[] | null,
  compensationCommissionOtherTypeOptions: SelectOption[] | null,
  compensationCommissionProductPercentageOptions: SelectOption[] | null,
  compensationCommissionProductTypeOptions: SelectOption[] | null,
  compensationCommissionServicePercentageOptions: SelectOption[] | null,
  compensationCommissionServiceTypeOptions: SelectOption[] | null,
  compensationHourlyRateOptions: SelectOption[] | null,
  compensationSalaryRangeAnnualOptions: SelectOption[] | null,
  compensationTotalAnnualOptions: SelectOption[] | null,
  candidateReferralSourceOptions: SelectOption[] | null,
  candidateStateOptions: SelectOption[] | null,
  candidateYearsExperienceOptions: SelectOption[] | null
} {
  const [jobCategoryOptions, compensationOptions, compensationBonusAnnualOptions, compensationCommissionOtherPercentageOptions, compensationCommissionOtherTypeOptions, compensationCommissionProductPercentageOptions, compensationCommissionProductTypeOptions, compensationCommissionServicePercentageOptions, compensationCommissionServiceTypeOptions, compensationHourlyRateOptions, compensationSalaryRangeAnnualOptions, compensationTotalAnnualOptions, candidateReferralSourceOptions, candidateStateOptions, candidateYearsExperienceOptions] = useLoadDetails<SelectOption[]>(
    [jobCategoryOptions$, compensationOptions$, compensationBonusAnnualOptions$, compensationCommissionOtherPercentageOptions$, compensationCommissionOtherTypeOptions$, compensationCommissionProductPercentageOptions$, compensationCommissionProductTypeOptions$, compensationCommissionServicePercentageOptions$, compensationCommissionServiceTypeOptions$, compensationHourlyRateOptions$, compensationSalaryRangeAnnualOptions$, compensationTotalAnnualOptions$, candidateReferralSourceOptions$, candidateStateOptions$, candidateYearsExperienceOptions$],
    DetailService.getProfileChoiceDetail);
  return {jobCategoryOptions, compensationOptions, compensationBonusAnnualOptions, compensationCommissionOtherPercentageOptions, compensationCommissionOtherTypeOptions, compensationCommissionProductPercentageOptions, compensationCommissionProductTypeOptions, compensationCommissionServicePercentageOptions, compensationCommissionServiceTypeOptions, compensationHourlyRateOptions, compensationSalaryRangeAnnualOptions, compensationTotalAnnualOptions, candidateReferralSourceOptions, candidateStateOptions, candidateYearsExperienceOptions};
}

export function useCompanyChoiceDetails(): {
  businessTypeOptions: SelectOption[] | null, 
  employerReferralSourcOptions: SelectOption[] | null, 
  stateOptions: SelectOption[] | null, 
  totalAnnualRevenueOptions: SelectOption[] | null
} {
  const [businessTypeOptions, employerReferralSourcOptions, stateOptions, totalAnnualRevenueOptions] = useLoadDetails<SelectOption[]>(
    [businessTypeOptions$, employerReferralSourceOptions$, stateOptions$, totalAnnualRevenueOptions$], 
    DetailService.getCompanyChoiceDetail);
  return {businessTypeOptions, employerReferralSourcOptions, stateOptions, totalAnnualRevenueOptions};
}

export function useJobChoiceDetails(): {
  jobCategoryOptions: SelectOption[] | null,
  jobDurationOptions: SelectOption[] | null,
  jobPostLifeCycleOptions: SelectOption[] | null,
  stateOptions: SelectOption[] | null,
} {
  const [jobCategoryOptions, jobDurationOptions, jobPostLifeCycleOptions, stateOptions] = useLoadDetails<SelectOption[]>(
    [jobCategoryOptions$, jobDurationOptions$, jobPostLifeCycleOptions$, stateOptions$], 
    DetailService.getJobChoiceDetail);
  return {jobCategoryOptions, jobDurationOptions, jobPostLifeCycleOptions, stateOptions};
}

export function useCreditPackages(): {singlePackage: CreditPackageData | null, multiPackage: CreditPackageData | null} {
  const [singlePackage, multiPackage] = useLoadDetails<CreditPackageData>([singlePackage$, multiPackage$], CreditsService.getPackageList);
  return {singlePackage, multiPackage};
}

export function useServerConstants(): {serverConstants: ServerConstantsData | null, error: any} {
  // useLoadDetails is overkill for this
  const serverConstants = useSubject(serverConstants$);
  const [error, setError] = useState<any>();

  useEffect(() => {
    if (!serverConstants) {
      DetailService.getServerConstantsDetail().subscribe({
        error: (err) => setError(err)
      })
    }
  }, [serverConstants])
  return {serverConstants, error};
}

export function useCredits(): [number | null, any] {
  const credits = useSubject(credits$);
  const [error, setError] = useState<any>();

  useEffect(() => {
    if (credits === null) {
      CreditsService.getAvailableCredits().subscribe({
        error: (err) => setError(err)
      })
    }
  }, [credits])
  
  return [credits, error];
}
