import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { UserData } from '../../lib/types';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './AdminUserCard.module.scss';

const AdminUserCard = (props: {data: UserData}): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onViewProfile = () => {
    setSearchParams(`approve=user&approveID=${props.data.uuid}`);
  }
  
  return (
    <div className={styles['container']}>
      
      <div className={styles.nameWrap}>
        <p className='eyebrow'>{props.data.role}</p>
        <h4>
          {
            !!props.data.company && props.data.company.name
          }
          {
            !!props.data.company && !!props.data.company.name && ' - '
          }
          {
            !!props.data.profile && 
            <>{props.data.profile.first_name} {props.data.profile.last_name}</>
          }
          {
            !!props.data.profile && <br />
          }
          
          <span>{props.data.email}</span>
        </h4>
      </div>

      <div className={`eyebrow btn btn--oval ${styles.resume}`} data-active={!!props.data.profile && props.data.profile.resumes.length > 0}>
        {
          !!props.data.profile &&
          props.data.profile.resumes.length > 0 ? 'Resume' : 'No Resume'
        }
      </div>

      <div className={`eyebrow btn btn--oval ${styles.status}`} data-active={props.data.is_active}>
        {
          props.data.is_active ? 'Active' : 'Not Active'
        }
      </div>

      <button className='btn btn--oval' onClick={onViewProfile}>
        <SVGIcon id='arrow' rotate={-45} />
        View Profile
      </button>
    </div>
  )
};

export default AdminUserCard;