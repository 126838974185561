import React, { useEffect, useRef, useState } from 'react';
import {  usePaginate, useQuery, useSubject } from '../../lib/hooks';
import AdminService from '../../lib/services/admin.service';
import { ErrorResponse, UserData } from '../../lib/types';
import { dashboardPageSize, selectedJob$, selectedProfile$ } from '../../pages/Dashboard/state';
import ViewCandidateUser from '../AdminViewUser/ViewCandidateUser';
import RecruiterApplyModal from '../ApplyModal/RecruiterApplyModal';
import ApplySuccessModal from '../ApplySuccessModal/ApplySuccessModal';
import JobDisplay from '../JobDisplay/JobDisplay';
import RecruiterCandidateCard from '../RecruiterCandidateCard/RecruiterCandidateCard';
import SearchInput from '../SearchInput/SearchInput';
import UserFilters from '../Filters/UserFilters/UserFilters';
import styles from './AdminReviewJobPost.module.scss';

const AdminViewAndApply = (): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const profile = useSubject(selectedProfile$);
  const [loadError, setLoadError] = useState<Error | ErrorResponse>();
  const showingRef = useRef<HTMLParagraphElement>(null);
  const jobData = useSubject(selectedJob$);
  const [{page, search, category, geography__geo_distance, applyByProxy, applySuccess,
    role, compensation, compensation_salary_range_annual, referral_source, selectID, profileID}, setSearchParams, searchParams] = useQuery();
  const [{ count, next, previous, results}, error] = usePaginate(AdminService.getUsers, `${selectID ? `selectID=${selectID}` : ''}` +
  `&page=1&page_size=${dashboardPageSize}${role ? `&role=${role}` : ''}${search ? `&search=${search}` : ''}${compensation ? `&compensation=${compensation}` : ''}`+
  `${geography__geo_distance ? `&geography__geo_distance=${geography__geo_distance}` : ''}${category ? `&category=${category}` : ''}`+
  `${compensation_salary_range_annual ? `&compensation_salary_range_annual=${compensation_salary_range_annual}` : ''}${referral_source ? `&referral_source=${referral_source}` : ''}`,
    !!profileID
  );

  const onBack = (toSearch?: boolean) => {
    if (toSearch) {
      searchParams.set('life_cycles', 'active');
      searchParams.delete('selectID');
      searchParams.delete('role');
    } else {
      selectedProfile$.next(null);
      searchParams.delete('profileID');
    }
    setSearchParams(searchParams);
    document.body.scrollTo(0, toSearch ? 0 :(ref.current as HTMLDivElement).offsetTop - 81);
  }

  const onApply = () => {
    searchParams.set('applyByProxy', 'true');
    setSearchParams(searchParams);
  }

  const onNext = () => {
    if (next) setSearchParams(next.split('?')[1]);
    document.body.scrollTo(0,(ref.current as HTMLDivElement).offsetTop - 81);
  }

  const onPrevious = () => {
    if (previous) setSearchParams(previous.split('?')[1]);
    document.body.scrollTo(0,(ref.current as HTMLDivElement).offsetTop - 81);
  }

  const getShowStart = (): number | string => {
    return !page || page == '1' ? '1' : (parseInt(page) - 1) * dashboardPageSize + 1;
  }

  const getShowEnd = (): number | string => {
    return !page || page == '1' ? length : ((parseInt(page) - 1) * dashboardPageSize) + results.length;
  }

  const onRevealCallback = (b: boolean) => {
    if (showingRef.current) {
      (showingRef.current as HTMLParagraphElement).dataset.animWidth = b.toString();
    }
  }

  useEffect(() => {
    if (role !== 'candidate') {
      searchParams.set('role', 'candidate');
      setSearchParams(searchParams);
    }
  }, [role]);

  useEffect(() => {
    if (error) setLoadError(error);
  }, [error]);

  useEffect(() => {
    if (profileID) {
      AdminService.getUser(profileID).subscribe({
        next: dta => selectedProfile$.next(dta),
        error: err => setLoadError(err)
      })
    }
  }, [profileID]);

  return (
    <>
      <div className={`content ${styles['content']}`} style={{paddingBottom: '6px'}}>
        <div className={styles.header}>
          <h2>Selected Job Post</h2>
          <button className='btn' onClick={() => onBack(true)}>
            Back to Search
          </button>
        </div>

        <div className={styles.jobInfoWrap} data-side-by-side={false}>
          <JobDisplay data={jobData} review={true} />
        </div>

      </div>

      <div ref={ref} className={`content ${styles['content']}`}>
        {
          !profileID &&
          <>
            <div className={styles.header}>
              <h2>{`Search Candidates (${count > 0 ? count : 0})`}</h2>
              <p ref={showingRef} className={`eyebrow${error ? ' error' : ''} ${styles.showingP}`} data-anim-width="false">
                {
                  count === 0 && !loadError && 'No results'
                }
                {
                  (count === -1 || (profileID && !profile)) && !loadError && '...loading'
                }
                {
                  `SHOWING ${getShowStart()}-${getShowEnd()} OF ${count} CANDIDATES`
                }
                {
                  loadError && `ERROR! ${(loadError as ErrorResponse).response?.detail || loadError.message}`
                }
              </p>
              <div className={styles.searchFilterWrap}>
                <SearchInput alwaysCollapsible={true} revealCallback={onRevealCallback} />
                <UserFilters defaultRole='candidate' hide={['role']}/>
              </div>
            </div>

            <div className={styles.resultsWrap}>
              {
                role === 'candidate' &&
                results?.map((dta: UserData) => 
                  <RecruiterCandidateCard key={dta.uuid} data={dta} />
                )
              }
              <p className='eyebrow pages'>
                {
                  count > 0 && page &&
                  `PAGE ${page} OF ${Math.ceil(count / dashboardPageSize)}`
                }
              </p>
              <div className={styles.btnWrap}>
                {
                  previous &&
                  <button className='btn btn--white btn--border' onClick={onPrevious}>Previous</button>
                }
                {
                  next &&
                  <button className='btn btn--white btn--border' onClick={onNext}>Next</button>
                }
              </div>
            </div>
          </>
        }

        {
          profileID && profile &&
          <>
            <div className={styles.profileWrap}>
              <ViewCandidateUser data={profile} />
            </div>
            <div className={styles.btnWrap}>
              <button className='btn btn--transparent btn--border' onClick={() => onBack(false)}>Back</button>
              <button className='btn btn--dt-wide' onClick={onApply}>Apply on Candidate&apos;s Behalf</button>
            </div>
          </>
        }
        
      </div>

      {
        !!applyByProxy && !!profile &&
        <RecruiterApplyModal candidateData={profile}/>
      }

      {
        !!applySuccess &&
        <ApplySuccessModal />
      }

      
    </>
    
  )
};

export default AdminViewAndApply;