import React, { ReactElement, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from '../lib/app-routes';
import { useSubject } from '../lib/hooks';
import { userData$, loggedIn$ } from '../lib/state';
import { AppRoute } from '../lib/types';
import { findRoute } from '../lib/util.functions';

const GuardedRoutes = (): ReactElement => {
  const userData = useSubject(userData$);
  const loggedIn = useSubject(loggedIn$);
  const [ isAuthorized, setIsAuthorized ] = useState(false);
  const [ prev, setPrev ] = useState<string>();
  const loc = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const routeData: AppRoute | undefined = findRoute(loc.pathname);
    let auth = true;
    if (routeData) {
      auth = false;
      if (routeData.protectedBy.loggedIn !== undefined && (loggedIn === routeData.protectedBy.loggedIn)) auth = true;
      if (userData && routeData.protectedBy.roles && routeData.protectedBy.roles.length) {
        auth = routeData.protectedBy.roles.includes(userData.role);
      }
    }
    setIsAuthorized(auth);
    if (auth) {
      setPrev(loc.pathname + loc.search);
    } else {
      if (!loggedIn && loc.pathname + loc.search === '/dashboard/drafts?post=new') {
        navigate(ROUTES.registration.path + '?role=employer', {replace: true});
      } else if (prev) {
        navigate(prev, {replace: true});
      } else {
        navigate(loggedIn ? ROUTES.dashboard.path.replace(':pageID', 'jobs') : ROUTES.login.path, {replace: true});
      }
    }
  }, [loc])

  return (
    isAuthorized ? <Outlet/> : <></>
  )
}

export default GuardedRoutes;