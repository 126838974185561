import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { map, tap } from 'rxjs';
import { lifeCycleOptions } from '../../../lib/constants';
import { useFormState, useJobChoiceDetails, useQuery } from '../../../lib/hooks';
import GMapsService from '../../../lib/services/gmaps.service';
import StorageService from '../../../lib/services/storage.service';
import { InputAutoCompleteCallbackData, SelectOption, SelectOptionLifeCycle } from '../../../lib/types';
import { getDeepClone } from '../../../lib/util.functions';
import DropDown from '../../DropDown/DropDown';
import InputAutoComplete from '../../InputAutoComplete/InputAutoComplete';
import Select from '../../Select';
import SVGIcon from '../../SVGIcon/SVGIcon';
import Tag from '../../Tag/Tag';
import styles from './JobFilters.module.scss';


const JobFiltersLifeCycles = (props: {pageSize?: number, isWidget?: boolean, filterOut?: string[]}): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);
  const validityState = useFormState(formRef);
  const [reveal, setReveal] = useState(false);
  const [overflow, setOverflow] = useState(false);
  const {jobCategoryOptions} = useJobChoiceDetails();
  const [jobCategories, setJobCategories] = useState<SelectOption[]>([]);
  const [catValues, setCatValues] = useState<SelectOption[]>([]);
  const [catKey, setCatKey] = useState(0);
  const [lifeCycles, setLifeCycles] = useState<SelectOptionLifeCycle[]>(
    getDeepClone(lifeCycleOptions).filter((opt: SelectOptionLifeCycle) => {
      return props.filterOut ? !props.filterOut.find(v => v === opt.value) : true;
  }));
  const [lcValues, setLCValues] = useState<SelectOptionLifeCycle[]>([]);
  const [lcKey, setLCKey] = useState(0);
  const [placeID, setPlaceID] = useState<string>();
  const [disableLocation, setDisableLocation] = useState(false);
  const [geoValue, setGeoValue] = useState<SelectOption | undefined>(StorageService.get('geoValue'));
  const [geoKey, setGeoKey] = useState<string>();
  const [{categories__in, geography__geo_distance, life_cycle__in}, setSearchParams, searchParams] = useQuery();
  const [notifyCount, setNotifyCount]= useState<number>();

  const onLocationSelected = (obj: InputAutoCompleteCallbackData) => {
    setPlaceID(obj.placeID);
    setGeoKey(validityState.values.location);
    setDisableLocation(true);
  }

  const onRadiusSelected = (value: string) => {
    if (placeID) {
      GMapsService.getDetails(placeID, ['geometry'])
        .pipe(map(dta => dta.geometry?.location))
        .subscribe({
          next: (loc) => addGeo(`${value}__${loc?.lat()}__${loc?.lng()}`)
        }
      )
    }
  }

  const addGeo = (value: string) => {
    setGeoValue({ label: geoKey as string, value });
  }
  
  const onRemoveGeo = () => {
    setGeoValue(undefined);
    setDisableLocation(false);
    setPlaceID(undefined);
    setGeoKey(undefined);
  }

  const onCatSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.isTrusted) addCategory(e.target.value);
  }

  const addCategory = (value: string) => {
    const vArr: SelectOption[] = [...catValues];
    const opt = jobCategories.find(c => c.value === value);
    if (opt) {
      opt.disabled = true;
      vArr.push(opt);
    }
    setCatValues(vArr);
    setJobCategories([...jobCategories]);
    setCatKey(catKey + 1);
  }

  const onRemoveCategory = (idx: number) => {
    const opt = jobCategories.find(c => c.value === catValues[idx].value);
    if (opt) opt.disabled = false;
    setJobCategories([...jobCategories]);
    const vArr: SelectOption[] = [...catValues];
    vArr.splice(idx, 1);
    setCatValues(vArr);
  }

  const onLifeCycleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.isTrusted) addLifeCycle(e.target.value);
  }

  const addLifeCycle = (value: string) => {
    const vArr: SelectOptionLifeCycle[] = [...lcValues];
    const opt = lifeCycles.find(c => c.value === value);
    if (opt) {
      opt.disabled = true;
      vArr.push(opt);
    }
    setLCValues(vArr);
    setLifeCycles([...lifeCycles]);
    setLCKey(lcKey + 1);
  }

  const onRemoveLifeCycle = (idx: number) => {
    const opt = lifeCycles.find(c => c.value === lcValues[idx].value);
    if (opt) opt.disabled = false;
    setLifeCycles([...lifeCycles]);
    const vArr: SelectOptionLifeCycle[] = [...lcValues];
    vArr.splice(idx, 1);
    setLCValues(vArr);
  }

  const onReveal = () => {
    setTimeout(() => {
      setOverflow(!overflow)
    }, reveal ? 0 : 500);
    setReveal(!reveal)
  }

  const docClickCallback = useCallback((e: MouseEvent) => {
    if(!ref.current?.contains(e.target as Node)
    && (e.target as Node).isConnected
    && (e.target as HTMLElement).id !== 'job-filters-container'
    && (e.target as HTMLElement).id !== 'job-filters-icon-btn'){
      setOverflow(false);
      setReveal(false)
    } 
  }, [setReveal, setTimeout]);

  useEffect(() => {
    return () => {
      StorageService.remove('geoValue');
      document.removeEventListener('click', docClickCallback);
    }
  },[]);

  useEffect(() => {
    if (reveal) {
      document.addEventListener('click', docClickCallback);
    } else {
      document.removeEventListener('click', docClickCallback);
    }
  },[reveal]);

  useEffect(() => {
    if (geoValue) {
      searchParams.set('geography__geo_distance', geoValue.value);
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
      StorageService.save('geoValue', geoValue, true);
    } else if (geography__geo_distance) {
      searchParams.delete('geography__geo_distance');
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
      StorageService.remove('geoValue');
    }
  }, [geoValue])

  useEffect(() => {
    if (catValues.length) {
      searchParams.set('categories__in', catValues.map(o => o.value).join('__'));
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
    } else if (categories__in) {
      searchParams.delete('categories__in');
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
    }
  }, [catValues])

  useEffect(() => {
    if (lcValues.length) {
      searchParams.set('life_cycle__in', lcValues.map(o => o.value).join('__'));
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
    } else if (life_cycle__in) {
      searchParams.delete('life_cycle__in');
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
    }
  }, [lcValues])

  useEffect(() => {
    if (jobCategoryOptions) {
      setJobCategories([ 
        {label: 'Job Category', value: 'default' }, 
        ...getDeepClone(jobCategoryOptions).filter((o: SelectOption) => o.value !== 'default')
      ]);
    }
  }, [jobCategoryOptions])

  useEffect(() => {
    if (reveal && !categories__in) {
      jobCategories.forEach(o => o.disabled = false);
      setJobCategories([...jobCategories]);
    }
    /* populate cat tags after refresh & reveal */
    if (reveal && categories__in && !catValues.length) {
      const vArr: SelectOption[] = [];
      const split = categories__in.split('__');
      jobCategories.forEach(o => o.disabled = false);
      split.forEach(v => {
        const opt = jobCategories.find(o => o.value === v);
        if (opt) {
          opt.disabled = true;
          vArr.push(opt);
        }
      });
      setCatValues(vArr);
      setJobCategories([...jobCategories]);
      setCatKey(catKey + 1);
    }
  }, [reveal, categories__in])

  useEffect(() => {
    /* populate lc tags after refresh & reveal */
    if (reveal && life_cycle__in && !lcValues.length) {
      const vArr: SelectOptionLifeCycle[] = [];
      const split = life_cycle__in.split('__');
      lifeCycles.forEach(o => o.disabled = false);
      split.forEach(v => {
        const opt = lifeCycles.find(o => o.value === v);
        if (opt) {
          opt.disabled = true;
          vArr.push(opt);
        }
      });
      setLCValues(vArr as SelectOptionLifeCycle[]);
      setLifeCycles([...lifeCycles]);
      setLCKey(lcKey + 1);
    }
  }, [reveal, life_cycle__in])

  useEffect(() => {
    /* remove geo query if no tags on reveal */
    if (reveal && geography__geo_distance && !geoValue) {
      setGeoValue(undefined);
    }
  }, [reveal, geography__geo_distance])

  useEffect(() => {
    if (reveal) {
      setNotifyCount(undefined);
    } else {
      setNotifyCount(((categories__in?.split('__').length || 0) + (geography__geo_distance?.split(',').length || 0) + (life_cycle__in?.split('__').length || 0)) || undefined);
    }
  }, [reveal, categories__in, geography__geo_distance, life_cycle__in])
  
  return (
    <>
      <button id='job-filters-icon-btn' className={styles.icon} onClick={onReveal} disabled={reveal !== overflow} 
        data-notify-count={notifyCount} data-is-widget={props.isWidget || false}>
        <SVGIcon id='filters' />
      </button>
      <div ref={ref} id='job-filters-container' className={styles.container} data-reveal={reveal} data-overflow={overflow}
        data-is-widget={props.isWidget || false}>
        <div className={styles.titleRow}>
          <span className='eyebrow'>Add Filters</span>
          <button className={styles.icon} onClick={onReveal} disabled={reveal !== overflow}>
            <SVGIcon id='filters' />
          </button>
        </div>

        <div className={styles.row}>
          <form ref={formRef} id='filters'>

            <div className={styles.inputSelectWrap}>
              <div className={styles.locationWrap} data-loc-selected={!!placeID}>
                <InputAutoComplete key={geoKey|| disableLocation.toString()}
                  completeType='locality'
                  valueAs='place_id'
                  labelFull={true}
                  leftIcon='map-pin'
                  attributes={{ id: 'location', type: 'text', placeholder: 'Search city or ZIP codes', 
                              defaultValue: geoKey, disabled: disableLocation || undefined}}
                  onValueSet={(v) => onLocationSelected(v)}
                />
                {
                  !!placeID &&
                  <DropDown 
                    className={styles.radiusDDWrap} 
                    label='Radius' 
                    leftIcon='radio'
                    attributes={{ id: 'radius', defaultValue: '10mi'}} 
                    options={[
                      {label: '2 mi', value: '2mi'},
                      {label: '5 mi', value: '5mi'},
                      {label: '10 mi', value: '10mi'},
                      {label: '15 mi', value: '15mi'},
                      {label: '20 mi', value: '20mi'},
                    ]}
                    onSelected={onRadiusSelected}
                  />
                }
              </div>
            </div>

            <div className={styles.inputSelectWrap}>
              <Select key={catKey}
                leftIcon='briefcase'
                options={jobCategories}
                attributes={{ id: 'category', defaultValue: 'default',  onChange: onCatSelect}}
              />
            </div>

            <div className={styles.inputSelectWrap}>
              <Select key={lcKey}
                leftIcon='clock'
                options={[{label: 'Life Cycle', value: 'default'}, ...lifeCycles]}
                attributes={{ id: 'life_cycle__in', defaultValue: 'default',  onChange: onLifeCycleSelect}}
              />
            </div>

          </form> 
        </div>

        {
          (!!catValues.length || !!geoValue || !!lcValues) &&
          <div>
            <div className={styles.titleRow} data-32>
              <span className='eyebrow' data-filter-by>Filter by</span>
            </div>
            <div className={styles.filtersWrap}>
              {
                lcValues.map((o, i) => 
                  <Tag key={o.label} text={o.label} onClick={onRemoveLifeCycle} onClickArgs={[i]} isWidget={props.isWidget}/>
                )
              }
              {
                geoValue &&  
                <Tag key={geoValue.label} text={geoValue.label} onClick={onRemoveGeo} isWidget={props.isWidget}/>
              }
              {
                catValues.map((o, i) => 
                  <Tag key={o.label} text={o.label} onClick={onRemoveCategory} onClickArgs={[i]} isWidget={props.isWidget}/>
                )
              }
            </div>
          </div>
        }
      </div>
    </>
  )
};

export default JobFiltersLifeCycles;