import { catchError, delay, map, Observable, of, tap, throwError } from 'rxjs';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { DOMAIN } from '../constants';
import Logger from '../logger';
import { annualSalaryRangeOptions$, compensationOptions$, jobCategoryOptions$, 
        candidateReferralSourceOptions$, registerFlowOptions$, roleOptions$, 
        businessTypeOptions$, employerReferralSourceOptions$, stateOptions$, totalAnnualRevenueOptions$, jobDurationOptions$, jobPostLifeCycleOptions$, serverConstants$, compensationBonusAnnualOptions$, compensationCommissionOtherPercentageOptions$, compensationCommissionOtherTypeOptions$, compensationCommissionProductPercentageOptions$, compensationCommissionProductTypeOptions$, compensationCommissionServicePercentageOptions$, compensationCommissionServiceTypeOptions$, compensationHourlyRateOptions$, compensationSalaryRangeAnnualOptions$, compensationTotalAnnualOptions$, candidateStateOptions$, candidateYearsExperienceOptions$ } from '../state';
import { CompanyChoiceDetailData, JobChoiceDetailData, ProfileChoiceDetailData, UserChoiceDetailData, ServerConstantsData } from '../types';
import StorageService from './storage.service';


export default abstract class DetailService {

  public static getUserChoiceDetail(): Observable<UserChoiceDetailData | null> {
    if (DetailService.loadingStatus.userChoiceDetail) return of(null);
    DetailService.loadingStatus.userChoiceDetail = true;
    //
    return ajax.get(`${DOMAIN}/api/choices/users/`).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      map((dta: UserChoiceDetailData) => {
        return { 
          registration_flow: dta.registration_flow,
          role: [ {value: 'default', label: ''}, ...dta.role]
        }
      }),
      tap((dta: UserChoiceDetailData) => {
        Logger.info('UserChoiceDetail', dta);
        StorageService.save('roleOptions', dta.role, true)
                      .save('registerFlowOptions', dta.registration_flow, true);
        registerFlowOptions$.next(dta.registration_flow);
        roleOptions$.next(dta.role);
        DetailService.loadingStatus.userChoiceDetail = false;
      }),
      catchError(err => {
        DetailService.loadingStatus.userChoiceDetail = false;
        return DetailService.handleError(err, 'User Choice Detail load error');
      })
    );
  }

  public static getProfileChoiceDetail(): Observable<ProfileChoiceDetailData | null> {
    if (DetailService.loadingStatus.profileChoiceDetail) return of(null);
    DetailService.loadingStatus.profileChoiceDetail = true;
    //
    return ajax.get(`${DOMAIN}/api/choices/profiles/`).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      map((dta: ProfileChoiceDetailData) => {
        return { 
          categories: [{value: 'default', label: ''}, ...dta.categories],
          compensation: [{value: 'default', label: ''}, ...dta.compensation],
          compensation_bonus_annual: [{value: 'default', label: ''}, ...dta.compensation_bonus_annual],
          compensation_commission_other_percentage: [{value: 'default', label: '%*'}, ...dta.compensation_commission_other_percentage],
          compensation_commission_other_type: [{value: 'default', label: 'Revenue Type*'}, ...dta.compensation_commission_other_type],
          compensation_commission_product_percentage: [{value: 'default', label: '%*'}, ...dta.compensation_commission_product_percentage],
          compensation_commission_product_type: [{value: 'default', label: 'Revenue Type*'}, ...dta.compensation_commission_product_type],
          compensation_commission_service_percentage: [{value: 'default', label: '%*'}, ...dta.compensation_commission_service_percentage],
          compensation_commission_service_type: [{value: 'default', label: 'Revenue Type*'}, ...dta.compensation_commission_service_type],
          compensation_hourly_rate: [{value: 'default', label: ''}, ...dta.compensation_hourly_rate],
          compensation_salary_range_annual: [{value: 'default', label: ''}, ...dta.compensation_salary_range_annual],
          compensation_total_annual: [{value: 'default', label: ''}, ...dta.compensation_total_annual],
          referral_source: [{value: 'default', label: ''}, ...dta.referral_source],
          state: [{value: 'default', label: ''}, ...dta.state],
          years_experience: [{value: 'default', label: ''}, ...dta.years_experience]
        }
      }),
      tap((dta: ProfileChoiceDetailData) => {
        Logger.info('ProfileChoiceDetail', dta);
        StorageService.save('jobCategoryOptions', dta.categories.sort((a, b) => a.label > b.label ? 1 : -1), true)
                      .save('compensationOptions', dta.compensation, true)
                      .save('compensationBonusAnnualOptions', dta.compensation_bonus_annual, true)
                      .save('compensationCommissionOtherPercentageOptions', dta.compensation_commission_other_percentage, true)
                      .save('compensationCommissionOtherTypeOptions', dta.compensation_commission_other_type, true)
                      .save('compensationCommissionProductPercentageOptions', dta.compensation_commission_product_percentage, true)
                      .save('compensationCommissionProductTypeOptions', dta.compensation_commission_product_type, true)
                      .save('compensationCommissionServicePercentageOptions', dta.compensation_commission_service_percentage, true)
                      .save('compensationCommissionServiceTypeOptions', dta.compensation_commission_service_type, true)
                      .save('compensationHourlyRateOptions', dta.compensation_hourly_rate, true)
                      .save('compensationSalaryRangeAnnualOptions', dta.compensation_salary_range_annual, true)
                      .save('compensationTotalAnnualOptions', dta.compensation_total_annual, true)
                      .save('candidateReferralSourceOptions', dta.referral_source, true)
                      .save('candidateStateOptions', dta.state, true)
                      .save('candidateYearsExperienceOptions', dta.years_experience, true);
        jobCategoryOptions$.next(dta.categories);
        compensationOptions$.next(dta.compensation);
        compensationBonusAnnualOptions$.next(dta.compensation_bonus_annual);
        compensationCommissionOtherPercentageOptions$.next(dta.compensation_commission_other_percentage);
        compensationCommissionOtherTypeOptions$.next(dta.compensation_commission_other_type);
        compensationCommissionProductPercentageOptions$.next(dta.compensation_commission_product_percentage);
        compensationCommissionProductTypeOptions$.next(dta.compensation_commission_product_type);
        compensationCommissionServicePercentageOptions$.next(dta.compensation_commission_service_percentage);
        compensationCommissionServiceTypeOptions$.next(dta.compensation_commission_service_type);
        compensationHourlyRateOptions$.next(dta.compensation_hourly_rate);
        compensationSalaryRangeAnnualOptions$.next(dta.compensation_salary_range_annual);
        compensationTotalAnnualOptions$.next(dta.compensation_total_annual);
        candidateReferralSourceOptions$.next(dta.referral_source);
        candidateStateOptions$.next(dta.state);
        candidateYearsExperienceOptions$.next(dta.years_experience);
        DetailService.loadingStatus.profileChoiceDetail = false;
      }),
      catchError(err => {
        DetailService.loadingStatus.profileChoiceDetail = false;
        return DetailService.handleError(err, 'Profile Choice Detail load error');
      })
    );
  }

  public static getCompanyChoiceDetail(): Observable<CompanyChoiceDetailData | null> {
    if (DetailService.loadingStatus.companyChoiceDetail) return of(null);
    DetailService.loadingStatus.companyChoiceDetail = true;
    //
    return ajax.get(`${DOMAIN}/api/choices/companies/`).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      map((dta: CompanyChoiceDetailData) => {
        return { 
          business_type: [{value: 'default', label: ''}, ...dta.business_type],
          referral_source: [{value: 'default', label: '', hidden: true}, ...dta.referral_source],
          state: [{value: 'default', label: ''}, ...dta.state],
          total_annual_revenue: [{value: 'default', label: ''}, ...dta.total_annual_revenue]
        }
      }),
      tap((dta: CompanyChoiceDetailData) => {
        Logger.info('CompanyChoiceDetailData', dta);
        StorageService.save('businessTypeOptions', dta.business_type, true)
                      .save('employerReferralSourceOptions', dta.referral_source, true)
                      .save('stateOptions', dta.state, true)
                      .save('totalAnnualRevenueOptions', dta.total_annual_revenue, true);
        businessTypeOptions$.next(dta.business_type);
        employerReferralSourceOptions$.next(dta.referral_source);
        stateOptions$.next(dta.state);
        totalAnnualRevenueOptions$.next(dta.total_annual_revenue);
        DetailService.loadingStatus.companyChoiceDetail = false;
      }),
      catchError(err => {
        DetailService.loadingStatus.companyChoiceDetail = false;
        return DetailService.handleError(err, 'Company Choice Detail load error');
      })
    );
  }
  public static getJobChoiceDetail(): Observable<JobChoiceDetailData | null> {
    if (DetailService.loadingStatus.jobChoiceDetail) return of(null);
    DetailService.loadingStatus.jobChoiceDetail = true;
    //
    return ajax.get(`${DOMAIN}/api/choices/jobs/`).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      map((dta: JobChoiceDetailData) => {
        return { 
          categories: [{value: 'default', label: ''}, ...dta.categories.sort((a, b) => a.label > b.label ? 1 : -1)],
          duration: [{value: 'default', label: ''}, ...dta.duration],
          life_cycle: [{value: 'default', label: ''}, ...dta.life_cycle],
          state: [{value: 'default', label: ''}, ...dta.state]
        }
      }),
      tap((dta: JobChoiceDetailData) => {
        Logger.info('JobChoiceDetailData', dta);
        StorageService.save('jobCategoryOptions', dta.categories, true)
                      .save('jobDurationOptions', dta.duration, true)
                      .save('jobPostLifeCycleOptions', dta.life_cycle, true)
                      .save('stateOptions', dta.state, true);
        jobCategoryOptions$.next(dta.categories);
        jobDurationOptions$.next(dta.duration);
        jobPostLifeCycleOptions$.next(dta.life_cycle);
        stateOptions$.next(dta.state);
        DetailService.loadingStatus.jobChoiceDetail = false;
      }),
      catchError(err => {
        DetailService.loadingStatus.jobChoiceDetail = false;
        return DetailService.handleError(err, 'Job Choice Detail load error');
      })
    );
  }

  public static getServerConstantsDetail(): Observable<ServerConstantsData | null> {
    if (DetailService.loadingStatus.serverConstantsDetail) return of(null);
    DetailService.loadingStatus.serverConstantsDetail = true;
    //
    return ajax.get(`${DOMAIN}/api/constants/`).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      map((dta: ServerConstantsData) => {
        return {
          job_snob_company_uuid: dta.job_snob_company_uuid
        }
      }),
      tap((dta: ServerConstantsData) => {
        Logger.info('ServerConstantsDetail', dta);
        StorageService.save('serverConstants', dta, true);
        serverConstants$.next(dta);
        DetailService.loadingStatus.serverConstantsDetail = false;
      }),
      catchError(err => {
        DetailService.loadingStatus.serverConstantsDetail = false;
        return DetailService.handleError(err, 'Server Constants Detail load error');
      })
    );
  }

  private static handleError(err: AjaxError, msg: string): Observable<never> {
    Logger.error(msg);
    return throwError(() => err);
  }

  private static loadingStatus = {
    userChoiceDetail: false,
    profileChoiceDetail: false,
    companyChoiceDetail: false,
    jobChoiceDetail: false,
    serverConstantsDetail: false,
  }

}
