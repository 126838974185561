import React from 'react';
import { Helmet } from 'react-helmet';
import LPMatchmaker from './LPMatchmaker/LPMatchmaker';
import LPHero from './LPHero/LPHero';
import LPRecruiter from './LPRecruiter/LPRecruiter';
// import LPData from './LPData/LPData';
import LPNewsletter from './LPNewsletter/LPNewsletter';
import ErrorBoundary from '../../components/ErrorBoundary';

const LandingPage = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>JobSnob | Industry Leader for Medical Aesthetics Jobs</title>
      </Helmet>
      <div className={`content-wrap content-wrap--no-padding-m`}>
        <div className='content'>
          <ErrorBoundary>
            <LPHero />
            <LPRecruiter />
            <LPMatchmaker />
            {/* <LPData /> */}
            <LPNewsletter />
          </ErrorBoundary>
        </div>
      </div>
    </>
  )
};

export default LandingPage;
