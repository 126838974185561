import React from 'react';
import { Helmet } from 'react-helmet';
import CheckoutSuccessComponent from '../../../components/CheckoutSuccessComponent/CheckoutSuccessComponent';

const CheckoutSuccess = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <title>JobSnob: Payment Successful</title>
      </Helmet>
      <div className='content-wrap bg-purchase-success'>
        <CheckoutSuccessComponent />
      </div>
    </>
  )
};

export default CheckoutSuccess;
