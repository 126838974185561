import React from 'react';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './Tag.module.scss';

const Tag = (props: {text: string; onClick: (...args: any[]) => void; onClickArgs?: any[], isWidget?: boolean}): JSX.Element => {
  return (
    <div className={styles.tag} data-is-widget={props.isWidget || false}>
      {props.text}
      <button onClick={() => props.onClick.apply(undefined, props.onClickArgs || [])}>
        <SVGIcon id='close' />
      </button>
    </div>
  )
};

export default Tag;