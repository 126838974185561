import React, { useEffect, useState } from 'react';
import { find, repeat, switchMap, take, tap } from 'rxjs';
import { ROUTES } from '../../lib/app-routes';
import { useQuery, useSubject } from '../../lib/hooks';
import CreditsService from '../../lib/services/credits.service';
import EmployerJobService from '../../lib/services/employer.job.service';
import { ErrorResponse } from '../../lib/types';
import { getDeepClone } from '../../lib/util.functions';
import { myJobs$ } from '../../pages/Dashboard/state';
import { checkoutSuccessModuleShow$ } from '../../pages/Purchase/state';
import CheckoutSuccessComponent from '../CheckoutSuccessComponent/CheckoutSuccessComponent';
import Modal from '../Modal/Modal';
import styles from './CheckoutSuccessModal.module.scss';

const CheckoutSuccessModal = (): JSX.Element => {
  const show = useSubject(checkoutSuccessModuleShow$);
  const [{checkoutSuccess, autoSubmit}, setSearchParams, searchParams] = useQuery();
  const [error, setError] = useState<ErrorResponse>();

  const onCloseBtn = () => {
    searchParams.delete('checkoutSuccess');
    searchParams.delete('autoSubmit');
    setSearchParams(searchParams);
    checkoutSuccessModuleShow$.next(false);
  }

  useEffect(() => {
    if (show) {
      searchParams.delete('payment_intent');
      searchParams.delete('payment_intent');
      searchParams.delete('payment_intent_client_secret');
      searchParams.delete('redirect_status');
      setSearchParams(searchParams);
    }
  }, [show])

  useEffect(() => {
    if (autoSubmit) {
      CreditsService.getAvailableCredits().pipe(
        repeat({delay: 500, count: 6}),
        find(dta => dta.count > 0),
        take(1),
        switchMap(() => EmployerJobService.getJob(autoSubmit)),
        switchMap((dta) => {
          dta.life_cycle = 'review';
          // User is submitting for review, therefore a posting event should be tracked
          return EmployerJobService.updateJob(dta.uuid as string, dta, true);
        })
      ).subscribe({
        next: (job) => {
          const jobs = getDeepClone(myJobs$.value);
          jobs.push(job);
          myJobs$.next(jobs);
          CreditsService.getAvailableCredits().subscribe();
        },
        error: (err) => setError(err)
      });
    }
  }, [autoSubmit])
  
  return (
    <>
    {
      checkoutSuccess && location.pathname !== ROUTES.checkoutSuccess.path &&
      <Modal show={show} modalClass={styles.successModal} onCloseBtn={onCloseBtn}>
        <CheckoutSuccessComponent onModalClose={onCloseBtn}/>
      </Modal>
    }
    {
      !!error &&
      <Modal show={!!error} onCloseBtn={() => setError(undefined)}>
        <p className='eyebrow error' style={{width: '100%', textAlign: 'center', fontSize: '22px'}}>ERROR!</p>
        <p>There was a problem submittimg your job post. Your job has been saved as a draft.</p>
      </Modal>
    }
    </>
    
  )
};

export default CheckoutSuccessModal;