import React from 'react';
import SVGIcon from '../SVGIcon/SVGIcon';
import { IconColor } from '../SVGIcon/types';
import './SocialMedia.scss';

interface SocialMediaProps {
  fbColor: IconColor;
  instaColor: IconColor;
  inColor: IconColor;
  altFB?: boolean;
  altOrder?: boolean;
  size: 24 | 21;
}

const SocialMedia = (props: SocialMediaProps): JSX.Element => {
  
  return (
    <div className={`social-media-wrap size-${props.size}${props.altOrder ? ' alt-order': ''}`}>
      <a className='fb-link' href='https://www.facebook.com/TheRealJobSnob/' target='_blank' rel='noreferrer'>
        <SVGIcon id={props.altFB ? 'facebook-alt' : 'facebook'} color={props.fbColor} />
      </a>
      <a className='insta-link' href='https://www.instagram.com/therealjobsnob/?hl=en' target='_blank' rel='noreferrer'>
        <SVGIcon id='instagram' color={props.instaColor} />
      </a>
      <a className='in-link' href='https://www.linkedin.com/in/job-snob-0b88a4141' target='_blank' rel='noreferrer'>
        <SVGIcon id='linkedin' color={props.inColor} />
      </a>
    </div>
  )
};

export default SocialMedia;