import { catchError, map, Observable, retry, switchMap, take, tap, throwError } from 'rxjs';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { draftsAppsModCount$, jobsCount$, newApplicantsCount$ } from '../../pages/Dashboard/state';
import { credits$ } from '../../pages/Purchase/state';
import { DOMAIN } from '../constants';
import Logger from '../logger';
import { accessToken$, refreshToken$, loggedIn$, userData$ } from '../state';
import { LoginSubmitData, LoginResponseData } from '../types';
import { handleFormError, objToFormData } from '../util.functions';
import StorageService from './storage.service';


export default abstract class AuthService {

  public static login(data: LoginSubmitData ): Observable<LoginResponseData> {
    Logger.log('AuthService.login()', data);
    return ajax.post(`${DOMAIN}/api/jwt/create/`, objToFormData(data)).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response as LoginResponseData),
      tap((dta: LoginResponseData) => {
        accessToken$.next(dta.access);
        refreshToken$.next(dta.refresh);
        loggedIn$.next(true);
        StorageService.save('loggedIn', true).save('refresh', dta.refresh, true);
      }),
      catchError((e: AjaxError) => handleFormError(e, ['email', 'password'])))
  }

  public static onLogout(): void {
    Logger.log('AuthService.onLogout()');
    const remember = StorageService.get('remember');
    userData$.next(null);
    accessToken$.next(null);
    refreshToken$.next(null);
    credits$.next(null);
    jobsCount$.next(0);
    draftsAppsModCount$.next(0);
    newApplicantsCount$.next(0);
    StorageService.clear();
    if (remember) StorageService.save('remember', remember);
  }

  public static refresh(): Observable<{access: string} | never> {
    Logger.log('AuthService.refresh()');
    const refresh = refreshToken$.value;

    const obs$ = ajax.post(`${DOMAIN}/api/jwt/refresh/`, objToFormData({refresh})).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      tap((dta: {access: string}) => {
        accessToken$.next(dta.access);
      }),
      catchError((e: any) => {
        loggedIn$.next(false);
        return throwError(() => e);
      })
    );
    return obs$.pipe(retry({count: 1, delay: 2000}));
  }

  public static tokenGuaranteeSwitchMap<T>(obsFn$: (token: string, dta: T) => Observable<any>, data: T): Observable<any> {
    Logger.log('AuthService.tokenGuaranteeSwitchMap()');
    return accessToken$.pipe(
      take(1),
      switchMap((token) => token
        ? obsFn$(token, data).pipe(
          catchError((err) => err.response?.code === 'token_not_valid' 
          ? this.refresh().pipe(switchMap((obj) => obsFn$(obj.access, data)))
          : throwError(() => err)
        ))
        : this.refresh().pipe(
          switchMap((obj) => obsFn$(obj.access, data))
        )
      )
    );
  }

}
