import React, { useEffect, useState } from 'react';
import { UserData } from '../../lib/types';
import styles from './AdminViewUser.module.scss';
import JobDetail from '../JobDetail/JobDetail';
import { parsePhoneNumber } from 'libphonenumber-js';
import SVGIcon from '../SVGIcon/SVGIcon';
import { useCompanyChoiceDetails } from '../../lib/hooks';

const ViewEmployerUser = (props: {data: UserData, mapUrl?: string, phone?: string}): JSX.Element => {
  const {
    businessTypeOptions,
    employerReferralSourcOptions,
    totalAnnualRevenueOptions
  } = useCompanyChoiceDetails();
  const [mapURL, setMapUrl] = useState<string>();
  const [phone, setPhone] = useState<string>();

  useEffect(() => {
    if (props.data) {
      const url = new URL('https://www.google.com/maps/search/');
      url.searchParams.append('api', '1');
      url.searchParams.append('query', `${props.data.company?.state} ${props.data.company?.zip_code}`);
      setMapUrl(url.href);
      //
      if (props.data.profile.phone_number && props.data.profile.phone_number !== '') {
        const phoneNumber = parsePhoneNumber(props.data.profile.phone_number, 'US')
        setPhone(props.data.profile.phone_number.startsWith('+') ? phoneNumber.formatInternational() : phoneNumber.formatNational());
      }
    }
  }, [props.data])
  return (
    <>
      <div className={styles.header}>
        <div className={styles.roleWrap}>
          <span className='eyebrow'>{props.data.role}</span>
          <JobDetail
            icon='user' 
            text={props.data.is_active ? 'active' : 'not active'} 
            lifeCycle={props.data.is_active ? 'active' : 'deactivated'}
          />
        </div>
        <h1>{props.data.profile.first_name} {props.data.profile.last_name} {props.data.company?.name ? `- ${props.data.company?.name}` : ''}</h1>
        <h2>
          <a href={`mailto: ${props.data.email.toLowerCase()}`} target='_blank' rel='noreferrer'>
            {props.data.email}
          </a>
        </h2>
      </div>

      <div className={styles.moreInfo}>

        <div className={styles.left}>
          <div className={styles.linkWrap}>
            <a href={`tel: ${props.data.profile.phone_number}`}>
              <SVGIcon id='phone' />
              {phone}
            </a>

            <a href={mapURL} target='_blank' rel='noreferrer'>
              <SVGIcon id='map-pin' />
              {
                props.data.company?.state !== '' && `${props.data.company?.state} `
              }
              {props.data.company?.zip_code}
            </a>

            <a href={props.data.company?.website_url} target='_blank' rel='noreferrer'>
              <SVGIcon id='globe' />
              {props.data.company?.website_url}
            </a>

            {
              props.data.company?.facebook_url !== '' 
              ?
              <a href={props.data.company?.facebook_url} target='_blank' rel='noreferrer'>
                <SVGIcon id='facebook-alt' />
                {props.data.company?.facebook_url}
              </a>
              :
              <a>
                <SVGIcon id='facebook-alt' />
                NONE
              </a>
            }

            {
              props.data.company?.instagram_url !== '' 
              ?
              <a href={props.data.company?.instagram_url} target='_blank' rel='noreferrer'>
                <SVGIcon id='instagram' />
                {props.data.company?.instagram_url}
              </a>
              :
              <a>
                <SVGIcon id='instagram' />
                NONE
              </a>
            }
          </div>
          {
            employerReferralSourcOptions &&
            <p className={styles.infoP}>Referral Source: <span>{employerReferralSourcOptions.find(o => o.value === props.data.company?.referral_source)?.label || 'None'}</span></p>
          }

          {
            props.data.company?.referral_source === 'other' &&
            <p className={styles.infoP}>Other Referral Source: <span>{props.data.company?.referral_source_other}</span></p>
          }
        </div>

        <div className={styles.right}>
          {
            businessTypeOptions &&
            <p className={styles.infoP}>Type of Business: <span>{businessTypeOptions.find(o => o.value === props.data.company?.business_type)?.label}</span></p>
          }

          {
            props.data.company?.business_type === 'other' &&
            <p className={styles.infoP}>Other Business Type: <span>{props.data.company?.business_type_other}</span></p>
          }

          <p className={styles.infoP}>Number of Current Locations: <span>{props.data.company?.number_of_locations_current}</span></p>
          <p className={styles.infoP}>Number of Projected Locations in 12 Months: <span>{props.data.company?.number_of_locations_projected}</span></p>
          <p className={styles.infoP}>Number of W-2 &amp; contract employees: <span>{props.data.company?.number_of_employees}</span></p>
          <p className={styles.infoP}>Number of Practicing MDs: <span>{props.data.company?.number_of_doctors}</span></p>
          <p className={styles.infoP}>Number of Mid-level Providers: <span>{props.data.company?.number_of_providers}</span></p>

          {
            totalAnnualRevenueOptions &&
            <p className={styles.infoP}>Total Annual Revenue: <span>{totalAnnualRevenueOptions.find(o => o.value === props.data.company?.total_annual_revenue)?.label}</span></p>
          }
        </div>
      </div>
    </>
  )
};

export default ViewEmployerUser;