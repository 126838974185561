import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSubject } from '../../lib/hooks';
import { navMenuShow$, userData$, userMenuShow$ } from '../../lib/state';
import NavMenu from './NavMenu/NavMenu';
import SVGIcon from '../SVGIcon/SVGIcon';
import './Header.scss';
import UserMenu from './UserMenu/UserMenu';

const Header = (): JSX.Element => {
  const userData = useSubject(userData$);
  const showUserMenu = useSubject(userMenuShow$);
  const location = useLocation();

  useEffect(() => {
    /* close menus when location changes */
    navMenuShow$.next(false);
    userMenuShow$.next(false);
  }, [location]);
  
  return (
    <header>
      <nav className='content'>
        <Link to='/' className='logo'>
          <SVGIcon id='logo' color='white' />
        </Link>

        <NavMenu />

         <div className='icons-wrap'>
          <button className='menu' onClick={() => navMenuShow$.next(true)}>
            <SVGIcon id='menu' color='white' />
          </button>

          <button id='user-menu-btn' className='user' onClick={() => userMenuShow$.next(!showUserMenu)}>
            {
              !userData &&
              <SVGIcon id='user' color='white' />
            }
            {
              userData && userData.role !== 'candidate' &&
              <SVGIcon id='shiny-diamond' color='white' />
            }
            {
              userData && userData.role === 'candidate' &&
              <>
              {
                userData.profile.avatar_image_url
                ? <img className='profile-image' src={userData.profile.avatar_image_url} />
                : <SVGIcon id='user' color='white' />
              }
              </>
            }
          </button>
        </div>

        <UserMenu />
        
      </nav>
    </header>
  )
};

export default Header;