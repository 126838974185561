import React, { useState } from 'react';
import { useSubject } from '../../lib/hooks';
import CandidateJobService from '../../lib/services/candidate.job.service';
import { loggedIn$ } from '../../lib/state';
import SVGIcon from '../SVGIcon/SVGIcon';

import styles from './FavButton.module.scss';

interface FavButtonProps {
  jobID: string;
  isFavorited: boolean;
  disabled?: boolean;
  isWidget?: boolean;
  onClick?: () => void;
  onFavCallback?: (dta: {uuid: string; is_favorited: boolean}) => void;
  onFavError?: (err: Error) => void;
}

const FavButton = (props: FavButtonProps): JSX.Element => {
  const [isFav, setIsFav] = useState<boolean>(props.isFavorited);
  const [error, setError] = useState<string>();
  const loggedIn = useSubject(loggedIn$);

  const onClick = () => {
    if (props.onClick) props.onClick();
    if (error) setError(undefined);
    if (!loggedIn) return;
    CandidateJobService.markFavorite(props.jobID, !isFav).subscribe({
      next: dta => {
        setIsFav(dta.is_favorited);
        if (props.onFavCallback) props.onFavCallback(dta);
      },
      error: (err: Error) => {
        setError(err.message);
        if (props.onFavError) props.onFavError(err);
      }
    });
  }
  
  return (
    <button className={styles.favBtn} disabled={props.disabled} onClick={onClick} data-is-favorited={isFav} data-is-widget={props.isWidget || false}>
      <SVGIcon id='star' color='white'/>
      {
        error &&
        <p className='input-error'>{error}</p>
      }
    </button>
  )
};

export default FavButton;