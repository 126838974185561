import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CandidateJobCard from '../../../components/CandidateJobCard/CandidateJobCard';
import EmployerJobCard from '../../../components/EmployerJobCard/EmployerJobCard';
import JobFilters from '../../../components/Filters/JobFilters/JobFilters';
import JobFiltersLifeCycles from '../../../components/Filters/JobFilters/JobFiltersLifeCycles';
import SearchInput from '../../../components/SearchInput/SearchInput';
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import { ROUTES } from '../../../lib/app-routes';
import { usePaginate, useQuery, useSubject } from '../../../lib/hooks';
import CandidateJobService from '../../../lib/services/candidate.job.service';
import EmployerJobService from '../../../lib/services/employer.job.service';
import { ApplicantData, ErrorResponse, JobPostData } from '../../../lib/types';
import { getDeepClone } from '../../../lib/util.functions';
import { dashboardPageSize, draftsAppsModCount$, jobsCount$, myDraftsOrApps$, myJobs$ } from '../state';
import { DashboardPage } from '../types';
import styles from './DashboardList.module.scss';

const DashboardList = (props: {type: 'employer' | 'candidate', pageID: DashboardPage}): JSX.Element => {
  const jobsData  = useSubject<JobPostData[]>(myJobs$);
  const draftsOrAppsData  = useSubject<JobPostData[] | ApplicantData[]>(myDraftsOrApps$);
  const jobsCount  = useSubject<number>(jobsCount$);
  const draftsOrAppsCount  = useSubject<number>(draftsAppsModCount$);
  const [{page, life_cycle__in, geography__geo_distance, categories__in, post, checkoutSuccess, autoSubmit}, setSearchParams, searchParams] = useQuery();
  const navigate = useNavigate();
  const [{ count, next, previous, results}, error] = usePaginate(
    props.type === 'employer' ? EmployerJobService.getJobs
    : CandidateJobService[props.pageID === 'applications' ? 'getApplications' : 'getFavorites'],
    post ? searchParams.toString() : 
    `page=1&page_size=${dashboardPageSize}${life_cycle__in ? `&life_cycle__in=${life_cycle__in}` : 
    (props.type === 'employer' ? (props.pageID === 'jobs' ? '&life_cycle__in=active__deactivated__expired__review' : '') : '')}` +
    `${geography__geo_distance ? `&geography__geo_distance=${geography__geo_distance}` : ''}${categories__in ? `&categories__in=${categories__in}` : ''}` +
    /* below is so checkout modal doesn't disappear on return to dash after purchase */
    `${checkoutSuccess ? `&checkoutSuccess=${checkoutSuccess}` : ''}${autoSubmit ? `&autoSubmit=${autoSubmit}` : ''}`
  );

  const onNext = () => {
    if (next) setSearchParams(next.split('?')[1]);
    document.body.scrollTo(0,0);
  }

  const onPrevious = () => {
    if (previous) setSearchParams(previous.split('?')[1]);
    document.body.scrollTo(0,0);
  }

  const getShowStart = (): number | string => {
    return !page || page == '1' ? '1' : (parseInt(page) - 1) * dashboardPageSize + 1;
  }

  const getShowEnd = (): number | string => {
    const length = props.pageID === 'jobs' ? jobsData.length : draftsOrAppsData.length;
    return !page || page == '1' ? length : ((parseInt(page) - 1) * dashboardPageSize) + length;
  }

  const onPostNew = () => {
    if (props.pageID === 'drafts') setSearchParams('post=new');
    else navigate(ROUTES.dashboard.path.replace(':pageID', 'drafts') + '?post=new');
  }

  const onFavCallback = (dta: {uuid: string, is_favorited: boolean}) => {
    const updateJobsData = (getDeepClone(jobsData) as JobPostData[]).filter(d => d.uuid !== dta.uuid);
    myJobs$.next(updateJobsData);
    jobsCount$.next(jobsCount - 1);
  }

  useEffect(() => {
    if (props.type === 'employer') {
      if (props.pageID === 'jobs'){
        jobsCount$.next(count);
        myJobs$.next(results);
      } else if (props.pageID === 'drafts') {
        draftsAppsModCount$.next(count);
        myDraftsOrApps$.next(results as JobPostData[]);
      }
    } else if (props.type === 'candidate') {
      if (props.pageID === 'jobs'){
        jobsCount$.next(count);
        myJobs$.next(results);
      } else if (props.pageID === 'applications') {
        draftsAppsModCount$.next(count);
        myDraftsOrApps$.next(results as ApplicantData[]);
      }
    }
  }, [results, count]);

  return (
    <div className={`content ${styles['content']}`}>
      {
        props.type === 'employer' && props.pageID === 'jobs' &&
        <div className={styles.searchWrap}>
          <SearchInput />
          <JobFiltersLifeCycles pageSize={dashboardPageSize} filterOut={['draft']} />
        </div>
      }

      {
        props.type === 'employer' && props.pageID === 'drafts' &&
        <div className={styles.searchWrap}>
          <SearchInput />
          <JobFilters pageSize={dashboardPageSize} />
        </div>
      }

      <div className={styles.header}>
        <h2>
          {
            `${props.type === 'employer' ? 'My' : (props.pageID === 'jobs' ? ' Saved' : '') } 
            ${props.pageID} (${props.pageID === 'jobs' ? jobsCount > 0 ? jobsCount : 0 : draftsOrAppsCount > 0 ? draftsOrAppsCount : 0})`
          }
        </h2>
        <p className={`eyebrow${error ? ' error' : ''} ${styles.showingP}`}>
          {
            props.pageID === 'jobs' && jobsCount > 0 && jobsData && !error &&
            `SHOWING ${getShowStart()}-${getShowEnd()} OF ${jobsCount} JOBS`
          }
          {
            (props.pageID === 'drafts' || props.pageID === 'applications') && 
            draftsOrAppsCount > 0 && draftsOrAppsData && !error &&
            `SHOWING ${getShowStart()}-${getShowEnd()} OF ${draftsOrAppsCount} ${props.pageID.toUpperCase()}`
          }
          {
            count === 0 && !error && 'No results'
          }
          {
            props.pageID === 'jobs' && jobsCount === -1 && !error && '...loading'
          }
          {
            props.pageID !== 'jobs' && draftsOrAppsCount === -1 && !error && '...loading'
          }
          {
            error && `ERROR! ${(error as ErrorResponse).response?.detail || error.message}`
          }
        </p>
        {
          props.type === 'employer' &&
          <button data-desktop className='btn btn--auto-width' onClick={onPostNew}>
            <SVGIcon id='plus' color='white' />
            Post a new job
          </button>
        }
        {
          props.type === 'candidate' &&
          <Link data-desktop to={ROUTES.jobboard.path}
            className='btn'>
            <SVGIcon id='briefcase' color='white' />
            go to job board
          </Link>
        }
      </div>

      <div className={styles.jobsContainer} data-candidate>
        {
          props.type === 'employer' && (jobsData || draftsOrAppsData) &&
          (props.pageID === 'jobs' ? jobsData : draftsOrAppsData).map((job) => 
            <EmployerJobCard 
              key={job.uuid as string + Math.random()} 
              type={props.pageID.slice(0, -1) as 'job' | 'draft'} 
              jobData={job as JobPostData}
            />
          )
        }
        {
          props.type === 'candidate' && (jobsData || draftsOrAppsData) &&
          (props.pageID === 'jobs' ? jobsData : draftsOrAppsData).map((job) => 
            <CandidateJobCard 
              key={job.uuid as string + Math.random()} 
              type={props.pageID.slice(0, -1) as 'job' | 'application'} 
              jobData={job} 
              onFavCallback={onFavCallback}
            />
          )
        }
        <p className='eyebrow pages'>
          {
            count > 0 && page &&
            `PAGE ${page} OF ${Math.ceil(count / dashboardPageSize)}`
          }
        </p>
        { 
          (previous || next) &&
          <div className={styles.bottonBtnWrap} data-desktop>
          {
            previous &&
            <button className='btn btn--grey-6 btn--border' onClick={onPrevious}>Previous</button>
          }
          {
            next &&
            <button className='btn btn--grey-6 btn--border' onClick={onNext}>Next</button>
          }
        </div>
        }
      </div>

      <div data-mobile className={styles.bottonBtnWrap}>
        {
          previous &&
          <button className='btn btn--white btn--border' onClick={onPrevious}>Previous</button>
        }
        {
          next &&
          <button className='btn btn--white btn--border' onClick={onNext}>Next</button>
        }
        {
          props.type === 'employer' &&
          <button className='btn' onClick={onPostNew}>
            <SVGIcon id='plus' color='white' />
            Post a new job
          </button>
        }
        {
          props.type === 'candidate' &&
          <Link to={ROUTES.jobboard.path}
            className={`btn${ props.pageID === 'jobs' ? ' btn--grey-6 btn--border' : ''}`}>
            <SVGIcon id='briefcase' color={props.pageID === 'jobs' ? 'black' : 'white'} />
            go to job board
          </Link>
        }
      </div>
    </div>
  )
};

export default DashboardList;