import React, { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import CandidateProfile from './CandidateProfile';
import EmployerProfile from './EmployerProfile';
import { useFormState, useSubject } from '../../../lib/hooks';
import ResumeService from '../../../lib/services/resume.service';
import UserService from '../../../lib/services/user.service';
import { userData$, loggedIn$ } from '../../../lib/state';
import { ErrorResponse, FormValidityState, UserData, UserUpdateData } from '../../../lib/types';
import styles from './Profile.module.scss';
import { Helmet } from 'react-helmet';
import AdminProfile from './AdminProfile';
import { map } from 'rxjs';
import GMapsService from '../../../lib/services/gmaps.service';
import { useNavigate } from 'react-router-dom';
import Logger from '../../../lib/logger';
import { ROUTES } from '../../../lib/app-routes';

const Profile = (): JSX.Element => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const formState = useFormState<FormValidityState>(formRef);
  const userData = useSubject<UserData | null>(userData$);
  const loggedIn = useSubject(loggedIn$);
  const navigate = useNavigate();
  const [profileSuccess, setProfileSuccess] = useState(false);
  const [profileError, setProfileError] = useState<ErrorResponse | null>(null);
  const [resumeError, setResumeError] = useState<ErrorResponse | null>(null);
  const [imgError, setImgError] = useState<ErrorResponse | null>(null);
  const [roleLabel, setRoleLabel] = useState<string>();
  const [disabled, setDisabled] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [resumeUploading, setResumeUploading] = useState(false);
  const [latitude, setLatitude] = useState<string | number>();
  const [longitude, setLongitude] = useState<string | number>();

  const setDisabledCallback = useCallback((b: boolean) => {
    setDisabled(b);
  }, [setDisabled])

  const setLatLong = (zip: string) => {
    GMapsService.getByRegion(zip).subscribe({
      next: dta => {
        if (dta.predictions.length && dta.predictions[0].place_id) {
          GMapsService.getDetails(dta.predictions[0].place_id, ['geometry'])
            .pipe(map(obj => obj.geometry?.location))
            .subscribe({
              next: (loc) => {
                setLatitude(parseFloat(loc?.lat().toFixed(9) as string));
                setLongitude(parseFloat(loc?.lng().toFixed(9) as string));
              }
            }
          )
        }
      },
        error: err => console.log(err)
    })
  }

  useEffect(() => {
    if (loggedIn && userData?.requires_onboarding) {
      Logger.log('Redirecting user to on-boarding from Profile.');
      navigate(ROUTES.onboarding.path.replace(':step', 'complete-profile'));
    }

    if (userData) {
      setRoleLabel(userData.role[0].toUpperCase() + userData.role.substring(1));
      setProfileError(null);
      setResumeError(null);
      if (userData.role === 'candidate' && !userData.profile.latitude && userData.profile.zip_code) {
        setLatLong(userData.profile.zip_code);
      }
    }
  }, [userData])

  useEffect(() => {
    if(profileError) setProfileError(null);
    if(profileSuccess) setProfileSuccess(false);
  }, [formState])

  const updateProfile = (e: FormEvent) => {
    e.preventDefault();
    if(profileError) setProfileError(null);
    if(profileSuccess) setProfileSuccess(false);
    setDisabled(true);
    //
    const data: UserUpdateData = { 
      company: {},
      profile: {}
    }
    // add value to data
    for (const key in formState.values) {
      if (Object.prototype.hasOwnProperty.call(formState.values, key)) {
        if (formState.values[key] !== '' && 
            formState.values[key] !== 'default' &&
            !key.startsWith('resume-item') &&
            key !== 'email' &&
            key !== 'avatar_image_file' &&
            key !== 'resume_file' &&
            key !== 'current_password' &&
            key !== 'new_password' &&
            key !== 're_new_password'
        ) {
          const keySplit = key.split('.');
          (data[keySplit[0] as 'profile' | 'company'] as {[key: string]: string})[keySplit[1]] = formState.values[key];
        }
      }
    }
    if (latitude) (data.profile as {[key: string]: string | number}).latitude = latitude;
    if (longitude) (data.profile as {[key: string]: string | number}).longitude = longitude;
    if (userData?.profile.category_other && !(data.profile?.categories as unknown as string[]).includes('other')) 
      (data.profile as {[key: string]: string | number}).category_other = '';
    //
    UserService.updateUser(data).subscribe({
      next: () => {
        (formRef.current as HTMLFormElement).removeAttribute('data-touched');
        (formRef.current as HTMLFormElement).dispatchEvent(new Event('change', {bubbles: true}));
        setProfileSuccess(true);
        setTimeout(() => {
          setProfileSuccess(false);
        }, 4000);
      },
      error: err => {
        document.body.scrollTo(0,0);
        setProfileError(err);
      },
      complete: () => setDisabled(false)
    });
  }

  const onUploadResume = (e: ChangeEvent<HTMLInputElement> ) => {
    if(resumeError) setResumeError(null);
    setResumeUploading(true);
    setDisabled(true);
    ResumeService.uploadResume((e.target.files as FileList)[0])
      .subscribe({
        error: err => {setResumeError(err); setResumeUploading(false);},
        complete: () => {setDisabled(false), setResumeUploading(false);}
      });
  }

  const onDeleteResume = (uuid: string) => {
    if(resumeError) setResumeError(null);
    setDisabled(true);
    ResumeService.deleteResume(uuid).subscribe({
      error: err => setResumeError(err),
      complete: () => setDisabled(false)
    });
  }

  const onUploadImage = (e: ChangeEvent<HTMLInputElement>) => {
    if(imgError) setImgError(null);
    setImageUploading(true);
    UserService.updateAvatar({file: (e.target.files as FileList)[0], uuid: userData?.profile.uuid as string })
      .subscribe({
        error: err => {setImgError(err); setImageUploading(false);},
        complete: () => setImageUploading(false)
      });
  }

  const onDeleteImage = () => {
    if(imgError) setImgError(null);
    UserService.updateAvatar({file: '', uuid: userData?.profile.uuid as string })
      .subscribe({
        error: err => setImgError(err)
      });
  }
  
  return (
    <ErrorBoundary>
      <Helmet>
        {
          roleLabel &&
          <title>JobSnob: {roleLabel} Profile</title>
        }
      </Helmet>
      <div className={`content-wrap ${styles['content-wrap']} bg-account`}>
        <div className={styles.header}>
          <p className='eyebrow'>{roleLabel}</p>
          <h1>Profile</h1>
        </div>

        <form id='profile-form' ref={formRef} onSubmit={updateProfile}>
          {
            userData &&
            <>
              { 
                userData.role === 'candidate' &&
                <CandidateProfile { ...{
                  userData,
                  onDeleteResume,
                  onUploadResume,
                  onUploadImage,
                  onDeleteImage,
                  profileError,
                  resumeError,
                  imgError,
                  formState,
                  disabled,
                  imageUploading,
                  resumeUploading,
                  setDisabledCallback
                  }
                } />
              }

              { 
                userData.role === 'employer' &&
                <EmployerProfile { ...{
                  userData,
                  profileError,
                  formState,
                  disabled,
                  setDisabledCallback
                }} />
              }

              { 
                userData.role === 'administrator' &&
                <AdminProfile { ...{
                  userData,
                  profileError,
                  formState,
                  disabled,
                  setDisabledCallback
                }} />
              }

            {
              userData.role !== 'administrator' &&
              <div className={styles.cta}>
                <button type='submit' className='btn btn--dt-wide' disabled={disabled || !formState || !formState.valid || !formState.touched}>
                  {profileSuccess ? 'Saved!' : 'Save'}
                </button>
              </div>
            }
            </>
          }
        </form>
      </div>
    </ErrorBoundary>
  )
};

export default Profile;
