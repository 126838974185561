import React from 'react';
import { take, switchMap, of, tap } from 'rxjs';
import { useObservable, useQuery } from '../../lib/hooks';
import AdminService from '../../lib/services/admin.service';
import { applicant$ } from '../../pages/Dashboard/state';
import JobDisplay from '../JobDisplay/JobDisplay';
import ScrollToTop from '../ScrollToTop';
import ViewApplicant from '../AdminViewUser/ViewApplicant';
import styles from './ApplicantReview.module.scss';

const ApplicantReview = (props: {uuid: string}): JSX.Element => {
  const [{life_cycles}, setSearchParams, searchParams] = useQuery();
  const [data, error] = useObservable(
    applicant$.pipe(
      take(1),
      switchMap(dta => dta && dta.uuid === props.uuid
        ? of(dta)
        : AdminService.getApplication(props.uuid)
      ),
      tap(d => { if (d && d.is_new) AdminService.updateApplication({uuid: props.uuid, viewed: true}).subscribe()})
    )
  );

  const onApprove = (approve: boolean) => {
    AdminService.updateApplication({uuid: props.uuid, life_cycle: approve ? 'active' : 'deactivated'})
      .subscribe({
        next: () => onGoBack(),
        error: err => console.log(err)
      })
  }

  const onGoBack = () => {
    document.body.scrollTo(0,0);
    searchParams.delete('approve');
    searchParams.delete('approveID');
    setSearchParams(searchParams);
  }
  
  return (
    <>
      <ScrollToTop />
      {
        !!data &&
        <div className={`content ${styles.statusWrap}`}>
          <p className='eyebrow'>Application Status: {data.life_cycle}</p>
        </div>
      }
      <div className={`content ${styles.content}`}>
        {
          !!data &&
          <>
            <ViewApplicant 
              data={data.candidate} 
              resume={data.resume} 
              coverLetter={data.cover_letter && data.cover_letter !== '' ? data.cover_letter : undefined} 
              jobTitle={data.job.title}
            />
            <JobDisplay data={data.job} error={error} role={'administrator'} review={true} brief={true} />
          </>
        }
      </div>
      <div className={`content ${styles.btnWrap}`}>
        <button className='btn btn--destructive' onClick={() => onApprove(false)}>Decline</button>
        <button className='btn' onClick={() => onApprove(true)}>Approve</button>
        <button className='btn btn--transparent btn--border' onClick={onGoBack}>Cancel</button>
      </div>
    </>
  )
};

export default ApplicantReview;