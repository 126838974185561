import React from 'react';
import { UserData } from '../../lib/types';
import styles from './AdminViewUser.module.scss';
import JobDetail from '../JobDetail/JobDetail';

const ViewAdminUser = (props: {data: UserData}): JSX.Element => {
  return (
    <div className={styles.header}>
      <div className={styles.roleWrap}>
        <span className='eyebrow'>{props.data.role}</span>
        <JobDetail
          icon='user' 
          text={props.data.is_active ? 'active' : 'not active'} 
          lifeCycle={props.data.is_active ? 'active' : 'deactivated'}
        />
      </div>
      <h1>
        <a href={`mailto: ${props.data.email.toLowerCase()}`} target='_blank' rel='noreferrer'>
          {props.data.email}
        </a>
      </h1>
    </div>
  )
};

export default ViewAdminUser;