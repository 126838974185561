import React, { useEffect, useState } from 'react';
import { DEFAULT_INPUT_VALIDITY_STATE } from '../../lib/constants';
import { InputValidityState, CheckboxRadioAttributes } from '../../lib/types';
import SVGIcon from '../SVGIcon/SVGIcon';
import './Checkbox.scss';

const Checkbox = (props: { label: string, subLabel?: string, attributes: CheckboxRadioAttributes, type?: 'checkbox' | 'radio' }): JSX.Element => {
  const [validityState] = useState<InputValidityState>({ ...DEFAULT_INPUT_VALIDITY_STATE, valid: true });
  const [attr, setAttr] = useState<CheckboxRadioAttributes | null>(null);

  useEffect(() => {
    setAttr({
      ...props.attributes,
      type: props.type || 'checkbox',
      name: props.attributes.name || props.attributes.id
    });
  }, [props.attributes, validityState])

  
  return (
    <div className='checkbox-wrap'>
      <label className='form-sub-group' htmlFor={props.attributes.id}>
        <input {...attr} className={`${attr?.className ?? ''} ${validityState.valid ? 'valid' : 'invalid'}`} />
        <span className='checkmark'>
          <SVGIcon id='check' />
        </span>
        <span className={props.subLabel ? 'label-flex' : ''}>{props.label}
          {
            props.subLabel &&
            <span className='sub-label'>{props.subLabel}</span>
          }
        </span>
      </label>
    </div>
    
  )
};

export default Checkbox;