import { parsePhoneNumber } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { useObservable } from '../../lib/hooks';
import AdminService from '../../lib/services/admin.service';
import { ErrorResponse, UserData } from '../../lib/types';
import { getDeepClone } from '../../lib/util.functions';
import ScrollToTop from '../ScrollToTop';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './AdminViewUser.module.scss';
import ViewAdminUser from './ViewAdminUser';
import ViewCandidateUser from './ViewCandidateUser';
import ViewEmployerUser from './ViewEmployerUser';

const AdminViewUser = (props: {uuid: string}): JSX.Element => {
  const [userdata, error] = useObservable<UserData>(AdminService.getUser(props.uuid));
  const [data, setData] = useState<UserData>();
  const [updateError, setUpdateError] = useState<ErrorResponse>();
  const [mapURL, setMapUrl] = useState<string>();
  const [phone, setPhone] = useState<string>();

  const updateUser = () => {
    if (data) {
      AdminService.updateUser(data.uuid as string, !data.is_active).subscribe({
        next: dta => {
          const updateData = getDeepClone(data);
          updateData.is_active = dta.is_active;
          setData(updateData);
        },
        error: err => setUpdateError(err)
      })
    }
  }

  useEffect(() => {
    if (userdata) {
      setData(userdata);
      if (userdata.profile) {
        if (userdata.profile.zip_code) {
          const url = new URL('https://www.google.com/maps/search/');
          url.searchParams.append('api', '1');
          url.searchParams.append('query', `${userdata.profile.address || ''} ${userdata.profile.city || ''} ${userdata.profile.state || ''} ${userdata.profile.zip_code}`);
          setMapUrl(url.href);
        }
        //
        if (userdata.profile.phone_number && userdata.profile.phone_number !== '') {
          const phoneNumber = parsePhoneNumber(userdata.profile.phone_number, 'US')
          setPhone(userdata.profile.phone_number.startsWith('+') ? phoneNumber.formatInternational() : phoneNumber.formatNational());
        }
      }
    }
  }, [userdata])
  
  return (
    <>
      <ScrollToTop />
      {
        !data && !error &&
        <div className={`content ${styles.content}`}>
          <div className='loading-inview loading-inview--center'></div>
        </div>
      }
      {
        !!data && !error &&
        <div className={`content ${styles.content}`}>
          {
            data.role === 'administrator' &&
            <ViewAdminUser key={data.is_active.toString()} data={data} />
          }
          {
            data.role === 'employer' &&
            <ViewEmployerUser key={data.is_active.toString()} data={data} mapUrl={mapURL} phone={phone}/>
          }
          {
            data.role === 'candidate' &&
            <ViewCandidateUser key={data.is_active.toString()} data={data} mapUrl={mapURL} phone={phone}/>
          }
          <div className={styles.btnWrap}>
            <button className='btn' onClick={updateUser}>
              <SVGIcon id={data.is_active ? 'close' : 'check'} color='white' />
              {data.is_active ? 'Deactivate User' : 'Activate User'}
            </button>
          </div>
        </div>
      }
    </>
  )
};

export default AdminViewUser;