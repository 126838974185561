import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { map } from 'rxjs';
import { useFormState, useSubject, useUserChoiceDetails } from '../../lib/hooks';
import AdminService from '../../lib/services/admin.service';
import GMapsService from '../../lib/services/gmaps.service';
import RecruiterService from '../../lib/services/recruiter.service';
import { userData$ } from '../../lib/state';
import { FormValidityState, ErrorResponse, CreateUserData, UserRole } from '../../lib/types';
import { emailValidator } from '../../lib/validator.functions';
import Input from '../Input';
import Modal from '../Modal/Modal';
import Select from '../Select';
import styles from './CreateUserForm.module.scss';

const CreateUserForm = (props: {role: UserRole}): JSX.Element => {
  const userData = useSubject(userData$);
  const formRef = useRef<HTMLFormElement | null>(null);
  const formState = useFormState<FormValidityState>(formRef);
  const {roleOptions } = useUserChoiceDetails();
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [disabled, setDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState<string>()
  const [latitude, setLatitude] = useState<string | number>();
  const [longitude, setLongitude] = useState<string | number>();

  const onSubmit = (e: FormEvent): void => {
    e.preventDefault();
    setError(null);
    setDisabled(true);
    //
    const values: CreateUserData = { ...formState.values as unknown as CreateUserData };
    values.registration_flow = 'proxy';
    if (state) values.state = state;
    if (latitude) values.latitude = latitude;
    if (longitude) values.longitude = longitude;
    //
    (userData?.role === 'recruiter' ? RecruiterService.createUser : AdminService.createUser)(values).subscribe({
      next: () => formRef.current?.reset(),
      error: (e: ErrorResponse) => setError(e),
      complete: () => setShowModal(true)
    });
  };

  useEffect(() => {
    if (disabled !== !formState?.valid) {
      setDisabled(!formState?.valid);
    }
  }, [formState])

  const onZipGetState = () => {
    setTimeout(() => {
      if (formState.validity.zip_code) {
        GMapsService.getByRegion(formState.values.zip_code).subscribe({
          next: dta => {
            if (dta.predictions.length && dta.predictions[0].terms[1].value.length === 2) {
              setState(dta.predictions[0].terms[1].value);
              if (dta.predictions[0].place_id) {
                GMapsService.getDetails(dta.predictions[0].place_id, ['geometry'])
                  .pipe(map(obj => obj.geometry?.location))
                  .subscribe({
                    next: (loc) => {
                      setLatitude(parseFloat(loc?.lat().toFixed(9) as string));
                      setLongitude(parseFloat(loc?.lng().toFixed(9) as string));
                    }
                  }
                )
              }
            }
          }
        })
      }
    }, 200)
  }
  
  return (
    <div className={`content ${styles.content}`}>
      <div className={styles.header}>
        <h2>Create New {props.role} User</h2>
      </div>
      <div className={styles.formWrap}>
        <form ref={formRef} id='create-user' onSubmit={onSubmit}>
          <Input label='First Name*'
            forceError={!!error}
            errorMessage={error?.response.first_name}
            attributes={{type: 'text', id: 'first_name', required: true, autoComplete: 'given-name'}} 
          />

          <Input label='Last Name*'
            forceError={!!error}
            errorMessage={error?.response.last_name}
            attributes={{type: 'text', id: 'last_name', required: true, autoComplete: 'family-name'}} 
          />

          <Input label='Email*'
            forceError={!!error}
            errorMessage={error ? error.response.email : 'Email address is not valid.'}
            validatorFns={[emailValidator]}
            attributes={{type: 'email', id: 'email', required: true, autoComplete: 'email'}} 
          />

          <Input label='ZIP Code (optional)'
            forceError={!!error}
            errorMessage={error?.response.zip_code}
            attributes={{type: 'text', id: 'zip_code', autoComplete: 'zipcode',
                        onChange: onZipGetState }} 
          />

          <>
          {
            roleOptions &&
            <Select label='User is...*'
              options={roleOptions}
              errorMessage={''}
              attributes={{ id: 'role', required: true, defaultValue: props.role, disabled: true }}
            />
          }
          </>

          <div className={styles.btnWrap}>
            <button className='btn' type='submit' disabled={!formState?.valid}>submit</button>
          </div>
        </form>
      </div>
      <Modal show={showModal} onCloseBtn={() => setShowModal(false)}>
        <div>
          <p className='eyebrow center'>success!</p>
          <p>A new {props.role} user has been created.</p>
        </div>
        
      </Modal>
    </div>
  )
};

export default CreateUserForm;