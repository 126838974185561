import { catchError, delay, map, Observable, of, tap } from 'rxjs';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { newApplicantsCount$ } from '../../pages/Dashboard/state';
import { DOMAIN } from '../constants';
import Logger from '../logger';
import { ApplicantData, JobPostData, PaginateApplicantsData, PaginateJobsData, ServerConstantsData } from '../types';
import { handleFormError, objToFormData } from '../util.functions';
import AuthService from './auth.service';
import GTMService from './gtm.service';


export default abstract class EmployerJobService {

  public static postJob(data: JobPostData, sendGAEvent: boolean): Observable<JobPostData> {
    Logger.log('EmployerJobService.postJob()', data, sendGAEvent);
    return AuthService.tokenGuaranteeSwitchMap(EmployerJobService.createJob, {data, sendGAEvent});
  }

  public static updateJob(uid: string, data: JobPostData, sendGAEvent: boolean): Observable<JobPostData> {
    Logger.log('EmployerJobService.updateJob()', uid, data, sendGAEvent);
    return AuthService.tokenGuaranteeSwitchMap(EmployerJobService.jobUpdate, {uid, data, sendGAEvent});
  }

  public static modifyJob(uid: string, data: JobPostData): Observable<JobPostData> {
    Logger.log('EmployerJobService.modifyJob()', uid, data);
    return AuthService.tokenGuaranteeSwitchMap(EmployerJobService.jobModify, {...data, job: uid});
  }

  public static getJob(uuid: string): Observable<JobPostData> {
    Logger.log('EmployerJobService.getJob()', uuid);
    return AuthService.tokenGuaranteeSwitchMap(EmployerJobService.jobDetail, uuid);
  }

  public static deleteDraft(uuid: string): Observable<JobPostData> {
    Logger.log('EmployerJobService.deleteDraft()', uuid);
    return AuthService.tokenGuaranteeSwitchMap(EmployerJobService.jobDelete, uuid);
  }

  public static getJobs(queryParams: string): Observable<PaginateJobsData> {
    Logger.log('EmployerJobService.getJobs', queryParams);
    return AuthService.tokenGuaranteeSwitchMap(EmployerJobService.jobsList, queryParams);
  }

  public static getApplicants(uuid: string, queryParams: string): Observable<PaginateApplicantsData> {
    Logger.log('EmployerJobService.getApplicants', uuid, queryParams);
    return AuthService.tokenGuaranteeSwitchMap(EmployerJobService.applicationsList, {uuid, queryParams});
  }

  public static getApplicant(uuid: string): Observable<ApplicantData> {
    Logger.log('EmployerJobService.getApplicant', uuid);
    return AuthService.tokenGuaranteeSwitchMap(EmployerJobService.applicantDetail, uuid);
  }

  public static markViewed(uuid: string): Observable<{is_new: boolean, uuid: string}> {
    Logger.log('EmployerJobService.markViewed()', uuid);
    return AuthService.tokenGuaranteeSwitchMap(EmployerJobService.viewed, uuid);
  }

  public static getNewApplicationCount(): Observable<{count: number}> {
    Logger.log('EmployerJobService.getNewApplicationCount()');
    return AuthService.tokenGuaranteeSwitchMap(EmployerJobService.newApplicationCount, undefined);
  }

  private static createJob(access: string, dta: {data: JobPostData, sendGAEvent: boolean}): Observable<JobPostData> {
    return ajax.post(`${DOMAIN}/api/employers/jobs/`, dta.data, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      tap(() => {
        if (dta.sendGAEvent) {
          GTMService.pushEvent({
            'event': 'job_posted',
            'price': dta.data.credit?.value
          });
        } else {
          Logger.log('Suppressed GA event.')
        }
      }),
      catchError((e: AjaxError) => handleFormError(e, [
        'category', 'city', 'compensation', 'description', 'duration', 'key_responsibilities', 
        'qualifications', 'requires_recruiter', 'state', 'title' 
      ]))
    );
  }

  private static jobUpdate(access: string, dta: {uid: string, data: JobPostData, sendGAEvent: boolean}): Observable<JobPostData> {
    return ajax.patch(`${DOMAIN}/api/employers/jobs/${dta.uid}`, dta.data, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      tap(() => {
        if (dta.sendGAEvent) {
          GTMService.pushEvent({
            'event': 'job_posted',
            'price': dta.data.credit?.value
          });
        } else {
          Logger.log('Suppressed GA event.')
        }
      }),
      catchError((e: AjaxError) => handleFormError(e, [
        'category', 'city', 'compensation', 'description', 'duration', 'key_responsibilities', 
        'qualifications', 'requires_recruiter', 'state', 'title' 
      ]))
    );
  }

  private static jobModify(access: string, data: JobPostData): Observable<JobPostData> {
    return ajax.post(`${DOMAIN}/api/employers/modifications/`, data, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e, [
        'category', 'city', 'compensation', 'description', 'duration', 'key_responsibilities', 
        'qualifications', 'requires_recruiter', 'state', 'title' 
      ]))
    );
  }

  private static jobDetail(access: string, uuid: string): Observable<JobPostData> {
    return ajax.get(`${DOMAIN}/api/employers/jobs/${uuid}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static jobDelete(access: string, uuid: string): Observable<JobPostData> {
    return ajax.delete(`${DOMAIN}/api/employers/jobs/${uuid}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static jobsList(access: string, queryParams: string): Observable<PaginateJobsData> {
    return ajax.get(`${DOMAIN}/api/employers/jobs/?${queryParams}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static applicationsList(access: string, dta: {uuid: string, queryParams: string}): Observable<PaginateApplicantsData> {
    return ajax.get(`${DOMAIN}/api/employers/jobs/${dta.uuid}/applications/?${dta.queryParams}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static applicantDetail(access: string, uuid: string): Observable<ApplicantData> {
    return ajax.get(`${DOMAIN}/api/employers/applications/${uuid}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static viewed(access: string, uuid: string): Observable<{is_new: boolean, uuid: string}> {
    return ajax.patch(`${DOMAIN}/api/employers/applications/${uuid}`, objToFormData({viewed: true}), { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      tap(() => this.getNewApplicationCount()),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static newApplicationCount(access: string): Observable<{count: number}> {
    return ajax.get(`${DOMAIN}/api/employers/counts/applications/`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response as {count: number}),
      tap(dta => newApplicantsCount$.next(dta.count)),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

}