import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import { ROUTES } from '../../../lib/app-routes';
import { UserRole } from '../../../lib/types';
import { dashboardPageSize } from '../state';
import { DashboardPage } from '../types';
import styles from './DashboardNav.module.scss';

const DashboardNav = (props: {role: UserRole, pageID: DashboardPage, notifyCountLeft?: number, notifyCountRight?: number}): JSX.Element => {
  const [leftActive, setLeftActive] = useState(false);
  const [rightActive, setRightActive] = useState(false);
  const [leftTitle, setLeftTitle] = useState<string>();
  const [rightTitle, setRightTitle] = useState<string>();
  const [leftIcon, setLeftIcon] = useState<string>('');
  const [rightIcon, setRightIcon] = useState<string>('');
  const [leftNav, setLeftNav] = useState<DashboardPage | string>();
  const [rightNav, setRightNav] = useState<DashboardPage | string>();
  const navigate = useNavigate();

  const onLeftClick = () => {
    navigate(ROUTES.dashboard.path.replace(':pageID', leftNav as DashboardPage));
  }

  const onRightClick = () => {
    navigate(ROUTES.dashboard.path.replace(':pageID', rightNav as DashboardPage));
  }

  useEffect(() => {
    switch (props.role) {
      case 'employer':
        setLeftNav(`jobs?page=1&page_size=${dashboardPageSize}&life_cycle__in=active__deactivated__expired__review`);
        setRightNav(`drafts?page=1&page_size=${dashboardPageSize}&life_cycle__in=draft`);
        setLeftTitle('My Jobs');
        setRightTitle('Drafts');
        setLeftIcon('briefcase');
        setRightIcon('edit');
        break;

      case 'candidate':
        setLeftNav('applications');
        setRightNav('jobs');
        setLeftTitle('Applications');
        setRightTitle('Saved Jobs');
        setLeftIcon('paper-airplane');
        setRightIcon('star');
        break;
    
      default:
        break;
    }
  }, [props.role]);

  useEffect(() => {
    switch (props.role) {
      case 'employer':
        if (props.pageID === 'jobs') {
          setLeftActive(true);
          setRightActive(false);
        } else if (props.pageID === 'drafts') {
          setRightActive(true);
          setLeftActive(false);
        }
        break;
        
      case 'candidate':
        if (props.pageID === 'jobs') {
          setLeftActive(false);
          setRightActive(true);
        } else if (props.pageID === 'applications') {
          setRightActive(false);
          setLeftActive(true);
        }
        break;
    
      default:
        break;
    }
  }, [props.pageID]);
  
  return (
    <div className={styles['nav-wrap']}>
      <button onClick={onLeftClick}>
        <div className={styles.iconWrap} data-active={leftActive} data-notify-count={props.notifyCountLeft ? props.notifyCountLeft : undefined}>
          <SVGIcon 
            id={leftIcon} 
            color={leftActive ? 'white' : 'earthy-grey'}
          />
        </div>
        <p className='eyebrow'>{leftTitle}</p>
        </button>

      <button onClick={onRightClick}>
        <div className={styles.iconWrap} data-active={rightActive} data-notify-count={props.notifyCountRight ? props.notifyCountRight : undefined}>
          <SVGIcon 
            id={rightIcon} 
            color={rightActive ? 'white' : 'earthy-grey'}
          />
        </div>
        <p className='eyebrow'>{rightTitle}</p>
      </button>

      <div className={styles.line} data-right={rightActive}></div>
    </div>
  )
};

export default DashboardNav;