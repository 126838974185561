import React, { useEffect, useState } from 'react';
import { ApplicantData, JobPostData, ModifiedJobData } from '../../lib/types';
import EmployerJobCardDetails from '../EmployerJobCard/EmployerJobCardDetails';
import styles from '../EmployerJobCard/EmployerJobCard.module.scss';
import { jobForReview$, selectedJob$ } from '../../pages/Dashboard/state';
import { useSearchParams } from 'react-router-dom';
import { Subscription } from 'rxjs';
import AdminService from '../../lib/services/admin.service';
import { InView } from 'react-intersection-observer';
import ApplicantCard from '../ApplicantCard/ApplicantCard';
import TwirlDown from '../TwirlDown/TwirlDown';

const AdminJobCard = (props: {type: 'all' | 'modified' | 'apply', jobData: JobPostData | ModifiedJobData}): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [applicantsData, setApplicantsData] = useState<ApplicantData[]>([]);
  const [loadMore, setLoadMore] = useState(true);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const [newCount, setNewCount]  = useState<number>(0);
  const [count, setCount]  = useState<number>();
  const [showApplicants, setShowApplicants] = useState(0);
  const [applicantSub, setApplicantSub] = useState<Subscription>();

  const onInViewChange = (inView: boolean) => {
    if (loadMore && inView) {
      const params = nextUrl ? nextUrl.split('?')[1] : 'page=1&page_size=6';
      setApplicantSub(AdminService.getJobApplications(props.jobData.uuid as string, params).subscribe({
        next: dta => {
          setCount(dta.count);
          if (dta.results[0]) setNewCount(dta.results[0].job.new_applications_count);
          setNextUrl(dta.next);
          setLoadMore(dta.next ? true : false);
          setApplicantsData([ ...applicantsData, ...dta.results]);
        }
      }));
    } 
  }

  const onViewDetails = () => {
    jobForReview$.next(props.jobData);
    if (props.jobData.life_cycle === 'active') {
      setSearchParams({view: props.jobData.uuid as string});
    } else {
      if (props.jobData.life_cycle === 'expired' || props.jobData.life_cycle === 'deactivated') {
        setSearchParams({activateID: props.jobData.uuid as string});
      } else {
        setSearchParams({
          approve: props.jobData.life_cycle ? (props.jobData.life_cycle === 'review' ? 'new' : 'modified') : 'modified', 
          approveID: props.jobData.uuid as string
        });
      }
    }
  }

  const onSelectJob = () => {
    selectedJob$.next(props.jobData);
    setSearchParams({selectID: props.jobData.uuid as string, role: 'candidate'});
  }

  useEffect(() => {
    if (nextUrl) setNextUrl(null);
    if (props.jobData.life_cycle === 'active') onInViewChange(true);
  }, [props.jobData]);

  useEffect(() => {
    return () => {
      if (applicantSub && !applicantSub.closed) applicantSub.unsubscribe();
    }
  }, []);

  return (
    <div className={`job-card ${styles['container']}`}>
      <div className='job-card-title-menu-wrap'>
        <h3>{props.jobData.title} <span className='company-name'>- {props.jobData.company_name} ({props.jobData.employer_name})</span></h3>
        <button className='btn btn--auto-width' onClick={props.type === 'apply' ? onSelectJob : onViewDetails}>
          {props.type === 'apply' ? 'Select & Find Candidates' : 'View Details'}
        </button>
        
      </div>
      <EmployerJobCardDetails type='job' jobData={props.jobData} />

      {
        props.type === 'all' && props.jobData.life_cycle === 'active' && count === undefined &&
        <div className='loading-inview'></div>
      }

      {
          props.type === 'all' && props.jobData.life_cycle === 'active' && !!count &&
          <TwirlDown title={`Show Candidates (${count})`} reveal={showApplicants}>
            <div className={styles.candidatesWrap}>
              {
                applicantsData.map((d,i)=> <ApplicantCard key={i} data={d}/>)
              }
            </div>
            <InView as="div" onChange={onInViewChange}>
              {
                loadMore && <div className='loading-inview loading-inview--center loading-inview--mt'></div>
              }
            </InView>
          </TwirlDown>
        }
    </div>
  )
};

export default AdminJobCard;