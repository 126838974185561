import React, { useRef } from 'react';
import styles from './LPNewsletter.module.scss';
import imageM from '../../../images/hp-newsletter-m.jpg';
import image from '../../../images/hp-newsletter.jpg';
import GTMService from '../../../lib/services/gtm.service';

const LPNewsletter = (): JSX.Element => {
  const ref = useRef<HTMLFormElement | null>(null);

  return (
    <section className={styles['newsletter-wrap']}>
      <div className={styles.bg}></div>

      <picture>
        <source media="(min-width: 992px)" srcSet={image} />
        <img src={imageM} alt='hand holding envelope'/>
      </picture>

      <div className={styles.content}>
          <div className={styles.titleWrap}>
            <p className='eyebrow'>Join our newsletter</p>
            <h2><span>Want Some</span> Awesome<br />In Your Inbox?</h2>
            <p>Sign up to get exclusive job listings and industry news</p>
          </div>

          <div className="klaviyo-form-RCCuqk" style={{width: '100%'}}></div>

          {/* <!-- Begin Mailchimp Signup Form --> */}
          {/* <div id="mc_embed_signup" style={{width: '100%'}}>
              <form ref={ref} action="https://jobsnob.us19.list-manage.com/subscribe/post?u=7367faed35ae42970f8e9a121&amp;id=fdb5da125e&amp;f_id=002e36e3f0" 
                method="post" 
                id="mc-embedded-subscribe-form" 
                name="mc-embedded-subscribe-form" 
                className="validate" 
                target="_self"
              >
                  //<div className="indicates-required">
                  //  <span className="asterisk">*</span> indicates required
                  //</div>
                  <div className="mc-field-group form-sub-group form-sub-group--border">
                    <label htmlFor="mce-EMAIL">Email</label>
                    <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required/>
                    <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
                  </div>
                  <div className="mc-field-group form-sub-group form-sub-group--border">
                    <label htmlFor="mce-NAME">Name</label>
                    <input type="text" name="NAME" className="" id="mce-NAME" required/>
                    <span id="mce-NAME-HELPERTEXT" className="helper_text"></span>
                  </div>
                  <div className="mc-field-group form-sub-group form-sub-group--border">
                    <label htmlFor="mce-ZIPCODE">Zip</label>
                    <input type="text" name="ZIPCODE" className="" id="mce-ZIPCODE" required/>
                    <span id="mce-ZIPCODE-HELPERTEXT" className="helper_text"></span>
                  </div>
                  <div id="mce-responses" className="clear">
                    <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
                    <div className="response" id="mce-success-response" style={{display: 'none'}}></div>
                  </div>
                  // <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> 
                  <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                    <input type="text" name="b_7367faed35ae42970f8e9a121_fdb5da125e" tabIndex={-1}/>
                  </div>
                  <div className={`clear ${styles.btnWrap}`}>
                    <input 
                      onClick={() => {if (ref.current?.checkValidity()) GTMService.pushEvent({ 'event': 'newsletter_sign_up' })}}
                      type="submit" 
                      value="Count Me in" 
                      name="subscribe" 
                      id="mc-embedded-subscribe" 
                      className="btn btn--white btn--border"/>
                  </div>
            </form>
          </div> */}

        {/* <!--End mc_embed_signup--> */}
        </div>
      
    </section>
  )
};

export default LPNewsletter;
