import React, { useEffect } from 'react';
import { EditorState, LexicalEditor, EditorThemeClasses} from 'lexical';
import { ListItemNode, ListNode} from '@lexical/list';
import { $convertToMarkdownString, TRANSFORMERS} from '@lexical/markdown';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import styles from './Editor.module.scss';
import EditorToolbar from './EditorToolbar';
import { markdownInput$, markdownOutput$ } from './state';
import ImportMarkdownPlugin from './ImportMarkdownPlugin';
import { useSubject } from '../../lib/hooks';

const theme: EditorThemeClasses = {
  text: {
    bold: 'bold',
    italic: 'italic'
  }
}

const Editor = (): JSX.Element => {
  const markdown = useSubject(markdownInput$);
  
  const onError = (error: Error) => {
    console.error('Editor Error:', error);
  }

  const initialConfig = {
    namespace: 'JSEditor',
    theme,
    onError,
    nodes: [
      ListNode,
      ListItemNode
    ]
  };

  const onChange = (editorState: EditorState, editor: LexicalEditor) => {
    editorState.read(() => {
      markdownOutput$.next($convertToMarkdownString(TRANSFORMERS))
    });
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <EditorToolbar />
      <div className={styles.editorWrap}>
        <RichTextPlugin
          contentEditable={<ContentEditable className={styles.editorInput} />}
          placeholder={<div className={styles.placeholder}>Write job description here.</div>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <OnChangePlugin onChange={onChange} />
        <ImportMarkdownPlugin markdown={markdown} />
        <ListPlugin />
        <HistoryPlugin />
      </div>
    </LexicalComposer>
  )
};

export default Editor;