import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { map, tap } from 'rxjs';
import { useCompanyChoiceDetails, useFormState, useJobChoiceDetails, useProfileChoiceDetails, useQuery, useUserChoiceDetails } from '../../../lib/hooks';
import GMapsService from '../../../lib/services/gmaps.service';
import StorageService from '../../../lib/services/storage.service';
import { InputAutoCompleteCallbackData, SelectOption, UserRole } from '../../../lib/types';
import DropDown from '../../DropDown/DropDown';
import InputAutoComplete from '../../InputAutoComplete/InputAutoComplete';
import Select from '../../Select';
import SVGIcon from '../../SVGIcon/SVGIcon';
import Tag from '../../Tag/Tag';
import styles from './UserFilters.module.scss';

const UserFilters = (props: {pageSize?: number, defaultRole?: UserRole, hide?: string[]}): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const {roleOptions} = useUserChoiceDetails();
  const {
    jobCategoryOptions,
    compensationOptions,
    compensationBonusAnnualOptions,
    compensationCommissionOtherPercentageOptions,
    compensationCommissionOtherTypeOptions,
    compensationCommissionProductPercentageOptions,
    compensationCommissionProductTypeOptions,
    compensationCommissionServicePercentageOptions,
    compensationCommissionServiceTypeOptions,
    compensationHourlyRateOptions,
    compensationSalaryRangeAnnualOptions,
    compensationTotalAnnualOptions,
    candidateReferralSourceOptions,
    candidateStateOptions,
    candidateYearsExperienceOptions
  } = useProfileChoiceDetails();
  const {employerReferralSourcOptions} = useCompanyChoiceDetails();
  const formRef = useRef<HTMLFormElement | null>(null);
  const validityState = useFormState(formRef);
  const [reveal, setReveal] = useState(false);
  const [overflow, setOverflow] = useState(false);
  
  const [userRoles, setUserRoles] = useState<SelectOption[]>([]);
  const [roleValues, setRoleValues] = useState<SelectOption[]>([]);
  const [roleKey, setRoleKey] = useState(0);

  const [jobCategories, setJobCategories] = useState<SelectOption[]>([]);
  const [catValues, setCatValues] = useState<SelectOption[]>([]);
  const [catKey, setCatKey] = useState(0);

  const [salaryRanges, setSalaryRanges] = useState<SelectOption[]>([]);
  const [salaryRangeValues, setSalaryRangeValues] = useState<SelectOption[]>([]);
  const [salaryKey, setSalaryKey] = useState(0);

  const [compensationChoices, setCompensationChoices] = useState<SelectOption[]>([]);
  const [compensationValues, setCompensationValues] = useState<SelectOption[]>([]);
  const [compKey, setCompKey] = useState(0);

  const [referralSources, setReferralSources] = useState<SelectOption[]>([]);
  const [referralValues, setReferralValues] = useState<SelectOption[]>([]);
  const [referKey, setReferKey] = useState(0);

  const [placeID, setPlaceID] = useState<string>();
  const [disableLocation, setDisableLocation] = useState(false);
  const [geoValue, setGeoValue] = useState<SelectOption | undefined>(StorageService.get('geoValue'));
  const [geoKey, setGeoKey] = useState<string>();

  const [{categories__in, geography__geo_distance, role, compensation, compensation_salary_range_annual, referral_source}, setSearchParams, searchParams] = useQuery();
  const [notifyCount, setNotifyCount]= useState<number>();

  const onLocationSelected = (obj: InputAutoCompleteCallbackData) => {
    setPlaceID(obj.placeID);
    setGeoKey(validityState.values.location);
    setDisableLocation(true);
  }

  const onRadiusSelected = (value: string) => {
    if (placeID) {
      GMapsService.getDetails(placeID, ['geometry'])
        .pipe(map(dta => dta.geometry?.location))
        .subscribe({
          next: (loc) => addGeo(`${value}__${loc?.lat()}__${loc?.lng()}`)
        }
      )
    }
  }

  const addGeo = (value: string) => {
    setGeoValue({ label: geoKey as string, value });
  }
  
  const onRemoveGeo = () => {
    setGeoValue(undefined);
    setDisableLocation(false);
    setPlaceID(undefined);
    setGeoKey(undefined);
  }

  const getValues = (id: string): SelectOption[] => {
    let values: SelectOption[] = []; 
    switch (id) {
      case 'categories__in':
        values = [...catValues];
        break;

      case 'role':
        values = [...roleValues];
        break;

      case 'compensation':
        values = [...compensationValues];
        break;

      case 'compensation_salary_range_annual':
        values = [...salaryRangeValues];
        break;

      case 'referral_source':
        values = [...referralValues];
        break;

      default:
        break;
    }
    return values;
  }

  const getOptions = (id: string): SelectOption[] => {
    let options: SelectOption[] = []; 
    switch (id) {
      case 'categories__in':
        options = jobCategories;
        break;

      case 'role':
        options = userRoles;
        break;

      case 'compensation':
        options = compensationChoices;
        break

      case 'compensation_salary_range_annual':
        options = salaryRanges;
        break;

      case 'referral_source':
        options = referralSources;
        break;

      default:
        break;
    }
    return options;
  }

  const setValues = (id: string, arr: SelectOption[]) => {
    let options: SelectOption[] = []; 
    switch (id) {
      case 'categories__in':
        setCatValues(arr);
        setJobCategories([...jobCategories]);
        if (getAllowMulti(id)) setCatKey(catKey + 1);
        break;

      case 'role':
        setRoleValues(arr);
        setUserRoles([...userRoles]);
        if (getAllowMulti(id)) setRoleKey(roleKey + 1);
        break;

      case 'compensation':
        setCompensationValues(arr);
        setCompensationChoices([...compensationChoices]);
        if (getAllowMulti(id)) setCompKey(compKey + 1);
        break;

      case 'compensation_salary_range_annual':
        setSalaryRangeValues(arr);
        setSalaryRanges([...salaryRanges]);
        if (getAllowMulti(id)) setSalaryKey(salaryKey + 1);
        break;

      case 'referral_source':
        setReferralValues(arr)
        setReferralSources([...referralSources]);
        if (getAllowMulti(id)) setReferKey(referKey + 1);
        break;

      default:
        break;
    }
    return options;
  }

  const onSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.isTrusted) addSelectValue(e.target.value, e.target.id);
  }

  const getAllowMulti = (id: string): boolean => {
    if (id === 'categories__in') return true;
    /* if (id === 'compensation') return true; */
    return false;
  }

  const addSelectValue = (value: string, id: string) => {
    const allowMulti = getAllowMulti(id);
    const vArr: SelectOption[] = allowMulti ? getValues(id) : [];
    const opt = getOptions(id).find(c => c.value === value);
    if (opt && opt.value !== 'default') {
      if (allowMulti) opt.disabled = true;
      vArr.push(opt);
    }
    setValues(id, vArr);
  }

  const onRemoveTag = (idx: number, id: string) => {
    const opt = getOptions(id).find(c => c.value === getValues(id)[idx].value);
    if (opt && opt.disabled) opt.disabled = false;
    const vArr: SelectOption[] = [...getValues(id)];
    vArr.splice(idx, 1);
    setValues(id, vArr);
  }

  const onReveal = () => {
    setTimeout(() => {
      setOverflow(!overflow)
    }, reveal ? 0 : 500);
    setReveal(!reveal)
  }

  const docClickCallback = useCallback((e: MouseEvent) => {
    if(!ref.current?.contains(e.target as Node)
    && (e.target as Node).isConnected
    && (e.target as HTMLElement).id !== 'user-filters-container'
    && (e.target as HTMLElement).id !== 'user-filters-icon-btn'){
      setOverflow(false);
      setReveal(false)
    } 
  }, [setReveal, setTimeout]);

  useEffect(() => {
    if (props.defaultRole) {
      searchParams.set('role', props.defaultRole);
      setSearchParams(searchParams);
    }
    return () => {
      StorageService.remove('geoValue');
      document.removeEventListener('click', docClickCallback);
    }
  },[]);

  useEffect(() => {
    if (reveal) {
      document.addEventListener('click', docClickCallback);
    } else {
      document.removeEventListener('click', docClickCallback);
    }
  },[reveal]);

  useEffect(() => {
    if (geoValue) {
      searchParams.set('geography__geo_distance', geoValue.value);
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
      StorageService.save('geoValue', geoValue, true);
    } else if (geography__geo_distance) {
      searchParams.delete('geography__geo_distance');
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
      StorageService.remove('geoValue');
    }
  }, [geoValue])

  useEffect(() => {
    if (catValues.length) {
      searchParams.set('categories__in', catValues.map(o => o.value).join('__'));
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
    } else if (categories__in) {
      searchParams.delete('categories__in');
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
    }
  }, [catValues])

  useEffect(() => {
    if (roleValues.length) {
      searchParams.set('role', roleValues.map(o => o.value).join());
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
    } else if (role) {
      searchParams.delete('role');
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
    }
  }, [roleValues])

  useEffect(() => {
    if (salaryRangeValues.length) {
      searchParams.set('compensation_salary_range_annual', salaryRangeValues.map(o => o.value).join());
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
    } else if (salaryRangeValues) {
      searchParams.delete('compensation_salary_range_annual');
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
    }
  }, [salaryRangeValues])

  useEffect(() => {
    if (compensationValues.length) {
      searchParams.set('compensation', compensationValues.map(o => o.value).join());
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
    } else if (compensationValues) {
      searchParams.delete('compensation');
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
    }
  }, [compensationValues])

  useEffect(() => {
    if (referralValues.length) {
      searchParams.set('referral_source', referralValues.map(o => o.value).join());
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
    } else if (referralValues) {
      searchParams.delete('referral_source');
      if (searchParams.has('page')) searchParams.set('page', '1');
      setSearchParams(searchParams);
    }
  }, [referralValues])

  useEffect(() => {
    if (role !== 'candidate') {
      if (catValues.length) {
        jobCategories.forEach(o => {if(o.disabled) o.disabled = false});
        setValues('categories__in', []);
      }

      if (salaryRangeValues.length) {
        salaryRanges.forEach(o => {if(o.disabled) o.disabled = false});
        setValues('compensation_salary_range_annual', []);
      }

      if (compensationValues.length) {
        compensationChoices.forEach(o => {if(o.disabled) o.disabled = false});
        setValues('compensation', []);
      }

      if (referralValues.length) {
        referralSources.forEach(o => {if(o.disabled) o.disabled = false});
        setValues('referral_source', []);
      }
    }
    
    if (role !== 'employer') {
      if (referralValues.length) {
        referralSources.forEach(o => {if(o.disabled) o.disabled = false});
        setValues('referral_source', []);
      }
    }
  }, [role])


  useEffect(() => {
    if (jobCategoryOptions) {
      setJobCategories([ 
        {label: 'Categories', value: 'default' }, 
        ...jobCategoryOptions.filter(o => o.value !== 'default')
      ]);
    }
  }, [jobCategoryOptions]);

  useEffect(() => {
    if (compensationSalaryRangeAnnualOptions) {
      compensationSalaryRangeAnnualOptions[0].label = 'Annual Salary Range';
      setSalaryRanges(compensationSalaryRangeAnnualOptions);
    }
  }, [compensationSalaryRangeAnnualOptions])

  useEffect(() => {
    if (compensationOptions) {
      compensationOptions[0].label = 'Compensation Type';
      setCompensationChoices(compensationOptions);
    }
  }, [compensationOptions])

  useEffect(() => {
    if (candidateReferralSourceOptions) {
      candidateReferralSourceOptions[0].label = 'Referral Type';
      if (role === 'candidate') {
        setReferralSources(candidateReferralSourceOptions);
        setReferKey(referKey + 1);
      }
    }
  }, [candidateReferralSourceOptions, role])

  useEffect(() => {
    if (employerReferralSourcOptions) {
      employerReferralSourcOptions[0].label = 'Referral Type';
      if (role === 'employer') {
        setReferralSources(employerReferralSourcOptions);
        setReferKey(referKey + 1);
      }
    }
  }, [employerReferralSourcOptions, role])

  useEffect(() => {
    if (roleOptions) {
      roleOptions[0].label = 'All User Roles';
      roleOptions[0].value = 'default';
      setUserRoles(roleOptions);
      setRoleKey(roleKey + 1);
    }
  }, [roleOptions]);

  useEffect(() => {
    /* populate tags after refresh & reveal */
    if (reveal) {
      if (categories__in && !catValues.length) {
        categories__in.split(',').forEach(v => addSelectValue(v, 'categories__in'));
      }

      if (compensation && !compensationValues.length) {
        compensation.split(',').forEach(v => addSelectValue(v, 'compensation'));
      }

      if (compensation_salary_range_annual && !salaryRangeValues.length) {
        compensation_salary_range_annual.split(',').forEach(v => addSelectValue(v, 'compensation_salary_range_annual'));
      }

      if (referral_source && !referralValues.length) {
        referral_source.split(',').forEach(v => addSelectValue(v, 'referral_source'));
      }

      if (role && !roleValues.length) {
        role.split(',').forEach(v => addSelectValue(v, 'role'));
      }
    }
  }, [reveal, categories__in, role, compensation, compensation_salary_range_annual, referral_source])

  useEffect(() => {
    /* remove geo query if no tags on reveal */
    if (reveal && geography__geo_distance && !geoValue) {
      setGeoValue(undefined);
    }
  }, [reveal, geography__geo_distance])

  useEffect(() => {
    if (reveal) {
      setNotifyCount(undefined);
    } else {
      setNotifyCount(
        ((categories__in?.split('__').length || 0) + (geography__geo_distance ? 1 : 0) + (referral_source?.split(',').length || 0) +
        (!props.defaultRole ? role?.split(',').length || 0 : 0) + (compensation?.split(',').length || 0) + (compensation_salary_range_annual?.split(',').length || 0))
         || undefined);
    }
  }, [reveal, categories__in, geography__geo_distance, role, compensation, referral_source])
  
  return (
    <>
      <button id='user-filters-icon-btn' className={styles.icon} onClick={onReveal} disabled={reveal !== overflow} 
        data-notify-count={notifyCount}>
        <SVGIcon id='filters' />
      </button>
      <div ref={ref} id='user-filters-container' className={styles.container} data-reveal={reveal} data-overflow={overflow}>
        <div className={styles.titleRow}>
          <span className='eyebrow'>Add Filters</span>
          <button className={styles.icon} onClick={onReveal} disabled={reveal !== overflow}>
            <SVGIcon id='filters' />
          </button>
        </div>

        <div className={styles.row}>
          <form ref={formRef} id='filters'>

            <div className={styles.inputSelectWrap} data-hidden={props.hide?.includes('location')}>
              <div className={styles.locationWrap} data-loc-selected={!!placeID}>
                <InputAutoComplete key={geoKey|| disableLocation.toString()}
                  completeType='locality'
                  valueAs='place_id'
                  labelFull={true}
                  leftIcon='map-pin'
                  attributes={{ id: 'location', type: 'text', placeholder: 'Search city or ZIP codes', 
                              defaultValue: geoKey, disabled: disableLocation || undefined}}
                  onValueSet={(v) => onLocationSelected(v)}
                />
                {
                  !!placeID &&
                  <DropDown 
                    className={styles.radiusDDWrap} 
                    label='Radius' 
                    leftIcon='radio'
                    attributes={{ id: 'radius', defaultValue: '10mi'}} 
                    options={[
                      {label: '2 mi', value: '2mi'},
                      {label: '5 mi', value: '5mi'},
                      {label: '10 mi', value: '10mi'},
                      {label: '15 mi', value: '15mi'},
                      {label: '20 mi', value: '20mi'},
                    ]}
                    onSelected={onRadiusSelected}
                  />
                }
              </div>
            </div>
            
            <div className={styles.inputSelectWrap} data-hidden={props.hide?.includes('role')}>
              <Select key={roleKey}
                leftIcon='profile'
                options={userRoles}
                attributes={{ id: 'role', onChange: onSelect, hidden: props.hide?.includes('role'),
                              defaultValue: getAllowMulti('role') ? 'default' : role || 'default' }}
              />
            </div>

            {
              role === 'candidate' &&
              <>
                <div className={styles.inputSelectWrap} data-hidden={props.hide?.includes('categories__in')}>
                  <Select key={catKey}
                    leftIcon='briefcase'
                    options={jobCategories}
                    attributes={{ id: 'categories__in',  onChange: onSelect, 
                                  defaultValue: getAllowMulti('categories__in') ? 'default' : categories__in || 'default'}}
                  />
                </div>

                <div className={styles.inputSelectWrap} data-hidden={props.hide?.includes('compensation')}>
                  <Select key={compKey}
                    label='$?'
                    options={compensationChoices}
                    attributes={{ id: 'compensation', onChange: onSelect,
                                  defaultValue: getAllowMulti('compensation') ? 'default' : compensation || 'default'}}
                  />
                </div>

                <div className={styles.inputSelectWrap} data-hidden={props.hide?.includes('compensation_salary_range_annual')}>
                  <Select key={salaryKey}
                    label='$'
                    options={salaryRanges}
                    attributes={{ id: 'compensation_salary_range_annual', onChange: onSelect,
                                  defaultValue: getAllowMulti('compensation_salary_range_annual') ? 'default' : compensation_salary_range_annual || 'default',  }}
                  />
                </div>
              </>
            }

            {
              (role === 'candidate' || role === 'employer') &&
              <div className={styles.inputSelectWrap} data-hidden={props.hide?.includes('referral_source')}>
                <Select key={referKey}
                  leftIcon='speech-bubble'
                  options={referralSources}
                  attributes={{ id: 'referral_source', onChange: onSelect,
                                defaultValue: getAllowMulti('referral_source') ? 'default' : referral_source || 'default'}}
                />
              </div>
            }
          </form> 
        </div>

        {
          (!!catValues.length || !!geoValue || (!!roleValues.length && !props.defaultRole)) &&
          <div>
            <div className={styles.titleRow} data-32>
              <span className='eyebrow' data-filter-by>Filter by</span>
            </div>
            <div className={styles.filtersWrap}>
              {
                geoValue &&  
                <Tag key={geoValue.label} text={geoValue.label} onClick={onRemoveGeo} />
              }
              {
                !props.defaultRole &&
                roleValues.map((o, i) =>
                  <Tag key={o.label} text={o.label} onClick={onRemoveTag} onClickArgs={[i, 'role']} />
                )
              }
              {
                catValues.map((o, i) =>
                  <Tag key={o.label} text={o.label} onClick={onRemoveTag} onClickArgs={[i, 'categories__in']} />
                )
              }
              {
                compensationValues.map((o, i) =>
                  <Tag key={o.label} text={o.label} onClick={onRemoveTag} onClickArgs={[i, 'compensation']} />
                )
              }
              {
                salaryRangeValues.map((o, i) =>
                  <Tag key={o.label} text={o.label} onClick={onRemoveTag} onClickArgs={[i, 'compensation_salary_range_annual']} />
                )
              }
              {
                referralValues.map((o, i) =>
                  <Tag key={o.label} text={o.label} onClick={onRemoveTag} onClickArgs={[i, 'referral_source']} />
                )
              }
            </div>
          </div>
        }
      </div>
    </>
  )
};

export default UserFilters;