import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../lib/app-routes';
import { useQuery } from '../../lib/hooks';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './ApplySuccessModal.module.scss';

const ApplySuccessModal = (): JSX.Element => {
  const [{ applySuccess }, setQuery, searchParams] = useQuery();
  return (
    <div className={`modal-container ${styles['container']}`}>
      <div className={`modal-content ${styles.content}`}>

        <button className='close-btn' onClick={() => setQuery('')}>
          <SVGIcon id='close' color='accent-grey'/>
        </button>

        <div className='icon60 icon60--border-black'>
          <SVGIcon id='checkmark' />
        </div>

        <div>
          <p className='eyebrow'>You did it!</p>
          <h2><span>Your Application Has Been Sent</span></h2>
        </div>

        {
          applySuccess === 'current' &&
          <button className='btn' onClick={() => setQuery('')}>
            Keep Looking
          </button>
        }

        {
          applySuccess === 'jobboard' &&
          <Link to={ROUTES.jobboard.path} className='btn'>
            Keep Looking
          </Link>
        }

        {
          applySuccess === 'recruiter' &&
          <button className='btn' 
            onClick={() => {
              searchParams.delete('profileID');
              searchParams.delete('applySuccess');
              setQuery(searchParams);
            }}>
            Keep Looking
          </button>
        }
        
      </div>
    </div>
  );
}
export default ApplySuccessModal