import { SHOW_CONSOLE } from "./constants";

export default class Logger {

  // Vars
  // ▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒

  static NAME = 'Logger';

  static styleBold = [
    'color: dark-gray',
    'font-weight: bold',
  ].join(';');

  static styleError = [
    'color: red',
    'text-align: left',
  ].join(';');

  static styleSubdued = [
    'color: gray',
    'font-weight: normal'
  ].join(';');

  // Logic
  // ▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒

  static error(message: any, ...args: any[]) {
    if (SHOW_CONSOLE) console.trace("%c" + message, this.styleError, ...args)
  }

  static info(message: any, ...args: any[]) {
    if (SHOW_CONSOLE) console.info("%c" + message, this.styleSubdued, ...args)
  }

  static count(message: string) {
    if (SHOW_CONSOLE) console.count(message);
  }

  static log(message: any, ...args: any[]) {
    if (SHOW_CONSOLE) console.log("%c" + message, this.styleBold, ...args);
  }

  static dir(message: any, ...args: any[]) {
    if (SHOW_CONSOLE) console.dir("%c" + message, this.styleBold, ...(args as []));
  }

  /**
   * Class static initialization block
   */
  static {
    if (SHOW_CONSOLE) Logger.info(`${this.NAME} > init`);
  }
}

