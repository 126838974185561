import { Observable, map, catchError } from "rxjs";
import { ajax, AjaxResponse, AjaxError } from "rxjs/ajax";
import { DOMAIN } from "../constants";
import Logger from "../logger";
import { CreateUserData, JobPostData } from "../types";
import { handleFormError, objToFormData } from "../util.functions";
import AuthService from "./auth.service";


export default abstract class RecruiterService {

  public static postJob(data: JobPostData): Observable<JobPostData> {
    Logger.log('RecruiterService.postJob()', data);
    return AuthService.tokenGuaranteeSwitchMap(RecruiterService.createJob, data);
  }

  public static getJob(uuid: string): Observable<JobPostData> {
    Logger.log('RecruiterService.getJob()', uuid);
    return AuthService.tokenGuaranteeSwitchMap(RecruiterService.jobDetail, uuid);
  }

  public static updateJob(uid: string, data: JobPostData): Observable<JobPostData> {
    Logger.log('RecruiterService.updateJob()', uid, data);
    return AuthService.tokenGuaranteeSwitchMap(RecruiterService.jobUpdate, {uid, data});
  }

  public static deleteJob(uid: string): Observable<any> {
    Logger.log('RecruiterService.deleteJob()', uid);
    return AuthService.tokenGuaranteeSwitchMap(RecruiterService.jobDelete, uid);
  }

  public static createUser(userData: CreateUserData): Observable<any> {
    Logger.log('RecruiterService.createUser()', userData);
    return AuthService.tokenGuaranteeSwitchMap(RecruiterService.userCreate, userData);
  }

  private static createJob(access: string, data: JobPostData): Observable<JobPostData> {
    return ajax.post(`${DOMAIN}/api/recruiters/jobs/`, data, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      /* tap(() => GTMService.pushEvent({ 'event': 'job_posted' })), */
      catchError((e: AjaxError) => handleFormError(e, [
        'category', 'city', 'compensation', 'description', 'duration', 'key_responsibilities', 
        'qualifications', 'requires_recruiter', 'state', 'title' 
      ]))
    );
  }

  private static jobDetail(access: string, uuid: string): Observable<JobPostData> {
    return ajax.get(`${DOMAIN}/api/recruiters/jobs/${uuid}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static jobUpdate(access: string, dta: {uid: string, data: JobPostData}): Observable<JobPostData> {
    return ajax.patch(`${DOMAIN}/api/recruiters/jobs/${dta.uid}`, dta.data, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e, [
        'category', 'city', 'compensation', 'description', 'duration', 'key_responsibilities', 
        'qualifications', 'requires_recruiter', 'state', 'title', 'expiration_date', 'additional_notification_emails'
      ]))
    );
  }

  private static jobDelete(access: string, uid: string): Observable<any> {
    return ajax.delete(`${DOMAIN}/api/recruiters/jobs/${uid}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static userCreate(access: string, userData: CreateUserData): Observable<any> {
    return ajax.post(`${DOMAIN}/api/recruiters/users/`, objToFormData(userData), { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }
}