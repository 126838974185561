import React, { useEffect } from 'react';
import {$convertFromMarkdownString, TRANSFORMERS} from '@lexical/markdown';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';


const ImportMarkdownPlugin = (props: { markdown?: string }): JSX.Element => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (props.markdown && editor) {
      editor.update(() => {
        $convertFromMarkdownString(props.markdown as string, TRANSFORMERS);
      });
    }
  }, [editor, props.markdown])

  return <></>
};

export default ImportMarkdownPlugin;