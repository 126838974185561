import React, { FormEvent, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import Input from '../../../components/Input';
import SoloNLIForm from '../../../components/SoloNLIForm/SoloNLIForm';
import { DEFAULT_FORM_VALIDITY_STATE, MIN_LENGTH_PW, PW_LENGTH_ERROR_MSG } from '../../../lib/constants';
import { ROUTES } from '../../../lib/app-routes';
import { ErrorResponse, FormValidityState, VerifyNewPWData } from '../../../lib/types';
import { getDeepClone } from '../../../lib/util.functions';
import { matchValueValidator } from '../../../lib/validator.functions';
import styles from './ResetPassword.module.scss';
import UserService from '../../../lib/services/user.service';

const ResetPassword = (): JSX.Element => {
  const [formState, setFormState] = useState<FormValidityState>(getDeepClone(DEFAULT_FORM_VALIDITY_STATE));
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const { uid, token } = useParams();

  const onSubmit = (e: FormEvent): void => {
    e.preventDefault();
    const values: VerifyNewPWData = { ...{...formState.values, uid, token } as unknown as VerifyNewPWData};
    UserService.verifyResetPassword(values).subscribe({
      next: () => setSuccess(true),
      error: (e: ErrorResponse) => setError(e)
    });
  };
  
  return (
    <>
      <Helmet>
        <title>Job Snob: Reset Password</title>
      </Helmet>
      <SoloNLIForm
          title={success ? 'New Password Created' : (error ? 'Something Went Wrong!' : 'Create New Password')}
          eyebrow={success ? 'success!' : (error ? 'Create New Password' : 'Almost there!')}
          contentWrapClass={success ? 'bg1-dark' : 'bg1-light'}
          onSubmit={onSubmit}
          setter={setFormState}
          defaultState={formState}
        >
          {
            success
            ?
            <>
              <div className={`link-wrap ${error ? styles.linkWrap : ''}`}>
                <Link to={ROUTES.login.path}>
                  back to login?
                </Link>
              </div>
            </>
            
            :
            <>
              <Input
                forceError={!!error}
                errorMessage={error? error?.response.new_password : PW_LENGTH_ERROR_MSG}
                attributes={{type: 'password', id: 'new_password', placeholder: 'Type your new password here',
                            required: true, minLength: MIN_LENGTH_PW, autoComplete: 'new-password' }} 
              />

              <Input
                forceError={!!error}
                errorMessage={error? error?.response.re_new_password || '' : 'does not match new password'}
                validatorFns={[[matchValueValidator, ['new_password'] as any]]}
                attributes={{type: 'password', id: 're_new_password', placeholder: 'Retype your new password',
                            required: true, minLength: MIN_LENGTH_PW, autoComplete: 'new-password' }} 
              />

              <button className='btn' type='submit'>onward!</button>
            </>
          }
        </SoloNLIForm>
    </>
  )
};

export default ResetPassword;