import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './LPHero.module.scss';
import heroImg1M from '../../../../src/images/hp-hero-1-m.jpg';
import heroImg1 from '../../../../src/images/hp-hero-1.jpg';
import heroImg2M from '../../../../src/images/hp-hero-2-m.jpg';
import heroImg2 from '../../../../src/images/hp-hero-2.jpg';
import { ROUTES } from '../../../lib/app-routes';
import { loggedIn$, userData$ } from '../../../lib/state';
import { useSubject } from '../../../lib/hooks';

const LPHero = (): JSX.Element => {
  const navigate = useNavigate();
  const userData = useSubject(userData$);

  const onPostJob = () => {
    if (userData && userData.role === 'employer') {
      navigate(ROUTES.dashboard.path.replace(':pageID', 'drafts') + '?post=new');
    } else {
      if (userData) loggedIn$.next(false);
      navigate(ROUTES.registration.path + '?role=employer');
    }
  }
  
  return (
    <section className={styles['hero']}>
      <div className={styles.bg}></div>

      <h1><span>Where Medical Aesthetic</span> Talent &amp; Opportunity Meet</h1>

      <div className={styles.ctasWrap}>
        <div>
          <picture>
            <source media="(min-width: 992px)" srcSet={heroImg1} />
            <img src={heroImg1M} />
          </picture>

          <Link to={ROUTES.jobboard.path} className='btn'>
            find a job
          </Link>
        </div>

        <div>
          <picture>
            <source media="(min-width: 992px)" srcSet={heroImg2} />
            <img src={heroImg2M} />
          </picture>

          <button className='btn' onClick={onPostJob}>
            post a job
          </button>
        </div>
      </div>
    </section>
  )
};

export default LPHero;