import { catchError, map, Observable, tap } from 'rxjs';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { selectedProfile$ } from '../../pages/Dashboard/state';
import { DOMAIN } from '../constants';
import Logger from '../logger';
import { userData$ } from '../state';
import { ResumeData, UserData } from '../types';
import { getDeepClone, handleFormError, objToFormData } from '../util.functions';
import AuthService from './auth.service';


export default abstract class ResumeService {

  public static uploadResume(file: File, profile?: string): Observable<ResumeData> {
    Logger.log('uploadResume()', file);
    return AuthService.tokenGuaranteeSwitchMap(ResumeService.resumeUpload, {file, profile});
  }

  public static deleteResume(uuid: string): Observable<unknown> {
    Logger.log('deleteResume()', uuid);
    return AuthService.tokenGuaranteeSwitchMap(ResumeService.resumeDelete, uuid);
  }

  public static downloadResume(fileData: {fileName: string, url: string}): Observable<string> {
    Logger.log('downloadResume()', fileData);
    return AuthService.tokenGuaranteeSwitchMap(ResumeService.download, fileData);
  }

  private static resumeUpload(access: string, data: {file: File, profile?: string}): Observable<ResumeData> {
    const ud = getDeepClone(userData$.value);
    let path = `${DOMAIN}/api/resumes/`;
    let formData = objToFormData({file: data.file});
    if (ud.role === 'administrator') path = `${DOMAIN}/api/administrators/resumes/`;
    if (ud.role === 'administrator') formData = objToFormData(data);

    return ajax.post(path, formData, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      tap((dta: ResumeData) => {
        if (ud.role === 'candidate') {
          if (ud) ud.profile.resumes[0] = dta;
          userData$.next(ud);
        } else {
          const pd = getDeepClone(selectedProfile$.value) as UserData;
          if (pd) pd.profile.resumes[0] = dta;
          selectedProfile$.next(pd);
        }
        
      }),
      catchError((e: AjaxError) => handleFormError(e, ['file', 'uuid']))
    );
  }

  private static resumeDelete(access: string, uuid: string): Observable<unknown> {
    const ud = getDeepClone(userData$.value) as UserData;
    let path = `${DOMAIN}/api/resumes/${uuid}`;
    if (ud.role === 'administrator') path = `${DOMAIN}/api/administrators/resumes/${uuid}`;
    //
    return ajax.delete(path, { 'Authorization': `Bearer ${access}` }).pipe(
      tap(() => {
        if (ud.role === 'candidate') {
          if (ud) ud.profile.resumes = ud.profile.resumes.filter(r => r.uuid !== uuid);
          userData$.next(ud);
        } else {
          const pd = getDeepClone(selectedProfile$.value) as UserData;
          if (pd) pd.profile.resumes = pd.profile.resumes.filter(r => r.uuid !== uuid);
          selectedProfile$.next(pd);
        }
      }),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static download(access: string, fileData: {fileName: string, url: string}):  Observable<string> {
    return new Observable((observer) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', fileData.url, true);
      //xhr.responseType = 'arraybuffer';
      xhr.responseType = 'blob';
      // xhr.setRequestHeader('Authorization', `Bearer ${access}`)
      xhr.onload = function () {
        const urlCreator = window.URL || window.webkitURL;
        const fileUrl = urlCreator.createObjectURL(this.response);
        const tag = document.createElement('a');
        tag.href =  fileUrl;
        tag.target = '_blank';
        tag.download = fileData.fileName;
        document.body.appendChild(tag);
        tag.click();
        observer.next(fileUrl);
        observer.complete();
      };
      xhr.onerror = () => {
        // most likely error will be CORS
        ResumeService._altDownload(fileData.fileName, fileData.url);
      };
      xhr.send();

      return () => {
        xhr.abort();
      };
    })
  }

  /* if file is cross domain it will open in another window */
  private static _altDownload(fileName: string, url: string): void {
    const tag = document.createElement('a');
    tag.href = url;
    tag.target = '_blank';
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  }

}