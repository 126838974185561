import React from 'react';
import styles from './LPRecruiter.module.scss';
import SVGIcon from '../../../components/SVGIcon/SVGIcon';

const LPRecruiter = (): JSX.Element => {
  
  return (
    <section className={styles['recruiter-wrap']}>
      <div className={styles.content}>
        <div className={styles.titleWrap}>
          <p className='eyebrow'>Work with us</p>
          <h2>Hire a Recruiter</h2>
        </div>
        
        <p>
          Finding talent is our talent. With a seriously impressive network of the most sought-after Medical Aesthetic 
          professionals, JobSnob connects you with candidates who have the experience and skills you need. We recommend our 
          concierge recruiting services for highly specialized and revenue-generating positions, versus our self-managed 
          job board, which is best used for entry-level and support roles.
        </p>
        <a href='mailto:JobSnob <info@jobsnob.net>?subject=Recruiting Inquiry&body=Please include the following information, and a JobSnob team member will be in touch shortly.%0D%0A%0D%0AFull Name -%0D%0APractice/Business Name -%0D%0AOpen Position/s -%0D%0APhone Number -%0D%0AEmail -%0D%0AOther relevant information -%0D%0A%0D%0AThank you,%0D%0AThe JobSnob Team' target='_blank' rel='noreferrer' className='btn btn--white btn--border'>
          Learn More
        </a>
      </div>

      <div className={styles.listWrap}>
        <p className='eyebrow'>What&apos;s included</p>
        <ul>
          <li><SVGIcon id='check' color='salmon' />Expert guidance from the JobSnob recruitment team </li>
          <li><SVGIcon id='check' color='salmon' />Highly curated candidate submissions</li>
          <li><SVGIcon id='check' color='salmon' />Full-cycle support from sourcing to hire</li>
          <li><SVGIcon id='check' color='salmon' />Inclusion in JobSnob marketing campaigns + job board</li>
          <li><SVGIcon id='check' color='salmon' />Complimentary consulting services</li>
        </ul>
      </div>
    </section>
  )
};

export default LPRecruiter;
