import React from 'react';
import { phoneValidator, urlValidator } from '../../../lib/validator.functions';
import { ProfileProps } from './types';
import styles from './Profile.module.scss';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { useCompanyChoiceDetails } from '../../../lib/hooks';
import ChangePassword from './ChangePassword';

const EmployerProfile = (props: ProfileProps): JSX.Element => {
  const {
    businessTypeOptions,
    employerReferralSourcOptions,
    stateOptions,
    totalAnnualRevenueOptions
  } = useCompanyChoiceDetails();

  return (
    <div className='content'>

      {/* Profile Details */}
      <div className={styles.pallet} data-employer>
        <h2>Profile Details</h2>
        <p>Sign up to get exclusive job listings and industry news</p>

        <Input label='Email*'
          attributes={{ type: 'email', id: 'email', disabled: true, defaultValue: props.userData.email, 
                      readOnly: true, autoComplete: 'username' }} 
        />

        <Input label='First Name*'
          forceError={!!props.profileError}
          errorMessage={props.profileError ? props.profileError.response.profile?.first_name : undefined }
          attributes={{ type: 'text', id: 'profile.first_name', required: true,
                      defaultValue: props.userData.profile.first_name || undefined }} 
        />

        <Input label='Last Name*'
          forceError={!!props.profileError}
          errorMessage={props.profileError ? props.profileError.response.profile?.last_name : undefined }
          attributes={{ type: 'text', id: 'profile.last_name', required: true,
                      defaultValue: props.userData.profile.last_name || undefined }}
        />

        <Input label='Phone*'
          validatorFns={[phoneValidator]}
          forceError={!!props.profileError}
          errorMessage={props.profileError ? props.profileError.response.profile?.phone_number : 'not a valid phone number' }
          attributes={{ type: 'tel', id: 'profile.phone_number', autoComplete: 'tel', required: true,
                      defaultValue: props.userData.profile.phone_number || undefined }}
        />
      </div>

      {/* Change PW */}
      <ChangePassword disabled={props.disabled} formState={props.formState} setDisabled={props.setDisabledCallback}/>

      {/* Company Details */}
      <div className={styles.pallet} data-employer>
        <h2>Company Details</h2>
        <p>Sign up to get exclusive job listings and industry news</p>

        <Input label='Company Name'
          forceError={!!props.profileError}
          errorMessage={props.profileError ? props.profileError.response.company?.name : undefined }
          attributes={{ type: 'text', id: 'company.name', placeholder: 'Enter company name',
                      defaultValue: props.userData.company?.name || undefined }}
        />

        {
          businessTypeOptions &&
          <Select label='Type of Business*'
            options={businessTypeOptions}
            errorMessage={props.profileError ? props.profileError.response.company?.business_type : undefined }
            attributes={{ id: 'company.business_type', required: true, 
                        defaultValue: props.userData.company?.business_type || undefined }}
          />
        }
        
        {
          (props.formState?.values['company.business_type'] === 'other') &&
          <Input label='Other Business Type*'
            forceError={!!props.profileError}
            errorMessage={props.profileError ? props.profileError.response.company.business_type_other : undefined }
            attributes={{ type: 'text', id: 'company.business_type_other', required: true, 
                        defaultValue: props.userData.company?.business_type_other || undefined }} 
          />
        }

        {
          stateOptions &&
          <Select label='State'
            options={stateOptions}
            errorMessage={props.profileError ? props.profileError.response.company?.state : undefined }
            attributes={{ id: 'company.state', defaultValue: props.userData.company?.state }}
          />
        }

        {
          (props.formState?.values['company.state'] === '--') &&
          <Input label='Other State (non-US state or province)*'
            forceError={!!props.profileError}
            errorMessage={props.profileError ? props.profileError.response.state_otherr : undefined }
            attributes={{ type: 'text', id: 'company.state_other', required: true, 
                        defaultValue: props.userData.company?.state_other }} 
          />
        } 

        <Input label='Zip Code*'
          forceError={!!props.profileError}
          /* validatorFns={[onlyNumbersValidator]} */
          errorMessage={props.profileError ? props.profileError.response.company?.zip_code : undefined }
          attributes={{ type: 'text', id: 'company.zip_code', placeholder: 'Enter zip code', required: true,
                      minLength: 5, defaultValue: props.userData.company?.zip_code || undefined }}
        />

        <Input label='Number of Current Locations'
          forceError={!!props.profileError}
          errorMessage={props.profileError ? props.profileError.response.company?.number_of_locations_current : undefined }
          attributes={{ type: 'number', id: 'company.number_of_locations_current',
                      min: 0, defaultValue: props.userData.company?.number_of_locations_current || undefined }}
        />

        <Input label='Number of Projected Locations in 12 Months'
          forceError={!!props.profileError}
          errorMessage={props.profileError ? props.profileError.response.company?.number_of_locations_projected : undefined }
          attributes={{ type: 'number', id: 'company.number_of_locations_projected',
                      min: 0, defaultValue: props.userData.company?.number_of_locations_projected || undefined }}
        />

        <Input label='Number of W-2 &amp; contract employees'
          forceError={!!props.profileError}
          errorMessage={props.profileError ? props.profileError.response.company?.number_of_employees : undefined }
          attributes={{ type: 'number', id: 'company.number_of_employees',
                      min: 0, defaultValue: props.userData.company?.number_of_employees || undefined }}
        />

        <Input label='Number of Practicing MDs'
          forceError={!!props.profileError}
          errorMessage={props.profileError ? props.profileError.response.company?.number_of_doctors : undefined }
          attributes={{ type: 'number', id: 'company.number_of_doctors',
                      min: 0, defaultValue: props.userData.company?.number_of_doctors || undefined }}
        />

        <Input label='Number of Mid-level Providers'
          forceError={!!props.profileError}
          errorMessage={props.profileError ? props.profileError.response.company?.number_of_providers : undefined }
          attributes={{ type: 'number', id: 'company.number_of_providers',
                      min: 0, defaultValue: props.userData.company?.number_of_providers || undefined }}
        />

        {
          totalAnnualRevenueOptions &&
          <Select label='Total Annual Revenue'
            options={totalAnnualRevenueOptions}
            errorMessage={props.profileError ? props.profileError.response.company?.total_annual_revenue : undefined }
            attributes={{ id: 'company.total_annual_revenue',
                        defaultValue: props.userData.company?.total_annual_revenue || 'default' }}
          />
        }

        {
          employerReferralSourcOptions &&
            <Select label='Referral Source'
            options={employerReferralSourcOptions}
            errorMessage={props.profileError ? props.profileError.response.company?.referral_source : undefined }
            attributes={{ id: 'company.referral_source',
                        defaultValue: props.userData.company?.referral_source || 'default' }}
          />
        }
        
        {
          props.formState?.values['company.referral_source'] === 'other' &&
          <Input label='Other Referral Source'
            forceError={!!props.profileError}
            errorMessage={props.profileError ? props.profileError.response.company.referral_source_other : undefined }
            attributes={{ type: 'text', id: 'company.referral_source_other',
                        defaultValue: props.userData.company?.referral_source_other || undefined }} 
          />
        }

        <Input label='Website*'
          forceError={!!props.profileError}
          errorMessage={props.profileError ? props.profileError.response.company?.website_url : 'url is not valid' }
          validatorFns={[urlValidator]}
          attributes={{ type: 'text', id: 'company.website_url', placeholder: 'Enter company website url',
                      required: true, defaultValue: props.userData.company?.website_url || undefined }}
        />

        <Input label='Facebook'
          forceError={!!props.profileError}
          errorMessage={props.profileError ? props.profileError.response.company?.facebook_url : 'url is not valid' }
          validatorFns={[urlValidator]}
          attributes={{ type: 'text', id: 'company.facebook_url', placeholder: 'Enter company Facebook url',
                      defaultValue: props.userData.company?.facebook_url || undefined }}
        />

        <Input label='Instagram'
          forceError={!!props.profileError}
          errorMessage={props.profileError ? props.profileError.response.company?.instagram_url : 'url is not valid' }
          validatorFns={[urlValidator]}
          attributes={{ type: 'text', id: 'company.instagram_url', placeholder: 'Enter company Instagram url',
                      defaultValue: props.userData.company?.instagram_url || undefined }}
        />
      </div>

    </div>
  )
};

export default EmployerProfile;