import { isValidPhoneNumber } from "libphonenumber-js";
import { MutableRefObject } from "react";
import { ALL_DIGITS_REGEX, EMAIL_REGEX, SPECIAL_CHAR_REGEX, URL_REGEX } from "./constants";
import Logger from "./logger";
import { ValidarorFn, FormValidityState, InputValidityState, ValidatorFnAndArgs } from "./types";

const setFormStateValues = (form: HTMLFormElement, state: FormValidityState): FormValidityState => {
  for (let index = 0; index < form.length; index++) {
    const el = form[index] as HTMLFormElement;
    const type = el.getAttribute('type');
    const required = el.hasAttribute('required');
    if ((el.tagName === 'INPUT' && type !== 'submit' && type !== 'button') || el.tagName === 'SELECT' || el.tagName === 'TEXTAREA') {
      if (el.name === 'ignore') {
        /* Logger.log(`ignore validity`, el); */
      } else if (required || el.value !== '') {
        state.validity[el.name] = 
        el.classList.contains('invalid') ? false : el.classList.contains('valid') || el.validity.valid;
      } else {
        state.validity[el.name] = true;
      }

      if (el.name === 'ignore') {
        /* Logger.log(`ignore value`, el); */
      } else if (type === 'checkbox') {
        state.values[el.name] = el.checked ? 'on' : 'off';
      } else if (type === 'radio') {
        if (el.checked) state.values[el.name] = el.validityState?.value || el.value;
      } else if (type === 'tel') {
        /* regex strip everything except digits and + */
        state.values[el.name] =  ('' + (el.validityState.value || el.value)).replace(/[^0-9+]/g, '');
      } else if (el.dataset.groupIndex) {
        if (el.dataset.groupIndex == '0') state.values[el.name] = [''];
        (state.values[el.name] as string[])[parseInt(el.dataset.groupIndex)] = el.validityState?.value || el.value;
      } else {
        state.values[el.name] = el.validityState?.value || el.value;
      }
    }
  }
  return state;
}

const filterFormData = (obj: {[key: string]: string | boolean}, form: HTMLFormElement): {[key: string]: string | boolean} => {
  return Object.fromEntries(Object.entries(obj).filter(v => v[0] !== '' && !!form[v[0]]));
}

export const updateFormState = (form: HTMLFormElement, formState: FormValidityState): FormValidityState => {
  if (!form) return formState;

  const state: FormValidityState = setFormStateValues(form, formState);
  state.values = filterFormData(state.values, form);
  state.validity = filterFormData(state.validity, form) as {[key: string]: boolean};
  const arr: boolean[] = Object.entries(state.validity).map(a => a[1]);

  state.valid = arr.length ? arr.reduce((prev, cur) => !prev ? prev : cur) : false;
  state.touched = form.dataset.touched === 'true';
  form.validityState = state;
  Logger.info('FormValidityState', state);
  return {...state};
}

export const validateRef = (ref: MutableRefObject<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | null>, state: InputValidityState, validatorFns?: (ValidarorFn | ValidatorFnAndArgs)[]): InputValidityState => {
  if (validatorFns) {
    state.valid = validatorFns
      .map(fn => 
        typeof fn === 'function' 
        ? [fn, [state.value || ref.current?.value, ref.current?.form?.validityState]] as unknown as [ValidarorFn, [any, FormValidityState, any[]] ]
        : [fn[0], [state.value || ref.current?.value, ref.current?.form?.validityState, ...fn[1] as any[]]] as unknown as [ValidarorFn, [any, FormValidityState, any[]]  ]
      )
      .map(arr => arr[0].apply(undefined, arr[1]))
      .concat([ref.current?.validity.valid || false])
      .reduce((prev, cur) => !prev ? prev : cur);
  } else {
    state.valid = (ref.current?.validity.valid || false) && (ref.current?.required ? ref.current?.value !== 'default' : true);
  }

  state = {
    ...state, 
    isDirty: !!ref.current?.value && (ref.current?.value !== '' && ref.current?.value !== 'default'), 
    value: ref.current?.dataset.value || ref.current?.value,
    count: state.count++
  };
  if (ref.current) (ref.current as any).validityState = state;
  return state;
}

export const emailValidator: ValidarorFn = (currentValue: string): boolean => {
  return EMAIL_REGEX.test(currentValue);
}

export const urlValidator: ValidarorFn = (currentValue: string): boolean => {
  return URL_REGEX.test(currentValue);
}

export const hasSpecCharValidator: ValidarorFn = (currentValue: string): boolean => {
  return SPECIAL_CHAR_REGEX.test(currentValue);
}

export const hasNumberValidator: ValidarorFn = (currentValue: string): boolean => {
  return (/\d/).test(currentValue);
}

export const onlyNumbersValidator: ValidarorFn = (currentValue: string): boolean => {
  return ALL_DIGITS_REGEX.test(currentValue);
}

export const matchValueValidator: ValidarorFn = (currentValue: string, formValidityState: FormValidityState | undefined, matchID: string): boolean => {
  return formValidityState?.values[matchID] === currentValue;
};

export const phoneValidator: ValidarorFn = (currentValue: string): boolean => {
  return isValidPhoneNumber(currentValue, currentValue.startsWith('+') ? undefined : 'US');
}