import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import AdminJobCard from '../../../components/AdminJobCard/AdminJobCard';
import AdminUserCard from '../../../components/AdminUserCard/AdminUserCard';
import ApplicantCard from '../../../components/ApplicantCard/ApplicantCard';
import JobFilters from '../../../components/Filters/JobFilters/JobFilters';
import JobFiltersLifeCycles from '../../../components/Filters/JobFilters/JobFiltersLifeCycles';
import SearchInput from '../../../components/SearchInput/SearchInput';
import Select from '../../../components/Select';
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import UserFilters from '../../../components/Filters/UserFilters/UserFilters';
import { usePaginate, useQuery, useSubject } from '../../../lib/hooks';
import AdminService from '../../../lib/services/admin.service';
import { ApplicantData, ErrorResponse, JobPostData, ModifiedJobData, SortUserData } from '../../../lib/types';
import { activeJobs$, dashboardPageSize, DEFAULT_ADMIN_JOBS_LIFE_CYCLE_IN, draftsAppsModCount$, modifiedJobs$, myJobs$ } from '../state';
import { DashboardPage } from '../types';
import styles from './DashboardList.module.scss';
import UseAnimations from "react-useanimations";
import loading from 'react-useanimations/lib/loading';

const AdminDashboardList = (props: {pageID: DashboardPage}): JSX.Element => {
  const showingRef = useRef<HTMLParagraphElement>(null);
  const jobsData  = useSubject<JobPostData[]>(myJobs$);
  const activeJobsData  = useSubject<JobPostData[]>(activeJobs$);
  const modifiedJobs  = useSubject<ModifiedJobData[]>(modifiedJobs$);
  const [applicants, setApplicants]  = useState<ApplicantData[]>();
  const [appCount, setAppCount]  = useState(0);
  const [users, setUsers]  = useState<SortUserData[]>();
  const [userCount, setUserCount]  = useState(0);
  const [userReportDownloading, setUserReportDownloading] = useState(false);
  const modifiedJobsCount  = useSubject<number>(draftsAppsModCount$);
  const [{page, search, life_cycles, life_cycle__in, categories__in, geography__geo_distance, 
        role, compensation, compensation_salary_range_annual, referral_source}, setSearchParams, searchParams] = useQuery();
  const [{ count, next, previous, results}, error] = usePaginate(
    props.pageID === 'jobs' || props.pageID === 'apply-by-proxy' ? AdminService.getJobsList : 
    (props.pageID === 'users' ? AdminService.getUsers : (props.pageID === 'applications' ? AdminService.getApplicationsList : AdminService.getModifiedList)),
    `page=1&page_size=${dashboardPageSize}${role ? `&role=${role}` : ''}${life_cycles ? `&life_cycles=${life_cycles}` : ''}${life_cycle__in ? `&life_cycle__in=${life_cycle__in}` : ''}${search ? `&search=${search}` : ''}`+
    `${geography__geo_distance ? `&geography__geo_distance=${geography__geo_distance}` : ''}${categories__in ? `&categories__in=${categories__in}` : ''}`+
    `${compensation ? `&compensation=${compensation}` : ''}${compensation_salary_range_annual ? `&compensation_salary_range_annual=${compensation_salary_range_annual}` : ''}${referral_source ? `&referral_source=${referral_source}` : ''}`
  );

  const onDownloadUserReport = () => {
    setUserReportDownloading(true);
    AdminService.downloadUserReport().subscribe({
      complete: () => setUserReportDownloading(false)
    });
  }

  const onNext = () => {
    if (next) setSearchParams(next.split('?')[1]);
    document.body.scrollTo(0,0);
  }

  const onPrevious = () => {
    if (previous) setSearchParams(previous.split('?')[1]);
    document.body.scrollTo(0,0);
  }

  const getShowStart = (): number | string => {
    return !page || page == '1' ? '1' : (parseInt(page) - 1) * dashboardPageSize + 1;
  }

  const getShowEnd = (): number | string => {
    const length = props.pageID === 'users' ? (users as SortUserData[]).length : (props.pageID === 'jobs' ? jobsData.length : 
                  (props.pageID === 'applications' ? (applicants as ApplicantData[]).length : (props.pageID === 'modifications' ? modifiedJobs.length : activeJobsData.length) ) );
    return !page || page == '1' ? length : ((parseInt(page) - 1) * dashboardPageSize) + length;
  }

  const onAppSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === '') {
      searchParams.delete('life_cycles');
    } else {
      searchParams.set('life_cycles', e.target.value);
    }
    setSearchParams(searchParams);
  }

  const onRevealCallback = (b: boolean) => {
    if (showingRef.current) {
      (showingRef.current as HTMLParagraphElement).dataset.animWidth = b.toString();
    }
  }

  useEffect(() => {
    AdminService.getApplicationsCount('life_cycles=review').subscribe();
    AdminService.getJobsCount('life_cycles=review').subscribe();
    AdminService.getModifiedCount().subscribe();
  }, []);

  useEffect(() => {
    if (props.pageID === 'jobs' && !life_cycle__in) {
      searchParams.set('life_cycle__in', DEFAULT_ADMIN_JOBS_LIFE_CYCLE_IN);
      setSearchParams(searchParams);
    }
  }, [props.pageID]);

  useEffect(() => {
    if (props.pageID === 'jobs') {
      myJobs$.next(results as JobPostData[]);
    } else if (props.pageID === 'modifications') {
      if (count !== -1) draftsAppsModCount$.next(count);
      modifiedJobs$.next(results as ModifiedJobData[]);
    } else if (props.pageID === 'users') {
      if (count !== -1) setUserCount(count);
      results.forEach((d: SortUserData) => d.sort = d.is_active ? 1 : 0);
      setUsers(results.sort((a,b) => a.sort - b.sort));
    } else if (props.pageID === 'applications') {
      if (count !== -1) setAppCount(count);
      setApplicants(results as ApplicantData[]);
    } else if (props.pageID === 'apply-by-proxy') {
      activeJobs$.next(results as JobPostData[]);
    }
  }, [results, count]);


  return (
    <div className={`content ${styles['content']}`}>
      <div className={styles.header}>
        <h2>
          { 
            props.pageID === 'users' && 'Users' 
          }
          {
            props.pageID === 'jobs' &&
            `Job Results (${count > 0 ? count : 0})`
          }
          {
            props.pageID === 'apply-by-proxy' &&
            `Job Results (${count > 0 ? count : 0})`
          }
          {
            props.pageID === 'modifications' &&
            `Modified Jobs (${modifiedJobsCount > 0 ? modifiedJobsCount : 0})`
          }
          {
            props.pageID === 'applications' &&
            `Applications (${appCount > 0 ? appCount : 0})`
          }
        </h2>
        {
          props.pageID === 'users' &&
          <div className={styles.btnWrap}>
            <button className='btn btn--oval' disabled={userReportDownloading} onClick={onDownloadUserReport}>
              <span>
                {
                  userReportDownloading && <UseAnimations animation={loading} />
                }
                {
                  !userReportDownloading && <SVGIcon id='download' color='white' />
                }
              </span>
            </button>
          </div>
        }
        <p ref={showingRef} className={`eyebrow${error ? ' error' : ''} ${styles.showingP}`} data-type={props.pageID} data-anim-width="false">
          {
            props.pageID === 'users' && userCount > 0 && users && !error &&
            `SHOWING ${getShowStart()}-${getShowEnd()} OF ${userCount} USERS`
          }
          {
            props.pageID === 'applications' && appCount > 0 && applicants && !error &&
            `SHOWING ${getShowStart()}-${getShowEnd()} OF ${appCount} APPLICANTIONS`
          }
          {
            (props.pageID === 'jobs' || props.pageID === 'apply-by-proxy') 
            && count > 0 && jobsData && !error &&
            `SHOWING ${getShowStart()}-${getShowEnd()} OF ${count} JOBS`
          }
          {
            props.pageID === 'modifications' && modifiedJobsCount > 0 && modifiedJobs && !error &&
            `SHOWING ${getShowStart()}-${getShowEnd()} OF ${modifiedJobsCount} JOBS`
          }
          {
            count === 0 && !error && 'No results'
          }
          {
            count === -1 && !error && '...loading'
          }
          {
            error && `ERROR! ${(error as ErrorResponse).response?.detail || error.message}`
          }
        </p>
        {
          props.pageID === 'users' &&
          <div className={styles.searchFilterWrap}>
            <SearchInput alwaysCollapsible={true} revealCallback={onRevealCallback}/>
            <UserFilters />
          </div>
        }
        {
          props.pageID === 'apply-by-proxy' &&
          <div className={styles.searchFilterWrap}>
            <SearchInput alwaysCollapsible={true} revealCallback={onRevealCallback}/>
            <JobFilters pageSize={dashboardPageSize}/>
          </div>
        }
        {
          props.pageID === 'jobs' &&
          <div className={styles.searchFilterWrap}>
            <SearchInput alwaysCollapsible={true} revealCallback={onRevealCallback}/>
            <JobFiltersLifeCycles pageSize={dashboardPageSize} filterOut={['draft']} />
          </div>
        }
        {
          props.pageID === 'applications' &&
          <div className='form-like'>
            <Select label='View:' 
              options={[
                {label: 'All', value: ''},
                {label: 'Needs Review', value: 'review'},
                {label: 'Active', value: 'active'}
              ]}
              attributes={{id: 'app-status', onChange: onAppSelect, defaultValue: life_cycles || ''}}
            />
          </div>
        }
      </div>

      <div className={styles.jobsContainer} data-candidate>
        {
          props.pageID === 'jobs' &&
          jobsData.map((job) => 
            <AdminJobCard 
              key={job.uuid} 
              type={'all'} 
              jobData={job as JobPostData} 
            />
          )
        }
        {
          props.pageID === 'apply-by-proxy' &&
          activeJobsData.map((job) => 
            <AdminJobCard 
              key={job.uuid} 
              type={'apply'} 
              jobData={job as JobPostData} 
            />
          )
        }
        {
          props.pageID === 'modifications' &&
          modifiedJobs.map((job) => 
            <AdminJobCard 
              key={job.uuid} 
              type={'modified'} 
              jobData={job as ModifiedJobData} 
            />
          )
        }
        {
          props.pageID === 'users' && users &&
          users.map((user) => 
            <AdminUserCard 
              key={user.uuid} 
              data={user} 
            />
          )
        }
        {
          props.pageID === 'applications' && applicants &&
          applicants.map((data) => 
            <ApplicantCard 
              key={data.uuid} 
              data={data}
              isReview={true}
            />
          )
        }
        <p className='eyebrow pages'>
          {
            count > 0 && page &&
            `PAGE ${page} OF ${Math.ceil(count / dashboardPageSize)}`
          }
        </p>
        { 
          (previous || next) &&
          <div className={styles.bottonBtnWrap} data-desktop>
          {
            previous &&
            <button className='btn btn--grey-6 btn--border' onClick={onPrevious}>Previous</button>
          }
          {
            next &&
            <button className='btn btn--grey-6 btn--border' onClick={onNext}>Next</button>
          }
        </div>
        }
      </div>

      <div data-mobile className={styles.bottonBtnWrap}>
        {
          previous &&
          <button className='btn btn--white btn--border' onClick={onPrevious}>Previous</button>
        }
        {
          next &&
          <button className='btn btn--white btn--border' onClick={onNext}>Next</button>
        }
      </div>
    </div>
  )
};

export default AdminDashboardList;