import React, { useState, useRef, useEffect, MouseEvent } from "react";
import GMapsService from "../../lib/services/gmaps.service";
import { InputAutoCompleteCallbackData, InputAutoCompleteOption } from "../../lib/types";
import { getDeepClone } from "../../lib/util.functions";
import { InputProps } from "../Input";
import SVGIcon from "../SVGIcon/SVGIcon";
import InputAuto from "./InputAuto";
import styles from './InputAutoComplete.module.scss';
import glogo from '../../images/google-logo-grey.png';
import { ALL_DIGITS_REGEX } from "../../lib/constants";

interface InputAutoCompleteProps extends InputProps {
  completeType: 'city' | 'zip' | 'city|zip' | 'locality';
  valueAs: 'label' | 'place_id' ;
  labelFull?: boolean;
  onValueSet?: (obj: InputAutoCompleteCallbackData) => void;
}

const InputAutoComplete = (props: InputAutoCompleteProps): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [value, setValue] = useState<string>();
  const [placeID, setPlaceID] = useState<string>();
  const [terms, setTerms] = useState<{offset: number, value: string}[]>();
  const [inputProps, setInputProps] = useState<InputProps>();
  const [options, setOptions] = useState<InputAutoCompleteOption[]>([]);

  const onClick = (e: MouseEvent) => {
    e.preventDefault();
    const prps:InputProps = getDeepClone(inputProps);
    prps.attributes.defaultValue = (e.target as HTMLInputElement).dataset.label;
    setInputProps(prps);
    setOptions([]);
    setTimeout(() => {
      const index = parseInt((e.target as HTMLInputElement).dataset.index as string);
      setValue(options[index].value);
      setTerms(options[index].terms);
      setPlaceID(options[index].place_id);
    }, 200);
    setTimeout(() => {
      (ref.current as HTMLDivElement)?.querySelector('input')?.dispatchEvent(new Event('change', {bubbles: true}));
    }, 300);
  }

  const onResults = (results: google.maps.places.AutocompleteResponse) =>  {
    const arr: InputAutoCompleteOption[] = [];
    let labelValue;
    results.predictions.forEach(dta => {
      labelValue = props.completeType === 'locality' && ALL_DIGITS_REGEX.test(dta.terms[0].value) ? dta.terms[dta.terms.length - 1].value : dta.terms[0].value;
      if (dta.types.includes('neighborhood')) {
        labelValue = `${labelValue}, ${ALL_DIGITS_REGEX.test(dta.terms[0].value) ? dta.terms[dta.terms.length - 2].value : dta.terms[1].value}`;
      }
      arr.push({
        label: props.completeType === 'city|zip' || props.labelFull ? dta.description : labelValue, 
        listLabel: dta.description,
        value: props.valueAs === 'place_id' ? dta.place_id : labelValue,
        terms: dta.terms,
        place_id: dta.place_id
      });
    });
    if (!arr.length) setValue(undefined);
    setOptions(arr);
  }

  useEffect(() => {
    const prps = getDeepClone(props);
    delete prps.completeType;
    delete prps.valueAs;
    delete prps.onValueSet;
    delete prps.onTermsSet;
    setInputProps(prps);
  }, [])

  useEffect(() => {
    if (props.onValueSet && value && terms && placeID) {
      props.onValueSet({value, terms, placeID});
    } 
  }, [value, terms, placeID]);

  return (
    <div ref={ref} className={styles.container} data-disabled={props.attributes.disabled}>
      {
        inputProps &&
        <InputAuto 
          key={inputProps.attributes.defaultValue?.toString()} 
          onResults={onResults} 
          completeType={props.completeType} 
          inputProps={inputProps} 
          value={value}
        />
      }
      {
        options.length > 0 &&
        <div className={styles.optionsContainer}>
          {
            options.map((opt, i) => 
              <div key={i} className='form-sub-group input-option' 
                onClick={onClick}
                data-index={i} 
                data-label={opt.label} 
              >
                {
                  props.leftIcon &&
                  <span className='input-icon input-icon--left'>
                    <SVGIcon id={props.leftIcon} color='ice-grey' />
                  </span>
                }
                {opt.listLabel}
              </div>
            )
          }
          <div className={styles.gLogo}>
            powered by <img src={glogo} alt='Google logo' title='powered by Google' aria-label='powered by Google'/>
          </div>
        </div>
      }
      
    </div>
  )
}

export default InputAutoComplete;