import React, { FormEvent, KeyboardEvent, MouseEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { IS_SAFARI } from '../../lib/constants';
import { TextAreaAttributes } from '../../lib/types';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './TextAreaAddMulti.module.scss';

interface IProps {
  attributes: TextAreaAttributes,
  defaultValues?: string[],
  label?: string,
  className?: string;
  errorMessage?: string;
  forceError?: boolean;
  children?: ReactElement | ReactElement[];
}

const TextAreaAddMulti = (props: IProps): JSX.Element => {
  const ref = useRef<HTMLTextAreaElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [attr, setAttr ] = useState<TextAreaAttributes | null>(null);
  const [name, setName ] = useState<string>();
  const [editValue, setEditValue ] = useState<string>();
  const [editIndex, setEditIndex ] = useState<number>();
  const [key, setKey ] = useState(0);
  const [required, setRequired ] = useState<boolean>();
  const [values, setValues] = useState<(string[])>(['']);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();

  const onAdd = (e?: MouseEvent) => {
    if (e) e.preventDefault();
    //
    setHasError(false);
    setErrorMsg(undefined);
    //
    const vArr: string[] = [...values];
    if (editIndex !== undefined) {
      vArr.splice(editIndex, 0, (ref.current as HTMLTextAreaElement).value);
      setEditIndex(undefined);
    } else {
      vArr.push((ref.current as HTMLTextAreaElement).value);
    }
    if (editValue) setEditValue(undefined);
    setKey(key + 1);
    setValues(vArr.filter(v => v !== ''));
    setTimeout(() => {
      ref.current?.dispatchEvent(new Event('change', {bubbles: true}));
      (ref.current as HTMLTextAreaElement).focus();
    }, 200);
  }

  const onDelete = (index: number) => {
    let vArr: string[] = [...values];
    vArr.splice(index, 1);
    if (!vArr.length) vArr = [''];
    setValues(vArr);
    setTimeout(() => {
      ref.current?.dispatchEvent(new Event('change', {bubbles: true}));
    }, 200);
  }

  const onEdit = (value: string, index: number) => {
    setKey(key + 1);
    setEditValue(value);
    setEditIndex(index);
    onDelete(index);
  }

  const onKeyDown = (e: KeyboardEvent) => {
    if(e.key === 'Enter' && ref.current?.value !== '') {
      e.preventDefault();
      onAdd();
    }
  }

  const onInvalid = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setHasError(true);
    setErrorMsg('At least 1 entry is required.');
    setTimeout(() => {
      document.querySelector('.input-error')?.parentElement?.scrollIntoView(IS_SAFARI ? false : {block: 'center'});
    }, 100);
  }

  useEffect(() => {
    setName(props.attributes.name);
    setRequired(props.attributes.required || false);
    setAttr({ 
      ...props.attributes,
      name: 'ignore',
      required: false
    });
  }, [props.attributes])

  useEffect(() => {
    if (props.defaultValues && props.defaultValues.length) setValues(props.defaultValues);
  }, [props.defaultValues])

  useEffect(() => {
    if (props.forceError) setHasError(true);
  }, [props.forceError])

  useEffect(() => {
    setErrorMsg(props.errorMessage);
  }, [props.errorMessage])

  /* useEffect(() => {
    console.log('TextAreaAddMulti values', values);
  }, [values]) */
  
  return (
    <div className={`form-sub-group form-sub-group--column ${props.className ? props.className : ''}`}>
      { 
        !!props.label && 
        <label htmlFor={props.attributes.name}>{props.label}</label>
      }

      <textarea key={key} ref={ref} {...attr} data-multi={true} defaultValue={editValue} onKeyDown={onKeyDown}></textarea>

      <button className={styles.btn} onClick={onAdd} disabled={!ref.current?.value || ref.current?.value === ''}>
        <span><SVGIcon id='plus' color='white' /></span>
        {editIndex !== undefined ? 'Accept Edit' : 'Add'}
      </button>

      <div ref={containerRef} className={styles.valuesContainer} data-empty={values.toString() === ''}>
        {
          values.map((val, i) =>
            <div key={i} className={styles.tag} data-hidden={val === ''}>
              <input key={val} name={name} data-group-index={i} defaultValue={val} 
                    required={required} tabIndex={-1} hidden onInvalid={onInvalid} />
              <span>{val}</span>
              <button type='button' title='edit' onClick={(e: MouseEvent) => {e.preventDefault(); onEdit(val, i);}}>
                <SVGIcon id='edit' />
              </button>
              <button type='button' title='delete' onClick={(e: MouseEvent) => {e.preventDefault(); onDelete(i);}}>
                <SVGIcon id='close' />
              </button>
            </div>
          )
        }
      </div>

      {
        hasError && errorMsg &&
        <div className='input-error' style={{bottom: '-13px'}}>{errorMsg}</div>
      }

      {
        !!props.children &&
        <div className='form-sub-sub-group'>
          {props.children}
        </div>
      }
    </div>
  )
};

export default TextAreaAddMulti;