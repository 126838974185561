import Logger from '../logger';

export default abstract class WidgetService {

  private static targetWindow = window.parent.window;

  public static postHref(path: string, queries?: string[]): void {
    Logger.log('WidgetService.postHref()');
    this.targetWindow.postMessage({type: 'href', path, queries}, '*');
  }

  public static postMount(): void {
    Logger.log('WidgetService.postMount()');
    this.targetWindow.postMessage({type: 'mount'}, '*');
  }

  public static postHeight(height: number): void {
    Logger.log('WidgetService.postHeight()');
    this.targetWindow.postMessage({type: 'height', height}, '*');
  }

}
