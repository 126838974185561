import React, { FormEvent, useEffect, useState } from 'react';
import { IS_SAFARI } from '../lib/constants';
import { EditorTextAreaAttributes } from '../lib/types';
import Editor from './Editor/Editor';

export interface IProps {
  label?: string,
  attributes: EditorTextAreaAttributes,
  errorMessage?: string,
  forceError?: boolean,
  className?: string,
}

const EditorTextArea = (props: IProps): JSX.Element => {
  const [attr, setAttr] = useState<EditorTextAreaAttributes | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();

  const onInvalid = (e: FormEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setHasError(true);
    setErrorMsg('This field is required.');
    setTimeout(() => {
      document.querySelector('.input-error')?.parentElement?.scrollIntoView(IS_SAFARI ? false : {block: 'center'});
    }, 100);
  }

  useEffect(() => {
    setHasError(props.forceError || false);
  }, [props.forceError])

  useEffect(() => {
    setErrorMsg(props.errorMessage);
  }, [props.errorMessage])

  useEffect(() => {
    setAttr({
      ...props.attributes,
      name: props.attributes.name || props.attributes.id,
      hidden: true,
      inputMode: 'text'
    });
  }, [props.attributes])

  return (
    <div className='form-sub-group form-sub-group--column'>
      <label>Job Description*</label>
      <textarea {...attr} onInvalid={onInvalid}>
      </textarea>
      
      <Editor />
      {
        hasError && errorMsg &&
        <p className='input-error' style={{bottom: '-13px'}}>{errorMsg}</p>
      }
    </div>
  )
};

export default EditorTextArea;