import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { PW_LENGTH_ERROR_MSG, MIN_LENGTH_PW } from '../../../lib/constants';
import { useSubject } from '../../../lib/hooks';
import UserService from '../../../lib/services/user.service';
import { userData$ } from '../../../lib/state';
import { ErrorResponse, FormValidityState } from '../../../lib/types';
import { matchValueValidator } from '../../../lib/validator.functions';
import Input from '../../../components/Input';
import styles from './Profile.module.scss';


const ChangePassword = (props: {disabled: boolean; formState: FormValidityState; setDisabled: (b: boolean) => void;}): JSX.Element => {
  const [pwError, setPwError] = useState<ErrorResponse | null>(null);
  const [pwSuccess, setPwSuccess] = useState(false);
  const userData = useSubject(userData$);
  const [key, setKey] = useState(0);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (props.formState
      && props.formState.values.current_password !== '' && props.formState.validity.current_password
      && props.formState.values.new_password !== '' && props.formState.validity.new_password
      && props.formState.values.re_new_password !== '' && props.formState.validity.re_new_password
    ) {
      if (!valid) setValid(true);
    } else {
      if (valid) setValid(false);
    }
    if (pwError) setPwError(null);
  }, [props.formState])

  const triggerValidationUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      (e.target as HTMLInputElement).form
      ?.querySelector('[name="re_new_password"]')
      ?.dispatchEvent(new Event('change', {bubbles: false}));
    }, 400);
  }

  const onChangePassword = (e: MouseEvent) => {
    e.preventDefault();
    if (pwError) setPwError(null);
    if(pwSuccess) setPwSuccess(false);
    setValid(false);
    props.setDisabled(true);
    //
    const data = { 
      current_password: props.formState.values.current_password,
      new_password: props.formState.values.new_password,
      re_new_password: props.formState.values.re_new_password
    }
    //
    UserService.updatePassword(data).subscribe({
      next: () => {
        setKey(key + 1);
        setPwSuccess(true);
        props.setDisabled(false);
        setTimeout(() => {
          setPwSuccess(false)
        }, 4000);
      },
      error: err => {
        setPwError(err);
        setValid(true);
        props.setDisabled(false);
      }
    });
  }

  return (
    <div key={key} className={styles.pallet} 
      data-employer={userData?.role === 'employer'}
      data-candidate={userData?.role == 'candidate'}>
      <h2>Change Password</h2>
      <p>Update your password below</p>

      <Input label='Current Password*'
        forceError={!!pwError}
        errorMessage={pwError ? pwError.response.current_password : undefined}
        attributes={{ type: 'password', id: 'current_password', autoComplete: 'current-password', 'data-ignore-touch': true }}
      />

      <Input label='New Password*'
        forceError={!!pwError}
        errorMessage={pwError ? pwError.response.new_password : PW_LENGTH_ERROR_MSG}
        attributes={{ type: 'password', id: 'new_password', minLength: MIN_LENGTH_PW, autoComplete: 'new-password', 
                    'data-ignore-touch': true, onChange: triggerValidationUpdate }}
      />

      <Input label='Repeat New Password*'
        forceError={!!pwError}
        errorMessage={pwError ? pwError.response.re_new_password : 'does not match new password'}
        validatorFns={[[matchValueValidator, ['new_password'] as any]]}
        attributes={{ type: 'password', id: 're_new_password', autoComplete: 'new-password', 'data-ignore-touch': true }}
      />

      <button type='button' className='btn btn--save' disabled={props.disabled || !valid} onClick={onChangePassword}>
        {pwSuccess ? 'Password Updated!' : 'Update Password'}
      </button>
    </div>
  )
};

export default ChangePassword;