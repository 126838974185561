import React, { ChangeEvent, useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import styles from '../Profile/Profile.module.scss';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { phoneValidator } from '../../../lib/validator.functions';
import { useProfileChoiceDetails } from '../../../lib/hooks';
import { UserData, ErrorResponse, FormValidityState } from '../../../lib/types';
import Checkbox from '../../../components/Checkbox/Checkbox';
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import { ACCEPT_PDF_DOC_DOCX, ACCEPT_PNG_JPG } from '../../../lib/constants';
import UploadButton from '../../../components/UploadButton';
import SelectAddMulti from '../../../components/SelectAddMulti/SelectAddMulti';
import { Value } from 'react-date-picker/dist/cjs/shared/types';

interface IProps {
  userData: UserData; 
  profileError: ErrorResponse | null
  onDeleteResume: (uuid: string) => void;
  onUploadResume: (e: ChangeEvent<HTMLInputElement> ) => void;
  onUploadImage: (e: ChangeEvent<HTMLInputElement> ) => void;
  resumeError: ErrorResponse | null;
  imgError: ErrorResponse | null;
  resumeUploading: boolean;
  imageUploading: boolean;
  formState: FormValidityState;
}

const CandidateCompleteProfile = (props: IProps): JSX.Element => {
  /* const [category, setCategory] = useState<string>(); */
  const [dobValue, onDOBChange] = useState<Value>(null);
  const [referral, setReferral] = useState<string>();
  const [calFirstOpen, setCalFirstOpen] = useState(false);
  const [calBtn, setCalBtn] = useState<HTMLButtonElement | null>(null);
  const {
    jobCategoryOptions,
    compensationOptions,
    compensationBonusAnnualOptions,
    compensationCommissionOtherPercentageOptions,
    compensationCommissionOtherTypeOptions,
    compensationCommissionProductPercentageOptions,
    compensationCommissionProductTypeOptions,
    compensationCommissionServicePercentageOptions,
    compensationCommissionServiceTypeOptions,
    compensationHourlyRateOptions,
    compensationSalaryRangeAnnualOptions,
    compensationTotalAnnualOptions,
    candidateReferralSourceOptions,
    candidateStateOptions,
    candidateYearsExperienceOptions
  } = useProfileChoiceDetails();

  /* const onCategoryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCategory(e.target.value);
  } */

  const onReferralChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setReferral(e.target.value);
  }

  // this helps w mobile/iphone calendar placement
  const onCalendarOpen = () => {
    if (!calFirstOpen) {
      setCalFirstOpen(true);
      calBtn?.click();
      setTimeout(() => {
        calBtn?.click();
      }, 100);
    }
  }

  // allows typing DOB date rather than selecting date from calendar
  useEffect(() => {
    setCalBtn(document.querySelector('.react-date-picker__calendar-button') as HTMLButtonElement);
    const dayInput: HTMLInputElement | null = document.querySelector('.react-date-picker__inputGroup__day');
    const monthInput: HTMLInputElement | null = document.querySelector('.react-date-picker__inputGroup__month');
    const yearInput: HTMLInputElement | null = document.querySelector('.react-date-picker__inputGroup__year');
    const checkInputs = (e: any) => {
      if (((e.type === 'keyup' && e.key === 'Enter') || e.type === 'blur')
        && yearInput?.value.length
        && monthInput?.value.length
        && dayInput?.value.length
      ) {
        const localeDate = new Date(parseInt(yearInput?.value), parseInt(monthInput?.value) - 1, parseInt(dayInput?.value)).toLocaleDateString();
        onDOBChange(new Date(localeDate));
        if (document.querySelector('.react-date-picker__calendar--open')) calBtn?.click();
      }
    };
    yearInput?.addEventListener('keyup', checkInputs);
    yearInput?.addEventListener('blur', checkInputs);
    monthInput?.addEventListener('keyup', checkInputs);
    monthInput?.addEventListener('blur', checkInputs);
    dayInput?.addEventListener('keyup', checkInputs);
    dayInput?.addEventListener('blur', checkInputs);
    return () => {
      yearInput?.removeEventListener('keyup', checkInputs);
      yearInput?.removeEventListener('blur', checkInputs);
      monthInput?.removeEventListener('keyup', checkInputs);
      monthInput?.removeEventListener('blur', checkInputs);
      dayInput?.removeEventListener('keyup', checkInputs);
      dayInput?.removeEventListener('blur', checkInputs);
    }
  }, [])

  return (
    <div className='content'>

      {/* Profile Details */}
      <div className={styles.pallet} data-candidate>
        <h2>Profile Details</h2>
        <p>Let’s start with the basics</p>

        <Input label='First Name'
          attributes={{ type: 'text', id: 'profile.first_name', disabled: true,
                      defaultValue: props.userData.profile.first_name || undefined }} 
        />

        <Input label='Last Name'
          attributes={{ type: 'text', id: 'profile.last_name', disabled: true,
                      defaultValue: props.userData.profile.last_name || undefined }}
        />

        <Input label='Phone*'
          validatorFns={[phoneValidator]}
          forceError={!!props.profileError}
          errorMessage={props.profileError ? props.profileError.response.profile?.phone_number : 'not a valid phone number' }
          attributes={{ type: 'tel', id: 'profile.phone_number', autoComplete: 'tel', required: true,
                      placeholder: 'Enter your phone number', defaultValue: props.userData.profile.phone_number || undefined }}
        />

        <div className='form-sub-group form-sub-group--border'>
          <label htmlFor='profile.date_of_birth'>Date of Birth*</label>
          {/* <input className='has-label' type='date' name='profile.date_of_birth' placeholder='mm-dd-yyyy' required /> */}
          <DatePicker 
            name='profile.date_of_birth' 
            onChange={onDOBChange}
            onCalendarOpen={onCalendarOpen}
            openCalendarOnFocus={false}
            value={dobValue} required={true} />
        </div>

        <Input label='Email'
          attributes={{ type: 'email', id: 'email', disabled: true, defaultValue: props.userData.email }} 
        />

        {
          candidateReferralSourceOptions &&
          <Select label='How did you hear about us?'
            options={candidateReferralSourceOptions}
            errorMessage={props.profileError ? props.profileError.response.profile?.referral_source: undefined }
            attributes={{ id: 'profile.referral_source', defaultValue: props.userData.profile.referral_source || 'default',
                        onChange: onReferralChange }}
          />
        }

        {
          referral === 'other' &&
          <Input label='Other Referral Source'
            forceError={!!props.profileError}
            errorMessage={props.profileError ? props.profileError.response.profile.referral_source_other : undefined }
            attributes={{ type: 'text', id: 'profile.referral_source_other',
                        defaultValue: props.userData.profile.referral_source_other || undefined }} 
          />
        }
      </div>

      {/* Profile Bio */}
      <div className={styles.pallet} data-candidate>
        <h2>Profile Bio</h2>
        <p>Give a little information about yourself</p>
        <textarea id='profile.bio' name='profile.bio' inputMode='text' rows={3} 
        placeholder='Keep it short and sweet!' defaultValue={props.userData.profile.bio || undefined}></textarea>
        {
          props.profileError && props.profileError.response.profile.bio &&
          <p className='input-error'>{props.profileError.response.profile.bio}</p>
        }
      </div>

      {/* Resume */}
      <div className={styles.pallet} data-candidate>
        <h2>Resume</h2>
        {
          props.userData.profile.resumes.length > 0 &&
          <fieldset>
          {
            props.userData.profile.resumes.map((r,i) => 
              <div key={`resume-item-${i}`} className={styles.resumeItem}>
                <Checkbox type='radio' label={r.file_name.toUpperCase()} subLabel={r.file_upload_date} attributes={{id: `resume-item-${i}`, defaultChecked: i === 0}} />
                <button type='button' onClick={() => props.onDeleteResume(r.uuid)}>
                  <SVGIcon id='trash' color='earthy-grey' />
                </button>
              </div>
            )
          }
          </fieldset>
        }
        
        <p>
          Applying for a job requires at least one resume.
          <br />
          You can upload documents in the the following file formats: DOC, DOCX or PDF. (max 5MB)
        </p>
        <p>Resumes are not searchable or shared with any employers without your explicit consent.</p>
        <UploadButton 
          uploading={props.resumeUploading}
          text='Upload Resume'
          className='btn--white btn--border'
          inputName='resume_file'
          accept={ACCEPT_PDF_DOC_DOCX}
          onChange={props.onUploadResume}
          disabled={!!props.userData.profile.resumes.length}
        />
        {
          props.resumeError &&
          <p className='input-error'>{props.resumeError.response.avatar_image_file || props.resumeError.response.uuid}</p>
        }
      </div>

      {/* Work Info */}
      <div className={styles.pallet} data-candidate>
        <h2>Work Information</h2>
        <p>Sign up to get exclusive job listings and industry news</p>

        {
          jobCategoryOptions &&
          <SelectAddMulti label='Job Categories*'
            options={jobCategoryOptions}
            forceError={!!props.profileError}
            errorMessage={props.profileError ? props.profileError.response.profile.categories : undefined}
            attributes={{ id: 'profile.categories', required: true }}
            defaultValues={ props.userData.profile.categories?.length > 0 ? props.userData.profile.categories : undefined }
          />
        }
        
        {
          props.formState?.values['profile.categories']?.includes('other') &&
          <Input label='Category Other*'
            forceError={!!props.profileError}
            errorMessage={props.profileError ? props.profileError.response.profile.category_other : undefined }
            attributes={{ type: 'text', id: 'profile.category_other', required: true,
                        defaultValue: props.userData.profile.category_other || undefined }} 
          />
        }

        {
          candidateYearsExperienceOptions &&
          <Select label='Years of Aesthetic Industry Experience*'
            options={candidateYearsExperienceOptions}
            errorMessage={props.profileError ? props.profileError.response.profile.years_experience : undefined }
            attributes={{ id: 'profile.years_experience', required: true,
                        defaultValue: props.userData.profile.years_experience || undefined }}
          />
        }

        {
          compensationOptions &&
          <Select label='Compensation Type*'
            options={compensationOptions}
            errorMessage={props.profileError ? props.profileError.response.profile.compensation : undefined }
            attributes={{ id: 'profile.compensation', required: true, 
                        defaultValue: props.userData.profile.compensation || undefined }}
          />
        }

        {
          props.formState?.values['profile.compensation']?.match(/^salary$/) && compensationSalaryRangeAnnualOptions &&
          <Select label='Salary Range (Annual)*'
            options={compensationSalaryRangeAnnualOptions}
            errorMessage={props.profileError ? props.profileError.response.profile.compensation_salary_range_annual : undefined }
            attributes={{ id: 'profile.compensation_salary_range_annual', required: true,
                        defaultValue: props.userData.profile.compensation_salary_range_annual || undefined }}
          />
        }

        {
          props.formState?.values['profile.compensation']?.match(/^salary_commission$/) &&
          compensationCommissionOtherPercentageOptions &&
          compensationCommissionOtherTypeOptions &&
          compensationCommissionProductPercentageOptions &&
          compensationCommissionProductTypeOptions &&
          compensationCommissionServicePercentageOptions &&
          compensationCommissionServiceTypeOptions &&
          compensationSalaryRangeAnnualOptions &&
          <>
            <Select label='Salary Range (Annual)*'
              options={compensationSalaryRangeAnnualOptions}
              errorMessage={props.profileError ? props.profileError.response.profile.compensation_salary_range_annual : undefined }
              attributes={{ id: 'profile.compensation_salary_range_annual', required: true,
                          defaultValue: props.userData.profile.compensation_salary_range_annual || undefined }}
            />

            <label>Commission Categories* (Select at least one):</label>
            <fieldset className='inline'>
              <Checkbox
                type='checkbox'
                label=''
                subLabel='Product'
                attributes={{ id: 'profile.compensation_commission_product', defaultChecked: !!props.userData.profile.compensation_commission_product }} />
              {
                props.formState?.values['profile.compensation_commission_product']?.includes('on') &&
                <>
                  <Select
                    options={compensationCommissionProductTypeOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_product_type : undefined }
                    attributes={{ id: 'profile.compensation_commission_product_type', required: true,
                                defaultValue: props.userData.profile.compensation_commission_product_type || undefined }}
                    className='form-sub-group--inline'
                  />
                  <Select
                    options={compensationCommissionProductPercentageOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_product_percentage : undefined }
                    attributes={{ id: 'profile.compensation_commission_product_percentage', required: true,
                                defaultValue: props.userData.profile.compensation_commission_product_percentage || undefined }}
                  />
                </>
              }
            </fieldset>
            <fieldset className='inline'>
              <Checkbox
                type='checkbox'
                label=''
                subLabel='Services'
                attributes={{ id: 'profile.compensation_commission_service', defaultChecked: !!props.userData.profile.compensation_commission_service }} />
              {
                props.formState?.values['profile.compensation_commission_service']?.includes('on') &&
                <>
                  <Select
                    options={compensationCommissionServiceTypeOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_service_type : undefined }
                    attributes={{ id: 'profile.compensation_commission_service_type', required: true,
                                defaultValue: props.userData.profile.compensation_commission_service_type || undefined }}
                    className='form-sub-group--inline'
                  />
                  <Select
                    options={compensationCommissionServicePercentageOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_service_percentage : undefined }
                    attributes={{ id: 'profile.compensation_commission_service_percentage', required: true,
                                defaultValue: props.userData.profile.compensation_commission_service_percentage || undefined }}
                  />
                </>
              }
            </fieldset>
            <fieldset className='inline'>
              <Checkbox
                type='checkbox'
                label=''
                subLabel='Other'
                attributes={{ id: 'profile.compensation_commission_other', defaultChecked: !!props.userData.profile.compensation_commission_other }} />
              {
                props.formState?.values['profile.compensation_commission_other']?.includes('on') &&
                <>
                  <Select
                    options={compensationCommissionOtherTypeOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_other_type : undefined }
                    attributes={{ id: 'profile.compensation_commission_other_type', required: true,
                                defaultValue: props.userData.profile.compensation_commission_other_type || undefined }}
                    className='form-sub-group--inline'
                  />
                  <Select
                    options={compensationCommissionOtherPercentageOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_other_percentage : undefined }
                    attributes={{ id: 'profile.compensation_commission_other_percentage', required: true,
                                defaultValue: props.userData.profile.compensation_commission_other_percentage || undefined }}
                  />
                </>
              }
            </fieldset>
          </>
        }

        {
          props.formState?.values['profile.compensation']?.match(/^salary_bonus/) &&
          compensationBonusAnnualOptions &&
          compensationSalaryRangeAnnualOptions &&
          <>
            <Select label='Salary Range (Annual)*'
              options={compensationSalaryRangeAnnualOptions}
              errorMessage={props.profileError ? props.profileError.response.profile.compensation_salary_range_annual : undefined }
              attributes={{ id: 'profile.compensation_salary_range_annual', required: true,
                          defaultValue: props.userData.profile.compensation_salary_range_annual || undefined }}
            />

            <Select label='Total Annual Bonus*'
              options={compensationBonusAnnualOptions}
              errorMessage={props.profileError ? props.profileError.response.profile.compensation_bonus_annual : undefined }
              attributes={{ id: 'profile.compensation_bonus_annual', required: true,
                          defaultValue: props.userData.profile.compensation_bonus_annual || undefined }}
            />
          </>
        }

        {
          props.formState?.values['profile.compensation']?.match(/^hourly$/) &&
          compensationHourlyRateOptions &&
          <Select label='Hourly Rate*'
            options={compensationHourlyRateOptions}
            errorMessage={props.profileError ? props.profileError.response.profile.compensation_hourly_rate : undefined }
            attributes={{ id: 'profile.compensation_hourly_rate', required: true,
                        defaultValue: props.userData.profile.compensation_hourly_rate || undefined }}
          />
        }

        {
          props.formState?.values['profile.compensation']?.match(/^hourly_commission$/) &&
          compensationCommissionOtherPercentageOptions &&
          compensationCommissionOtherTypeOptions &&
          compensationCommissionProductPercentageOptions &&
          compensationCommissionProductTypeOptions &&
          compensationCommissionServicePercentageOptions &&
          compensationCommissionServiceTypeOptions &&
          compensationHourlyRateOptions &&
          <>
            <Select label='Hourly Rate*'
              options={compensationHourlyRateOptions}
              errorMessage={props.profileError ? props.profileError.response.profile.compensation_hourly_rate : undefined }
              attributes={{ id: 'profile.compensation_hourly_rate', required: true,
                          defaultValue: props.userData.profile.compensation_hourly_rate || undefined }}
            />

            <label>Commission Categories* (Select at least one):</label>
            <fieldset className='inline'>
              <Checkbox
                type='checkbox'
                label=''
                subLabel='Product'
                attributes={{ id: 'profile.compensation_commission_product', defaultChecked: !!props.userData.profile.compensation_commission_product }} />
              {
                props.formState?.values['profile.compensation_commission_product']?.includes('on') &&
                <>
                  <Select
                    options={compensationCommissionProductTypeOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_product_type : undefined }
                    attributes={{ id: 'profile.compensation_commission_product_type', required: true,
                                defaultValue: props.userData.profile.compensation_commission_product_type || undefined }}
                    className='form-sub-group--inline'
                  />
                  <Select
                    options={compensationCommissionProductPercentageOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_product_percentage : undefined }
                    attributes={{ id: 'profile.compensation_commission_product_percentage', required: true,
                                defaultValue: props.userData.profile.compensation_commission_product_percentage || undefined }}
                  />
                </>
              }
            </fieldset>
            <fieldset className='inline'>
              <Checkbox
                type='checkbox'
                label=''
                subLabel='Services'
                attributes={{ id: 'profile.compensation_commission_service', defaultChecked: !!props.userData.profile.compensation_commission_service }} />
              {
                props.formState?.values['profile.compensation_commission_service']?.includes('on') &&
                <>
                  <Select
                    options={compensationCommissionServiceTypeOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_service_type : undefined }
                    attributes={{ id: 'profile.compensation_commission_service_type', required: true,
                                defaultValue: props.userData.profile.compensation_commission_service_type || undefined }}
                    className='form-sub-group--inline'
                  />
                  <Select
                    options={compensationCommissionServicePercentageOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_service_percentage : undefined }
                    attributes={{ id: 'profile.compensation_commission_service_percentage', required: true,
                                defaultValue: props.userData.profile.compensation_commission_service_percentage || undefined }}
                  />
                </>
              }
            </fieldset>
            <fieldset className='inline'>
              <Checkbox
                type='checkbox'
                label=''
                subLabel='Other'
                attributes={{ id: 'profile.compensation_commission_other', defaultChecked: !!props.userData.profile.compensation_commission_other }} />
              {
                props.formState?.values['profile.compensation_commission_other']?.includes('on') &&
                <>
                  <Select
                    options={compensationCommissionOtherTypeOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_other_type : undefined }
                    attributes={{ id: 'profile.compensation_commission_other_type', required: true,
                                defaultValue: props.userData.profile.compensation_commission_other_type || undefined }}
                    className='form-sub-group--inline'
                  />
                  <Select
                    options={compensationCommissionOtherPercentageOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_other_percentage : undefined }
                    attributes={{ id: 'profile.compensation_commission_other_percentage', required: true,
                                defaultValue: props.userData.profile.compensation_commission_other_percentage || undefined }}
                  />
                </>
              }
            </fieldset>
          </>
        }

        {
          props.formState?.values['profile.compensation']?.match(/^commission$/) &&
          compensationCommissionOtherPercentageOptions &&
          compensationCommissionOtherTypeOptions &&
          compensationCommissionProductPercentageOptions &&
          compensationCommissionProductTypeOptions &&
          compensationCommissionServicePercentageOptions &&
          compensationCommissionServiceTypeOptions &&
          <>
            <label>Commission Categories* (Select at least one):</label>
            <fieldset className='inline'>
              <Checkbox
                type='checkbox'
                label=''
                subLabel='Product'
                attributes={{ id: 'profile.compensation_commission_product', defaultChecked: !!props.userData.profile.compensation_commission_product }} />
              {
                props.formState?.values['profile.compensation_commission_product']?.includes('on') &&
                <>
                  <Select
                    options={compensationCommissionProductTypeOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_product_type : undefined }
                    attributes={{ id: 'profile.compensation_commission_product_type', required: true,
                                defaultValue: props.userData.profile.compensation_commission_product_type || undefined }}
                    className='form-sub-group--inline'
                  />
                  <Select
                    options={compensationCommissionProductPercentageOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_product_percentage : undefined }
                    attributes={{ id: 'profile.compensation_commission_product_percentage', required: true,
                                defaultValue: props.userData.profile.compensation_commission_product_percentage || undefined }}
                  />
                </>
              }
            </fieldset>
            <fieldset className='inline'>
              <Checkbox
                type='checkbox'
                label=''
                subLabel='Services'
                attributes={{ id: 'profile.compensation_commission_service', defaultChecked: !!props.userData.profile.compensation_commission_service }} />
              {
                props.formState?.values['profile.compensation_commission_service']?.includes('on') &&
                <>
                  <Select
                    options={compensationCommissionServiceTypeOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_service_type : undefined }
                    attributes={{ id: 'profile.compensation_commission_service_type', required: true,
                                defaultValue: props.userData.profile.compensation_commission_service_type || undefined }}
                    className='form-sub-group--inline'
                  />
                  <Select
                    options={compensationCommissionServicePercentageOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_service_percentage : undefined }
                    attributes={{ id: 'profile.compensation_commission_service_percentage', required: true,
                                defaultValue: props.userData.profile.compensation_commission_service_percentage || undefined }}
                  />
                </>
              }
            </fieldset>
            <fieldset className='inline'>
              <Checkbox
                type='checkbox'
                label=''
                subLabel='Other'
                attributes={{ id: 'profile.compensation_commission_other', defaultChecked: !!props.userData.profile.compensation_commission_other }} />
              {
                props.formState?.values['profile.compensation_commission_other']?.includes('on') &&
                <>
                  <Select
                    options={compensationCommissionOtherTypeOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_other_type : undefined }
                    attributes={{ id: 'profile.compensation_commission_other_type', required: true,
                                defaultValue: props.userData.profile.compensation_commission_other_type || undefined }}
                    className='form-sub-group--inline'
                  />
                  <Select
                    options={compensationCommissionOtherPercentageOptions}
                    errorMessage={props.profileError ? props.profileError.response.profile.compensation_commission_other_percentage : undefined }
                    attributes={{ id: 'profile.compensation_commission_other_percentage', required: true,
                                defaultValue: props.userData.profile.compensation_commission_other_percentage || undefined }}
                  />
                </>
              }
            </fieldset>
          </>
        }

        {
          compensationTotalAnnualOptions &&
          <Select label='Total Annual Compensation*'
            options={compensationTotalAnnualOptions}
            errorMessage={props.profileError ? props.profileError.response.profile.compensation_total_annual : undefined }
            attributes={{ id: 'profile.compensation_total_annual', required: true,
                        defaultValue: props.userData.profile.compensation_total_annual || undefined }}
          />
        }

        <p>Compensation data is confidential. Candidates who complete the compensation profile below will have complimentary access to JobSnob&apos;s annual industry compensation report.</p>
      </div>

      {/* Profile Image */}
      <div className={styles.pallet} data-candidate>
        <h2>Profile Image</h2>
        <p>Upload a profile picture. Recommend size: 160x160px</p>
        {
          props.userData.profile.avatar_image_url && props.userData.profile.avatar_image_url !== '' &&
          <img className={`profile-image ${styles.avatar}`} src={props.userData.profile.avatar_image_url} />
        }
        <UploadButton 
          uploading={props.imageUploading}
          text='Upload Image'
          className='btn--white btn--border'
          inputName='avatar_image_file'
          accept={ACCEPT_PNG_JPG}
          onChange={props.onUploadImage}
        />
        {
          props.imgError &&
          <p className='input-error'>{props.imgError.response.avatar_image_file || props.imgError.response.uuid}</p>
        }
      </div>

    </div>
  )
};

export default CandidateCompleteProfile;
