import React, { ReactElement, useEffect, useState } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props: {children?: ReactElement | ReactElement[]}) => {
  const location = useLocation();
  const [prevPath, setPrevPath] = useState<string>();

  useEffect(() => {
    if (prevPath !== location.pathname) {
      document.body.scrollTo(0,0);
    }
    setPrevPath(location.pathname);
  }, [location]);

  return <>{props.children}</>
};

export default ScrollToTop;