import React, { useEffect, useState } from 'react';
import { SassString } from 'sass';
import { SelectAttributes, SelectOption } from '../../lib/types';
import Select from '../Select';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './NavDropDown.module.scss';

interface DropDownProps {
  attributes: SelectAttributes,
  options: SelectOption[],
  label: string,
  className?: string;
  leftIcon?: string;
  onSelected?: (value: string) => void;
}

const DropDown = (props: DropDownProps): JSX.Element => {
  const [value, setValue] = useState<string>(props.attributes.defaultValue as string);
  const [label, setLabel] = useState<string>(props.label);
  const [choices, setChoices] = useState<SelectOption[]>([]);

  const onClick = () => {
    if (!choices.length) {
      setChoices(props.options);
    }
  }

  const onMouseLeave = () => {
    setChoices([]);
  }

  const onChoice = (value: string, labelValue: string) => {
    setValue(value);
    setChoices([]);
  }

  useEffect(() => {
    if (value && props.onSelected) props.onSelected(value);
  }, [value]);
  
  return (
    <div className={`${styles.dropDownWrap}${props.className ? ` ${props.className}` : ''}`}>
      <div className={styles.label} onClick={onClick}>
        {label}
        {
          props.leftIcon &&
          <span className='input-icon input-icon--left'>
            <SVGIcon id={props.leftIcon} />
          </span>
        }
      </div>
      {
        choices.length > 0 &&
        <div className={styles.choicesContainer} onMouseLeave={onMouseLeave}>
          {
            choices.map((opt, i) => 
              <div key={i} className='form-sub-group input-option' 
                onClick={() => onChoice(opt.value, opt.label)}
                data-selected={value === opt.value} 
              >
                {opt.label}
              </div>
            )
          }
        </div>
      }
    </div>
  )
};

export default DropDown;
