import { catchError, delay, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { DOMAIN } from '../constants';
import Logger from '../logger';
import { userData$ } from '../state';
import { ApplicantData, ApplicationData, JobPostData, PaginateApplicantsData, PaginateJobsData } from '../types';
import { handleFormError, objToFormData } from '../util.functions';
import AuthService from './auth.service';
import GTMService from './gtm.service';


export default abstract class CandidateJobService {

  public static getJob(uuid: string): Observable<JobPostData> {
    Logger.log('CandidateJobService.getJob()', uuid);
    return userData$.pipe(
      take(2),
      switchMap(val => val 
        ? AuthService.tokenGuaranteeSwitchMap(CandidateJobService.jobDetail, uuid)
        : CandidateJobService.jobDetail(undefined, uuid)
      )
    );
    /* return userData$.value 
            ? AuthService.tokenGuaranteeSwitchMap(CandidateJobService.jobDetail, uuid)
            : CandidateJobService.jobDetail(undefined, uuid); */
  }

  public static getJobs(queryParams: string): Observable<PaginateJobsData> {
    Logger.log('CandidateJobService.getJobs', queryParams);
    return userData$.pipe(
      take(2),
      switchMap(val => val 
        ? AuthService.tokenGuaranteeSwitchMap(CandidateJobService.jobsList, queryParams)
        : CandidateJobService.jobsList(undefined, queryParams)
      )
    );
    /* return userData$.value 
            ? AuthService.tokenGuaranteeSwitchMap(CandidateJobService.jobsList, queryParams)
            : CandidateJobService.jobsList(undefined, queryParams); */
  }

  public static getFavorites(queryParams: string): Observable<PaginateJobsData> {
    Logger.log('CandidateJobService.getJobs', queryParams);
    return AuthService.tokenGuaranteeSwitchMap(CandidateJobService.favoritesList, queryParams);
  }

  public static markFavorite(uuid: string, fav: boolean): Observable<{is_favorited: boolean; uuid: string}> {
    Logger.log('CandidateJobService.markFavorite', uuid, userData$.value);
    return userData$.value
            ? AuthService.tokenGuaranteeSwitchMap(CandidateJobService.favorite, {uuid, fav})
            : throwError(() => new Error('Must be logged in to mark as favorite.'));
  }

  public static getApplications(queryParams: string): Observable<PaginateApplicantsData> {
    Logger.log('CandidateJobService.getApplicants', queryParams);
    return AuthService.tokenGuaranteeSwitchMap(CandidateJobService.applicationsList, queryParams);
  }

  public static apply(data: ApplicationData): Observable<PaginateApplicantsData> {
    Logger.log('CandidateJobService.apply', data);
    return AuthService.tokenGuaranteeSwitchMap(CandidateJobService.application, data);
  }

  private static jobDetail(access: string | undefined, uuid: string): Observable<JobPostData> {
    return ajax.get(`${DOMAIN}/api/jobs/${uuid}`, access ? { 'Authorization': `Bearer ${access}` } : undefined).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static jobsList(access: string | undefined, queryParams: string): Observable<PaginateJobsData> {
    return ajax.get(`${DOMAIN}/api/jobs?${queryParams}`, access ? { 'Authorization': `Bearer ${access}` } : undefined).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static favoritesList(access: string, queryParams: string): Observable<PaginateJobsData> {
    return ajax.get(`${DOMAIN}/api/jobs/favorites/?${queryParams}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static favorite(access: string, dta: {uuid: string, fav: boolean}): Observable<{is_favorited: boolean; uuid: string}> {
    return ajax.put(`${DOMAIN}/api/jobs/${dta.uuid}`, objToFormData({favorite: dta.fav}), { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  private static applicationsList(access: string, queryParams: string): Observable<PaginateApplicantsData> {
    return ajax.get(`${DOMAIN}/api/applications/?${queryParams}`, { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

  /* Apply to Job */
  private static application(access: string, data: ApplicationData): Observable<ApplicantData> {
    return ajax.post(`${DOMAIN}/api/applications/`, objToFormData(data), { 'Authorization': `Bearer ${access}` }).pipe(
      map((ajaxResp: AjaxResponse<any>) => ajaxResp.response),
      tap(() => GTMService.pushEvent({ 'event': 'applied_for_job' })),
      catchError((e: AjaxError) => handleFormError(e))
    );
  }

}