import React, { useEffect, useState } from 'react';
import { UserData } from '../../lib/types';
import styles from './AdminViewUser.module.scss';
import JobDetail from '../JobDetail/JobDetail';
import { useProfileChoiceDetails } from '../../lib/hooks';
import { parsePhoneNumber } from 'libphonenumber-js';
import SVGIcon from '../SVGIcon/SVGIcon';
import ResumeService from '../../lib/services/resume.service';

const ViewCandidateUser = (props: {data: UserData, mapUrl?: string, phone?: string}): JSX.Element => {
  const {
    jobCategoryOptions,
    compensationOptions,
    compensationBonusAnnualOptions,
    compensationCommissionOtherPercentageOptions,
    compensationCommissionOtherTypeOptions,
    compensationCommissionProductPercentageOptions,
    compensationCommissionProductTypeOptions,
    compensationCommissionServicePercentageOptions,
    compensationCommissionServiceTypeOptions,
    compensationHourlyRateOptions,
    compensationSalaryRangeAnnualOptions,
    compensationTotalAnnualOptions,
    candidateReferralSourceOptions,
    candidateStateOptions,
    candidateYearsExperienceOptions
  } = useProfileChoiceDetails();
  const [mapURL, setMapUrl] = useState<string>(props.mapUrl as string);
  const [phone, setPhone] = useState<string>(props.phone as string);

  const onDownloadResume = () => {
    ResumeService.downloadResume({ 
      fileName: props.data.profile.resumes[0].file_name as string,
      url: props.data.profile.resumes[0].file_url as string
    }).subscribe();
  }

  useEffect(() => {
    if (props.data) {
      if (!props.mapUrl) {
        const url = new URL('https://www.google.com/maps/search/');
        url.searchParams.append('api', '1');
        url.searchParams.append('query', `${props.data.profile.address} ${props.data.profile.city} ${props.data.profile.state} ${props.data.profile.zip_code}`);
        setMapUrl(url.href);
      }
      //
      if (!props.phone && props.data.profile.phone_number && props.data.profile.phone_number !== '') {
        const phoneNumber = parsePhoneNumber(props.data.profile.phone_number, 'US')
        setPhone(props.data.profile.phone_number.startsWith('+') ? phoneNumber.formatInternational() : phoneNumber.formatNational());
      }
    }
  }, [props.data])
  
  return (
    <>
      <div className={styles.header}>
        <img className='profile-image' src={props.data.profile.avatar_image_url} />
        <div className={styles.roleWrap}>
          <span className='eyebrow'>{props.data.role}</span>
          <JobDetail
            icon='user' 
            text={props.data.is_active ? 'active' : 'not active'} 
            lifeCycle={props.data.is_active ? 'active' : 'deactivated'}
          />
        </div>
        <h1>{props.data.profile.first_name} {props.data.profile.last_name}</h1>
        <h2>
          <span>
            {jobCategoryOptions?.filter((o) => props.data.profile.categories.includes(o.value)).map(o => o.label).join(', ')}
            {props.data.profile.categories.includes('other') && props.data.profile.category_other !== '' ? `(${props.data.profile.category_other})` : ''}
          </span>
        </h2>
        <h2>
          <a href={`mailto: ${props.data.email.toLowerCase()}`} target='_blank' rel='noreferrer'>
            {props.data.email}
          </a>
        </h2>
      </div>

      <div className={styles.moreInfo}>
        {
          (!!props.data.profile.years_experience || !!props.data.profile.resumes.length) &&
          <div className={styles.detailsWrap}>
            {
              candidateYearsExperienceOptions &&
              <JobDetail icon='briefcase' text={`${candidateYearsExperienceOptions.find(o => o.value === props.data.profile.years_experience)?.label} years experience`} />
            }
            {
              props.data.profile.resumes[0] &&
              <button className='btn btn--oval btn--transparent btn--border btn--small' onClick={onDownloadResume}>
                <SVGIcon id='download' />
                Download Resume
              </button>
            }
          </div>
        }

        <div className={styles.left}>
          <div className={styles.bioWrap}>
            <h3 className='eyebrow'>Bio</h3>
            <div className={styles.bio}>
              {
                props.data.profile.bio &&
                props.data.profile.bio.split('\n').map((v, i) => v !== '' ? (v.length === 1 ? <br /> : <p key={i}>{v}</p>) : null)
              }
            </div>
          </div>

          <div className={styles.linkWrap}>
            {
              props.data.profile.date_of_birth &&
              <div>
              <SVGIcon id='cake-birthday' />
                {new Date(props.data.profile.date_of_birth).toLocaleDateString()}
              </div>
            }
            
            <a href={`tel: ${props.data.profile.phone_number}`}>
              <SVGIcon id='phone' />
              {phone}
            </a>

            <a href={mapURL} target='_blank' rel='noreferrer'>
              <SVGIcon id='map-pin' />
              {
                props.data.profile.address !== '' && `${props.data.profile.address}, `
              }
              {
                props.data.profile.city !== '' && `${props.data.profile.city}, `
              }
              {
                props.data.profile.state !== '' && `${props.data.profile.state} `
              }
              {props.data.profile.zip_code}
            </a>
          </div>
        </div>

        <div className={styles.right} data-candidate>
          {
            jobCategoryOptions &&
            <p className={styles.infoP}>Job Categories: <span>{jobCategoryOptions?.filter((o) => props.data.profile.categories.includes(o.value)).map(o => o.label).join(', ')}</span></p>
          }

          {
            props.data.profile.category_other &&
            <p className={styles.infoP}>Job Category (Other): <span>{props.data.profile.category_other}</span></p>
          }

          {
            compensationOptions &&
            <p className={styles.infoP}>Compensation Type: <span>{compensationOptions.find(o => o.value === props.data.profile.compensation)?.label}</span></p>
          }

          {
            props.data.profile.compensation.match(/^salary$/) &&
            compensationSalaryRangeAnnualOptions &&
            <p className={styles.infoP}>Salary Range (Annual): <span>{compensationSalaryRangeAnnualOptions.find(o => o.value === props.data.profile.compensation_salary_range_annual)?.label}</span></p>
          }

          {
            props.data.profile.compensation.match(/^salary_commission$/) &&
            compensationSalaryRangeAnnualOptions &&
            compensationCommissionOtherPercentageOptions &&
            compensationCommissionOtherTypeOptions &&
            compensationCommissionProductPercentageOptions &&
            compensationCommissionProductTypeOptions &&
            compensationCommissionServicePercentageOptions &&
            compensationCommissionServiceTypeOptions &&
            <>
              <p className={styles.infoP}>Salary Range (Annual): <span>{compensationSalaryRangeAnnualOptions.find(o => o.value === props.data.profile.compensation_salary_range_annual)?.label}</span></p>
              {
                props.data.profile.compensation_commission_product &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionProductTypeOptions.find(o => o.value === props.data.profile.compensation_commission_product_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionProductPercentageOptions.find(o => o.value === props.data.profile.compensation_commission_product_percentage)?.label}</span></p>
                </>
              }
              {
                props.data.profile.compensation_commission_service &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionServiceTypeOptions.find(o => o.value === props.data.profile.compensation_commission_service_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionServicePercentageOptions.find(o => o.value === props.data.profile.compensation_commission_service_percentage)?.label}</span></p>
                </>
              }
              {
                props.data.profile.compensation_commission_other &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionOtherTypeOptions.find(o => o.value === props.data.profile.compensation_commission_other_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionOtherPercentageOptions.find(o => o.value === props.data.profile.compensation_commission_other_percentage)?.label}</span></p>
                </>
              }
            </>
          }

          {
            props.data.profile.compensation.match(/^salary_bonus/) &&
            compensationSalaryRangeAnnualOptions &&
            compensationBonusAnnualOptions &&
            <>
              <p className={styles.infoP}>Salary Range (Annual): <span>{compensationSalaryRangeAnnualOptions.find(o => o.value === props.data.profile.compensation_salary_range_annual)?.label}</span></p>
              <p className={styles.infoP}>Annual Bonus: <span>{compensationBonusAnnualOptions.find(o => o.value === props.data.profile.compensation_bonus_annual)?.label}</span></p>
            </>
          }

          {
            props.data.profile.compensation.match(/^hourly$/) &&
            compensationHourlyRateOptions &&
            <p className={styles.infoP}>Hourly Rate: <span>{compensationHourlyRateOptions.find(o => o.value === props.data.profile.compensation_hourly_rate)?.label}</span></p>
          }

          {
            props.data.profile.compensation.match(/^hourly_commission$/) &&
            compensationHourlyRateOptions &&
            compensationCommissionOtherPercentageOptions &&
            compensationCommissionOtherTypeOptions &&
            compensationCommissionProductPercentageOptions &&
            compensationCommissionProductTypeOptions &&
            compensationCommissionServicePercentageOptions &&
            compensationCommissionServiceTypeOptions &&
            <>
              <p className={styles.infoP}>Hourly Rate: <span>{compensationHourlyRateOptions.find(o => o.value === props.data.profile.compensation_hourly_rate)?.label}</span></p>
              {
                props.data.profile.compensation_commission_product &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionProductTypeOptions.find(o => o.value === props.data.profile.compensation_commission_product_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionProductPercentageOptions.find(o => o.value === props.data.profile.compensation_commission_product_percentage)?.label}</span></p>
                </>
              }
              {
                props.data.profile.compensation_commission_service &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionServiceTypeOptions.find(o => o.value === props.data.profile.compensation_commission_service_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionServicePercentageOptions.find(o => o.value === props.data.profile.compensation_commission_service_percentage)?.label}</span></p>
                </>
              }
              {
                props.data.profile.compensation_commission_other &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionOtherTypeOptions.find(o => o.value === props.data.profile.compensation_commission_other_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionOtherPercentageOptions.find(o => o.value === props.data.profile.compensation_commission_other_percentage)?.label}</span></p>
                </>
              }
            </>
          }

          {
            props.data.profile.compensation.match(/^commission$/) &&
            compensationCommissionOtherPercentageOptions &&
            compensationCommissionOtherTypeOptions &&
            compensationCommissionProductPercentageOptions &&
            compensationCommissionProductTypeOptions &&
            compensationCommissionServicePercentageOptions &&
            compensationCommissionServiceTypeOptions &&
            <>
              {
                props.data.profile.compensation_commission_product &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionProductTypeOptions.find(o => o.value === props.data.profile.compensation_commission_product_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionProductPercentageOptions.find(o => o.value === props.data.profile.compensation_commission_product_percentage)?.label}</span></p>
                </>
              }
              {
                props.data.profile.compensation_commission_service &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionServiceTypeOptions.find(o => o.value === props.data.profile.compensation_commission_service_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionServicePercentageOptions.find(o => o.value === props.data.profile.compensation_commission_service_percentage)?.label}</span></p>
                </>
              }
              {
                props.data.profile.compensation_commission_other &&
                <>
                  <p className={styles.infoP}>Commission Type (Product): <span>{compensationCommissionOtherTypeOptions.find(o => o.value === props.data.profile.compensation_commission_other_type)?.label}</span></p>
                  <p className={styles.infoP}>Commission Percentage (Product): <span>{compensationCommissionOtherPercentageOptions.find(o => o.value === props.data.profile.compensation_commission_other_percentage)?.label}</span></p>
                </>
              }
            </>
          }

          {
            compensationTotalAnnualOptions &&
            <p className={styles.infoP}>Total Annual Compensation: <span>{compensationTotalAnnualOptions.find(o => o.value === props.data.profile.compensation_total_annual)?.label}</span></p>
          }

          {
            candidateReferralSourceOptions &&
            <p className={styles.infoP}>Referral Source: <span>{candidateReferralSourceOptions.find(o => o.value === props.data.profile.referral_source)?.label || 'None'}</span></p>
          }

          {
            props.data.profile.referral_source_other &&
            <p className={styles.infoP}>Referral Source (Other): <span>{props.data.profile.referral_source_other}</span></p>
          }

          {
            <p className={styles.infoP}>Requires On-boarding: <span>{`${props.data.requires_onboarding ? 'Yes' : 'No'}`}</span></p>
          }
        </div>
      </div>
    </>
  )
};

export default ViewCandidateUser;