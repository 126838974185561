import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollToTop from '../../../components/ScrollToTop';
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import { ROUTES } from '../../../lib/app-routes';
import UserService from '../../../lib/services/user.service';
import styles from './OnboardApplySuccess.module.scss';

const OnboardApplySuccess = (): JSX.Element => {

  useEffect(() => {
    UserService.updateUser({onboarding_redirect: null}).subscribe();
  }, []);
  
  return (
    <div className={`content-wrap content-wrap--no-padding bg-account ${styles.contentWrap}`}>
      <div className={`content ${styles.content}`}>

        <div className='icon60 icon60--border-black'>
          <SVGIcon id='checkmark' />
        </div>

        <div>
          <p className='eyebrow'>nicley done!</p>
          <h2><span>Application Received!</span></h2>
        </div>

        <p>Now that the hard work is done, why not keep looking and apply for another position? All of your info will be pre-populated, so it should only take a minute!</p>

        <Link to={ROUTES.jobboard.path} className='btn'>
          Keep Looking
        </Link>
        <ScrollToTop />
      </div>
    </div>
  )
};

export default OnboardApplySuccess;