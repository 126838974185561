import React, { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useSearchParams } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { ROUTES } from '../../lib/app-routes';
import { useCredits } from '../../lib/hooks';
import EmployerJobService from '../../lib/services/employer.job.service';
import { ApplicantData, JobPostData, LifeCycleValue } from '../../lib/types';
import { getDeepClone } from '../../lib/util.functions';
import { draftsAppsModCount$, dashboardErrorMsg$, jobsCount$, myDraftsOrApps$, myJobs$ } from '../../pages/Dashboard/state';
import { checkoutModuleShow$, checkoutReturnUrl$ } from '../../pages/Purchase/state';
import ApplicantCard from '../ApplicantCard/ApplicantCard';
import Modal from '../Modal/Modal';
import SVGIcon from '../SVGIcon/SVGIcon';
import TwirlDown from '../TwirlDown/TwirlDown';
import styles from './EmployerJobCard.module.scss';
import EmployerJobCardDetails from './EmployerJobCardDetails';
import JobCardMenu from './JobCardMenu';

const EmployerJobCard = (props: {type: 'job' | 'draft', jobData: JobPostData}): JSX.Element => {
  const [jobData, setJobData] = useState(props.jobData);
  const [applicantsData, setApplicantsData] = useState<ApplicantData[]>([]);
  const [loadMore, setLoadMore] = useState(true);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  const [newCount, setNewCount]  = useState<number>(0);
  const [count, setCount]  = useState<number>();
  const [collapseMenu, setCollapseMenu] = useState(true);
  const [showApplicants, setShowApplicants] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [applicantSub, setApplicantSub] = useState<Subscription>();
  const [lfSub, setLfSub] = useState<Subscription>();
  const [credits] = useCredits();
  const [ showModal, setShowModal ] = useState(false);

  const onInViewChange = (inView: boolean) => {
    if (loadMore && inView) {
      const params = nextUrl ? nextUrl.split('?')[1] : 'page=1&page_size=6';
      setApplicantSub(EmployerJobService.getApplicants(jobData.uuid as string, params).subscribe({
        next: dta => {
          setCount(dta.count);
          if (dta.results[0]) setNewCount(dta.results[0].job.new_applications_count);
          setNextUrl(dta.next);
          setLoadMore(dta.next ? true : false);
          setApplicantsData([ ...applicantsData, ...dta.results]);
        }
      }));
    } 
  }

  const updateLifeCycle = (life_cycle: LifeCycleValue) => {
    if (life_cycle === 'review' && !credits) {
      checkoutReturnUrl$.next(`${ROUTES.dashboard.path.replace(':pageID', 'jobs')}?autoSubmit=${jobData.uuid}&checkoutSuccess=true`);
      checkoutModuleShow$.next(true);
      return;
    }
    const data = {...getDeepClone(jobData), life_cycle};
    let sendGAEvent = life_cycle === 'review';  // A posting event should be tracked if the user is submitting for review
    setLfSub(EmployerJobService.updateJob(jobData.uuid as string, data, sendGAEvent).subscribe({
      next: (dta) => {
        if (props.type === 'draft' && life_cycle !== 'draft') {
          myDraftsOrApps$.next((myDraftsOrApps$.value as JobPostData[]).filter(d => d.uuid !== dta.uuid));
          draftsAppsModCount$.next(draftsAppsModCount$.value - 1);
        } else if (props.type === 'job' && (life_cycle === 'draft' || life_cycle === 'deactivated')) {
          myJobs$.next(myJobs$.value.filter(j => j.uuid !== dta.uuid));
          jobsCount$.next(jobsCount$.value - 1);
        } else {
          setJobData(dta);
        }
      },
      error: (err) => dashboardErrorMsg$.next(err.response.life_cycle)
    }));
  }

  const onEdit = () => {
    setSearchParams({edit: jobData.uuid as string});
  }

  const onReactivate = () => {
    setShowModal(true);
  }

  const onShowApplicants = () => {
    setShowApplicants(showApplicants + 1);
  }

  const collapseMenuToggle = () => {
    setCollapseMenu(!collapseMenu);
  }

  useEffect(() => {
    if (nextUrl) setNextUrl(null);
    if (jobData.life_cycle === 'active') onInViewChange(true);
  }, [jobData]);

  useEffect(() => {
    return () => {
      if (applicantSub && !applicantSub.closed) applicantSub.unsubscribe();
      if (lfSub && !lfSub.closed) lfSub.unsubscribe();
    }
  }, []);

  
  return (
    <div className={`job-card ${styles['container']}`} onClick={() => {if (!collapseMenu) collapseMenuToggle();}}>
        <div className='job-card-title-menu-wrap'>
          <h3>{jobData.title}</h3>

          {
            props.type === 'job' && jobData.life_cycle !== 'review' &&
            <JobCardMenu
              jobID={jobData.uuid as string}
              life_cycle={jobData.life_cycle}
              newCount={newCount}
              collapseMenu={collapseMenu}
              collapseMenuToggle={collapseMenuToggle}
              showApplicants={onShowApplicants}
              onDelete={() => updateLifeCycle('deactivated')}
              onReactivate={onReactivate}
            />
          }

          {
            props.type === 'draft' &&
            <div data-desktop className={styles.menuWrap}>
              <button className='btn btn--oval' onClick={() => updateLifeCycle('review')}>
                Submit
              </button>
              <button className={styles.editBtn} onClick={onEdit}>
                <SVGIcon id='edit' color='hover-black' />
              </button>
            </div>
          }
          
        </div>

        <EmployerJobCardDetails key={jobData.life_cycle} type={props.type} jobData={jobData} />

        {
          props.type === 'job' && jobData.life_cycle === 'active' && count === undefined &&
          <div className='loading-inview'></div>
        }

        {
          props.type === 'job' && jobData.life_cycle === 'active' && !!count &&
          <TwirlDown title={`Show Candidates (${count})`} reveal={showApplicants}>
            <div className={styles.candidatesWrap}>
              {
                applicantsData.map((d,i)=> <ApplicantCard key={i} data={d}/>)
              }
            </div>
            <InView as="div" onChange={onInViewChange}>
              {
                loadMore && <div className='loading-inview loading-inview--center loading-inview--mt'></div>
              }
            </InView>
          </TwirlDown>
        }

        {
          (jobData.life_cycle === 'deactivated' || jobData.life_cycle === 'expired') &&
          <button className='btn btn--oval' data-mobile onClick={onReactivate}>
            <SVGIcon id='redo' color='white' />
            Reactivate
          </button>
        }

        {
          props.type === 'draft' && 
          <div data-mobile className={styles.menuWrap}>
            <button className={styles.editBtn} onClick={onEdit}>
              <SVGIcon id='edit'color='hover-black' />
            </button>
            <button className='btn btn--oval' onClick={() => updateLifeCycle('review')}>
              Submit
            </button>
          </div>
        }

        <Modal show={showModal} onCloseBtn={() => setShowModal(false)}>
          <p>Reactivating this job will cost 1 credit.</p>
          <div className={styles.modalBtnWrap}>
            <button type='button' className='btn btn--white btn--border btn--auto-width' onClick={() => setShowModal(false)}>Cancel</button>
            <button type='button' className='btn btn--auto-width' onClick={() => {updateLifeCycle('review'); setShowModal(false);}}>Continue</button>
          </div>
        </Modal>
    </div>
  )
};

export default EmployerJobCard;