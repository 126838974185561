import { Observable, catchError, of, from, bindCallback, tap } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { GMAPS_API_KEY } from "../constants";
import Logger from "../logger";
import { handleFormError } from "../util.functions";

/* NOTE: Google Maps API script added in /src/App.tsx */
const typeCities = '(cities)';
const typeRegions = '(regions)';
const typeLocalities = ['locality', 'neighborhood', 'postal_code', 'sublocality'];
const regionUS = 'us';
const _global = window as any;
let apiLoaded = false;
let sessionToken: google.maps.places.AutocompleteSessionToken | undefined;
let autocompleteService: google.maps.places.AutocompleteService;
let placesService: google.maps.places.PlacesService;
/* let geocoderService: google.maps.Geocoder; */
let gmap: google.maps.Map;

_global.initMap = function() {
  apiLoaded = true;
  gmap = new google.maps.Map(document.createElement('div'));
  placesService = new google.maps.places.PlacesService(gmap);
  /* geocoderService = new google.maps.Geocoder(); */
  autocompleteService = new google.maps.places.AutocompleteService();
  Logger.log('Gmaps API Loaded!');
}

export default abstract class GMapsService {

  private static checkToken(): void {
    if (apiLoaded && !sessionToken) sessionToken = new google.maps.places.AutocompleteSessionToken();
  }

  public static getByCity(input: string, region = regionUS): Observable<google.maps.places.AutocompleteResponse> {
    GMapsService.checkToken();
    if (apiLoaded && input) {
      return from(autocompleteService.getPlacePredictions({input, sessionToken, types: [typeCities], region})).pipe(
        catchError((e: AjaxError) => handleFormError(e))
      );
    } else {
      return of({predictions: []});
    }
  }

  public static getByRegion(input: string, region = regionUS): Observable<google.maps.places.AutocompleteResponse> {
    GMapsService.checkToken();
    if (apiLoaded && input) {
      return from(autocompleteService.getPlacePredictions({input, sessionToken, types: [typeRegions], region})).pipe(
        catchError((e: AjaxError) => handleFormError(e))
      );
    } else {
      return of({predictions: []});
    }
  }

  public static getByLocality(input: string, region = regionUS): Observable<google.maps.places.AutocompleteResponse> {
    GMapsService.checkToken();
    if (apiLoaded && input) {
      return from(autocompleteService.getPlacePredictions({input, sessionToken, types: [...typeLocalities], region})).pipe(
        catchError((e: AjaxError) => handleFormError(e))
      );
    } else {
      return of({predictions: []});
    }
  }

  public static getDetails(placeId: string, fields?: string[]): Observable<google.maps.places.PlaceResult> {
    GMapsService.checkToken();
    const callback = function(result: google.maps.places.PlaceResult | null, status: google.maps.places.PlacesServiceStatus): google.maps.places.PlaceResult | null {
      Logger.log('GMapsService.getDetails(): callback', result);
      if (status != google.maps.places.PlacesServiceStatus.OK) throw {status, result};
      return result;
    }
    return bindCallback(placesService.getDetails.bind(placesService), callback)({placeId, fields, sessionToken}).pipe(
      tap(() => sessionToken = undefined)
    );
  }

  /* public static getByLatLng(location: {lat: number, lng: number}): Observable<google.maps.GeocoderResult[]> {
    GMapsService.checkToken();
    const callback = function(results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus): google.maps.GeocoderResult[] | null {
      Logger.log('GMapsService.getByLatLng(): callback', results);
      if (status != google.maps.GeocoderStatus.OK) throw {status, results};
      return results;
    }
    return bindCallback(geocoderService.geocode.bind(geocoderService), callback)({location}).pipe(
      tap(() => sessionToken = undefined)
    );
  } */
}