import React, { useCallback, useEffect, useState } from 'react';
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND } from 'lexical';
import { $isListItemNode, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import styles from './Editor.module.scss';
import SVGIcon from '../SVGIcon/SVGIcon';


const EditorToolbar = (): JSX.Element => {
  const [editor] = useLexicalComposerContext();
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUL, setIsUL] = useState(false);
  const [isOL, setIsOL] = useState(false);

  const onULBtn = () => {
    if (isUL) {
      // @ts-ignore
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    } else {
      // @ts-ignore
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    }
  }

  const onOLBtn = () => {
    if (isOL) {
      // @ts-ignore
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    } else {
      // @ts-ignore
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    }
  }

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const nodes = selection.getNodes();
      const listTypes = nodes.filter(node => $isListItemNode(node)).map(node => node.getParent()?.getListType());
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUL(listTypes.includes('bullet'));
      setIsOL(listTypes.includes('number'));
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      })
    );
  }, [updateToolbar, editor])


  return (
    <div className={styles.toolbar}>
      <button type='button' className='btn btn--small btn--border btn--white' 
        data-type='bold' data-active={isBold} 
        title={isBold ? 'remove bold styling' : 'bold'}
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}>
        B
      </button>

      <button type='button' className='btn btn--small btn--border btn--white' 
        data-type='italic' data-active={isItalic} 
        title={isItalic ? 'remove italic styling' : 'italic'}
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}>
        I
      </button>

      <button type='button' className='btn btn--small btn--border btn--white'
        data-active={isUL} onClick={onULBtn} 
        title={isUL ? 'remove bullet list' : 'insert bullet list'}>
        <SVGIcon id='list-ul-solid' />
      </button>

      <button type='button' className='btn btn--small btn--border btn--white'
        data-active={isOL} onClick={onOLBtn} 
        title={isOL ? 'remove numbered list' : 'insert numbered list'}>
        <SVGIcon id='list-ol-solid' />
      </button>
    </div>
  )
};

export default EditorToolbar;