import { BehaviorSubject } from "rxjs";
import StorageService from "./services/storage.service";
import { SelectOption, UserData, ServerConstantsData } from "./types";

export const loggedIn$ = new BehaviorSubject<boolean>(StorageService.get('loggedIn') || false); // false

export const userData$ = new BehaviorSubject<UserData | null>(null); //null

export const accessToken$ = new BehaviorSubject<string | null>(null);

export const refreshToken$ = new BehaviorSubject<string | null>(StorageService.get('refresh') || null);

export const navMenuShow$ = new BehaviorSubject(false);

export const userMenuShow$ = new BehaviorSubject(false);

export const roleOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('roleOptions') || null);

export const registerFlowOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('registerFlowOptions') || null);

export const annualSalaryRangeOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('annualSalaryRangeOptions') || null);

export const jobCategoryOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('jobCategoryOptions') || null);

export const jobDurationOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('jobDurationOptions') || null);

export const jobPostLifeCycleOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('jobPostLifeCycleOptions') || null);

export const compensationOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('compensationOptions') || null);

export const compensationBonusAnnualOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('compensationBonusAnnualOptions') || null);

export const compensationCommissionOtherPercentageOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('compensationCommissionOtherPercentageOptions') || null);

export const compensationCommissionOtherTypeOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('compensationCommissionOtherTypeOptions') || null);

export const compensationCommissionProductPercentageOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('compensationCommissionProductPercentageOptions') || null);

export const compensationCommissionProductTypeOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('compensationCommissionProductTypeOptions') || null);

export const compensationCommissionServicePercentageOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('compensationCommissionServicePercentageOptions') || null);

export const compensationCommissionServiceTypeOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('compensationCommissionServiceTypeOptions') || null);

export const compensationHourlyRateOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('compensationHourlyRateOptions') || null);

export const compensationSalaryRangeAnnualOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('compensationSalaryRangeAnnualOptions') || null);

export const compensationTotalAnnualOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('compensationTotalAnnualOptions') || null);

export const candidateReferralSourceOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('candidateReferralSourceOptions') || null);

export const candidateStateOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('candidateStateOptions') || null);

export const candidateYearsExperienceOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('candidateYearsExperienceOptions') || null);

export const businessTypeOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('businessTypeOptions') || null);

export const employerReferralSourceOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('employerReferralSourceOptions') || null);

export const stateOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('stateOptions') || null);

export const totalAnnualRevenueOptions$ = new BehaviorSubject<SelectOption[] | null>(StorageService.get('totalAnnualRevenueOptions') || null);

export const serverConstants$ = new BehaviorSubject<ServerConstantsData | null>(StorageService.get('serverConstants') || null);
