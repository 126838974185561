import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { map } from 'rxjs';
import styles from './Registration.module.scss';
import Input from '../../../components/Input';
import { getDeepClone } from '../../../lib/util.functions';
import { emailValidator, matchValueValidator } from '../../../lib/validator.functions';
import { DEFAULT_REGISTER_FORM_VALIDITY_STATE, MIN_LENGTH_PW, PW_LENGTH_ERROR_MSG, RECAPTCHA_KEY } from '../../../lib/constants';
import { ErrorResponse, FormValidityState, RegisterFlow, RegistrationData } from '../../../lib/types';
import Select from '../../../components/Select';
import Checkbox from '../../../components/Checkbox/Checkbox';
import NLIForm from '../../../components/NLIForm/NLIForm';
import { ROUTES } from '../../../lib/app-routes';
import StorageService from '../../../lib/services/storage.service';
import { useQuery, useUserChoiceDetails } from '../../../lib/hooks';
import UserService from '../../../lib/services/user.service';
import EmailSent from '../../../components/EmailSent/EmailSent';
import ReCAPTCHA from "react-google-recaptcha";
import GMapsService from '../../../lib/services/gmaps.service';

const Registration = (props: {isAdmin?: boolean}): JSX.Element => {
  const [formState, setFormState] = useState<FormValidityState>(getDeepClone(DEFAULT_REGISTER_FORM_VALIDITY_STATE));
  const {roleOptions, registerFlowOptions} = useUserChoiceDetails();
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [success, setSuccess] = useState(false);
  const [state, setState] = useState<string>();
  const captchaRef = useRef<ReCAPTCHA | null>(null);
  const [recapToken, setRecapToken] = useState<string | null>(location.origin.includes('localhost') ? 'fakeToken' : null);
  const [{onboarding_redirect, role, utm_source}, setQuery] =  useQuery();
  const [latitude, setLatitude] = useState<string | number>();
  const [longitude, setLongitude] = useState<string | number>();

  const onReCaptchaChange = (token: string | null) => {
    setRecapToken(token);
  }

  const onSubmit = (e: FormEvent): void => {
    e.preventDefault();
    setError(null);
    captchaRef.current?.reset();
    if (!location.origin.includes('localhost')) setRecapToken(null);
    //
    const values: RegistrationData = { ...formState.values as unknown as RegistrationData };
    if (values.remember) StorageService.save('remember', values.email);
    delete values.remember;
    if (onboarding_redirect && values.role === 'candidate') values.onboarding_redirect = onboarding_redirect;
    if (props.isAdmin) values.role = 'administrator';
    values.registration_flow = registerFlowOptions?.find(o => o.label === (props.isAdmin ? 'Administrator' : 'Standard'))?.value as RegisterFlow;
    if (state) values.state = state;
    if (latitude) values.latitude = latitude;
    if (longitude) values.longitude = longitude;
    if (utm_source && utm_source.includes('americanmedspa.org')) values.is_amspa_referral = true;
    //
    UserService.register(values).subscribe({
      next: () => setSuccess(true),
      error: (e: ErrorResponse) => setError(e),
      complete: () => setQuery('')
    });
  };

  const onZipGetState = () => {
    setTimeout(() => {
      if (formState.validity.zip_code) {
        GMapsService.getByRegion(formState.values.zip_code).subscribe({
          next: dta => {
            if (dta.predictions.length && dta.predictions[0].terms[1].value.length === 2) {
              setState(dta.predictions[0].terms[1].value);
              if (dta.predictions[0].place_id) {
                GMapsService.getDetails(dta.predictions[0].place_id, ['geometry'])
                  .pipe(map(obj => obj.geometry?.location))
                  .subscribe({
                    next: (loc) => {
                      setLatitude(parseFloat(loc?.lat().toFixed(9) as string));
                      setLongitude(parseFloat(loc?.lng().toFixed(9) as string));
                    }
                  }
                )
              }
            }
          }
        })
      }
    }, 200)
  }

  useEffect(() => {
    if (props.isAdmin) setQuery('role=administrator')
  }, [props.isAdmin])
  
  return (
    <>
      <Helmet>
        <title>JobSnob: Create {props.isAdmin ? 'Administration' : ''} Account</title>
      </Helmet>
      {
        success
        ?
        <EmailSent eyebrow='confirm registration email' />
        :
        <>
          <div className='content-wrap bg1-light-alt'>
              <div className={`content ${styles.content}`}>
                <div className={`quote-wrap ${styles['quoteWrap']}`}>
                  <div className={`quote-content ${styles.quoteContent}`}>
                    <p className='eyebrow'>Snob Quotes</p>
                    <h2 className='quote'>“The best place to hire, or be hired for the medical aesthetic industry. <span>Really.</span>”</h2>
                  </div>
                </div>

                <NLIForm 
                    title='Create Account'
                    eyebrow={props.isAdmin ? 'Administrator?' : 'Join us?'}
                    onSubmit={onSubmit}
                    setter={setFormState}
                    defaultState={formState}
                    formWrapClass={styles.formWrap}
                  >
                    <Input label='First Name*'
                      errorMessage={''}
                      attributes={{type: 'text', id: 'first_name', required: true, autoComplete: 'given-name'}} 
                    />

                    <Input label='Last Name*'
                      errorMessage={''}
                      attributes={{type: 'text', id: 'last_name', required: true, autoComplete: 'family-name'}} 
                    />

                    <Input label='ZIP Code*'
                      forceError={!!error}
                      errorMessage={error ? error?.response.zip_code : undefined}
                      attributes={{type: 'text', id: 'zip_code', required: true, autoComplete: 'zipcode',
                                  minLength: 5, onChange: onZipGetState }} 
                    />

                    <Input label='Email*'
                      forceError={!!error}
                      errorMessage={error ? error?.response.email : 'Email address is not valid.'}
                      validatorFns={[emailValidator]}
                      attributes={{type: 'email', id: 'email', required: true, autoComplete: 'email'}} 
                    />

                    <Input label='Password*'
                      forceError={!!error}
                      errorMessage={error ? error?.response.password : PW_LENGTH_ERROR_MSG}
                      attributes={{type: 'password', id: 'password', required: true, 
                                  minLength: MIN_LENGTH_PW, autoComplete: 'new-password'}} 
                    />

                    <Input label='Repeat Password*'
                      forceError={!!error}
                      errorMessage={error ? error?.response.password : 'password does not match'}
                      validatorFns={[[matchValueValidator, ['password'] as any]]}
                      attributes={{type: 'password', id: 're_password', required: true, autoComplete: 'new-password'}} 
                    />

                    <>
                    {
                      roleOptions &&
                      <Select label='I am...*'
                        options={props.isAdmin ? roleOptions : roleOptions.filter(o => o.label !== 'Administrator')}
                        errorMessage={''}
                        attributes={{ id: 'role', required: true, defaultValue: role, disabled: !!role }}
                      />
                    }
                    </>
                    
                    <div className={styles.checkboxWrap}>
                      <Checkbox label='Remember me' attributes={{ id: 'remember', defaultChecked: true }} />
                    </div>

                    <div className='recaptcha-wrap add-margin'>
                      <ReCAPTCHA 
                        ref={captchaRef}
                        sitekey={RECAPTCHA_KEY as string}
                        onChange={onReCaptchaChange}
                      />
                    </div>

                    <div className='add-margin'>
                      <button className='btn' type='submit' disabled={!recapToken || !formState?.valid}>confirm</button>
                    </div>
                    
                    <div className='link-wrap'>
                      <Link to={ROUTES.login.path}>
                        Login Instead?
                      </Link>
                    </div>
                </NLIForm>
              </div>
          </div>
        </>
      }
    </>
  )
};

export default Registration;
