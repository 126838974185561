import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import CheckoutModal from './components/CheckoutModal/CheckoutModal';
import CheckoutSuccessModal from './components/CheckoutSuccessModal/CheckoutSuccessModal';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { ROUTES } from './lib/app-routes';
import { DOMAIN, ENV, GMAPS_API_KEY, SHOW_CONSOLE } from './lib/constants';
import { useQuery, useSubject } from './lib/hooks';
import Logger from './lib/logger';
import AuthService from './lib/services/auth.service';
import { loggedIn$, userData$ } from './lib/state';
import { findRoute } from './lib/util.functions';
import { checkoutSuccessModuleShow$ } from './pages/Purchase/state';

function App(): JSX.Element {
  const userData = useSubject(userData$);
  const loggedIn = useSubject(loggedIn$);
  const navigate = useNavigate();
  const loc = useLocation();
  const [{checkoutSuccess, edit}] = useQuery();
  const [prevLoggedIn, setPrevLoggedIn] = useState(loggedIn);
  
  useEffect(() => {
    Logger.count('App loggedIn/userData useEffect');
    Logger.log('loggedIn', loggedIn, 'userData', userData, loc.pathname === ROUTES.login.path);
    if (!loggedIn && edit) {
      AuthService.onLogout();
      navigate(ROUTES.login.path);
    } else if (!loggedIn && prevLoggedIn) {
      /* Redirect user to login page when user logs out */
      AuthService.onLogout();
      const route = findRoute(loc.pathname);
      if (route?.protectedBy.loggedIn) navigate(ROUTES.login.path);
    } else if (userData && loggedIn) {
      /* Direct user to page based on onboarding status */
      if (userData.requires_onboarding) {
        Logger.log('Redirecting user to on-boarding from App.');
        navigate(ROUTES.onboarding.path.replace(':step', 'complete-profile'));
      } else if (loc.pathname === ROUTES.login.path) {
        navigate(ROUTES.dashboard.path.replace(':pageID', 'jobs'));
      }
    }
    setPrevLoggedIn(loggedIn);
  }, [loggedIn, userData])

  useEffect(() => {
    if (checkoutSuccess) checkoutSuccessModuleShow$.next(true);
  }, [checkoutSuccess])

  useEffect(() => {
    Logger.info(`ENV: ${ENV}, DOMAIN: ${DOMAIN}, SHOW_CONSOLE: ${SHOW_CONSOLE}`);
    Logger.count('App render');
  }, [])
  
  return (
    <>
    <Helmet>
      <script async
        src={`https://maps.googleapis.com/maps/api/js?key=${GMAPS_API_KEY}&libraries=places&callback=initMap`}>
      </script>
    </Helmet>
    {
      !loc.pathname.includes('/jobboard/widget') &&
      <Header />
    }
    <main>
      <Outlet />
      <CheckoutModal />
      <CheckoutSuccessModal />
    </main>
    {
      !loc.pathname.includes('/jobboard/widget') &&
      <Footer />
    }
    
    </>
  );
}

export default App;
