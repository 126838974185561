import { BehaviorSubject, Subject } from "rxjs";
import { CreditPackageData } from "../../lib/types";

export const singlePackage$ = new BehaviorSubject<CreditPackageData | null>(null);

export const multiPackage$ = new BehaviorSubject<CreditPackageData | null>(null);

export const credits$ = new BehaviorSubject<number | null>(null);

export const purchaseErrorMsg$ = new Subject<string | null>();

export const checkoutModuleShow$ = new BehaviorSubject(false);

export const checkoutSuccessModuleShow$ = new BehaviorSubject(false);

export const checkoutReturnUrl$ = new Subject<string>();