import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { of, switchMap, take, tap } from 'rxjs';
import { parsePhoneNumber } from 'libphonenumber-js'
import JobDetail from '../../../components/JobDetail/JobDetail';
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import { ROUTES } from '../../../lib/app-routes';
import { useJobChoiceDetails, useObservable } from '../../../lib/hooks';
import EmployerJobService from '../../../lib/services/employer.job.service';
import ResumeService from '../../../lib/services/resume.service';
import { applicant$ } from '../../Dashboard/state';
import styles from './ViewCandidate.module.scss';

const ViewCandidate = (): JSX.Element => {
  const navigate = useNavigate();
  const {jobCategoryOptions} = useJobChoiceDetails();
  const [mapURL, setMapUrl] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const { uuid } = useParams();
  const [data, error] = useObservable(
    applicant$.pipe(
      take(1),
      switchMap(dta => dta && dta.uuid === uuid
        ? of(dta)
        : EmployerJobService.getApplicant(uuid as string)
      ),
      tap(d => { if (d && d.is_new) EmployerJobService.markViewed(uuid as string).subscribe()})
    )
  );
  
  const onDownloadResume = () => {
    ResumeService.downloadResume({ 
      fileName: data?.resume.file_name as string,
      url: data?.resume.file_url as string
    }).subscribe();
  }

  useEffect(() => {
    if (error) navigate(ROUTES.dashboard.path.replace(':pageID', 'jobs'));
  }, [error])

  useEffect(() => {
    if (data) {
      const url = new URL('https://www.google.com/maps/search/');
      url.searchParams.append('api', '1');
      url.searchParams.append('query', `${data.candidate.address} ${data.candidate.city} ${data.candidate.state} ${data.candidate.zip_code}`);
      setMapUrl(url.href);
      //
      if (data.candidate.phone_number && data.candidate.phone_number !== '') {
        const phoneNumber = parsePhoneNumber(data.candidate.phone_number, 'US')
        setPhone(data.candidate.phone_number.startsWith('+') ? phoneNumber.formatInternational() : phoneNumber.formatNational());
      }
    }
  }, [data])
  
  return (
    <>
      <Helmet>
        <title>JobSnob: View Candidate</title>
      </Helmet>
      <div className='content-wrap content-wrap--no-padding bg-account'>
        {
          !data &&
          <div className={`content ${styles['content']}`}>
            <div className='loading-inview loading-inview--center'></div>
          </div>
        }

        {
          data &&
          <div className={`content ${styles['content']}`}>

            <Link className={styles.backBtn} to={ROUTES.dashboard.path.replace(':pageID', 'jobs')}>
              <SVGIcon id='chevron' rotate={90} color='accent-grey' />
            </Link>

            <div className={styles.header}>
              <img className='profile-image' src={data?.candidate.avatar_image_url} />
              <p className='eyebrow'>candidate</p>
              <h1><span>{data.candidate.first_name} {data.candidate.last_name}</span></h1>
              <h2>
                <span>
                  {jobCategoryOptions?.filter((o) => data.candidate.categories.includes(o.value)).map(o => o.label).join(', ')}
                  {data.candidate.categories.includes('other') && data.candidate.category_other !== '' ? `(${data.candidate.category_other})` : ''}
                </span>
              </h2>
            </div>

            <div className={styles.detailsWrap}>
              {
                data.candidate.years_experience &&
                <JobDetail icon='briefcase' text={`${data.candidate.years_experience} years experience`} />   
              }
              <JobDetail icon='building' text={data.job.duration} />
              {
                (data.candidate.city !== '' || data.candidate.state !== '') &&
                <JobDetail 
                  icon='map-pin' 
                  text={`${data.candidate.city !== '' ? data.candidate.city : ''}${data.candidate.state && data.candidate.state !== '' ? `, ${data.candidate.state}` : ''}`} />
              }
            </div>

            <p className={styles.bio}>{data.candidate.bio}</p>

            {
              data.candidate.cover_letter && data.candidate.cover_letter !== '' &&
              <p className={styles.cover}>{data.candidate.cover_letter}</p>
            }

            <div className={styles.infoWrap}>
              <a href={`mailto: ${data.candidate.email}`}>
                <SVGIcon id='email' />
                {data.candidate.email}
              </a>

              <a href={`tel: ${data.candidate.phone_number}`}>
                <SVGIcon id='phone' />
                {phone}
              </a>

              <a href={mapURL} target='_blank' rel='noreferrer'>
                <SVGIcon id='map-pin' />
                {
                  data.candidate.address !== '' && `${data.candidate.address}, `
                }
                {
                  data.candidate.city !== '' && `${data.candidate.city}, `
                }
                {
                  data.candidate.city !== '' && `${data.candidate.city}, `
                }
                {data.candidate.state} {data.candidate.zip_code}
              </a>
            </div>

            <div className={styles.btnWrap}>
              {
                data.cover_letter && data.cover_letter !== '' &&
                <a className='btn btn--oval' target='_blank' rel='noreferrer'
                  href={'data:attachment/text,' + encodeURI(data.cover_letter)}
                  download={`${data.job.title}-${data.candidate.first_name}-${data.candidate.last_name}-cover-letter.txt`}
                >
                  <SVGIcon id='download' color='white' />
                  Download Cover Letter
                </a>
              }

              <button className='btn btn--oval' onClick={onDownloadResume}>
                <SVGIcon id='download' color='white' />
                Download Resume
              </button>
            </div>

          </div>
        }
        
      </div>
    </>
  )
};

export default ViewCandidate;
