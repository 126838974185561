import { AppRoutes } from "./types";

export const ROUTES: AppRoutes = {
  landingPage: {
    path: '/',
    protectedBy: {}
  },
  login: { 
    path: '/account/sign-in', 
    protectedBy: {
      loggedIn: false
  }},
  registration: {
    path: '/account/register',
    protectedBy: {
      loggedIn: false
    }
  },
  verifyEmail: {
    path: '/account/register/confirm/:uid/:token',
    protectedBy: {
      loggedIn: false
    }
  },
  forgotPassword: {
    path: '/account/recover',
    protectedBy: {
      loggedIn: false
    }
  },
  resetPassword: {
    path: '/account/recover/confirm/:uid/:token',
    protectedBy: {
      loggedIn: false
    }
  },
  profile: {
    path: '/account/profile',
    protectedBy: {
      loggedIn: true
    }
  },
  onboarding: {
    path: '/account/onboarding/:step',
    protectedBy: {
      loggedIn: true,
      roles: ['employer', 'candidate']
    }
  },
  orderHistory: {
    path: '/employer/order-history',
    protectedBy: {
      loggedIn: true,
      roles: ['employer']
    }
  },
  jobPostSubmitted: {
    path: '/employer/job-post-submitted',
    protectedBy: {
      loggedIn: true,
      roles: ['employer']
    }
  },
  viewCandidate: {
    path: '/employer/view-candidate/:uuid',
    protectedBy: {
      loggedIn: true,
      roles: ['administrator', 'employer']
    }
  },
  dashboard: {
    path: '/dashboard/:pageID',
    protectedBy: {
      loggedIn: true
    }
  },
  jobboard: {
    path: '/jobboard',
    protectedBy: {}
  },
  jobboardWidget: {
    path: '/jobboard/widget/:hideDesc',
    protectedBy: {}
  },
  job: {
    path: '/jobboard/job/:uuid',
    protectedBy: {}
  },
  jobsRedirect: {
    path: '/jobs',
    protectedBy: {}
  },
  purchase: {
    path: '/employers',
    protectedBy: {}
  },
  checkout: {
    path: '/employers/checkout',
    protectedBy: {
      loggedIn: true,
      roles: ['employer']
    }
  },
  checkoutSuccess: {
    path: '/employers/checkout/success',
    protectedBy: {
      loggedIn: true,
      roles: ['employer']
    }
  },
  adminRegistration: {
    path: '/admin/register',
    protectedBy: {
      loggedIn: false
    }
  }
}