import { PaymentElement, PaymentRequestButtonElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentRequest, StripeError, StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import React, { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CrashService from '../../lib/services/crash.service';
import CreditsService from '../../lib/services/credits.service';
import { CreditPackageData, PaymentData } from '../../lib/types';
import { purchaseErrorMsg$ } from '../../pages/Purchase/state';
import styles from './CheckoutComponent.module.scss';

interface IProps {
  creditPackage: CreditPackageData;
  price: string;
  paymentData: PaymentData;
  disable: boolean;
  returnUrl: string;
}

const CheckoutForm = (props: IProps): JSX.Element => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [error, setError] = useState<StripeError>();
  const [ready, setReady] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const navigate = useNavigate();

  const onReady = () => {
    setReady(true);
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (error) setError(undefined);
    if (!stripe || !elements) return;
    setFormComplete(false);
    //
    if (props.paymentData.checkout_code) {
      CreditsService.checkoutPayment(props.paymentData.payment_intent_id, props.paymentData.checkout_code)
        .subscribe({
          next: () => CreditsService.getAvailableCredits().subscribe(),
          error: (err) => purchaseErrorMsg$.next(err.response.checkout_code ? `Checkout Code: ${err.response.checkout_code}` : err.response.detail),
          complete: () => navigate(props.returnUrl),
        });
    } else {
      stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${location.origin}${props.returnUrl}`,
        }
      }).then((result) => {
        if (result.error) {
          CrashService.report({message: result.error.message, stack_trace: JSON.stringify(result.error)});
          purchaseErrorMsg$.next(result.error.message as string);
        }
      })
    }
  }

  const onChange = (e: StripePaymentElementChangeEvent) => {
    if (e.complete !== formComplete) setFormComplete(e.complete);
  }

  const onLoadError = () => {
    purchaseErrorMsg$.next('An unexpected error occurred. Please, refresh the page and try again.');
  }

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: props.creditPackage.default_price.currency,
        total: {
          label: props.creditPackage.name,
          amount: props.paymentData.amount
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, props.paymentData]);

  return (
    <>
    {
      elements &&
      <form onSubmit={onSubmit} data-ready={ready}>
        {
          paymentRequest &&
          <>
            <PaymentRequestButtonElement options={{paymentRequest}} />
            <div className={styles.divide}>
              <div className={styles.line}></div>
              <span>Or pay with card</span>
            </div>
          </>
        }

        <PaymentElement id='payment-element' onReady={onReady} onChange={onChange} onLoadError={onLoadError}/>

        <button type='submit' disabled={props.disable || !formComplete} className='btn btn--dt-wide'>
          Pay ${props.price}
        </button>
      </form>
    }
    </>
  )
}

export default CheckoutForm;