import React, { useRef, useEffect } from "react";
import { of, delay, switchMap, fromEvent, map, debounceTime } from "rxjs";
import { ALL_DIGITS_REGEX } from "../../lib/constants";
import { useObservable } from "../../lib/hooks";
import Logger from "../../lib/logger";
import GMapsService from "../../lib/services/gmaps.service";
import Input, { InputProps } from "../Input";
import styles from './InputAutoComplete.module.scss';


const InputAuto = (props: {inputProps: InputProps, completeType: 'city' | 'zip' | 'city|zip' | 'locality', value?: string, onResults: (r:google.maps.places.AutocompleteResponse) => void}): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [results, error] = useObservable(
    of(null).pipe(
      delay(20),
      switchMap(() =>
        fromEvent((ref.current as HTMLDivElement).querySelector('input') as HTMLInputElement, 'keyup').pipe(
          map(e => (e.target as HTMLInputElement).value),
          debounceTime(400),
          switchMap((v) => {
            if (props.completeType === 'city') return GMapsService.getByCity(v);
            else if (props.completeType === 'zip') return GMapsService.getByCity(v);
            else if (props.completeType === 'locality') return GMapsService.getByLocality(v);
            else return ALL_DIGITS_REGEX.test(v) ? GMapsService.getByRegion(v) : GMapsService.getByCity(v);
          })
        )
      )
    )
  )

  useEffect(() => {
    if(results) props.onResults(results);
  }, [results])

  useEffect(() => {
    if (error) Logger.error(error);
  }, [error])

  useEffect(() => {
    if (props.value)(ref.current?.querySelector('input') as HTMLInputElement).dataset.value = props.value;
  }, [props.value])

  return (
    <div ref={ref} className={styles.container}>
      <Input { ...props.inputProps}/>
    </div>
  )
}

export default InputAuto;