import Logger from '../logger';

export default abstract class GTMService {

  public static pushEvent(data: object): void {
    // data is assumed to be the entire data layer payload:
    //    https://developers.google.com/tag-platform/tag-manager/web/datalayer
    Logger.log('GTMService.pushEvent()', data);
    // @ts-ignorets-igore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignorets-igore
    window.dataLayer.push(data);
  }

}
