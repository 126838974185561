import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import SVGIcon from '../../../components/SVGIcon/SVGIcon';
import { ROUTES } from '../../../lib/app-routes';
import styles from './JobPostSuccess.module.scss';
import image from '../../../images/hands-phone.jpg';

const JobPostSuccess = (): JSX.Element => {
  
  return (
    <>
      <Helmet>
        <title>JobSnob: Job Post Successfully Submitted</title>
      </Helmet>
      <div className='content-wrap bg2'>
        <div className={`content ${styles['content']}`}>
          <div className={styles.quoteWrap}>

            <div className='icon60 icon60--border-black'>
              <SVGIcon id='checkmark' />
            </div>

            <div>
              <p className='eyebrow'>You did it!</p>
              <h1 className='quote'><span>Now, let the</span> candidates <span>come to you</span></h1>
            </div>

            <p className={styles.msg}>
              Our recruiters just need a minute to review your job post to ensure it meets the highest quality standards. 
              This helps us maintain the level of quality we&apos;re known for, and will help you get the right candidates applying 
              for your job. Now kick up your feet, sit back, and relax, and we&apos;ll send you an email once your job is live.
            </p>

            <Link to={ROUTES.dashboard.path.replace(':pageID', 'jobs')} className='underline'>Visit Dashboard</Link>
          </div>

          <div className={styles.blogWrap}>
            <p className='eyebrow'>Snob Blog</p>
            <img src={image} />
            <h2>4 Benefits of Using a Recruiter for HR Professionals</h2>
            <p>Here are a few of the ways a professional recruiting agency can help your Human Resources team.</p>
            <a href="https://info.jobsnob.net/blog" target="_self" className='btn'>Read More</a>
          </div>
        </div>
      </div>
    </>
  )
};

export default JobPostSuccess;
