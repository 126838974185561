import { Observable, throwError } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { ROUTES } from "./app-routes";
import { ResponseError } from "./classes";
import { AsYouType } from 'libphonenumber-js';
import Logger from "./logger";
import { AppRoute } from "./types";

export const objToFormData = (obj: { [key: string]: any }): FormData => {
  const formDta = new FormData();
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      formDta.append(key, obj[key]);
    }
  }
  return formDta;
}

export const getDeepClone = (object: any): any => {
  return JSON.parse(JSON.stringify(object))
}

export const formatPhone = (n: string | undefined): string | undefined => {
  if (!n) return undefined;
  return new AsYouType(n.startsWith('+') ? undefined : 'US').input(n);
}

export const handleFormError = (err: AjaxError, expectedKeys?: string[]): Observable<never> => {
  Logger.error(err);
  let resp: {[key: string]: string};
  //
  if (err.status === 413) {
    resp = {file: `File too large.${err.request.url.includes('/resumes') ? ' Size should not exceed 5 MB.' : ''}`};
  } else {
    resp = err.response;
    if (!err.response && expectedKeys) {
      resp = {};
      expectedKeys.forEach(key => { resp[key] = err.message});
    } else if (err.response && expectedKeys) {
      expectedKeys.forEach(key => {
        if(!err.response[key] && err.response.detail) err.response[key] = err.response.detail;
      })
    }
    Object.entries(resp).forEach(([key, val]) => {
      if (Array.isArray(val)) resp[key] = val.join(', ');
    })
  }
  return throwError(() => new ResponseError(err.message, resp, {cause: err}));
}

export const compareRoutePaths = (path: string, pathFromRoutes: string): boolean => {
  const mapIndexes: number[] = [];
  let hasParam: boolean;
  let isIndex: boolean;

  pathFromRoutes = pathFromRoutes.split('/')
    .map((str, i) => {
      hasParam = str.startsWith(':');
      if (hasParam) {
        mapIndexes.push(i);
        return '*';
      } else {
        return str;
      }
    })
    .join('/'); 

  path = path.split('/')
    .map((str, i) => {
      isIndex = mapIndexes.includes(i);
      return isIndex ? '*' : str;
    })
    .join('/');

  return path === pathFromRoutes;
}

export const findRoute = (path: string): AppRoute | undefined => {
  const arr = Object.entries(ROUTES).find(([k,r]) => compareRoutePaths(path, r.path));
  return arr ? arr[1] : undefined;
}