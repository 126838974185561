import React, { ChangeEvent, FormEvent, MouseEventHandler, ReactElement, useEffect, useRef, useState } from 'react';
import { InputAttributes, InputValidityState, ValidarorFn, ValidatorFnAndArgs } from '../lib/types';
import { DEFAULT_INPUT_VALIDITY_STATE, IS_SAFARI } from '../lib/constants';
import SVGIcon from './SVGIcon/SVGIcon';
import { IconColor } from './SVGIcon/types';
import { useInputState } from '../lib/hooks';
import { formatPhone } from '../lib/util.functions';

export interface InputProps {
  label?: string,
  attributes: InputAttributes,
  errorMessage?: string,
  forceError?: boolean,
  icon?: string, 
  leftIcon?: string,
  iconColor?: IconColor,
  children?: ReactElement | ReactElement[],
  className?: string,
  dataAttr?: string | number;
  dataAttr2?: string | number;
  onIcon?: MouseEventHandler<HTMLSpanElement>
  validatorFns?: (ValidarorFn | ValidatorFnAndArgs)[]
}

const Input = (props: InputProps): JSX.Element => {
  const [attr, setAttr] = useState<InputAttributes | null>(null);
  const ref = useRef<HTMLInputElement | null>(null);
  const validityState = useInputState<InputValidityState>(ref, {...DEFAULT_INPUT_VALIDITY_STATE, message: props.errorMessage }, props.validatorFns);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();

  const setFocus = () => {
    ref.current?.focus();
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.attributes.onChange) props.attributes.onChange(e);
    if (hasError) setHasError(false);
  }

  const onInvalid = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setHasError(true);
    setErrorMsg('This field is required.');
    setTimeout(() => {
      document.querySelector('.input-error')?.parentElement?.scrollIntoView(IS_SAFARI ? false : {block: 'center'});
    }, 100);
  }

  const onWheel = () => {
    // Prevent numeric input from incrementing/decrementing on mouse scroll
    ref.current?.blur();
  };

  useEffect(() => {
    setHasError(props.forceError || false);
  }, [props.forceError])

  useEffect(() => {
    setErrorMsg(props.errorMessage);
  }, [props.errorMessage])

  useEffect(() => {
    setAttr({
      ...props.attributes,
      name: props.attributes.name || props.attributes.id,
      defaultValue: props.attributes.type === 'tel' ? formatPhone(props.attributes.defaultValue as string) : props.attributes.defaultValue,
      onChange
    });
  }, [props.attributes])

  useEffect(() => {
    if (ref.current?.value && props.attributes.type === 'tel') {
      ref.current.value = formatPhone(ref.current.value) as string;
    }
  }, [ref.current?.value])

  return (
    <div className={`form-sub-group form-sub-group--border ${props.className || ''}`} onClick={setFocus}
    data-disabled={attr?.disabled} data-attr={props.dataAttr} data-attr-2={props.dataAttr2}>
      {
        props.label &&
        <label htmlFor={props.attributes.id}>{props.label}{attr?.required === false ? <span> (Optional)</span> : null}</label>
      }
      {
        props.leftIcon &&
        <span className='input-icon input-icon--left'>
          <SVGIcon id={props.leftIcon} color='ice-grey' />
        </span>
      }
      
      <input ref={ref} 
        {...attr} 
        onChange={onChange}
        onInvalid={onInvalid}
        onWheel={onWheel}
        className={`${attr?.className ?? ''}${props.icon ? ' has-icon' : ''}${props.label ? ' has-label' : ''}${validityState?.valid ? ' valid' : ' invalid'}`} 
        />

      {
        ((errorMsg && validityState?.isDirty && !validityState?.valid) || (hasError && errorMsg)) &&
        <div className='input-error'>{errorMsg}</div>
      }

      {
        props.icon &&
        <>
          {
            props.icon === 'checkmark' && validityState?.isDirty && validityState?.valid ?
              <span className='input-icon input-icon--checkmark' onClick={props.onIcon}>
                <SVGIcon id='checkmark' color={props.iconColor}/>
              </span> :
              <>
                {
                  props.icon !== 'checkmark' &&
                  <span className={`input-icon input-icon--${props.icon}`} onClick={props.onIcon}>
                    <SVGIcon id={props.icon} color={props.iconColor}/>
                  </span>
                }
              </>

          }
        </>
      }

      {props.children}

    </div>
  )
};

export default Input;