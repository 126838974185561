import React, {useCallback, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSubject } from '../../../lib/hooks';
import { ROUTES } from '../../../lib/app-routes';
import { loggedIn$, userData$, userMenuShow$ } from '../../../lib/state';
import SocialMedia from '../../SocialMedia/SocialMedia';
import SVGIcon from '../../SVGIcon/SVGIcon';
import './UserMenu.scss';

const UserMenu = (): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const show = useSubject(userMenuShow$);
  const loggedIn = useSubject(loggedIn$);
  const userData = useSubject(userData$);
  const navigate = useNavigate();
  const setActiveClass = ({ isActive }: {isActive: boolean}) => isActive ? 'active' : undefined;

  const onSignOut = () => {
    loggedIn$.next(false);
    navigate(ROUTES.login.path);
  };

  const docClickCallback = useCallback((e: MouseEvent) => {
    if(!ref.current?.contains(e.target as Node) && 
    (e.target as HTMLElement).id !== 'user-menu-btn'){
      userMenuShow$.next(false);
    } 
  }, []);

  useEffect(() => {
    if (!loggedIn) {
      userMenuShow$.next(false);
    }
  },[loggedIn]);

  useEffect(() => {
    if (show) {
      document.addEventListener('click', docClickCallback);
    } else {
      document.removeEventListener('click', docClickCallback);
    }
  },[show]);

  return (
    <div ref={ref} className='user-menu-container'>
      <div className={`user-menu-wrap${show ? ' show' : ''}`}>
        <button className='close-btn' onClick={() => userMenuShow$.next(!show)}>
          <SVGIcon id='close' color='hover-black' />
        </button>

        <ul>
          <>
          {
            loggedIn && userData &&
            <>
            <li><NavLink to={ROUTES.dashboard.path.replace(':pageID', 'jobs')} className={setActiveClass}>Dashboard</NavLink></li>
            <li><NavLink to={ROUTES.profile.path} className={setActiveClass}>profile</NavLink></li>
            {
              userData.role === 'employer' &&
              <li><NavLink to={ROUTES.orderHistory.path} className={setActiveClass}>order history</NavLink></li>
            }
            </>
          }

          {
            loggedIn 
            ?
            <li onClick={onSignOut}>Sign Out</li>
            :
            <>
              <li><NavLink to={ROUTES.login.path} className={setActiveClass}>Sign In</NavLink></li>
              <li><NavLink to={ROUTES.registration.path} className={setActiveClass}>Sign Up</NavLink></li>
            </>
          }
          </>
        </ul>

        <SocialMedia fbColor='black' instaColor='hover-black' inColor='black' size={24} />
      </div>
    </div>
  )
};

export default UserMenu;