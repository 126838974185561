import React, { ReactElement, useEffect, useRef, useState } from 'react';
import SVGIcon from '../SVGIcon/SVGIcon';
import './TwirlDown.scss';

const TwirlDown = (props: {title: string, reveal?: any, children?: ReactElement | ReactElement[]}): JSX.Element => {
  const [reveal, setReveal] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const revealToggle = () => {
    setReveal(reveal === 0 ? ref.current?.firstElementChild?.clientHeight || 4000 : 0);
  }

  useEffect(() => {
    if (props.reveal && reveal === 0) setReveal(ref.current?.firstElementChild?.clientHeight || 4000);
  },[props.reveal])

  return (
    <div className='twirl-down-container'>
      <div className='twirl-down-title-wrap' onClick={revealToggle} data-reveal={reveal !== 0}>
        {props.title}
        <span>
          <SVGIcon id='chevron' rotate={reveal ? -180 : 0} color={reveal ? 'stone-grey' : 'black'} />
        </span>
      </div>
      <div ref={ref} className='twirl-down-children-wrap' data-reveal={reveal !== 0} style={{maxHeight: `${reveal}px`}}>
        {props.children}
        
      </div>
    </div>
  )
};

export default TwirlDown;