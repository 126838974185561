import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DEFAULT_INPUT_VALIDITY_STATE } from '../lib/constants';
import { useSelectState } from '../lib/hooks';
import { InputValidityState, SelectAttributes, SelectOption, ValidarorFn, ValidatorFnAndArgs } from '../lib/types';
import SVGIcon from './SVGIcon/SVGIcon';

export interface SelectProps {
  options: SelectOption[],
  attributes: SelectAttributes,
  label?: string,
  className?: string;
  leftIcon?: string;
  errorMessage?: string,
  forceError?: boolean,
  validatorFns?: (ValidarorFn | ValidatorFnAndArgs)[],
  getElement?: (el: HTMLSelectElement) => void
}

const Select = (props: SelectProps): JSX.Element => {
  const [attr, setAttr ] = useState<SelectAttributes | null>(null);
  const ref = useRef<HTMLSelectElement | null>(null);
  const validityState = useSelectState<InputValidityState>(ref, {...DEFAULT_INPUT_VALIDITY_STATE,  message: props.errorMessage}, props.validatorFns);

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (props.attributes.onChange) props.attributes.onChange(e);
    setTimeout(() => {
      if (ref.current && (ref.current as HTMLSelectElement).form) {
        (ref.current as HTMLSelectElement).form?.dispatchEvent(new Event('change', {bubbles: true}));
      } 
    }, 200);
  }

  useEffect(() => {
    setAttr({ 
      ...props.attributes,
      name: props.attributes.name || props.attributes.id,
      onChange
    });
  }, [props.attributes])

  useEffect(() => {
    if (props.getElement && ref.current) {
      props.getElement.apply(undefined, [ref.current as HTMLSelectElement])
    }
  }, [ref.current])

  return (
    <div className={`form-sub-group form-sub-group--border form-sub-group--stretch ${props.className || ''}`} data-disabled={attr?.disabled}>
      { props.label && <label htmlFor={props.attributes.id}>{props.label}</label> }
      {
        props.leftIcon &&
        <span className='input-icon input-icon--left'>
          <SVGIcon id={props.leftIcon} color='ice-grey' />
        </span>
      }
      <div className={`select-wrap ${!validityState?.isDirty ? 'pristine' : ''}`}>
        <select ref={ref} key={attr?.defaultValue as string} id={props.attributes.id} {...attr} className={`${attr?.className ?? ''}${props.label ? ' has-label' : ''}${validityState?.valid ? ' valid' : ' invalid'}`}>
          {
            props.options.map((el, i) => <option key={i} value={el.value} disabled={el.disabled === true}>{el.label}</option> )
          }
        </select>
      </div>
      <span className='input-icon input-icon--triangle'>
        <SVGIcon id='triangle' rotate={0}/>
      </span>
      {
        ((props.errorMessage && validityState?.isDirty && !validityState?.valid) || (props.forceError && props.errorMessage)) &&
        <div className='input-error'>{props.errorMessage}</div>
      }
    </div>
  )
};

export default Select;