import React, { useEffect, useState } from 'react';
import { OrderData } from '../../lib/types';
import styles from './PastOrderCard.module.scss';

const PastOrderCard = (props: {data: OrderData, onBuyAgain: (id: string) => void}): JSX.Element => {
  const [date, setDate] = useState<string>();
  const [price, setPrice] = useState<string>();

  useEffect(() => {
    setDate(new Date(props.data.purchased_date).toLocaleString('default', { month: 'long', day: '2-digit', year: 'numeric' }));
    const arr = props.data.amount ? props.data.amount.toString().split('') : ['0','0','0'];
    arr.splice(-2, 0, '.');
    setPrice(arr.join(''));
  }, [props.data]);
  
  return (
    <div className={styles.container}>
      <div data-desktop className={styles.namePrice}>
        <p className={`eyebrow ${styles.name}`}>{props.data.product_name}</p>
        <p className={styles.price}>${price}</p>
      </div>
      <p data-mobile className={`eyebrow ${styles.name}`}>{props.data.product_name}</p>
      <p className={`eyebrow ${styles.date}`}>DATE: {date}</p>
      <p data-mobile className={styles.price}><span>Total: </span>${price}</p>

      <div className={styles.btnWrap}>
        <button className='btn btn--oval' disabled={!props.data.amount} onClick={() => props.onBuyAgain(props.data.product_id)}>
          Buy Again
        </button>
      </div>
      
    </div>
  )
};

export default PastOrderCard;