import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../lib/app-routes';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './CheckoutSuccessComponent.module.scss';

const CheckoutSuccessComponent = (props: {onModalClose?: () => void}): JSX.Element => {
  
  return (
    <div className={`content ${styles.content}`}>
      <div className={styles.successWrap}>
        <div className='icon60 icon60--border-black'>
          <SVGIcon id='checkmark' />
        </div>

        <div>
          <p className='eyebrow'>You did it!</p>
          <h1><span>Your payment was succesful</span></h1>
        </div>

        <p>
          Looking good! Hiring shouldn&apos;t be a headache, and that&apos;s where JobSnob can help. 
          Our nationwide database of candidates is exclusive to Medical Aesthetics, 
          which means that JobSnob candidates are better qualified and more likely to meet your hiring needs.
        </p>

        {
          !props.onModalClose &&
          <Link to={ROUTES.dashboard.path.replace(':pageID', 'drafts') + '?post=new'} 
            className='btn btn--dt-wide btn--pad-0'>
            Next: Complete details
          </Link>
        }

        {
          props.onModalClose &&
          <button className='btn btn--dt-wide btn--pad-0' onClick={props.onModalClose}>
            Next: View My Jobs
          </button>
        }

        
      </div>

      <div className={styles.factWrap}>
        <p className='eyebrow'>Snob Fact</p>
        <p className={styles.fact}>
          JobSnob is the first and only recruiting agency &amp; job board that is exclusively dedicated to medical aesthetics, 
          which means we have the most comprehensive candidate database in the industry. 
        </p>
      </div>
    </div>
  )
};

export default CheckoutSuccessComponent;
