import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useFormState, useQuery, useSubject } from '../../lib/hooks';
import AdminService from '../../lib/services/admin.service';
import { ApplicationByProxyData, ErrorResponse, FormValidityState, ResumeData, UserData } from '../../lib/types';
import { emailValidator, phoneValidator } from '../../lib/validator.functions';
import { selectedJob$ } from '../../pages/Dashboard/state';
import CoverLetterInput from '../CoverLetterInput/CoverLetterInput';
import Input from '../Input';
import Modal from '../Modal/Modal';
import ResumeInput from '../ResumeInput/ResumeInput';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './ApplyModal.module.scss';

const RecruiterApplyModal = (props: {candidateData: UserData}): JSX.Element => {
  const [{ selectID }, setQuery, searchParams] = useQuery();
  const formRef = useRef<HTMLFormElement | null>(null);
  const formState = useFormState<FormValidityState>(formRef);
  const [error, setError] = useState<ErrorResponse>();
  const [resume, setResume] = useState<ResumeData>();
  const jobData = useSubject(selectedJob$);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    //
    const values: ApplicationByProxyData = {
      ...formState.values as ApplicationByProxyData,
      candidate: props.candidateData.uuid,
      job: selectID, 
      resume: resume?.uuid,
      cover_letter: formState.values.cover_letter
    };
    //
    AdminService.postApplication(values).subscribe({
      next: () => {
        searchParams.set('applySuccess', 'recruiter');
        searchParams.delete('applyByProxy');
        setQuery(searchParams);
      },
      error: err => setError(err)
    })
  }

  useEffect(() => {
    if (props.candidateData && props.candidateData.profile.resumes.length) {
      setResume(props.candidateData.profile.resumes[0]);
    }
  }, [props.candidateData])
  
  return (
    <div className={`modal-container ${styles['container']}`}>
      <div className={`modal-content ${styles.content}`} data-loaded={!!jobData}>

        <button className='close-btn' onClick={() => {searchParams.delete('applyByProxy'); setQuery(searchParams)}}>
          <SVGIcon id='close' color='accent-grey'/>
        </button>

        <div className={styles.msgWrap}>
          <div>
            <p className='eyebrow'>Apply. Fast.</p>
            <h2>Your Dream Career Starts With JobSnob</h2>
          </div>

          <div className='icon60 icon60--black' data-desktop>
            <SVGIcon id='chevron' color='white' rotate={-90} />
          </div>
        </div>

        <div className={styles.formWrap}>
          <form ref={formRef} onSubmit={onSubmit}>
            <p className='eyebrow'>Applying to &quot;{jobData?.title}&quot;</p>
            {
              !!error && (!!error.response.job || !!error.response.candidate) &&
              <Modal show={!!error} onCloseBtn={() => setError(undefined)}>
                <p className='eyebrow error' style={{width: '100%', textAlign: 'center', fontSize: '22px'}}>ERROR!</p>
                <p>{error.response.job || error.response.candidate}</p>
              </Modal>
            }
            <Input label='First Name*'
              forceError={!!error}
              errorMessage={error ? error.response.first_name : undefined }
              attributes={{ type: 'text', id: 'first_name', disabled: !!props.candidateData,
                required: true, defaultValue: props.candidateData.profile.first_name }} 
            />

            <Input label='Last Name*'
              forceError={!!error}
              errorMessage={error ? error.response.last_name : undefined }
              attributes={{ type: 'text', id: 'last_name', disabled: !!props.candidateData,
                required: true, defaultValue: props.candidateData.profile.last_name }} 
            />

            <Input label='Email*'
              forceError={!!error}
              errorMessage={error ? error.response.email : undefined }
              validatorFns={[emailValidator]}
              attributes={{ type: 'email', id: 'email', required: true,
                defaultValue: props.candidateData.email }} 
            />

            <Input label='Phone*'
              validatorFns={[phoneValidator]}
              forceError={!!error}
              errorMessage={error ? error.response.phone_number : 'not a valid phone number'}
              attributes={{ type: 'tel', id: 'phone_number', required: true, autoComplete: 'tel', 
                          defaultValue: props.candidateData.profile.phone_number || undefined }}
            />

            <ResumeInput error={error} notCandidate={true} onResumeUploaded={() => {
              formRef.current?.dispatchEvent(new Event('change', {bubbles: true}));
            }} />

            <CoverLetterInput />

            <div className={styles.btnWrap}>
              <button type='submit' className='btn btn--dt-wide' disabled={!formState?.valid}>Apply</button>
            </div>
            
          </form>
        </div>

      </div>
    </div>
  )
};

export default RecruiterApplyModal;
