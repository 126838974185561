import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../lib/app-routes';
import SoloNLIForm from '../SoloNLIForm/SoloNLIForm';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './EmailSent.module.scss';

interface EmailSentProps {
  eyebrow: string,
  notReceivedFn?: () => void
}

const EmailSent = (props: EmailSentProps): JSX.Element => {
  return (
    <SoloNLIForm title='Sent!' eyebrow={props.eyebrow} contentWrapClass='bg1-dark' formClass={styles.form}>
      <p>Check your email for the <span className='bold'>next step</span>.</p>
      <div className='icon60 icon60--border-black'>
        <SVGIcon id='email' />
      </div>
      <Link to={ROUTES.login.path} className={styles.link1}>Back to login?</Link>
      <>
        {
          props.notReceivedFn &&
          <a onClick={props.notReceivedFn} className={styles.link2}>i did not receive an email</a>
        }
      </>
    </SoloNLIForm>
  )
};

export default EmailSent;