import React from 'react';
import { UserRole } from '../../lib/types';
import { OnboardingStep } from '../../pages/Account/Onboarding/types';
import ErrorBoundary from '../ErrorBoundary';
import SVGIcon from '../SVGIcon/SVGIcon';
import styles from './OnboardingHeader.module.scss';

const OnboardingHeader = (props: {step: OnboardingStep, role: UserRole}): JSX.Element => {
  
  return (
    <ErrorBoundary>
    <div className={styles['header']}>
      <div className={styles.wrap}>

        <div className={styles.line} data-step={props.step}></div>

        <div className={styles.step1}>
          <div className={styles.iconWrap}>
            <div className={styles.dot}></div>
          </div>
          {
            props.step === 'complete-profile'
            ? <h1 data-step={props.step}>Complete<br />Profile</h1>
            : <div data-step={props.step}>Complete<br />Profile</div>
          }
        </div>

        <div className={styles.step2}>
          <div className={styles.iconWrap} data-step={props.step}>
            <SVGIcon id='checkmark' />
          </div>
          {
            props.role === 'employer' &&
            <>
              {
                props.step === 'submit-job-post'
                ? <h1 data-step={props.step}>Submit Job<br />Post</h1>
                : <div data-step={props.step}>Submit Job<br />Post</div>
              }
            </>
          }

          {
            props.role === 'candidate' &&
            <>
              {
                props.step === 'apply-to-job'
                ? <h1 data-step={props.step}>Apply to<br />Job</h1>
                : <div data-step={props.step}>Apply to<br />Job</div>
              }
            </>
          }
        </div>

      </div>

    </div>

    <div>
      <h4>Please provide the following information to proceed.</h4>
    </div>
    </ErrorBoundary>
  )
};

export default OnboardingHeader;