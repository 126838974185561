import React from 'react';
import styles from './SnobTip.module.scss';

const SnobTip = (): JSX.Element => {

  return (
    <div className={styles['container']}>
      <div className='eyebrow-hl-wrap'>
        <p className='eyebrow'>Snob Tip</p>
        <h3 className='quote'>“For highly skilled and revenue-generating positions, concierge recruiting is recommended.”</h3>
      </div>
      <a className='btn btn--dusty-pink btn--border' 
        href='mailto: info@jobsnob.net' target='_blank' rel='noreferrer'>
        Contact Us
      </a>
    </div>
  )
};

export default SnobTip;