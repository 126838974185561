import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { useFormState, useSubject } from '../../../lib/hooks';
import UserService from '../../../lib/services/user.service';
import { ErrorResponse, FormValidityState, UserData, UserUpdateData } from '../../../lib/types';
import { Helmet } from 'react-helmet';
import OnboardingHeader from '../../../components/OnboardingHeader/OnboardingHeader';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../lib/app-routes';
import styles from '../Profile/Profile.module.scss';
import EmployerCompleteProfile from './EmployerCompleteProfile';
import CandidateCompleteProfile from './CandidateCompleteProfile';
import ResumeService from '../../../lib/services/resume.service';
import { map } from 'rxjs';
import GMapsService from '../../../lib/services/gmaps.service';

const CompleteProfile = (props: {userData: UserData}): JSX.Element => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const formState = useFormState<FormValidityState>(formRef);
  const [profileError, setProfileError] = useState<ErrorResponse | null>(null);
  const [resumeError, setResumeError] = useState<ErrorResponse | null>(null);
  const [imgError, setImgError] = useState<ErrorResponse | null>(null);
  const [roleLabel, setRoleLabel] = useState<string>();
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const [imageUploading, setImageUploading] = useState(false);
  const [resumeUploading, setResumeUploading] = useState(false);
  const [latitude, setLatitude] = useState<string | number>();
  const [longitude, setLongitude] = useState<string | number>();

  useEffect(() => {
    if (props.userData) {
      setRoleLabel(props.userData.role[0].toUpperCase() + props.userData.role.substring(1));
      setProfileError(null);
      if (!props.userData.profile.latitude && props.userData.profile.zip_code && props.userData.profile.zip_code !== '') {
        GMapsService.getByRegion(props.userData.profile.zip_code).subscribe({
          next: dta => {
            if (dta.predictions.length && dta.predictions[0].place_id) {
              GMapsService.getDetails(dta.predictions[0].place_id, ['geometry'])
                .pipe(map(obj => obj.geometry?.location))
                .subscribe({
                  next: (loc) => {
                    setLatitude(parseFloat(loc?.lat().toFixed(9) as string));
                    setLongitude(parseFloat(loc?.lng().toFixed(9) as string));
                  }
                }
              )
            }
          }
        })
      }
    }
  }, [props.userData])

  const updateProfile = (e: FormEvent) => {
    e.preventDefault();
    if(profileError) setProfileError(null);
    //
    const data: UserUpdateData = { 
      company: {},
      profile: {}
    }
    // add value to data
    for (const key in formState.values) {
      if (Object.prototype.hasOwnProperty.call(formState.values, key)) {
        if (formState.values[key] !== '' && 
            formState.values[key] !== 'default' &&
            !key.startsWith('resume-item') &&
            key !== 'avatar_image_file' &&
            key !== 'resume_file' &&
            key !== 'email' &&
            key !== 'day' &&
            key !== 'month' &&
            key !== 'year'
        ) {
          const keySplit = key.split('.');
          (data[keySplit[0] as 'profile' | 'company' ] as {[key: string]: string})[keySplit[1]] = formState.values[key];
        }
      }
    }
    if (latitude) (data.profile as {[key: string]: string | number}).latitude = latitude;
    if (longitude) (data.profile as {[key: string]: string | number}).longitude = longitude;
    if (props.userData.role === 'employer' && !data.company?.state) (data.company as {[key: string]: string | number}).state = '';
    if (props.userData.profile.category_other && !(data.profile?.categories as string[]).includes('other')) 
      (data.profile as {[key: string]: string | number}).category_other = '';
    //
    UserService.updateUser(data).subscribe({
      next: () => {
        if (props.userData.role === 'employer') {
          navigate(ROUTES.onboarding.path.replace(':step', 'submit-job-post'));
        } else if (props.userData.onboarding_redirect) {
          navigate(ROUTES.onboarding.path.replace(':step', 'apply-to-job'));
        } else {
          navigate(ROUTES.jobboard.path);
        }
      },
      error: err => setProfileError(err)
    });
  }

  const onUploadResume = (e: ChangeEvent<HTMLInputElement> ) => {
    if(resumeError) setResumeError(null);
    setDisabled(true);
    setResumeUploading(true);
    ResumeService.uploadResume((e.target.files as FileList)[0])
      .subscribe({
        error: err => {setResumeError(err); setResumeUploading(false);},
        complete: () => {setDisabled(false); setResumeUploading(false);}
      });
  }

  const onDeleteResume = (uuid: string) => {
    if(resumeError) setResumeError(null);
    setDisabled(true);
    ResumeService.deleteResume(uuid).subscribe({
      error: err => setResumeError(err),
      complete: () => setDisabled(false)
    });
  }

  const onUploadImage = (e: ChangeEvent<HTMLInputElement>) => {
    if(imgError) setImgError(null);
    setImageUploading(true);
    UserService.updateAvatar({file: (e.target.files as FileList)[0], uuid: props.userData.profile.uuid as string })
      .subscribe({
        error: err => {setImgError(err); setImageUploading(false);},
        complete: () => setImageUploading(false)
      });
  }
  
  return (
    <ErrorBoundary>
      <Helmet>
        {
          roleLabel &&
          <title>JobSnob: {roleLabel} Onboarding - Complete Profile</title>
        }
      </Helmet>
      <div className={`content-wrap ${styles['content-wrap']} bg-account`}>
        <OnboardingHeader step='complete-profile' role={props.userData.role} />

        <form ref={formRef} onSubmit={updateProfile}>
          {
            props.userData &&
            <>
              {
                props.userData.role === 'candidate' &&
                <CandidateCompleteProfile { ...{
                  userData: props.userData,
                  profileError,
                  resumeError,
                  imgError,
                  onUploadResume,
                  onDeleteResume,
                  onUploadImage,
                  imageUploading,
                  resumeUploading,
                  formState
                }} />
              }

              { 
                props.userData.role === 'employer' &&
                <EmployerCompleteProfile { ...{
                  userData: props.userData,
                  profileError,
                  formState
                }} />
              }
            </>
          }
          <div className={styles.cta}>
            <button type='submit' className='btn btn--dt-wide' disabled={disabled || !formState?.valid}>Save &amp; Continue</button>
          </div>
        </form>
      </div>
    </ErrorBoundary>
  )
};

export default CompleteProfile;
